import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {HomeCarouselModel} from '../../models/home-carousel.model';
import {CustomPageModel} from '../../models';
import {convert} from 'url-slug';
import {Router} from '@angular/router';
import {CustomPagesService} from '../../service';

@Component({
  selector: 'app-carousel-wrapper',
  templateUrl: './carousel-wrapper.component.html',
  styleUrls: ['./carousel-wrapper.component.scss']
})
export class CarouselWrapperComponent implements OnInit, AfterViewInit {

  @Input() carouselItems: HomeCarouselModel[] = [];
  index = 8;
  speed = 3000;
  infinite = true;
  direction = 'right';
  activeIndex: number = 0;
  loaded: boolean = false;
  highlightedCustomPage: CustomPageModel;

  constructor(private router: Router,  private customPagesService: CustomPagesService) {
    this.fetchCustomPages();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loaded = true;
    }, 750);
  }

  getCarouselItemBg(item) {
    return {
      'background': `url('${item.imageUrl}') center center no-repeat`,
      'background-size': 'cover'
    };
  }


  indexChanged(index) {
    this.activeIndex = index;
  }

  fetchCustomPages()  {

    this.customPagesService.getCustomPages().subscribe((customPages: CustomPageModel[])=> {

      if (customPages && customPages.length) {
        if (customPages[0].active){
          this.highlightedCustomPage = customPages[0];
        }
      }
    });
  }
  openHighlightedCustomPage(){
    this.router.navigateByUrl(`pages/${convert(this.highlightedCustomPage.title)}`);
  }

  getHighlightedCustomPageBackground() {
    return {
      background: `url(${this.highlightedCustomPage.imageUrl})`,
    };
  }

}
