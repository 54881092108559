import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent implements OnInit {

  @Input() submitButtonTranslateKey: string;
  @Input() disabled: boolean;
  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


  getSubmitButtonTranslateKey(){
    return this.submitButtonTranslateKey ? this.submitButtonTranslateKey : 'COMMON.SAVE';
  }

}
