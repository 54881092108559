import * as _ from "underscore";
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {DataListModel} from "..";
import {Data} from "@angular/router";

@Injectable()
export class CrudUtilities {


  addNewItemToList(listSubject: BehaviorSubject<DataListModel>, newItem: any) {
    let list = listSubject.value ? {...listSubject.value} : {items: [], totalPages: 1, totalElements: 1};
    list.items.push(newItem);
    listSubject.next(list);
  }

  editListItem(listSubject: BehaviorSubject<DataListModel>, editedItem: any) {
    const list = {...listSubject.value};
    _.extend(_.findWhere(list.items, {
      id: editedItem.id
    }), editedItem);

    listSubject.next(list);
  }

  deleteListItem(listSubject: BehaviorSubject<DataListModel>, editedItem: any) {
    let list = {...listSubject.value};
    list.items = _.without(listSubject.value.items, _.findWhere(listSubject.value.items, {
      id: editedItem.id
    }));
    listSubject.next(list);
  }


}
