import {Injectable} from '@angular/core';
import {ReplaySubject} from "rxjs";

@Injectable()
export class StateService {

  private sideMenuStateSubject = new ReplaySubject<boolean>(1);
  public sideMenuState = this.sideMenuStateSubject.asObservable();

  private navigationLoaderStateSubject = new ReplaySubject<boolean>(1);
  public navigationLoaderState = this.navigationLoaderStateSubject.asObservable();

  constructor() {
  }

  setSelectedSideMenuState(sideMenuState) {
    this.sideMenuStateSubject.next(sideMenuState);
  }

  setNavigationLoaderState(state) {
    this.navigationLoaderStateSubject.next(state);
  }


}
