import {RoomAttributeModel} from "./roomAttributeModel";
import {MapDimensionsModel} from "./map-dimensions.model";

export class RoomModel {
  id?: number;
  name?: string;
  type?: string;
  safeCode?: string;
  note?: string;
  numberOfBeds?: number;
  numberOfExtraBeds?: number;
  sequence?: number;
  phone?: string;
  price?: number;
  weekendExtraCharge?: number;
  attributes?: RoomAttributeModel[];
  mapDimensions: MapDimensionsModel;
}
