import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StateService, AuthService, CustomPagesService} from '../../service';
import {TranslateService} from '@ngx-translate/core';
import {CustomPageModel, UserModel} from '../../models';
import {convert} from 'url-slug';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  isSidebarOpen: boolean = false;
  appToken: string;
  menuConfig: {}[];

  private MenuItemsBeforePages = [
    {key: 'MENU.MENU_1', route: 'about'},
    {key: 'MENU.MENU_2', route: 'references'},
  ];

  private MenuItemsAfterPage = [
    {key: 'MENU.MENU_3', route: 'services'},
    {key: 'MENU.MENU_4', route: 'partners'},
    {key: 'MENU.MENU_5', route: 'contact'}
  ];


  constructor(private router: Router,
              private userService: AuthService,
              private translate: TranslateService,
              private stateService: StateService,
              private customPagesService: CustomPagesService) {

    this.stateService.sideMenuState.subscribe((state) => {
      this.isSidebarOpen = state;
    });

    this.fetchCustomPages();

    this.configureMenu();

  }

  ngOnInit() {

    this.stateService.setSelectedSideMenuState(false);
    this.userService.token.subscribe(
      (token) => {
        this.appToken = token;
      }
    );
  }


  toggleSideBar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.stateService.setSelectedSideMenuState(this.isSidebarOpen);
  }

  openPage(menu) {
    this.router.navigateByUrl(menu.route);
  }

  configureMenu() {
    this.menuConfig = [
      {key: 'MENU.MENU_1', route: 'about'},
      {key: 'MENU.MENU_2', route: 'references'},
      {key: 'MENU.MENU_3', route: 'services'},
      {key: 'MENU.MENU_4', route: 'partners'},
      {key: 'MENU.MENU_5', route: 'contact'}
    ];
  }

  fetchCustomPages(){

    this.customPagesService.getCustomPages().subscribe((customPages: CustomPageModel[])=> {
      const customPageMenuItems = customPages
        .filter(item=> item.active)
        .map(item=> ({key: item.id.toString(), route: `pages/${convert(item.title)}`, label: item.title}));


      if (customPageMenuItems.length) {
        this.menuConfig = [
          ...this.MenuItemsBeforePages,
          ...customPageMenuItems,
          ...this.MenuItemsAfterPage
        ];
      }

    })
  }
}
