import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ApplicationsService} from '../service';
import {Observable} from 'rxjs';

@Injectable()
export class ApplicationsResolver implements Resolve<any> {
  constructor(private applicationsService: ApplicationsService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return this.applicationsService.getApplications();

  }
}
