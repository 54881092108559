import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {RouterModule} from '@angular/router';
import {ArticlesResolver, HomeCarouselItemsResolver, SharedModule} from '../shared';
import {ReferencesComponent} from './references/references.component';
import {ServicesComponent} from './services/services.component';
import {ContactComponent} from './contact/contact.component';
import {PartnersComponent} from './partners/partners.component';
import {ReferenceComponent} from './reference/reference.component';
import {NgMasonryGridModule} from 'ng-masonry-grid';
import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {EmbedVideo} from 'ngx-embed-video/dist';
import {NgxResponsiveEmbedModule} from 'ngx-responsive-embed';
import {AngularSvgIconModule} from 'angular-svg-icon';
import 'hammerjs';
import {ArticlePreviewComponent} from './article-preview/article-preview.component';
import {AboutPageComponent} from './about-page/about-page.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {AdminComponent} from './admin/admin.component';
import {ArticleManagerPage} from './admin/components/article-manager/article-manager-page.component';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {NgxHmCarouselModule} from 'ngx-hm-carousel';
import {HomeCarouselManagerPage} from './admin/components/home-carousel-manager/home-carousel-manager-page.component';
import {ReferenceManagerPageComponent} from './admin/components/reference-manager-page/reference-manager-page.component';
import {ReferencesResolver} from '../shared/resolvers/references-resolver.service';
import { AuthComponent } from './auth/auth.component';
import {PartnerManagerPage} from './admin/components/partner-manager/partner-manager-page.component';
import {PartnerItemsResolver} from '../shared/resolvers/partner-items-resolver.service';
import { ArticlePageComponent } from './article-page/article-page.component';
import { ArticlesComponent } from './articles/articles.component';
import {ArticleResolver} from '../shared/resolvers/article-resolver.service';
import {ApplicationManagerPage} from './admin/components/applications-manager/application-manager-page.component';
import {ApplicationsResolver} from '../shared/resolvers/applications-resolver.service';
import {ApplicationPageComponent} from './application-page/application-page.component';
import {ApplicationPreviewComponent} from './application-preview/application-preview.component';
import {ApplicationsComponent} from './applications/applications.component';
import {ApplicationResolver} from '../shared/resolvers/application-resolver.service';
import { ApplicationsIndicatorComponent } from './applications-indicator/applications-indicator.component';
import {CustomPagesManagerPageComponent} from './admin/components/custom-pages-manager/custom-pages-manager-page.component';
import {CustomPagesResolver} from '../shared/resolvers/custom-pages-resolver.service';
import {CustomPageComponent} from './custom-page/custom-page.component';

const homeRouting: ModuleWithProviders = RouterModule.forChild([
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'about',
    component: AboutPageComponent
  },
  {
    path: 'references',
    component: ReferencesComponent
  },
  {
    path: 'pages/:id',
    component: CustomPageComponent
  },
  {
    path: 'reference/:id',
    component: ReferenceComponent
  },
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'partners',
    component: PartnersComponent
  },
  {
    path: 'article/:id',
    component: ArticlePageComponent,
    resolve: {
      article: ArticleResolver
    }
  },
  {
    path: 'articles',
    component: ArticlesComponent,
    resolve: {
      articlesResolve: ArticlesResolver
    }
  },
  {
    path: 'application/:id',
    component: ApplicationPageComponent,
    resolve: {
      application: ApplicationResolver
    }
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    resolve: {
      applicationsResolve: ApplicationsResolver
    }
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'article-manager',
        component: ArticleManagerPage,
        resolve: {
          articlesResolve: ArticlesResolver
        }
      },
      {
        path: 'home-carousel-manager',
        component: HomeCarouselManagerPage,
        resolve: {
          homeCarouselItemsResolve: HomeCarouselItemsResolver
        }
      },
      {
        path: 'reference-manager',
        component: ReferenceManagerPageComponent,
        resolve: {
          referencesResolve: ReferencesResolver
        }
      },
      {
        path: 'partner-manager',
        component: PartnerManagerPage,
        resolve: {
          partnersResolve: PartnerItemsResolver
        }
      },
      {
        path: 'application-manager',
        component: ApplicationManagerPage,
        resolve: {
          applicationsResolve: ApplicationsResolver
        }
      },
      {
        path: 'custom-pages-manager',
        component: CustomPagesManagerPageComponent,
        resolve: {
          customPagesResolve: CustomPagesResolver
        }
      },
    ]
  }
]);

@NgModule({
  declarations: [
    HomeComponent,
    ReferencesComponent,
    ServicesComponent,
    ContactComponent,
    PartnersComponent,
    ReferenceComponent,
    ArticlePreviewComponent,
    AboutPageComponent,
    AdminComponent,
    ArticleManagerPage,
    ApplicationManagerPage,
    HomeCarouselManagerPage,
    ReferenceManagerPageComponent,
    PartnerManagerPage,
    AuthComponent,
    ArticlePageComponent,
    ArticlesComponent,
    ApplicationPageComponent,
    ApplicationPreviewComponent,
    ApplicationsComponent,
    ApplicationsIndicatorComponent,
    CustomPagesManagerPageComponent,
    CustomPageComponent,
  ],
  imports: [
    homeRouting,
    SharedModule,
    CommonModule,
    NgMasonryGridModule,
    GalleryModule.withConfig({loadingMode: 'indeterminate'}),
    LightboxModule.withConfig({}),
    EmbedVideo,
    AngularSvgIconModule,
    NgxResponsiveEmbedModule,
    CKEditorModule,
    AngularFireStorageModule,
    NgxHmCarouselModule
  ]
})
export class HomeModule {
}
