import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {RoomContainerModel, RoomContainersModel} from "../models";
import {ApiService} from "./api.service";
import {CrudUtilities} from "../util/crud-utilities";

@Injectable()
export class RoomContainerService {


  public roomContainersSubject = new BehaviorSubject<RoomContainersModel>(new RoomContainersModel());
  public roomContainers = this.roomContainersSubject.asObservable();

  
  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {

  }


  setRoomList(roomContainers: RoomContainersModel) {
    this.roomContainersSubject.next(roomContainers);
  }


  createRoomContainer(roomContainer: RoomContainerModel) {
    this.crudUtils.addNewItemToList(this.roomContainersSubject, roomContainer);

    // return this.apiService.post('hotel/room-container', roomContainer).subscribe((response) => {
    //   this.crudUtils.addNewItemToList(this.roomContainersSubject, response);
    // });
  }

  editRoomContainer(roomContainer: RoomContainerModel) {
    this.crudUtils.editListItem(this.roomContainersSubject, roomContainer);
    // return this.apiService
    //   .patch(`hotel/room-container/${roomContainer.id}`, roomContainer).subscribe((response) => {
    //     this.crudUtils.editListItem(this.roomContainersSubject, response);
    //   });
  }


  deleteRoomContainer(roomContainer, dialogRef) {
    return this.apiService.delete(`hotel/roomContainers/${roomContainer.id}`).subscribe(() => {
      this.crudUtils.deleteListItem(this.roomContainersSubject, roomContainer);
      // dialogRef.close();
    })
  }


  


}
