import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ArticlesService} from '../service';
import {Observable} from 'rxjs';

@Injectable()
export class ArticlesResolver implements Resolve<any> {
  constructor(private articlesService: ArticlesService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return this.articlesService.getArticles();

  }
}
