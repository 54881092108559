import {StreetTypeModel} from "./street-type.model";
import {CountryModel} from "./country.model";

export class GuestModel {
  id: string;
  name: string;
  zipCode: string;
  city: string;
  district?: string;
  streetName: string;
  streetType: StreetTypeModel;
  streetNumber: string;
  building?: string;
  stairway?: string;
  level?: string;
  door?: string;
  address?: string;
  country: CountryModel;
  email?: string;
  phone?: string;
  mobile?: string;
  mothersName?: string;
  taxNumber?: string;
  accountNumber?: string;

}
