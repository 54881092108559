import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CUSTOM_MAP_STYLE} from '../../util/map-style';

@Component({
  selector: 'app-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss']
})
export class AddressMapComponent implements OnInit {

  @ViewChild('search')
  public searchElementRef: ElementRef;

  zoom = 15;
  mapCenterLatitude = 47.676412;
  mapCenterLongitude = 17.540130;
  bounds: any;
  map: any;
  customGoogleMapStyle: any;

  constructor() {

    this.customGoogleMapStyle = CUSTOM_MAP_STYLE;

  }

  ngOnInit() {


  }


  onMapReady(map) {
    this.map = map;
    this.map.styles = this.customGoogleMapStyle;
    this.setBounds();

  }

  setBounds() {

    // this.bounds = new google.maps.LatLngBounds();
    //
    // _.forEach(this.projects, (projectItem: ProjectItem) => {
    //   debugger;
    //   if (projectItem.longitude && projectItem.latitude) {
    //     const latLng = new google.maps.LatLng(projectItem.longitude, projectItem.latitude);
    //     this.bounds.extend(latLng);
    //   }
    // });
    //
    // this.map.fitBounds(this.bounds);
    // this.map.panToBounds(this.bounds);
  }


}
