import {GalleryImageModel} from './gallery-image.model';

export class ReferenceModel {
  id?: number;
  title?: string;
  type?: string;
  summary?: string;
  body?: string;
  imageUrl?: string;
  imageUrlFilePath?: string;
  galleryImages?: GalleryImageModel[] = [];
  createdAt?: number;
  updatedAt?: number;
  topic?: string;
}
