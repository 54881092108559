import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SmartTabConfigInterface} from "../../interface";
import {Router} from "@angular/router";

@Component({
  selector: 'app-smart-tabs',
  templateUrl: './smart-tabs.component.html',
  styleUrls: ['./smart-tabs.component.scss']
})
export class SmartTabsComponent implements OnInit {

  @Input() tabsConfig: SmartTabConfigInterface[];
  @Output() tabChanged = new EventEmitter();

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  tabClicked(tab) {
    console.log("tabClicked", tab);
    this.router.navigateByUrl(tab.path);
  }

}
