import {Component, OnInit} from '@angular/core';
import {ArticleModel} from '../../shared/models/article.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss']
})
export class ArticlePageComponent implements OnInit {

  article: ArticleModel;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.route.data.subscribe(
      (data: { article: ArticleModel }) => {
        this.article = data.article;
        console.log('this.article', this.article);

      }
    );
  }

  openURL = () => {
    let win = window.open(this.article.link, '_blank');
    win.focus();
  };


}
