import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {CountryModel} from "../../models";
import {map, startWith} from "rxjs/operators";
import {AddressUtils} from "../../util/address-utils";
import {CommonEntitiesService} from "../../service/common-entities.service";

@Component({
  selector: 'app-county-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
  @Input() formControl: FormControl;
  options: CountryModel[] = [];
  filteredOptions: Observable<CountryModel[]>;

  constructor(private addressUtils: AddressUtils, private commonEntitiesService: CommonEntitiesService) {

    this.commonEntitiesService.getCountries().subscribe((countries: CountryModel[]) => {
      this.options = countries;
    })

  }


  ngOnInit() {
    this.filteredOptions = this.formControl.valueChanges
      .pipe(
        startWith<string | CountryModel>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
  }

  displayFn(country?: CountryModel): string | undefined {
    return country ? country.name : undefined;
  }

  private _filter(name: string): CountryModel[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

}
