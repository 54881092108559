import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-html-editor-wrapper',
  templateUrl: './html-editor-wrapper.component.html',
  styleUrls: ['./html-editor-wrapper.component.scss']
})
export class HtmlEditorWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild('editorRef') editorRef;
  @ViewChild('content') displayedContentRef;
  @Input() value: string = '';
  @Input() editMode: boolean = false;
  @Input() showButtons: boolean = true;
  @Output() contentSaved = new EventEmitter();
  @Output() contentChanged = new EventEmitter();

  public Editor = DecoupledEditor;


  constructor() {
  }

  ngAfterViewInit() {
    this.displayedContentRef.nativeElement.innerHTML = this.value;
  }

  ngOnInit() {
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  setEditMode() {
    this.editMode = true;
  }


  saveAboutPageContent() {
    this.editMode = false;
    this.displayedContentRef.nativeElement.innerHTML = this.value;
    this.contentSaved.emit(this.value);
  }

  toggleToolbarVisibility(visible) {
    this.editorRef.elementRef.nativeElement.querySelector('.ck-toolbar').style.display = visible ? 'block' : 'none';
  }
}
