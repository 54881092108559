import {Injectable} from '@angular/core';

import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {JwtService} from './jwt.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './auth.service';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private jwtService: JwtService, public toastr: ToastrService, private translate: TranslateService, private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token: string = this.jwtService.getToken();

    if (token && (request.method === 'POST' || request.method === 'PATCH' || request.method === 'DELETE')) {
      let params = request.params.append('auth', token);
      request = request.clone({params: params});
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
    }

    request = request.clone({headers: request.headers.set('Accept', 'application/json')});

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {

        if (error.status === 0) {
          this.toastr.error(error.message, 'Disabled CORS');
        } else if (error.status === 401) {
          this.translate.get('MESSAGES.PERMISSION_DENIED').subscribe((translateValue: string) => {
            this.toastr.error(translateValue);
          });
          this.authService.purgeAuth();
        } else {
          this.translate.get('MESSAGES.' + error.error.error.message).subscribe((translateValue: string) => {
            this.toastr.error(translateValue);
          });
        }

        return throwError(error);
      }));
  }
}
