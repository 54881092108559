import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  deletableItemTitle: string = '';
  deleteItem: any;
  @Output() onDelete = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) deleteItem: any) {
    this.deletableItemTitle = deleteItem.title;
    this.deleteItem = deleteItem;


  }

  ngOnInit() {
  }

  delete() {
    this.onDelete.emit(this.deleteItem);
  }

  close() {
    this.dialogRef.close();
  }

}
