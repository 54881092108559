import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from './api.service';
import {CrudUtilities} from '../util/crud-utilities';
import {map} from 'rxjs/operators';
import {PartnerItemsModel} from '../models/partner-items.model';
import {PartnerModel} from '../models/partner.model';

@Injectable()
export class PartnerService {


  public partnerItemsSubject = new BehaviorSubject<PartnerItemsModel>(new PartnerItemsModel());
  public partnerItems = this.partnerItemsSubject.asObservable();

  public selectedPartnerItemSubject = new BehaviorSubject<PartnerModel>(new PartnerModel());
  public selectedPartnerItem = this.selectedPartnerItemSubject.asObservable();


  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {

  }

  setPartnerItems(partnerItems: PartnerItemsModel) {
    this.partnerItemsSubject.next(partnerItems);
  }

  createPartnerItem(partnerItem: PartnerModel) {
    return this.apiService.post('partners.json', partnerItem).subscribe((response) => {

      if (response.name) {
        partnerItem.id = response.name;
        this.selectedPartnerItemSubject.next({...partnerItem});
        this.crudUtils.addNewItemToList(this.partnerItemsSubject, partnerItem);
      }
    });
  }

  editPartnerItem(partnerItem: PartnerModel) {
    return this.apiService
      .patch(`partners/${partnerItem.id}.json`, partnerItem).subscribe((response) => {
        this.selectedPartnerItemSubject.next({...response});
        this.crudUtils.editListItem(this.partnerItemsSubject, response);
      });
  }

  deletePartnerItem(partnerItem, dialogRef) {
    return this.apiService.delete(`partners/${partnerItem.id}.json`).subscribe(() => {
      this.crudUtils.deleteListItem(this.partnerItemsSubject, partnerItem);
      dialogRef.close();

    });
  }

  getPartnerItems() {
    return this.apiService.get('partners.json').pipe(map((response) => {

      let list = [];
      for (let key in response) {
        response[key].id = key;
        list.push(response[key]);
      }
      return list;
    }));
  }


}
