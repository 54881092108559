import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-user-role-selector',
  templateUrl: './user-role-selector.component.html',
  styleUrls: ['./user-role-selector.component.scss']
})
export class UserRoleSelectorComponent implements OnInit {

  @Input() formControl: FormControl;
  selected: string;
  roles: string[] = ['admin', 'waiter'];

  constructor() {
  }


  ngOnInit() {
    this.selected = this.formControl.value;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1 === o2;
  }

}
