import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ApplicationsModel} from '../models/applications.model';
import {CrudUtilities} from '../util/crud-utilities';
import {ApplicationModel} from '../models/application.model';
import {map} from 'rxjs/operators';

@Injectable()
export class ApplicationsService {


  public applicationsSubject = new BehaviorSubject<ApplicationsModel>(new ApplicationsModel());
  public applications = this.applicationsSubject.asObservable();

  public selectedApplicationSubject = new BehaviorSubject<ApplicationModel>(new ApplicationModel());
  public selectedApplication = this.selectedApplicationSubject.asObservable();


  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {

  }

  setApplicationList(applications: ApplicationsModel) {
    this.applicationsSubject.next(applications);
  }

  createApplication(application: ApplicationModel) {
    return this.apiService.post('applications.json', application).subscribe((response) => {

      if (response.name) {
        application.id = response.name;
        this.selectedApplicationSubject.next({...application});
        this.crudUtils.addNewItemToList(this.applicationsSubject, application);
      }
    });
  }

  editApplication(application: ApplicationModel) {
    return this.apiService
      .patch(`applications/${application.id}.json`, application).subscribe((response) => {
        this.selectedApplicationSubject.next({...response});
        this.crudUtils.editListItem(this.applicationsSubject, response);
      });
  }

  deleteApplication(application, dialogRef) {
    return this.apiService.delete(`applications/${application.id}.json`).subscribe(() => {
      this.crudUtils.deleteListItem(this.applicationsSubject, application);
      dialogRef.close();

    });
  }

  getApplications() {
    return this.apiService.get('applications.json').pipe(map((response) => {

      console.log(' getApplications response', response);

      let list = [];
      for (let key in response) {
        response[key].id = key;
        list.push(response[key]);
      }
      return list;
    }));
  }

  getApplication(id): Observable<any> {
    return this.apiService.get('applications/' + id + '.json');
  }


}
