import {Component, OnInit} from '@angular/core';
import {SmartTableConfigInterface} from '../../../../shared/interface';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {CustomPagesModel} from '../../../../shared/models/custom-pages.model';
import {CustomPageModel} from '../../../../shared/models/custom-page.model';
import {CustomPagesService} from '../../../../shared/service/custom-pages.service';
import {CustomPageModalComponent} from '../../../../shared/modal/custom-page-modal/custom-page-modal.component';

@Component({
  selector: 'app-custom-pages-manager-page',
  templateUrl: './custom-pages-manager-page.component.html',
  styleUrls: ['./custom-pages-manager-page.component.scss']
})
export class CustomPagesManagerPageComponent implements OnInit {


  tableData: CustomPagesModel;
  selectedCustomPage: CustomPageModel;
  smartTableConfig: SmartTableConfigInterface;


  constructor(public dialog: MatDialog, private customPagesService: CustomPagesService, private activatedRoute: ActivatedRoute) {

    this.initSmartTable();
    this.customPagesService.customPages.subscribe((customPages: CustomPagesModel) => {
      this.tableData = customPages;
    });

    this.customPagesService.selectedCustomPage.subscribe((customPage: CustomPageModel) => {
      this.selectedCustomPage = customPage;
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      (data: { customPagesResolve: any }) => {
        this.tableData.items = data.customPagesResolve;
      }
    );
  }

  initSmartTable() {
    this.smartTableConfig = {
      columns: [
        {labelKey: 'COMMON.LOGO', dataKey: 'logoUrl', isImage: true},
        {labelKey: 'COMMON.COVER_IMAGE', dataKey: 'imageUrl', isImage: true},
        {labelKey: 'CUSTOM_PAGE.TITLE', dataKey: 'title', isImage: false},
      ],
      displayedColumns: ['logoUrl', 'imageUrl', 'title']
    };
  }


  openDialog(selectedCustomPageModel?: CustomPageModel) {

    const dialogRef = this.dialog.open(CustomPageModalComponent, {
      hasBackdrop: true,
      width: '60vw',
      data: selectedCustomPageModel ? selectedCustomPageModel : new CustomPageModel()
    });

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }


  deleteItem(event) {
    this.customPagesService.deleteCustomPage(event.item, event.dialogRef);
  }

}
