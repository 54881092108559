import {Injectable} from "@angular/core";

@Injectable()
export class AddressUtils {


  getCountries(){
    return [
      {
        "name": "Afganisztán",
        "code": "AFG"
      },
      {
        "name": "Åland",
        "code": "ALA"
      },
      {
        "name": "Albánia",
        "code": "ALB"
      },
      {
        "name": "Algéria",
        "code": "DZA"
      },
      {
        "name": "Amerikai Csendes-óceáni szigetek",
        "code": "UMI"
      },
      {
        "name": "Amerikai Egyesült Államok",
        "code": "USA"
      },
      {
        "name": "Amerikai Szamoa",
        "code": "ASM"
      },
      {
        "name": "Amerikai Virgin-szigetek",
        "code": "VIR"
      },
      {
        "name": "Andorra",
        "code": "AND"
      },
      {
        "name": "Angola",
        "code": "AGO"
      },
      {
        "name": "Anguilla",
        "code": "AIA"
      },
      {
        "name": "Antarktisz",
        "code": "ATA"
      },
      {
        "name": "Antigua és Barbuda",
        "code": "ATG"
      },
      {
        "name": "Argentína",
        "code": "ARG"
      },
      {
        "name": "Aruba",
        "code": "ABW"
      },
      {
        "name": "Ausztrália",
        "code": "AUS"
      },
      {
        "name": "Ausztria",
        "code": "AUT"
      },
      {
        "name": "Azerbajdzsán",
        "code": "AZE"
      },
      {
        "name": "Bahama-szigetek",
        "code": "BHS"
      },
      {
        "name": "Bahrein",
        "code": "BHR"
      },
      {
        "name": "Banglades",
        "code": "BGD"
      },
      {
        "name": "Barbados",
        "code": "BRB"
      },
      {
        "name": "Belgium",
        "code": "BEL"
      },
      {
        "name": "Belize",
        "code": "BLZ"
      },
      {
        "name": "Benin",
        "code": "BEN"
      },
      {
        "name": "Bermuda",
        "code": "BMU"
      },
      {
        "name": "Bhután",
        "code": "BTN"
      },
      {
        "name": "Bissau-Guinea",
        "code": "GNB"
      },
      {
        "name": "Bolívia",
        "code": "BOL"
      },
      {
        "name": "Bosznia-Hercegovina",
        "code": "BIH"
      },
      {
        "name": "Botswana",
        "code": "BWA"
      },
      {
        "name": "Bouvet-sziget",
        "code": "BVT"
      },
      {
        "name": "Brazília",
        "code": "BRA"
      },
      {
        "name": "Brit Indiai-óceáni Terület",
        "code": "IOT"
      },
      {
        "name": "Brit Virgin-szigetek",
        "code": "VGB"
      },
      {
        "name": "Brunei",
        "code": "BRN"
      },
      {
        "name": "Bulgária",
        "code": "BGR"
      },
      {
        "name": "Burkina Faso",
        "code": "BFA"
      },
      {
        "name": "Burundi",
        "code": "BDI"
      },
      {
        "name": "Chile",
        "code": "CHL"
      },
      {
        "name": "Ciprus",
        "code": "CYP"
      },
      {
        "name": "Comore-szigetek",
        "code": "COM"
      },
      {
        "name": "Cook-szigetek",
        "code": "COK"
      },
      {
        "name": "Costa Rica",
        "code": "CRI"
      },
      {
        "name": "Curaçao",
        "code": "CUW"
      },
      {
        "name": "Csád",
        "code": "TCD"
      },
      {
        "name": "Csehország",
        "code": "CZE"
      },
      {
        "name": "Dánia",
        "code": "DNK"
      },
      {
        "name": "Dél-afrikai Köztársaság",
        "code": "ZAF"
      },
      {
        "name": "Déli-Georgia és Déli-Sandwich-szigetek",
        "code": "SGS"
      },
      {
        "name": "Dél-Szudán",
        "code": "SSD"
      },
      {
        "name": "Dél-Korea (Koreai Köztársaság)",
        "code": "KOR"
      },
      {
        "name": "Dominikai Közösség",
        "code": "DMA"
      },
      {
        "name": "Dominikai Köztársaság",
        "code": "DOM"
      },
      {
        "name": "Dzsibuti",
        "code": "DJI"
      },
      {
        "name": "Ecuador",
        "code": "ECU"
      },
      {
        "name": "Egyenlítői-Guinea",
        "code": "GNQ"
      },
      {
        "name": "Egyesült Arab Emírségek",
        "code": "ARE"
      },
      {
        "name": "Egyesült Királyság",
        "code": "GBR"
      },
      {
        "name": "Egyiptom",
        "code": "EGY"
      },
      {
        "name": "Elefántcsontpart",
        "code": "CIV"
      },
      {
        "name": "Salvador",
        "code": "SLV"
      },
      {
        "name": "Eritrea",
        "code": "ERI"
      },
      {
        "name": "Északi-Mariana-szigetek",
        "code": "MNP"
      },
      {
        "name": "Észak-Korea (Koreai NDK)",
        "code": "PRK"
      },
      {
        "name": "Észtország",
        "code": "EST"
      },
      {
        "name": "Etiópia",
        "code": "ETH"
      },
      {
        "name": "Falkland-szigetek",
        "code": "FLK"
      },
      {
        "name": "Fehéroroszország",
        "code": "BLR"
      },
      {
        "name": "Feröer",
        "code": "FRO"
      },
      {
        "name": "Fidzsi",
        "code": "FJI"
      },
      {
        "name": "Finnország",
        "code": "FIN"
      },
      {
        "name": "Francia déli és antarktiszi területek",
        "code": "ATF"
      },
      {
        "name": "Francia Guyana Francia Guyana",
        "code": "GUF"
      },
      {
        "name": "Franciaország",
        "code": "FRA"
      },
      {
        "name": "Francia Polinézia",
        "code": "PYF"
      },
      {
        "name": "Fülöp-szigetek",
        "code": "PHL"
      },
      {
        "name": "Gabon",
        "code": "GAB"
      },
      {
        "name": "Gambia",
        "code": "GMB"
      },
      {
        "name": "Ghána",
        "code": "GHA"
      },
      {
        "name": "Gibraltár",
        "code": "GIB"
      },
      {
        "name": "Görögország",
        "code": "GRC"
      },
      {
        "name": "Grenada",
        "code": "GRD"
      },
      {
        "name": "Grönland",
        "code": "GRL"
      },
      {
        "name": "Grúzia",
        "code": "GEO"
      },
      {
        "name": "Guadeloupe",
        "code": "GLP"
      },
      {
        "name": "Guam",
        "code": "GUM"
      },
      {
        "name": "Guatemala",
        "code": "GTM"
      },
      {
        "name": "Guernsey Bailiffség",
        "code": "GGY"
      },
      {
        "name": "Guinea",
        "code": "GIN"
      },
      {
        "name": "Guyana",
        "code": "GUY"
      },
      {
        "name": "Haiti",
        "code": "HTI"
      },
      {
        "name": "Heard-sziget és McDonald-szigetek",
        "code": "HMD"
      },
      {
        "name": "Hollandia",
        "code": "NLD"
      },
      {
        "name": "Honduras",
        "code": "HND"
      },
      {
        "name": "Hongkong",
        "code": "HKG"
      },
      {
        "name": "Horvátország",
        "code": "HRV"
      },
      {
        "name": "India",
        "code": "IND"
      },
      {
        "name": "Indonézia",
        "code": "IDN"
      },
      {
        "name": "Irak",
        "code": "IRQ"
      },
      {
        "name": "Irán",
        "code": "IRN"
      },
      {
        "name": "Írország",
        "code": "IRL"
      },
      {
        "name": "Izland",
        "code": "ISL"
      },
      {
        "name": "Izrael",
        "code": "ISR"
      },
      {
        "name": "Jamaica",
        "code": "JAM"
      },
      {
        "name": "Japán",
        "code": "JPN"
      },
      {
        "name": "Jemen",
        "code": "YEM"
      },
      {
        "name": "Jersey Bailiffség",
        "code": "JEY"
      },
      {
        "name": "Jordánia",
        "code": "JOR"
      },
      {
        "name": "Kajmán-szigetek",
        "code": "CYM"
      },
      {
        "name": "Kambodzsa",
        "code": "KHM"
      },
      {
        "name": "Kamerun",
        "code": "CMR"
      },
      {
        "name": "Kanada",
        "code": "CAN"
      },
      {
        "name": "Karácsony-sziget",
        "code": "CXR"
      },
      {
        "name": "Karibi Hollandia ( Bonaire,  Saba,  Sint Eustatius)",
        "code": "BES"
      },
      {
        "name": "Katar",
        "code": "QAT"
      },
      {
        "name": "Kazahsztán",
        "code": "KAZ"
      },
      {
        "name": "Kelet-Timor",
        "code": "TLS"
      },
      {
        "name": "Kenya",
        "code": "KEN"
      },
      {
        "name": "Kína",
        "code": "CHN"
      },
      {
        "name": "Kirgizisztán",
        "code": "KGZ"
      },
      {
        "name": "Kiribati",
        "code": "KIR"
      },
      {
        "name": "Kókusz (Keeling)-szigetek",
        "code": "CCK"
      },
      {
        "name": "Kolumbia",
        "code": "COL"
      },
      {
        "name": "Kongói Demokratikus Köztársaság (Zaire)",
        "code": "COD"
      },
      {
        "name": "Kongói Köztársaság (Kongó)",
        "code": "COG"
      },
      {
        "name": "Koszovó",
        "code": "XKX"
      },
      {
        "name": "Közép-Afrika",
        "code": "CAF"
      },
      {
        "name": "Kuba",
        "code": "CUB"
      },
      {
        "name": "Kuvait",
        "code": "KWT"
      },
      {
        "name": "Laosz",
        "code": "LAO"
      },
      {
        "name": "Lengyelország",
        "code": "POL"
      },
      {
        "name": "Lesotho",
        "code": "LSO"
      },
      {
        "name": "Lettország",
        "code": "LVA"
      },
      {
        "name": "Libanon",
        "code": "LBN"
      },
      {
        "name": "Libéria",
        "code": "LBR"
      },
      {
        "name": "Líbia",
        "code": "LBY"
      },
      {
        "name": "Liechtenstein",
        "code": "LIE"
      },
      {
        "name": "Litvánia",
        "code": "LTU"
      },
      {
        "name": "Luxemburg",
        "code": "LUX"
      },
      {
        "name": "Macedónia",
        "code": "MKD"
      },
      {
        "name": "Madagaszkár",
        "code": "MDG"
      },
      {
        "name": "Magyarország",
        "code": "HUN"
      },
      {
        "name": "Makaó",
        "code": "MAC"
      },
      {
        "name": "Malajzia",
        "code": "MYS"
      },
      {
        "name": "Malawi",
        "code": "MWI"
      },
      {
        "name": "Maldív-szigetek",
        "code": "MDV"
      },
      {
        "name": "Mali",
        "code": "MLI"
      },
      {
        "name": "Málta",
        "code": "MLT"
      },
      {
        "name": "Man",
        "code": "IMN"
      },
      {
        "name": "Marokkó",
        "code": "MAR"
      },
      {
        "name": "Marshall-szigetek",
        "code": "MHL"
      },
      {
        "name": "Martinique",
        "code": "MTQ"
      },
      {
        "name": "Mauritánia",
        "code": "MRT"
      },
      {
        "name": "Mauritius",
        "code": "MUS"
      },
      {
        "name": "Mayotte",
        "code": "MYT"
      },
      {
        "name": "Mexikó",
        "code": "MEX"
      },
      {
        "name": "Mianmar",
        "code": "MMR"
      },
      {
        "name": "Mikronézia",
        "code": "FSM"
      },
      {
        "name": "Moldova",
        "code": "MDA"
      },
      {
        "name": "Monaco",
        "code": "MCO"
      },
      {
        "name": "Mongólia",
        "code": "MNG"
      },
      {
        "name": "Montenegró",
        "code": "MNE"
      },
      {
        "name": "Montserrat",
        "code": "MSR"
      },
      {
        "name": "Mozambik",
        "code": "MOZ"
      },
      {
        "name": "Namíbia",
        "code": "NAM"
      },
      {
        "name": "Nauru",
        "code": "NRU"
      },
      {
        "name": "Németország",
        "code": "DEU"
      },
      {
        "name": "Nepál",
        "code": "NPL"
      },
      {
        "name": "Nicaragua",
        "code": "NIC"
      },
      {
        "name": "Niger",
        "code": "NER"
      },
      {
        "name": "Nigéria",
        "code": "NGA"
      },
      {
        "name": "Niue",
        "code": "NIU"
      },
      {
        "name": "Norfolk-sziget",
        "code": "NFK"
      },
      {
        "name": "Norvégia",
        "code": "NOR"
      },
      {
        "name": "Nyugat-Szahara",
        "code": "ESH"
      },
      {
        "name": "Olaszország",
        "code": "ITA"
      },
      {
        "name": "Omán",
        "code": "OMN"
      },
      {
        "name": "Oroszország",
        "code": "RUS"
      },
      {
        "name": "Örményország",
        "code": "ARM"
      },
      {
        "name": "Pakisztán",
        "code": "PAK"
      },
      {
        "name": "Palau",
        "code": "PLW"
      },
      {
        "name": "Palesztina",
        "code": "PSE"
      },
      {
        "name": "Panama",
        "code": "PAN"
      },
      {
        "name": "Pápua Új-Guinea",
        "code": "PNG"
      },
      {
        "name": "Paraguay",
        "code": "PRY"
      },
      {
        "name": "Peru",
        "code": "PER"
      },
      {
        "name": "Pitcairn-szigetek",
        "code": "PCN"
      },
      {
        "name": "Portugália",
        "code": "PRT"
      },
      {
        "name": "Puerto Rico",
        "code": "PRI"
      },
      {
        "name": "Réunion",
        "code": "REU"
      },
      {
        "name": "Románia",
        "code": "ROU"
      },
      {
        "name": "Ruanda",
        "code": "RWA"
      },
      {
        "name": "Saint-Barthélemy",
        "code": "BLM"
      },
      {
        "name": "Saint Kitts és Nevis",
        "code": "KNA"
      },
      {
        "name": "Saint Lucia",
        "code": "LCA"
      },
      {
        "name": "Saint-Martin",
        "code": "MAF"
      },
      {
        "name": "Saint-Pierre és Miquelon",
        "code": "SPM"
      },
      {
        "name": "Saint Vincent és a Grenadine-szigetek",
        "code": "VCT"
      },
      {
        "name": "Salamon-szigetek",
        "code": "SLB"
      },
      {
        "name": "San Marino",
        "code": "SMR"
      },
      {
        "name": "São Tomé és Príncipe",
        "code": "STP"
      },
      {
        "name": "Seychelle-szigetek",
        "code": "SYC"
      },
      {
        "name": "Sierra Leone",
        "code": "SLE"
      },
      {
        "name": "Sint Maarten",
        "code": "SXM"
      },
      {
        "name": "Spanyolország",
        "code": "ESP"
      },
      {
        "name": "Spitzbergák és Jan Mayen-sziget",
        "code": "SJM"
      },
      {
        "name": "Srí Lanka",
        "code": "LKA"
      },
      {
        "name": "Suriname",
        "code": "SUR"
      },
      {
        "name": "Svájc",
        "code": "CHE"
      },
      {
        "name": "Svédország",
        "code": "SWE"
      },
      {
        "name": "Szamoa",
        "code": "WSM"
      },
      {
        "name": "Szaúd-Arábia",
        "code": "SAU"
      },
      {
        "name": "Szenegál",
        "code": "SEN"
      },
      {
        "name": "Szent Ilona",
        "code": "SHN"
      },
      {
        "name": "Szerbia",
        "code": "SRB"
      },
      {
        "name": "Szerbia és Montenegró",
        "code": "SCG"
      },
      {
        "name": "Szingapúr",
        "code": "SGP"
      },
      {
        "name": "Szíria",
        "code": "SYR"
      },
      {
        "name": "Szlovákia",
        "code": "SVK"
      },
      {
        "name": "Szlovénia",
        "code": "SVN"
      },
      {
        "name": "Szomália",
        "code": "SOM"
      },
      {
        "name": "Szudán",
        "code": "SDN"
      },
      {
        "name": "Szváziföld",
        "code": "SWZ"
      },
      {
        "name": "Tádzsikisztán",
        "code": "TJK"
      },
      {
        "name": "Tajvan",
        "code": "TWN"
      },
      {
        "name": "Tanzánia",
        "code": "TZA"
      },
      {
        "name": "Thaiföld",
        "code": "THA"
      },
      {
        "name": "Togo",
        "code": "TGO"
      },
      {
        "name": "Tokelau-szigetek",
        "code": "TKL"
      },
      {
        "name": "Tonga",
        "code": "TON"
      },
      {
        "name": "Törökország",
        "code": "TUR"
      },
      {
        "name": "Trinidad és Tobago",
        "code": "TTO"
      },
      {
        "name": "Tunézia",
        "code": "TUN"
      },
      {
        "name": "Turks- és Caicos-szigetek",
        "code": "TCA"
      },
      {
        "name": "Tuvalu",
        "code": "TUV"
      },
      {
        "name": "Türkmenisztán",
        "code": "TKM"
      },
      {
        "name": "Uganda",
        "code": "UGA"
      },
      {
        "name": "Új-Kaledónia",
        "code": "NCL"
      },
      {
        "name": "Új-Zéland",
        "code": "NZL"
      },
      {
        "name": "Ukrajna",
        "code": "UKR"
      },
      {
        "name": "Uruguay",
        "code": "URY"
      },
      {
        "name": "Üzbegisztán",
        "code": "UZB"
      },
      {
        "name": "Vanuatu",
        "code": "VUT"
      },
      {
        "name": "Vatikán",
        "code": "VAT"
      },
      {
        "name": "Venezuela",
        "code": "VEN"
      },
      {
        "name": "Vietnám",
        "code": "VNM"
      },
      {
        "name": "Wallis és Futuna",
        "code": "WLF"
      },
      {
        "name": "Zambia",
        "code": "ZMB"
      },
      {
        "name": "Zimbabwe",
        "code": "ZWE"
      },
      {
        "name": "Zöld-foki Köztársaság",
        "code": "CPV"
      }
    ]
  }

  getStreetTypes(){
    return [
      {
        "name": "akna",
        "code": "akna"
      },
      {
        "name": "akna-alsó",
        "code": "akna-alsó"
      },
      {
        "name": "akna-felső",
        "code": "akna-felső"
      },
      {
        "name": "alagút",
        "code": "alagút"
      },
      {
        "name": "alsórakpart",
        "code": "alsórakpart"
      },
      {
        "name": "arborétum",
        "code": "arborétum"
      },
      {
        "name": "autóút",
        "code": "autóút"
      },
      {
        "name": "barakképület",
        "code": "barakképület"
      },
      {
        "name": "barlang",
        "code": "barlang"
      },
      {
        "name": "bejáró",
        "code": "bejáró"
      },
      {
        "name": "bekötőút",
        "code": "bekötőút"
      },
      {
        "name": "bánya",
        "code": "bánya"
      },
      {
        "name": "bányatelep",
        "code": "bányatelep"
      },
      {
        "name": "bástya",
        "code": "bástya"
      },
      {
        "name": "bástyája",
        "code": "bástyája"
      },
      {
        "name": "csárda",
        "code": "csárda"
      },
      {
        "name": "csónakházak",
        "code": "csónakházak"
      },
      {
        "name": "domb",
        "code": "domb"
      },
      {
        "name": "dűlő",
        "code": "dűlő"
      },
      {
        "name": "dűlők",
        "code": "dűlők"
      },
      {
        "name": "dűlősor",
        "code": "dűlősor"
      },
      {
        "name": "dűlőterület",
        "code": "dűlőterület"
      },
      {
        "name": "dűlőút",
        "code": "dűlőút"
      },
      {
        "name": "egyetemváros",
        "code": "egyetemváros"
      },
      {
        "name": "egyéb",
        "code": "egyéb"
      },
      {
        "name": "elágazás",
        "code": "elágazás"
      },
      {
        "name": "emlékút",
        "code": "emlékút"
      },
      {
        "name": "erdészház",
        "code": "erdészház"
      },
      {
        "name": "erdészlak",
        "code": "erdészlak"
      },
      {
        "name": "erdő",
        "code": "erdő"
      },
      {
        "name": "erdősor",
        "code": "erdősor"
      },
      {
        "name": "fasor",
        "code": "fasor"
      },
      {
        "name": "fasora",
        "code": "fasora"
      },
      {
        "name": "felső",
        "code": "felső"
      },
      {
        "name": "forduló",
        "code": "forduló"
      },
      {
        "name": "főmérnökség",
        "code": "főmérnökség"
      },
      {
        "name": "főtér",
        "code": "főtér"
      },
      {
        "name": "főút",
        "code": "főút"
      },
      {
        "name": "föld",
        "code": "föld"
      },
      {
        "name": "gyár",
        "code": "gyár"
      },
      {
        "name": "gyártelep",
        "code": "gyártelep"
      },
      {
        "name": "gyárváros",
        "code": "gyárváros"
      },
      {
        "name": "gyümölcsös",
        "code": "gyümölcsös"
      },
      {
        "name": "gát",
        "code": "gát"
      },
      {
        "name": "gátsor",
        "code": "gátsor"
      },
      {
        "name": "gátőrház",
        "code": "gátőrház"
      },
      {
        "name": "határsor",
        "code": "határsor"
      },
      {
        "name": "határút",
        "code": "határút"
      },
      {
        "name": "hegy",
        "code": "hegy"
      },
      {
        "name": "hegyhát",
        "code": "hegyhát"
      },
      {
        "name": "hegyhát dűlő",
        "code": "hegyhát dűlő"
      },
      {
        "name": "hegyhát",
        "code": "hegyhát"
      },
      {
        "name": "köz",
        "code": "köz"
      },
      {
        "name": "hrsz",
        "code": "hrsz"
      },
      {
        "name": "hrsz.",
        "code": "hrsz."
      },
      {
        "name": "ház",
        "code": "ház"
      },
      {
        "name": "hídfő",
        "code": "hídfő"
      },
      {
        "name": "iskola",
        "code": "iskola"
      },
      {
        "name": "játszótér",
        "code": "játszótér"
      },
      {
        "name": "kapu",
        "code": "kapu"
      },
      {
        "name": "kastély",
        "code": "kastély"
      },
      {
        "name": "kert",
        "code": "kert"
      },
      {
        "name": "kertsor",
        "code": "kertsor"
      },
      {
        "name": "kerület",
        "code": "kerület"
      },
      {
        "name": "kilátó",
        "code": "kilátó"
      },
      {
        "name": "kioszk",
        "code": "kioszk"
      },
      {
        "name": "kocsiszín",
        "code": "kocsiszín"
      },
      {
        "name": "kolónia",
        "code": "kolónia"
      },
      {
        "name": "korzó",
        "code": "korzó"
      },
      {
        "name": "kultúrpark",
        "code": "kultúrpark"
      },
      {
        "name": "kunyhó",
        "code": "kunyhó"
      },
      {
        "name": "kör",
        "code": "kör"
      },
      {
        "name": "körtér",
        "code": "körtér"
      },
      {
        "name": "körvasútsor",
        "code": "körvasútsor"
      },
      {
        "name": "körzet",
        "code": "körzet"
      },
      {
        "name": "körönd",
        "code": "körönd"
      },
      {
        "name": "körút",
        "code": "körút"
      },
      {
        "name": "köz",
        "code": "köz"
      },
      {
        "name": "kút",
        "code": "kút"
      },
      {
        "name": "kültelek",
        "code": "kültelek"
      },
      {
        "name": "lakóház",
        "code": "lakóház"
      },
      {
        "name": "lakókert",
        "code": "lakókert"
      },
      {
        "name": "lakónegyed",
        "code": "lakónegyed"
      },
      {
        "name": "lakópark",
        "code": "lakópark"
      },
      {
        "name": "lakótelep",
        "code": "lakótelep"
      },
      {
        "name": "lejtő",
        "code": "lejtő"
      },
      {
        "name": "lejáró",
        "code": "lejáró"
      },
      {
        "name": "liget",
        "code": "liget"
      },
      {
        "name": "lépcső",
        "code": "lépcső"
      },
      {
        "name": "major",
        "code": "major"
      },
      {
        "name": "malom",
        "code": "malom"
      },
      {
        "name": "menedékház",
        "code": "menedékház"
      },
      {
        "name": "munkásszálló",
        "code": "munkásszálló"
      },
      {
        "name": "mélyút",
        "code": "mélyút"
      },
      {
        "name": "műút",
        "code": "műút"
      },
      {
        "name": "oldal",
        "code": "oldal"
      },
      {
        "name": "orom",
        "code": "orom"
      },
      {
        "name": "park",
        "code": "park"
      },
      {
        "name": "parkja",
        "code": "parkja"
      },
      {
        "name": "parkoló",
        "code": "parkoló"
      },
      {
        "name": "part",
        "code": "part"
      },
      {
        "name": "pavilon",
        "code": "pavilon"
      },
      {
        "name": "piac",
        "code": "piac"
      },
      {
        "name": "pihenő",
        "code": "pihenő"
      },
      {
        "name": "pince",
        "code": "pince"
      },
      {
        "name": "pincesor",
        "code": "pincesor"
      },
      {
        "name": "postafiók",
        "code": "postafiók"
      },
      {
        "name": "puszta",
        "code": "puszta"
      },
      {
        "name": "pálya",
        "code": "pálya"
      },
      {
        "name": "pályaudvar",
        "code": "pályaudvar"
      },
      {
        "name": "rakpart",
        "code": "rakpart"
      },
      {
        "name": "repülőtér",
        "code": "repülőtér"
      },
      {
        "name": "rész",
        "code": "rész"
      },
      {
        "name": "rét",
        "code": "rét"
      },
      {
        "name": "sarok",
        "code": "sarok"
      },
      {
        "name": "sor",
        "code": "sor"
      },
      {
        "name": "sora",
        "code": "sora"
      },
      {
        "name": "sportpálya",
        "code": "sportpálya"
      },
      {
        "name": "sporttelep",
        "code": "sporttelep"
      },
      {
        "name": "stadion",
        "code": "stadion"
      },
      {
        "name": "strandfürdő",
        "code": "strandfürdő"
      },
      {
        "name": "sugárút",
        "code": "sugárút"
      },
      {
        "name": "szer",
        "code": "szer"
      },
      {
        "name": "sziget",
        "code": "sziget"
      },
      {
        "name": "szivattyútelep",
        "code": "szivattyútelep"
      },
      {
        "name": "szállás",
        "code": "szállás"
      },
      {
        "name": "szállások",
        "code": "szállások"
      },
      {
        "name": "szél",
        "code": "szél"
      },
      {
        "name": "szőlő",
        "code": "szőlő"
      },
      {
        "name": "szőlőhegy",
        "code": "szőlőhegy"
      },
      {
        "name": "szőlők",
        "code": "szőlők"
      },
      {
        "name": "sánc",
        "code": "sánc"
      },
      {
        "name": "sávház",
        "code": "sávház"
      },
      {
        "name": "sétány",
        "code": "sétány"
      },
      {
        "name": "tag",
        "code": "tag"
      },
      {
        "name": "tanya",
        "code": "tanya"
      },
      {
        "name": "tanyák",
        "code": "tanyák"
      },
      {
        "name": "telep",
        "code": "telep"
      },
      {
        "name": "temető",
        "code": "temető"
      },
      {
        "name": "tere",
        "code": "tere"
      },
      {
        "name": "tető",
        "code": "tető"
      },
      {
        "name": "turistaház",
        "code": "turistaház"
      },
      {
        "name": "téli kikötő",
        "code": "téli kikötő"
      },
      {
        "name": "tér",
        "code": "tér"
      },
      {
        "name": "tömb",
        "code": "tömb"
      },
      {
        "name": "udvar",
        "code": "udvar"
      },
      {
        "name": "utak",
        "code": "utak"
      },
      {
        "name": "utca",
        "code": "utca"
      },
      {
        "name": "utcája",
        "code": "utcája"
      },
      {
        "name": "vadaskert",
        "code": "vadaskert"
      },
      {
        "name": "vadászház",
        "code": "vadászház"
      },
      {
        "name": "vasúti megálló",
        "code": "vasúti megálló"
      },
      {
        "name": "vasúti őrház",
        "code": "vasúti őrház"
      },
      {
        "name": "vasútsor",
        "code": "vasútsor"
      },
      {
        "name": "vasútállomás",
        "code": "vasútállomás"
      },
      {
        "name": "vezetőút",
        "code": "vezetőút"
      },
      {
        "name": "villasor",
        "code": "villasor"
      },
      {
        "name": "vágóhíd",
        "code": "vágóhíd"
      },
      {
        "name": "vár",
        "code": "vár"
      },
      {
        "name": "várköz",
        "code": "várköz"
      },
      {
        "name": "város",
        "code": "város"
      },
      {
        "name": "vízmű",
        "code": "vízmű"
      },
      {
        "name": "völgy",
        "code": "völgy"
      },
      {
        "name": "zsilip",
        "code": "zsilip"
      },
      {
        "name": "zug",
        "code": "zug"
      },
      {
        "name": "állat és növ.kert",
        "code": "állat és növ.kert"
      },
      {
        "name": "állomás",
        "code": "állomás"
      },
      {
        "name": "árnyék",
        "code": "árnyék"
      },
      {
        "name": "árok",
        "code": "árok"
      },
      {
        "name": "átjáró",
        "code": "átjáró"
      },
      {
        "name": "őrház",
        "code": "őrház"
      },
      {
        "name": "őrházak",
        "code": "őrházak"
      },
      {
        "name": "őrházlak",
        "code": "őrházlak"
      },
      {
        "name": "út",
        "code": "út"
      },
      {
        "name": "útja",
        "code": "útja"
      },
      {
        "name": "útőrház",
        "code": "útőrház"
      },
      {
        "name": "üdülő",
        "code": "üdülő"
      },
      {
        "name": "üdülő-part",
        "code": "üdülő-part"
      },
      {
        "name": "üdülő-sor",
        "code": "üdülő-sor"
      },
      {
        "name": "üdülő-telep",
        "code": "üdülő-telep"
      }
    ]
  }

  getCities() {
    return [
      {
        "Település ID": 1,
        "KSH településazonosító törzsszám": 17376,
        zipCode: 8127,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Aba",
        "infó:": "eredeti:"
      },
      {
        "Település ID": 2,
        "KSH településazonosító törzsszám": 17376,
        zipCode: 8128,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Aba",
        "infó:": "http://pastebin.com/0daYddNU"
      },
      {
        "Település ID": 3,
        "KSH településazonosító törzsszám": 12441,
        zipCode: 5241,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Abádszalók",
        "infó:": "szerz%u0151, cc-by:"
      },
      {
        "Település ID": 4,
        "KSH településazonosító törzsszám": 12548,
        zipCode: 7678,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Abaliget",
        "infó:": "http://eaposztrof.com"
      },
      {
        "Település ID": 5,
        "KSH településazonosító törzsszám": 24554,
        zipCode: 3261,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Abasár",
        "infó:": "ez a változat:"
      },
      {
        "Település ID": 6,
        "KSH településazonosító törzsszám": 15662,
        zipCode: 3882,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Abaújalpár",
        "infó:": "http://link.amexrap.org/telepulesek"
      },
      {
        "Település ID": 7,
        "KSH településazonosító törzsszám": 26718,
        zipCode: 3882,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Abaújkér",
        "infó:": "szerz%u0151:"
      },
      {
        "Település ID": 8,
        "KSH településazonosító törzsszám": 2820,
        zipCode: 3815,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Abaújlak",
        "infó:": "http://mazsa.com"
      },
      {
        "Település ID": 9,
        "KSH településazonosító törzsszám": 3595,
        zipCode: 3881,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Abaújszántó",
        "infó:": ""
      },
      {
        "Település ID": 10,
        "KSH településazonosító törzsszám": 26338,
        zipCode: 3809,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Abaújszolnok",
        "infó:": ""
      },
      {
        "Település ID": 11,
        "KSH településazonosító törzsszám": 2273,
        zipCode: 3898,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Abaújvár",
        "infó:": ""
      },
      {
        "Település ID": 12,
        "KSH településazonosító törzsszám": 11882,
        zipCode: 9151,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Abda",
        "infó:": ""
      },
      {
        "Település ID": 13,
        "KSH településazonosító törzsszám": 10357,
        zipCode: 3753,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Abod",
        "infó:": ""
      },
      {
        "Település ID": 14,
        "KSH településazonosító törzsszám": 27872,
        zipCode: 2740,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Abony",
        "infó:": ""
      },
      {
        "Település ID": 15,
        "KSH településazonosító törzsszám": 4561,
        zipCode: 8256,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Ábrahámhegy",
        "infó:": ""
      },
      {
        "Település ID": 16,
        "KSH településazonosító törzsszám": 4428,
        zipCode: 2941,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Ács",
        "infó:": ""
      },
      {
        "Település ID": 17,
        "KSH településazonosító törzsszám": 18573,
        zipCode: 2683,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Acsa",
        "infó:": ""
      },
      {
        "Település ID": 18,
        "KSH településazonosító törzsszám": 7214,
        zipCode: 9746,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Acsád",
        "infó:": ""
      },
      {
        "Település ID": 19,
        "KSH településazonosító törzsszám": 33385,
        zipCode: 9168,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Acsalag",
        "infó:": ""
      },
      {
        "Település ID": 20,
        "KSH településazonosító törzsszám": 18139,
        zipCode: 2887,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Ácsteszér",
        "infó:": ""
      },
      {
        "Település ID": 21,
        "KSH településazonosító törzsszám": 23241,
        zipCode: 3292,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Adács",
        "infó:": ""
      },
      {
        "Település ID": 22,
        "KSH településazonosító törzsszám": 6080,
        zipCode: 8653,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Ádánd",
        "infó:": ""
      },
      {
        "Település ID": 23,
        "KSH településazonosító törzsszám": 7302,
        zipCode: 8561,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Adásztevel",
        "infó:": ""
      },
      {
        "Település ID": 24,
        "KSH településazonosító törzsszám": 8925,
        zipCode: 2457,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Adony",
        "infó:": ""
      },
      {
        "Település ID": 25,
        "KSH településazonosító törzsszám": 31307,
        zipCode: 8497,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Adorjánháza",
        "infó:": ""
      },
      {
        "Település ID": 26,
        "KSH településazonosító törzsszám": 6868,
        zipCode: 7841,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Adorjás",
        "infó:": ""
      },
      {
        "Település ID": 27,
        "KSH településazonosító törzsszám": 25812,
        zipCode: 7381,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ág",
        "infó:": ""
      },
      {
        "Település ID": 28,
        "KSH településazonosító törzsszám": 17686,
        zipCode: 6076,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Ágasegyháza",
        "infó:": ""
      },
      {
        "Település ID": 29,
        "KSH településazonosító törzsszám": 4880,
        zipCode: 9423,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Ágfalva",
        "infó:": ""
      },
      {
        "Település ID": 30,
        "KSH településazonosító törzsszám": 9362,
        zipCode: 3759,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Aggtelek",
        "infó:": ""
      },
      {
        "Település ID": 31,
        "KSH településazonosító törzsszám": 29407,
        zipCode: 9441,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Agyagosszergény",
        "infó:": ""
      },
      {
        "Település ID": 32,
        "KSH településazonosító törzsszám": 8776,
        zipCode: 4524,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ajak",
        "infó:": ""
      },
      {
        "Település ID": 33,
        "KSH településazonosító törzsszám": 6673,
        zipCode: 8400,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Ajka",
        "infó:": ""
      },
      {
        "Település ID": 34,
        "KSH településazonosító törzsszám": 6673,
        zipCode: 8447,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Ajka",
        "infó:": ""
      },
      {
        "Település ID": 35,
        "KSH településazonosító törzsszám": 6673,
        zipCode: 8448,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Ajka",
        "infó:": ""
      },
      {
        "Település ID": 36,
        "KSH településazonosító törzsszám": 6673,
        zipCode: 8451,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Ajka",
        "infó:": ""
      },
      {
        "Település ID": 37,
        "KSH településazonosító törzsszám": 6682,
        zipCode: 2862,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Aka",
        "infó:": ""
      },
      {
        "Település ID": 38,
        "KSH településazonosító törzsszám": 21944,
        zipCode: 6221,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Akasztó",
        "infó:": ""
      },
      {
        "Település ID": 39,
        "KSH településazonosító törzsszám": 33093,
        zipCode: 3779,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alacska",
        "infó:": ""
      },
      {
        "Település ID": 40,
        "KSH településazonosító törzsszám": 26824,
        zipCode: 7011,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Alap",
        "infó:": ""
      },
      {
        "Település ID": 41,
        "KSH településazonosító törzsszám": 25265,
        zipCode: 5142,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Alattyán",
        "infó:": ""
      },
      {
        "Település ID": 42,
        "KSH településazonosító törzsszám": 31653,
        zipCode: 2730,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Albertirsa",
        "infó:": ""
      },
      {
        "Település ID": 43,
        "KSH településazonosító törzsszám": 15176,
        zipCode: 8087,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Alcsútdoboz",
        "infó:": ""
      },
      {
        "Település ID": 44,
        "KSH településazonosító törzsszám": 6345,
        zipCode: 3353,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Aldebr%u0151",
        "infó:": ""
      },
      {
        "Település ID": 45,
        "KSH településazonosító törzsszám": 34245,
        zipCode: 6750,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Algy%u0151",
        "infó:": ""
      },
      {
        "Település ID": 46,
        "KSH településazonosító törzsszám": 2644,
        zipCode: 8921,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Alibánfa",
        "infó:": ""
      },
      {
        "Település ID": 47,
        "KSH településazonosító törzsszám": 13329,
        zipCode: 7934,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Almamellék",
        "infó:": ""
      },
      {
        "Település ID": 48,
        "KSH településazonosító törzsszám": 32346,
        zipCode: 2931,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Almásfüzit%u0151",
        "infó:": ""
      },
      {
        "Település ID": 49,
        "KSH településazonosító törzsszám": 23384,
        zipCode: 8935,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Almásháza",
        "infó:": ""
      },
      {
        "Település ID": 50,
        "KSH településazonosító törzsszám": 29595,
        zipCode: 5747,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Almáskamarás",
        "infó:": ""
      },
      {
        "Település ID": 51,
        "KSH településazonosító törzsszám": 20376,
        zipCode: 7932,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Almáskeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 52,
        "KSH településazonosító törzsszám": 27641,
        zipCode: 4285,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Álmosd",
        "infó:": ""
      },
      {
        "Település ID": 53,
        "KSH településazonosító törzsszám": 20482,
        zipCode: 3985,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alsóberecki",
        "infó:": ""
      },
      {
        "Település ID": 54,
        "KSH településazonosító törzsszám": 34184,
        zipCode: 7443,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Alsóbogát",
        "infó:": ""
      },
      {
        "Település ID": 55,
        "KSH településazonosító törzsszám": 19664,
        zipCode: 3717,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alsódobsza",
        "infó:": ""
      },
      {
        "Település ID": 56,
        "KSH településazonosító törzsszám": 14429,
        zipCode: 3837,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alsógagy",
        "infó:": ""
      },
      {
        "Település ID": 57,
        "KSH településazonosító törzsszám": 17385,
        zipCode: 7345,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Alsómocsolád",
        "infó:": ""
      },
      {
        "Település ID": 58,
        "KSH településazonosító törzsszám": 29665,
        zipCode: 7147,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Alsónána",
        "infó:": ""
      },
      {
        "Település ID": 59,
        "KSH településazonosító törzsszám": 23199,
        zipCode: 2351,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Alsónémedi",
        "infó:": ""
      },
      {
        "Település ID": 60,
        "KSH településazonosító törzsszám": 19512,
        zipCode: 8924,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Alsónemesapáti",
        "infó:": ""
      },
      {
        "Település ID": 61,
        "KSH településazonosító törzsszám": 11563,
        zipCode: 7148,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Alsónyék",
        "infó:": ""
      },
      {
        "Település ID": 76,
        "KSH településazonosító törzsszám": 30526,
        zipCode: 8226,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Alsóörs",
        "infó:": ""
      },
      {
        "Település ID": 62,
        "KSH településazonosító törzsszám": 32081,
        zipCode: 8394,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Alsópáhok",
        "infó:": ""
      },
      {
        "Település ID": 63,
        "KSH településazonosító törzsszám": 16425,
        zipCode: 2617,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Alsópetény",
        "infó:": ""
      },
      {
        "Település ID": 64,
        "KSH településazonosító törzsszám": 18829,
        zipCode: 8767,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Alsórajk",
        "infó:": ""
      },
      {
        "Település ID": 65,
        "KSH településazonosító törzsszám": 23223,
        zipCode: 3989,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alsóregmec",
        "infó:": ""
      },
      {
        "Település ID": 66,
        "KSH településazonosító törzsszám": 8767,
        zipCode: 8973,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Alsószenterzsébet",
        "infó:": ""
      },
      {
        "Település ID": 67,
        "KSH településazonosító törzsszám": 25283,
        zipCode: 7012,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Alsószentiván",
        "infó:": ""
      },
      {
        "Település ID": 68,
        "KSH településazonosító törzsszám": 33279,
        zipCode: 7826,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Alsószentmárton",
        "infó:": ""
      },
      {
        "Település ID": 70,
        "KSH településazonosító törzsszám": 22549,
        zipCode: 9983,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Alsószölnök",
        "infó:": ""
      },
      {
        "Település ID": 69,
        "KSH településazonosító törzsszám": 28839,
        zipCode: 3726,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alsószuha",
        "infó:": ""
      },
      {
        "Település ID": 71,
        "KSH településazonosító törzsszám": 8217,
        zipCode: 3735,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alsótelekes",
        "infó:": ""
      },
      {
        "Település ID": 72,
        "KSH településazonosító törzsszám": 7621,
        zipCode: 3069,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Alsótold",
        "infó:": ""
      },
      {
        "Település ID": 73,
        "KSH településazonosító törzsszám": 22725,
        zipCode: 9842,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Alsóújlak",
        "infó:": ""
      },
      {
        "Település ID": 74,
        "KSH településazonosító törzsszám": 29814,
        zipCode: 3811,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alsóvadász",
        "infó:": ""
      },
      {
        "Település ID": 75,
        "KSH településazonosító törzsszám": 21032,
        zipCode: 3571,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Alsózsolca",
        "infó:": ""
      },
      {
        "Település ID": 77,
        "KSH településazonosító törzsszám": 16197,
        zipCode: 6916,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Ambrózfalva",
        "infó:": ""
      },
      {
        "Település ID": 78,
        "KSH településazonosító törzsszám": 29975,
        zipCode: 4546,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Anarcs",
        "infó:": ""
      },
      {
        "Település ID": 79,
        "KSH településazonosító törzsszám": 28714,
        zipCode: 8675,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Andocs",
        "infó:": ""
      },
      {
        "Település ID": 80,
        "KSH településazonosító törzsszám": 17987,
        zipCode: 3399,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Andornaktálya",
        "infó:": ""
      },
      {
        "Település ID": 81,
        "KSH településazonosító törzsszám": 12317,
        zipCode: 9811,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Andrásfa",
        "infó:": ""
      },
      {
        "Település ID": 82,
        "KSH településazonosító törzsszám": 34227,
        zipCode: 2529,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Annavölgy",
        "infó:": ""
      },
      {
        "Település ID": 83,
        "KSH településazonosító törzsszám": 28370,
        zipCode: 8477,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Apácatorna",
        "infó:": ""
      },
      {
        "Település ID": 84,
        "KSH településazonosító törzsszám": 20303,
        zipCode: 4553,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Apagy",
        "infó:": ""
      },
      {
        "Település ID": 85,
        "KSH településazonosító törzsszám": 33561,
        zipCode: 2345,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Apaj",
        "infó:": ""
      },
      {
        "Település ID": 86,
        "KSH településazonosító törzsszám": 26125,
        zipCode: 7186,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Aparhant",
        "infó:": ""
      },
      {
        "Település ID": 87,
        "KSH településazonosító törzsszám": 14252,
        zipCode: 6931,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Apátfalva",
        "infó:": ""
      },
      {
        "Település ID": 88,
        "KSH településazonosító törzsszám": 8873,
        zipCode: 9982,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Apátistvánfalva",
        "infó:": ""
      },
      {
        "Település ID": 89,
        "KSH településazonosító törzsszám": 27298,
        zipCode: 7720,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Apátvarasd",
        "infó:": ""
      },
      {
        "Település ID": 90,
        "KSH településazonosító törzsszám": 7241,
        zipCode: 3032,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Apc",
        "infó:": ""
      },
      {
        "Település ID": 91,
        "KSH településazonosító törzsszám": 10108,
        zipCode: 2338,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Áporka",
        "infó:": ""
      },
      {
        "Település ID": 92,
        "KSH településazonosító törzsszám": 21148,
        zipCode: 6088,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Apostag",
        "infó:": ""
      },
      {
        "Település ID": 93,
        "KSH településazonosító törzsszám": 9353,
        zipCode: 4634,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Aranyosapáti",
        "infó:": ""
      },
      {
        "Település ID": 94,
        "KSH településazonosító törzsszám": 6886,
        zipCode: 7671,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Aranyosgadány",
        "infó:": ""
      },
      {
        "Település ID": 95,
        "KSH településazonosító törzsszám": 26198,
        zipCode: 3885,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Arka",
        "infó:": ""
      },
      {
        "Település ID": 96,
        "KSH településazonosító törzsszám": 14331,
        zipCode: 3663,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Arló",
        "infó:": ""
      },
      {
        "Település ID": 97,
        "KSH településazonosító törzsszám": 3771,
        zipCode: 3713,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Arnót",
        "infó:": ""
      },
      {
        "Település ID": 98,
        "KSH településazonosító törzsszám": 3823,
        zipCode: 3467,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Árokt%u0151",
        "infó:": ""
      },
      {
        "Település ID": 99,
        "KSH településazonosító törzsszám": 19062,
        zipCode: 6623,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Árpádhalom",
        "infó:": ""
      },
      {
        "Település ID": 100,
        "KSH településazonosító törzsszám": 32249,
        zipCode: 9132,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Árpás",
        "infó:": ""
      },
      {
        "Település ID": 101,
        "KSH településazonosító törzsszám": 3319,
        zipCode: 4115,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Ártánd",
        "infó:": ""
      },
      {
        "Település ID": 102,
        "KSH településazonosító törzsszám": 10339,
        zipCode: 6783,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Ásotthalom",
        "infó:": ""
      },
      {
        "Település ID": 103,
        "KSH településazonosító törzsszám": 26921,
        zipCode: 9177,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Ásványráró",
        "infó:": ""
      },
      {
        "Település ID": 104,
        "KSH településazonosító törzsszám": 4233,
        zipCode: 3841,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Aszaló",
        "infó:": ""
      },
      {
        "Település ID": 105,
        "KSH településazonosító törzsszám": 23852,
        zipCode: 2881,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Ászár",
        "infó:": ""
      },
      {
        "Település ID": 106,
        "KSH településazonosító törzsszám": 16188,
        zipCode: 2170,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Aszód",
        "infó:": ""
      },
      {
        "Település ID": 107,
        "KSH településazonosító törzsszám": 7339,
        zipCode: 8241,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Aszóf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 108,
        "KSH településazonosító törzsszám": 28583,
        zipCode: 7763,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Áta",
        "infó:": ""
      },
      {
        "Település ID": 109,
        "KSH településazonosító törzsszám": 6503,
        zipCode: 3371,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Átány",
        "infó:": ""
      },
      {
        "Település ID": 110,
        "KSH településazonosító törzsszám": 16090,
        zipCode: 3213,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Atkár",
        "infó:": ""
      },
      {
        "Település ID": 111,
        "KSH településazonosító törzsszám": 32735,
        zipCode: 7252,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Attala",
        "infó:": ""
      },
      {
        "Település ID": 112,
        "KSH településazonosító törzsszám": 5403,
        zipCode: 7757,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Babarc",
        "infó:": ""
      },
      {
        "Település ID": 113,
        "KSH településazonosító törzsszám": 9663,
        zipCode: 7814,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Babarcsz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 114,
        "KSH településazonosító törzsszám": 30474,
        zipCode: 7584,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Babócsa",
        "infó:": ""
      },
      {
        "Település ID": 115,
        "KSH településazonosító törzsszám": 19363,
        zipCode: 2943,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bábolna",
        "infó:": ""
      },
      {
        "Település ID": 116,
        "KSH településazonosító törzsszám": 28316,
        zipCode: 8658,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bábonymegyer",
        "infó:": ""
      },
      {
        "Település ID": 117,
        "KSH településazonosító törzsszám": 21263,
        zipCode: 8983,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Babosdöbréte",
        "infó:": ""
      },
      {
        "Település ID": 118,
        "KSH településazonosító törzsszám": 15042,
        zipCode: 9351,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Babót",
        "infó:": ""
      },
      {
        "Település ID": 119,
        "KSH településazonosító törzsszám": 10719,
        zipCode: 6430,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bácsalmás",
        "infó:": ""
      },
      {
        "Település ID": 120,
        "KSH településazonosító törzsszám": 10180,
        zipCode: 6453,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bácsbokod",
        "infó:": ""
      },
      {
        "Település ID": 121,
        "KSH településazonosító törzsszám": 27234,
        zipCode: 6454,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bácsborsód",
        "infó:": ""
      },
      {
        "Település ID": 122,
        "KSH településazonosító törzsszám": 8697,
        zipCode: 6511,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bácsszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 123,
        "KSH településazonosító törzsszám": 30155,
        zipCode: 6425,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bácssz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 124,
        "KSH településazonosító törzsszám": 22327,
        zipCode: 8257,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Badacsonytomaj",
        "infó:": ""
      },
      {
        "Település ID": 125,
        "KSH településazonosító törzsszám": 22327,
        zipCode: 8258,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Badacsonytomaj",
        "infó:": ""
      },
      {
        "Település ID": 126,
        "KSH településazonosító törzsszám": 22327,
        zipCode: 8261,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Badacsonytomaj",
        "infó:": ""
      },
      {
        "Település ID": 127,
        "KSH településazonosító törzsszám": 3267,
        zipCode: 8262,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Badacsonytördemic",
        "infó:": ""
      },
      {
        "Település ID": 128,
        "KSH településazonosító törzsszám": 3267,
        zipCode: 8263,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Badacsonytördemic",
        "infó:": ""
      },
      {
        "Település ID": 129,
        "KSH településazonosító törzsszám": 9131,
        zipCode: 2191,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Bag",
        "infó:": ""
      },
      {
        "Település ID": 130,
        "KSH településazonosító törzsszám": 20011,
        zipCode: 4286,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Bagamér",
        "infó:": ""
      },
      {
        "Település ID": 131,
        "KSH településazonosító törzsszám": 11059,
        zipCode: 8977,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Baglad",
        "infó:": ""
      },
      {
        "Település ID": 132,
        "KSH településazonosító törzsszám": 30368,
        zipCode: 8992,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bagod",
        "infó:": ""
      },
      {
        "Település ID": 133,
        "KSH településazonosító törzsszám": 28769,
        zipCode: 9145,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Bágyogszovát",
        "infó:": ""
      },
      {
        "Település ID": 134,
        "KSH településazonosító törzsszám": 29212,
        zipCode: 2836,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Baj",
        "infó:": ""
      },
      {
        "Település ID": 135,
        "KSH településazonosító törzsszám": 3522,
        zipCode: 6500,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Baja",
        "infó:": ""
      },
      {
        "Település ID": 136,
        "KSH településazonosító törzsszám": 3522,
        zipCode: 6503,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Baja",
        "infó:": ""
      },
      {
        "Település ID": 137,
        "KSH településazonosító törzsszám": 17020,
        zipCode: 9944,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bajánsenye",
        "infó:": ""
      },
      {
        "Település ID": 138,
        "KSH településazonosító törzsszám": 16744,
        zipCode: 2525,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bajna",
        "infó:": ""
      },
      {
        "Település ID": 139,
        "KSH településazonosító törzsszám": 29355,
        zipCode: 2533,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bajót",
        "infó:": ""
      },
      {
        "Település ID": 140,
        "KSH településazonosító törzsszám": 4738,
        zipCode: 8945,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bak",
        "infó:": ""
      },
      {
        "Település ID": 141,
        "KSH településazonosító törzsszám": 14395,
        zipCode: 7585,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bakháza",
        "infó:": ""
      },
      {
        "Település ID": 142,
        "KSH településazonosító törzsszám": 22275,
        zipCode: 7393,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bakóca",
        "infó:": ""
      },
      {
        "Település ID": 143,
        "KSH településazonosító törzsszám": 15167,
        zipCode: 4164,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Bakonszeg",
        "infó:": ""
      },
      {
        "Település ID": 144,
        "KSH településazonosító törzsszám": 8299,
        zipCode: 7675,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bakonya",
        "infó:": ""
      },
      {
        "Település ID": 145,
        "KSH településazonosító törzsszám": 24244,
        zipCode: 2885,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bakonybánk",
        "infó:": ""
      },
      {
        "Település ID": 146,
        "KSH településazonosító törzsszám": 23746,
        zipCode: 8427,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonybél",
        "infó:": ""
      },
      {
        "Település ID": 147,
        "KSH településazonosító törzsszám": 8730,
        zipCode: 8056,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Bakonycsernye",
        "infó:": ""
      },
      {
        "Település ID": 148,
        "KSH településazonosító törzsszám": 28936,
        zipCode: 8433,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Bakonygyirót",
        "infó:": ""
      },
      {
        "Település ID": 149,
        "KSH településazonosító törzsszám": 29513,
        zipCode: 8581,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonyjákó",
        "infó:": ""
      },
      {
        "Település ID": 150,
        "KSH településazonosító törzsszám": 7287,
        zipCode: 8571,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonykoppány",
        "infó:": ""
      },
      {
        "Település ID": 151,
        "KSH településazonosító törzsszám": 23153,
        zipCode: 8045,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Bakonykúti",
        "infó:": ""
      },
      {
        "Település ID": 152,
        "KSH településazonosító törzsszám": 25991,
        zipCode: 8422,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonynána",
        "infó:": ""
      },
      {
        "Település ID": 153,
        "KSH településazonosító törzsszám": 30410,
        zipCode: 8418,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonyoszlop",
        "infó:": ""
      },
      {
        "Település ID": 154,
        "KSH településazonosító törzsszám": 22062,
        zipCode: 9088,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Bakonypéterd",
        "infó:": ""
      },
      {
        "Település ID": 155,
        "KSH településazonosító törzsszám": 6327,
        zipCode: 8457,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonypölöske",
        "infó:": ""
      },
      {
        "Település ID": 156,
        "KSH településazonosító törzsszám": 29902,
        zipCode: 8557,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonyság",
        "infó:": ""
      },
      {
        "Település ID": 157,
        "KSH településazonosító törzsszám": 25229,
        zipCode: 2861,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bakonysárkány",
        "infó:": ""
      },
      {
        "Település ID": 158,
        "KSH településazonosító törzsszám": 23922,
        zipCode: 8557,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonyszentiván",
        "infó:": ""
      },
      {
        "Település ID": 159,
        "KSH településazonosító törzsszám": 22813,
        zipCode: 8430,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonyszentkirály",
        "infó:": ""
      },
      {
        "Település ID": 160,
        "KSH településazonosító törzsszám": 5944,
        zipCode: 8431,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Bakonyszentlászló",
        "infó:": ""
      },
      {
        "Település ID": 161,
        "KSH településazonosító törzsszám": 22381,
        zipCode: 2884,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bakonyszombathely",
        "infó:": ""
      },
      {
        "Település ID": 162,
        "KSH településazonosító törzsszám": 26417,
        zipCode: 8572,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonyszücs",
        "infó:": ""
      },
      {
        "Település ID": 163,
        "KSH településazonosító törzsszám": 24129,
        zipCode: 8555,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bakonytamási",
        "infó:": ""
      },
      {
        "Település ID": 164,
        "KSH településazonosító törzsszám": 29106,
        zipCode: 6768,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Baks",
        "infó:": ""
      },
      {
        "Település ID": 165,
        "KSH településazonosító törzsszám": 3975,
        zipCode: 7834,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Baksa",
        "infó:": ""
      },
      {
        "Település ID": 166,
        "KSH településazonosító törzsszám": 18184,
        zipCode: 3836,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Baktakék",
        "infó:": ""
      },
      {
        "Település ID": 167,
        "KSH településazonosító törzsszám": 2325,
        zipCode: 4561,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Baktalórántháza",
        "infó:": ""
      },
      {
        "Település ID": 168,
        "KSH településazonosító törzsszám": 15097,
        zipCode: 8946,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Baktüttös",
        "infó:": ""
      },
      {
        "Település ID": 169,
        "KSH településazonosító törzsszám": 22521,
        zipCode: 3780,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Balajt",
        "infó:": ""
      },
      {
        "Település ID": 170,
        "KSH településazonosító törzsszám": 13657,
        zipCode: 2660,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Balassagyarmat",
        "infó:": ""
      },
      {
        "Település ID": 171,
        "KSH településazonosító törzsszám": 23676,
        zipCode: 6764,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Balástya",
        "infó:": ""
      },
      {
        "Település ID": 172,
        "KSH településazonosító törzsszám": 11527,
        zipCode: 3347,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Balaton",
        "infó:": ""
      },
      {
        "Település ID": 173,
        "KSH településazonosító törzsszám": 25308,
        zipCode: 8243,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonakali",
        "infó:": ""
      },
      {
        "Település ID": 174,
        "KSH településazonosító törzsszám": 5838,
        zipCode: 8220,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonalmádi",
        "infó:": ""
      },
      {
        "Település ID": 175,
        "KSH településazonosító törzsszám": 27377,
        zipCode: 8649,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonberény",
        "infó:": ""
      },
      {
        "Település ID": 176,
        "KSH településazonosító törzsszám": 33853,
        zipCode: 8630,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonboglár",
        "infó:": ""
      },
      {
        "Település ID": 177,
        "KSH településazonosító törzsszám": 33853,
        zipCode: 8691,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonboglár",
        "infó:": ""
      },
      {
        "Település ID": 178,
        "KSH településazonosító törzsszám": 3072,
        zipCode: 8272,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatoncsicsó",
        "infó:": ""
      },
      {
        "Település ID": 179,
        "KSH településazonosító törzsszám": 12238,
        zipCode: 8312,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonederics",
        "infó:": ""
      },
      {
        "Település ID": 180,
        "KSH településazonosító törzsszám": 19460,
        zipCode: 8613,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonendréd",
        "infó:": ""
      },
      {
        "Település ID": 181,
        "KSH településazonosító törzsszám": 20729,
        zipCode: 8646,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonfenyves",
        "infó:": ""
      },
      {
        "Település ID": 182,
        "KSH településazonosító törzsszám": 29461,
        zipCode: 8164,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonf%u0151kajár",
        "infó:": ""
      },
      {
        "Település ID": 187,
        "KSH településazonosító törzsszám": 7117,
        zipCode: 8623,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonföldvár",
        "infó:": ""
      },
      {
        "Település ID": 185,
        "KSH településazonosító törzsszám": 21175,
        zipCode: 8230,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonfüred",
        "infó:": ""
      },
      {
        "Település ID": 186,
        "KSH településazonosító törzsszám": 21175,
        zipCode: 8236,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonfüred",
        "infó:": ""
      },
      {
        "Település ID": 183,
        "KSH településazonosító törzsszám": 2219,
        zipCode: 8175,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonf%u0171zf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 184,
        "KSH településazonosító törzsszám": 2219,
        zipCode: 8184,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonf%u0171zf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 188,
        "KSH településazonosító törzsszám": 17002,
        zipCode: 8313,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Balatongyörök",
        "infó:": ""
      },
      {
        "Település ID": 189,
        "KSH településazonosító törzsszám": 3638,
        zipCode: 8275,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonhenye",
        "infó:": ""
      },
      {
        "Település ID": 190,
        "KSH településazonosító törzsszám": 5148,
        zipCode: 8172,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonkenese",
        "infó:": ""
      },
      {
        "Település ID": 191,
        "KSH településazonosító törzsszám": 5148,
        zipCode: 8174,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonkenese",
        "infó:": ""
      },
      {
        "Település ID": 192,
        "KSH településazonosító törzsszám": 7375,
        zipCode: 8648,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonkeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 193,
        "KSH településazonosító törzsszám": 33862,
        zipCode: 8638,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonlelle",
        "infó:": ""
      },
      {
        "Település ID": 194,
        "KSH településazonosító törzsszám": 26462,
        zipCode: 8753,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Balatonmagyaród",
        "infó:": ""
      },
      {
        "Település ID": 195,
        "KSH településazonosító törzsszám": 14562,
        zipCode: 8647,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonmáriafürd%u0151",
        "infó:": ""
      },
      {
        "Település ID": 196,
        "KSH településazonosító törzsszám": 11916,
        zipCode: 8637,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balaton%u0151szöd",
        "infó:": ""
      },
      {
        "Település ID": 197,
        "KSH településazonosító törzsszám": 33844,
        zipCode: 8255,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonrendes",
        "infó:": ""
      },
      {
        "Település ID": 198,
        "KSH településazonosító törzsszám": 16601,
        zipCode: 8651,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonszabadi",
        "infó:": ""
      },
      {
        "Település ID": 199,
        "KSH településazonosító törzsszám": 24907,
        zipCode: 8624,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonszárszó",
        "infó:": ""
      },
      {
        "Település ID": 200,
        "KSH településazonosító törzsszám": 22822,
        zipCode: 8636,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonszemes",
        "infó:": ""
      },
      {
        "Település ID": 201,
        "KSH településazonosító törzsszám": 21324,
        zipCode: 8710,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 202,
        "KSH településazonosító törzsszám": 17154,
        zipCode: 8252,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonszepezd",
        "infó:": ""
      },
      {
        "Település ID": 203,
        "KSH településazonosító törzsszám": 28501,
        zipCode: 8233,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonsz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 204,
        "KSH településazonosító törzsszám": 15565,
        zipCode: 8242,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonudvari",
        "infó:": ""
      },
      {
        "Település ID": 205,
        "KSH településazonosító törzsszám": 16470,
        zipCode: 8712,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Balatonújlak",
        "infó:": ""
      },
      {
        "Település ID": 206,
        "KSH településazonosító törzsszám": 3559,
        zipCode: 8171,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Balatonvilágos",
        "infó:": ""
      },
      {
        "Település ID": 207,
        "KSH településazonosító törzsszám": 31103,
        zipCode: 8054,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Balinka",
        "infó:": ""
      },
      {
        "Település ID": 208,
        "KSH településazonosító törzsszám": 31103,
        zipCode: 8055,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Balinka",
        "infó:": ""
      },
      {
        "Település ID": 209,
        "KSH településazonosító törzsszám": 26958,
        zipCode: 4233,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Balkány",
        "infó:": ""
      },
      {
        "Település ID": 210,
        "KSH településazonosító törzsszám": 13408,
        zipCode: 6035,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Ballószög",
        "infó:": ""
      },
      {
        "Település ID": 211,
        "KSH településazonosító törzsszám": 2918,
        zipCode: 4060,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Balmazújváros",
        "infó:": ""
      },
      {
        "Település ID": 212,
        "KSH településazonosító törzsszám": 5102,
        zipCode: 9771,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Balogunyom",
        "infó:": ""
      },
      {
        "Település ID": 213,
        "KSH településazonosító törzsszám": 25937,
        zipCode: 6412,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Balotaszállás",
        "infó:": ""
      },
      {
        "Település ID": 214,
        "KSH településazonosító törzsszám": 15963,
        zipCode: 4468,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Balsa",
        "infó:": ""
      },
      {
        "Település ID": 215,
        "KSH településazonosító törzsszám": 24457,
        zipCode: 8614,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bálványos",
        "infó:": ""
      },
      {
        "Település ID": 216,
        "KSH településazonosító törzsszám": 31422,
        zipCode: 2944,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bana",
        "infó:": ""
      },
      {
        "Település ID": 217,
        "KSH településazonosító törzsszám": 14173,
        zipCode: 8443,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bánd",
        "infó:": ""
      },
      {
        "Település ID": 218,
        "KSH településazonosító törzsszám": 7603,
        zipCode: 7914,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bánfa",
        "infó:": ""
      },
      {
        "Település ID": 219,
        "KSH településazonosító törzsszám": 25159,
        zipCode: 3642,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bánhorváti",
        "infó:": ""
      },
      {
        "Település ID": 220,
        "KSH településazonosító törzsszám": 24341,
        zipCode: 2653,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Bánk",
        "infó:": ""
      },
      {
        "Település ID": 221,
        "KSH településazonosító törzsszám": 8439,
        zipCode: 8891,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bánokszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 222,
        "KSH településazonosító törzsszám": 21953,
        zipCode: 3654,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bánréve",
        "infó:": ""
      },
      {
        "Település ID": 223,
        "KSH településazonosító törzsszám": 24378,
        zipCode: 7711,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bár",
        "infó:": ""
      },
      {
        "Település ID": 224,
        "KSH településazonosító törzsszám": 26480,
        zipCode: 4937,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Barabás",
        "infó:": ""
      },
      {
        "Település ID": 225,
        "KSH településazonosító törzsszám": 7047,
        zipCode: 2426,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Baracs",
        "infó:": ""
      },
      {
        "Település ID": 226,
        "KSH településazonosító törzsszám": 7047,
        zipCode: 2427,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Baracs",
        "infó:": ""
      },
      {
        "Település ID": 227,
        "KSH településazonosító törzsszám": 8581,
        zipCode: 2471,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Baracska",
        "infó:": ""
      },
      {
        "Település ID": 228,
        "KSH településazonosító törzsszám": 26693,
        zipCode: 4161,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Báránd",
        "infó:": ""
      },
      {
        "Település ID": 229,
        "KSH településazonosító törzsszám": 20464,
        zipCode: 7841,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Baranyahidvég",
        "infó:": ""
      },
      {
        "Település ID": 230,
        "KSH településazonosító törzsszám": 24749,
        zipCode: 7384,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Baranyajen%u0151",
        "infó:": ""
      },
      {
        "Település ID": 231,
        "KSH településazonosító törzsszám": 5485,
        zipCode: 7383,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Baranyaszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 232,
        "KSH településazonosító törzsszám": 6196,
        zipCode: 9169,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Barbacs",
        "infó:": ""
      },
      {
        "Település ID": 233,
        "KSH településazonosító törzsszám": 32799,
        zipCode: 7557,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Barcs",
        "infó:": ""
      },
      {
        "Település ID": 234,
        "KSH településazonosító törzsszám": 32799,
        zipCode: 7570,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Barcs",
        "infó:": ""
      },
      {
        "Település ID": 235,
        "KSH településazonosító törzsszám": 3735,
        zipCode: 7478,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bárdudvarnok",
        "infó:": ""
      },
      {
        "Település ID": 236,
        "KSH településazonosító törzsszám": 27447,
        zipCode: 8948,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Barlahida",
        "infó:": ""
      },
      {
        "Település ID": 237,
        "KSH településazonosító törzsszám": 20048,
        zipCode: 3126,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Bárna",
        "infó:": ""
      },
      {
        "Település ID": 238,
        "KSH településazonosító törzsszám": 15778,
        zipCode: 8291,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Barnag",
        "infó:": ""
      },
      {
        "Település ID": 239,
        "KSH településazonosító törzsszám": 8624,
        zipCode: 2883,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bársonyos",
        "infó:": ""
      },
      {
        "Település ID": 240,
        "KSH településazonosító törzsszám": 15495,
        zipCode: 7923,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Basal",
        "infó:": ""
      },
      {
        "Település ID": 241,
        "KSH településazonosító törzsszám": 8846,
        zipCode: 3881,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Baskó",
        "infó:": ""
      },
      {
        "Település ID": 242,
        "KSH településazonosító törzsszám": 11712,
        zipCode: 7149,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Báta",
        "infó:": ""
      },
      {
        "Település ID": 243,
        "KSH településazonosító törzsszám": 28909,
        zipCode: 7164,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Bátaapáti",
        "infó:": ""
      },
      {
        "Település ID": 244,
        "KSH településazonosító törzsszám": 8864,
        zipCode: 7140,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Bátaszék",
        "infó:": ""
      },
      {
        "Település ID": 245,
        "KSH településazonosító törzsszám": 32337,
        zipCode: 7258,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Baté",
        "infó:": ""
      },
      {
        "Település ID": 246,
        "KSH településazonosító törzsszám": 3656,
        zipCode: 6528,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bátmonostor",
        "infó:": ""
      },
      {
        "Település ID": 247,
        "KSH településazonosító törzsszám": 33534,
        zipCode: 3070,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Bátonyterenye",
        "infó:": ""
      },
      {
        "Település ID": 248,
        "KSH településazonosító törzsszám": 33534,
        zipCode: 3078,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Bátonyterenye",
        "infó:": ""
      },
      {
        "Település ID": 249,
        "KSH településazonosító törzsszám": 24022,
        zipCode: 3336,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Bátor",
        "infó:": ""
      },
      {
        "Település ID": 250,
        "KSH településazonosító törzsszám": 2990,
        zipCode: 4343,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Bátorliget",
        "infó:": ""
      },
      {
        "Település ID": 251,
        "KSH településazonosító törzsszám": 18102,
        zipCode: 5830,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Battonya",
        "infó:": ""
      },
      {
        "Település ID": 252,
        "KSH településazonosító törzsszám": 11961,
        zipCode: 6351,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bátya",
        "infó:": ""
      },
      {
        "Település ID": 253,
        "KSH településazonosító törzsszám": 24864,
        zipCode: 8797,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Batyk",
        "infó:": ""
      },
      {
        "Település ID": 254,
        "KSH településazonosító törzsszám": 18698,
        zipCode: 8887,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bázakerettye",
        "infó:": ""
      },
      {
        "Település ID": 255,
        "KSH településazonosító törzsszám": 7427,
        zipCode: 8352,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bazsi",
        "infó:": ""
      },
      {
        "Település ID": 256,
        "KSH településazonosító törzsszám": 22901,
        zipCode: 8565,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Béb",
        "infó:": ""
      },
      {
        "Település ID": 257,
        "KSH településazonosító törzsszám": 23144,
        zipCode: 8866,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Becsehely",
        "infó:": ""
      },
      {
        "Település ID": 258,
        "KSH településazonosító törzsszám": 12016,
        zipCode: 2693,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Becske",
        "infó:": ""
      },
      {
        "Település ID": 259,
        "KSH településazonosító törzsszám": 27049,
        zipCode: 3768,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Becskeháza",
        "infó:": ""
      },
      {
        "Település ID": 260,
        "KSH településazonosító törzsszám": 18360,
        zipCode: 8985,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Becsvölgye",
        "infó:": ""
      },
      {
        "Település ID": 261,
        "KSH településazonosító törzsszám": 20710,
        zipCode: 8666,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bedegkér",
        "infó:": ""
      },
      {
        "Település ID": 262,
        "KSH településazonosító törzsszám": 33446,
        zipCode: 4128,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Bed%u0151",
        "infó:": ""
      },
      {
        "Település ID": 263,
        "KSH településazonosító törzsszám": 10384,
        zipCode: 9683,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bejcgyertyános",
        "infó:": ""
      },
      {
        "Település ID": 264,
        "KSH településazonosító törzsszám": 27058,
        zipCode: 8515,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Békás",
        "infó:": ""
      },
      {
        "Település ID": 265,
        "KSH településazonosító törzsszám": 6929,
        zipCode: 3903,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bekecs",
        "infó:": ""
      },
      {
        "Település ID": 266,
        "KSH településazonosító törzsszám": 9760,
        zipCode: 5630,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Békés",
        "infó:": ""
      },
      {
        "Település ID": 267,
        "KSH településazonosító törzsszám": 15200,
        zipCode: 5600,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Békéscsaba",
        "infó:": ""
      },
      {
        "Település ID": 268,
        "KSH településazonosító törzsszám": 15200,
        zipCode: 5623,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Békéscsaba",
        "infó:": ""
      },
      {
        "Település ID": 269,
        "KSH településazonosító törzsszám": 15200,
        zipCode: 5671,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Békéscsaba",
        "infó:": ""
      },
      {
        "Település ID": 270,
        "KSH településazonosító törzsszám": 26189,
        zipCode: 5946,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Békéssámson",
        "infó:": ""
      },
      {
        "Település ID": 271,
        "KSH településazonosító törzsszám": 2680,
        zipCode: 5561,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Békésszentandrás",
        "infó:": ""
      },
      {
        "Település ID": 272,
        "KSH településazonosító törzsszám": 4400,
        zipCode: 3343,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Bekölce",
        "infó:": ""
      },
      {
        "Település ID": 273,
        "KSH településazonosító törzsszám": 33260,
        zipCode: 3346,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Bélapátfalva",
        "infó:": ""
      },
      {
        "Település ID": 274,
        "KSH településazonosító törzsszám": 6910,
        zipCode: 7589,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bélavár",
        "infó:": ""
      },
      {
        "Település ID": 275,
        "KSH településazonosító törzsszám": 28662,
        zipCode: 7061,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Belecska",
        "infó:": ""
      },
      {
        "Település ID": 276,
        "KSH településazonosító törzsszám": 10588,
        zipCode: 9343,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Beled",
        "infó:": ""
      },
      {
        "Település ID": 277,
        "KSH településazonosító törzsszám": 17127,
        zipCode: 7543,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Beleg",
        "infó:": ""
      },
      {
        "Település ID": 278,
        "KSH településazonosító törzsszám": 9168,
        zipCode: 8855,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Belezna",
        "infó:": ""
      },
      {
        "Település ID": 279,
        "KSH településazonosító törzsszám": 19390,
        zipCode: 5643,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Bélmegyer",
        "infó:": ""
      },
      {
        "Település ID": 280,
        "KSH településazonosító törzsszám": 8466,
        zipCode: 2455,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Beloiannisz",
        "infó:": ""
      },
      {
        "Település ID": 281,
        "KSH településazonosító törzsszám": 20950,
        zipCode: 8978,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bels%u0151sárd",
        "infó:": ""
      },
      {
        "Település ID": 282,
        "KSH településazonosító törzsszám": 19008,
        zipCode: 7747,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Belvárdgyula",
        "infó:": ""
      },
      {
        "Település ID": 283,
        "KSH településazonosító törzsszám": 25441,
        zipCode: 4643,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Benk",
        "infó:": ""
      },
      {
        "Település ID": 284,
        "KSH településazonosító törzsszám": 25098,
        zipCode: 2216,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Bénye",
        "infó:": ""
      },
      {
        "Település ID": 285,
        "KSH településazonosító törzsszám": 32911,
        zipCode: 3045,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Bér",
        "infó:": ""
      },
      {
        "Település ID": 286,
        "KSH településazonosító törzsszám": 32124,
        zipCode: 9831,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bérbaltavár",
        "infó:": ""
      },
      {
        "Település ID": 287,
        "KSH településazonosító törzsszám": 2389,
        zipCode: 2687,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Bercel",
        "infó:": ""
      },
      {
        "Település ID": 288,
        "KSH településazonosító törzsszám": 28246,
        zipCode: 4934,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Beregdaróc",
        "infó:": ""
      },
      {
        "Település ID": 289,
        "KSH településazonosító törzsszám": 20677,
        zipCode: 4933,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Beregsurány",
        "infó:": ""
      },
      {
        "Település ID": 290,
        "KSH településazonosító törzsszám": 18467,
        zipCode: 4116,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Berekböszörmény",
        "infó:": ""
      },
      {
        "Település ID": 291,
        "KSH településazonosító törzsszám": 34005,
        zipCode: 5309,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Berekfürd%u0151",
        "infó:": ""
      },
      {
        "Település ID": 292,
        "KSH településazonosító törzsszám": 31927,
        zipCode: 7827,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Beremend",
        "infó:": ""
      },
      {
        "Település ID": 293,
        "KSH településazonosító törzsszám": 34290,
        zipCode: 3704,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Berente",
        "infó:": ""
      },
      {
        "Település ID": 294,
        "KSH településazonosító törzsszám": 29674,
        zipCode: 3834,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Beret",
        "infó:": ""
      },
      {
        "Település ID": 295,
        "KSH településazonosító törzsszám": 12788,
        zipCode: 4100,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Berettyóújfalu",
        "infó:": ""
      },
      {
        "Település ID": 296,
        "KSH településazonosító törzsszám": 12788,
        zipCode: 4103,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Berettyóújfalu",
        "infó:": ""
      },
      {
        "Település ID": 297,
        "KSH településazonosító törzsszám": 33127,
        zipCode: 8181,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Berhida",
        "infó:": ""
      },
      {
        "Település ID": 298,
        "KSH településazonosító törzsszám": 33127,
        zipCode: 8182,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Berhida",
        "infó:": ""
      },
      {
        "Település ID": 299,
        "KSH településazonosító törzsszám": 9034,
        zipCode: 2641,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Berkenye",
        "infó:": ""
      },
      {
        "Település ID": 300,
        "KSH településazonosító törzsszám": 16461,
        zipCode: 7664,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Berkesd",
        "infó:": ""
      },
      {
        "Település ID": 301,
        "KSH településazonosító törzsszám": 7472,
        zipCode: 4521,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Berkesz",
        "infó:": ""
      },
      {
        "Település ID": 302,
        "KSH településazonosító törzsszám": 18777,
        zipCode: 2639,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Bernecebaráti",
        "infó:": ""
      },
      {
        "Település ID": 303,
        "KSH településazonosító törzsszám": 26356,
        zipCode: 3575,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Berzék",
        "infó:": ""
      },
      {
        "Település ID": 304,
        "KSH településazonosító törzsszám": 30119,
        zipCode: 7516,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Berzence",
        "infó:": ""
      },
      {
        "Település ID": 305,
        "KSH településazonosító törzsszám": 30049,
        zipCode: 7838,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Besence",
        "infó:": ""
      },
      {
        "Település ID": 306,
        "KSH településazonosító törzsszám": 13639,
        zipCode: 4557,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Beseny%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 307,
        "KSH településazonosító törzsszám": 27517,
        zipCode: 3373,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Beseny%u0151telek",
        "infó:": ""
      },
      {
        "Település ID": 308,
        "KSH településazonosító törzsszám": 11305,
        zipCode: 5071,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Besenyszög",
        "infó:": ""
      },
      {
        "Település ID": 309,
        "KSH településazonosító törzsszám": 16346,
        zipCode: 2456,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Besny%u0151",
        "infó:": ""
      },
      {
        "Település ID": 310,
        "KSH településazonosító törzsszám": 21227,
        zipCode: 4488,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Beszterec",
        "infó:": ""
      },
      {
        "Település ID": 311,
        "KSH településazonosító törzsszám": 14119,
        zipCode: 7782,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bezedek",
        "infó:": ""
      },
      {
        "Település ID": 312,
        "KSH településazonosító törzsszám": 29805,
        zipCode: 9223,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Bezenye",
        "infó:": ""
      },
      {
        "Település ID": 313,
        "KSH településazonosító törzsszám": 12830,
        zipCode: 8934,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bezeréd",
        "infó:": ""
      },
      {
        "Település ID": 314,
        "KSH településazonosító törzsszám": 10560,
        zipCode: 9162,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Bezi",
        "infó:": ""
      },
      {
        "Település ID": 315,
        "KSH településazonosító törzsszám": 8891,
        zipCode: 2051,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Biatorbágy",
        "infó:": ""
      },
      {
        "Település ID": 316,
        "KSH településazonosító törzsszám": 13310,
        zipCode: 7671,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bicsérd",
        "infó:": ""
      },
      {
        "Település ID": 317,
        "KSH településazonosító törzsszám": 10481,
        zipCode: 2060,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Bicske",
        "infó:": ""
      },
      {
        "Település ID": 318,
        "KSH településazonosító törzsszám": 25256,
        zipCode: 4175,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Bihardancsháza",
        "infó:": ""
      },
      {
        "Település ID": 319,
        "KSH településazonosító törzsszám": 19956,
        zipCode: 4110,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Biharkeresztes",
        "infó:": ""
      },
      {
        "Település ID": 320,
        "KSH településazonosító törzsszám": 24828,
        zipCode: 4172,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Biharnagybajom",
        "infó:": ""
      },
      {
        "Település ID": 321,
        "KSH településazonosító törzsszám": 29887,
        zipCode: 4174,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Bihartorda",
        "infó:": ""
      },
      {
        "Település ID": 322,
        "KSH településazonosító törzsszám": 29610,
        zipCode: 5538,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Biharugra",
        "infó:": ""
      },
      {
        "Település ID": 323,
        "KSH településazonosító törzsszám": 11970,
        zipCode: 7043,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Bikács",
        "infó:": ""
      },
      {
        "Település ID": 324,
        "KSH településazonosító törzsszám": 4899,
        zipCode: 7346,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bikal",
        "infó:": ""
      },
      {
        "Település ID": 325,
        "KSH településazonosító törzsszám": 2945,
        zipCode: 4235,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Biri",
        "infó:": ""
      },
      {
        "Település ID": 326,
        "KSH településazonosító törzsszám": 5139,
        zipCode: 7747,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Birján",
        "infó:": ""
      },
      {
        "Település ID": 327,
        "KSH településazonosító törzsszám": 24925,
        zipCode: 7811,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bisse",
        "infó:": ""
      },
      {
        "Település ID": 328,
        "KSH településazonosító törzsszám": 5476,
        zipCode: 9625,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "B%u0151",
        "infó:": ""
      },
      {
        "Település ID": 329,
        "KSH településazonosító törzsszám": 29203,
        zipCode: 9542,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Boba",
        "infó:": ""
      },
      {
        "Település ID": 330,
        "KSH településazonosító törzsszám": 17543,
        zipCode: 8943,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bocfölde",
        "infó:": ""
      },
      {
        "Település ID": 331,
        "KSH településazonosító törzsszám": 22354,
        zipCode: 3368,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Boconád",
        "infó:": ""
      },
      {
        "Település ID": 332,
        "KSH településazonosító törzsszám": 5306,
        zipCode: 3574,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "B%u0151cs",
        "infó:": ""
      },
      {
        "Település ID": 333,
        "KSH településazonosító törzsszám": 8305,
        zipCode: 6235,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bócsa",
        "infó:": ""
      },
      {
        "Település ID": 334,
        "KSH településazonosító törzsszám": 31291,
        zipCode: 8776,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bocska",
        "infó:": ""
      },
      {
        "Település ID": 335,
        "KSH településazonosító törzsszám": 34102,
        zipCode: 4241,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Bocskaikert",
        "infó:": ""
      },
      {
        "Település ID": 336,
        "KSH településazonosító törzsszám": 20899,
        zipCode: 7672,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Boda",
        "infó:": ""
      },
      {
        "Település ID": 337,
        "KSH településazonosító törzsszám": 18254,
        zipCode: 8053,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Bodajk",
        "infó:": ""
      },
      {
        "Település ID": 588,
        "KSH településazonosító törzsszám": 22877,
        zipCode: 8991,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Böde",
        "infó:": ""
      },
      {
        "Település ID": 589,
        "KSH településazonosító törzsszám": 4163,
        zipCode: 8969,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bödeháza",
        "infó:": ""
      },
      {
        "Település ID": 338,
        "KSH településazonosító törzsszám": 11624,
        zipCode: 8085,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Bodmér",
        "infó:": ""
      },
      {
        "Település ID": 339,
        "KSH településazonosító törzsszám": 33002,
        zipCode: 7394,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bodolyabér",
        "infó:": ""
      },
      {
        "Település ID": 340,
        "KSH településazonosító törzsszám": 6220,
        zipCode: 9134,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Bodonhely",
        "infó:": ""
      },
      {
        "Település ID": 341,
        "KSH településazonosító törzsszám": 14933,
        zipCode: 3243,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Bodony",
        "infó:": ""
      },
      {
        "Település ID": 342,
        "KSH településazonosító törzsszám": 4321,
        zipCode: 8471,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Bodorfa",
        "infó:": ""
      },
      {
        "Település ID": 343,
        "KSH településazonosító törzsszám": 10506,
        zipCode: 7439,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bodrog",
        "infó:": ""
      },
      {
        "Település ID": 344,
        "KSH településazonosító törzsszám": 23737,
        zipCode: 3987,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bodroghalom",
        "infó:": ""
      },
      {
        "Település ID": 345,
        "KSH településazonosító törzsszám": 30784,
        zipCode: 3916,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bodrogkeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 346,
        "KSH településazonosító törzsszám": 33808,
        zipCode: 3917,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bodrogkisfalud",
        "infó:": ""
      },
      {
        "Település ID": 347,
        "KSH településazonosító törzsszám": 14401,
        zipCode: 3943,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bodrogolaszi",
        "infó:": ""
      },
      {
        "Település ID": 348,
        "KSH településazonosító törzsszám": 27429,
        zipCode: 3768,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bódvalenke",
        "infó:": ""
      },
      {
        "Település ID": 349,
        "KSH településazonosító törzsszám": 33303,
        zipCode: 3764,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bódvarákó",
        "infó:": ""
      },
      {
        "Település ID": 350,
        "KSH településazonosító törzsszám": 5926,
        zipCode: 3763,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bódvaszilas",
        "infó:": ""
      },
      {
        "Település ID": 351,
        "KSH településazonosító törzsszám": 25195,
        zipCode: 3412,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bogács",
        "infó:": ""
      },
      {
        "Település ID": 352,
        "KSH településazonosító törzsszám": 32151,
        zipCode: 7742,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bogád",
        "infó:": ""
      },
      {
        "Település ID": 353,
        "KSH településazonosító törzsszám": 21892,
        zipCode: 7836,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bogádmindszent",
        "infó:": ""
      },
      {
        "Település ID": 354,
        "KSH településazonosító törzsszám": 10694,
        zipCode: 7966,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bogdása",
        "infó:": ""
      },
      {
        "Település ID": 590,
        "KSH településazonosító törzsszám": 11651,
        zipCode: 9612,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bögöt",
        "infó:": ""
      },
      {
        "Település ID": 591,
        "KSH településazonosító törzsszám": 7296,
        zipCode: 9675,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bögöte",
        "infó:": ""
      },
      {
        "Település ID": 355,
        "KSH településazonosító törzsszám": 3425,
        zipCode: 7132,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Bogyiszló",
        "infó:": ""
      },
      {
        "Település ID": 356,
        "KSH településazonosító törzsszám": 4367,
        zipCode: 9324,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Bogyoszló",
        "infó:": ""
      },
      {
        "Település ID": 592,
        "KSH településazonosító törzsszám": 26532,
        zipCode: 8719,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Böhönye",
        "infó:": ""
      },
      {
        "Település ID": 357,
        "KSH településazonosító törzsszám": 14137,
        zipCode: 4114,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Bojt",
        "infó:": ""
      },
      {
        "Település ID": 358,
        "KSH településazonosító törzsszám": 19929,
        zipCode: 8741,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bókaháza",
        "infó:": ""
      },
      {
        "Település ID": 359,
        "KSH településazonosító törzsszám": 7311,
        zipCode: 2855,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Bokod",
        "infó:": ""
      },
      {
        "Település ID": 593,
        "KSH településazonosító törzsszám": 11299,
        zipCode: 4231,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Bököny",
        "infó:": ""
      },
      {
        "Település ID": 360,
        "KSH településazonosító törzsszám": 3841,
        zipCode: 3066,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Bokor",
        "infó:": ""
      },
      {
        "Település ID": 594,
        "KSH településazonosító törzsszám": 6558,
        zipCode: 7025,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Bölcske",
        "infó:": ""
      },
      {
        "Település ID": 361,
        "KSH településazonosító törzsszám": 3452,
        zipCode: 3016,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Boldog",
        "infó:": ""
      },
      {
        "Település ID": 362,
        "KSH településazonosító törzsszám": 13116,
        zipCode: 7937,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Boldogasszonyfa",
        "infó:": ""
      },
      {
        "Település ID": 363,
        "KSH településazonosító törzsszám": 18944,
        zipCode: 3884,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Boldogk%u0151újfalu",
        "infó:": ""
      },
      {
        "Település ID": 364,
        "KSH településazonosító törzsszám": 14474,
        zipCode: 3885,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Boldogk%u0151váralja",
        "infó:": ""
      },
      {
        "Település ID": 365,
        "KSH településazonosító törzsszám": 8396,
        zipCode: 3794,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Boldva",
        "infó:": ""
      },
      {
        "Település ID": 366,
        "KSH településazonosító törzsszám": 13994,
        zipCode: 7517,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bolhás",
        "infó:": ""
      },
      {
        "Település ID": 367,
        "KSH településazonosító törzsszám": 18120,
        zipCode: 7586,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bolhó",
        "infó:": ""
      },
      {
        "Település ID": 368,
        "KSH településazonosító törzsszám": 33154,
        zipCode: 7754,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bóly",
        "infó:": ""
      },
      {
        "Település ID": 369,
        "KSH településazonosító törzsszám": 10782,
        zipCode: 8992,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Boncodfölde",
        "infó:": ""
      },
      {
        "Település ID": 370,
        "KSH településazonosító törzsszám": 22673,
        zipCode: 7281,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Bonnya",
        "infó:": ""
      },
      {
        "Település ID": 371,
        "KSH településazonosító törzsszám": 33950,
        zipCode: 9073,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "B%u0151ny",
        "infó:": ""
      },
      {
        "Település ID": 372,
        "KSH településazonosító törzsszám": 6497,
        zipCode: 7150,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Bonyhád",
        "infó:": ""
      },
      {
        "Település ID": 373,
        "KSH településazonosító törzsszám": 6497,
        zipCode: 7187,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Bonyhád",
        "infó:": ""
      },
      {
        "Település ID": 374,
        "KSH településazonosító törzsszám": 14818,
        zipCode: 7158,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Bonyhádvarasd",
        "infó:": ""
      },
      {
        "Település ID": 595,
        "KSH településazonosító törzsszám": 6619,
        zipCode: 9152,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Börcs",
        "infó:": ""
      },
      {
        "Település ID": 375,
        "KSH településazonosító törzsszám": 8192,
        zipCode: 6795,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Bordány",
        "infó:": ""
      },
      {
        "Település ID": 376,
        "KSH településazonosító törzsszám": 3090,
        zipCode: 9554,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Borgáta",
        "infó:": ""
      },
      {
        "Település ID": 377,
        "KSH településazonosító törzsszám": 6725,
        zipCode: 7756,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Borjád",
        "infó:": ""
      },
      {
        "Település ID": 378,
        "KSH településazonosító törzsszám": 19327,
        zipCode: 6445,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Borota",
        "infó:": ""
      },
      {
        "Település ID": 379,
        "KSH településazonosító törzsszám": 10056,
        zipCode: 8885,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Borsfa",
        "infó:": ""
      },
      {
        "Település ID": 380,
        "KSH településazonosító törzsszám": 30669,
        zipCode: 3658,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Borsodbóta",
        "infó:": ""
      },
      {
        "Település ID": 381,
        "KSH településazonosító törzsszám": 16124,
        zipCode: 3426,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Borsodgeszt",
        "infó:": ""
      },
      {
        "Település ID": 382,
        "KSH településazonosító törzsszám": 6707,
        zipCode: 3462,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Borsodivánka",
        "infó:": ""
      },
      {
        "Település ID": 383,
        "KSH településazonosító törzsszám": 5315,
        zipCode: 3671,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Borsodnádasd",
        "infó:": ""
      },
      {
        "Település ID": 384,
        "KSH településazonosító törzsszám": 5315,
        zipCode: 3672,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Borsodnádasd",
        "infó:": ""
      },
      {
        "Település ID": 385,
        "KSH településazonosító törzsszám": 30207,
        zipCode: 3623,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Borsodszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 386,
        "KSH településazonosító törzsszám": 16799,
        zipCode: 3796,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Borsodszirák",
        "infó:": ""
      },
      {
        "Település ID": 387,
        "KSH településazonosító törzsszám": 9894,
        zipCode: 2644,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Borsosberény",
        "infó:": ""
      },
      {
        "Település ID": 388,
        "KSH településazonosító törzsszám": 4765,
        zipCode: 8479,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Borszörcsök",
        "infó:": ""
      },
      {
        "Település ID": 389,
        "KSH településazonosító törzsszám": 30252,
        zipCode: 8428,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Borzavár",
        "infó:": ""
      },
      {
        "Település ID": 596,
        "KSH településazonosító törzsszám": 21139,
        zipCode: 8772,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Börzönce",
        "infó:": ""
      },
      {
        "Település ID": 390,
        "KSH településazonosító törzsszám": 15501,
        zipCode: 9167,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "B%u0151sárkány",
        "infó:": ""
      },
      {
        "Település ID": 391,
        "KSH településazonosító törzsszám": 14368,
        zipCode: 7811,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bosta",
        "infó:": ""
      },
      {
        "Település ID": 392,
        "KSH településazonosító törzsszám": 26277,
        zipCode: 7475,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "B%u0151szénfa",
        "infó:": ""
      },
      {
        "Település ID": 393,
        "KSH településazonosító törzsszám": 22239,
        zipCode: 4955,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Botpalád",
        "infó:": ""
      },
      {
        "Település ID": 394,
        "KSH településazonosító törzsszám": 13365,
        zipCode: 7900,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Botykapeterd",
        "infó:": ""
      },
      {
        "Település ID": 395,
        "KSH településazonosító törzsszám": 5023,
        zipCode: 9727,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bozsok",
        "infó:": ""
      },
      {
        "Település ID": 396,
        "KSH településazonosító törzsszám": 31006,
        zipCode: 3994,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bózsva",
        "infó:": ""
      },
      {
        "Település ID": 397,
        "KSH településazonosító törzsszám": 6390,
        zipCode: 9752,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bozzai",
        "infó:": ""
      },
      {
        "Település ID": 398,
        "KSH településazonosító törzsszám": 13471,
        zipCode: 5527,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Bucsa",
        "infó:": ""
      },
      {
        "Település ID": 399,
        "KSH településazonosító törzsszám": 32984,
        zipCode: 9792,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bucsu",
        "infó:": ""
      },
      {
        "Település ID": 400,
        "KSH településazonosító törzsszám": 20613,
        zipCode: 8925,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bucsuszentlászló",
        "infó:": ""
      },
      {
        "Település ID": 401,
        "KSH településazonosító törzsszám": 7986,
        zipCode: 8893,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Bucsuta",
        "infó:": ""
      },
      {
        "Település ID": 402,
        "KSH településazonosító törzsszám": 3407,
        zipCode: 2093,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Budajen%u0151",
        "infó:": ""
      },
      {
        "Település ID": 403,
        "KSH településazonosító törzsszám": 23463,
        zipCode: 2011,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Budakalász",
        "infó:": ""
      },
      {
        "Település ID": 404,
        "KSH településazonosító törzsszám": 12052,
        zipCode: 2092,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Budakeszi",
        "infó:": ""
      },
      {
        "Település ID": 567,
        "KSH településazonosító törzsszám": 23278,
        zipCode: 2040,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Budaörs",
        "infó:": ""
      },
      {
        "Település ID": 405,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1011,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 406,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1012,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 407,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1013,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 408,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1014,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 409,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1015,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 410,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1016,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 411,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1021,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 412,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1022,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 413,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1023,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 414,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1024,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 415,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1025,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 416,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1026,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 417,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1027,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 418,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1028,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 419,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1029,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 420,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1031,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 421,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1032,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 422,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1033,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 423,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1034,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 424,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1035,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 425,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1036,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 426,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1037,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 427,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1038,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 428,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1039,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 429,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1041,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 430,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1042,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 431,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1043,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 432,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1044,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 433,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1045,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 434,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1046,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 435,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1047,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 436,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1048,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 437,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1051,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 438,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1052,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 439,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1053,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 440,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1054,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 441,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1055,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 442,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1056,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 443,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1061,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 444,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1062,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 445,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1063,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 446,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1064,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 447,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1065,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 448,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1066,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 449,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1067,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 450,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1068,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 451,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1069,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 452,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1071,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 453,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1072,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 454,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1073,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 455,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1074,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 456,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1075,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 457,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1076,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 458,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1077,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 459,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1078,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 460,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1081,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 461,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1082,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 462,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1083,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 463,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1084,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 464,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1085,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 465,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1086,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 466,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1087,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 467,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1088,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 468,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1089,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 469,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1091,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 470,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1092,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 471,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1093,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 472,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1094,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 473,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1095,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 474,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1096,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 475,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1097,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 476,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1098,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 477,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1101,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 478,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1102,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 479,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1103,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 480,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1104,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 481,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1105,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 482,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1106,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 483,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1107,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 484,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1108,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 485,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1111,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 486,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1112,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 487,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1113,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 488,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1114,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 489,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1115,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 490,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1116,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 491,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1117,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 492,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1118,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 493,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1119,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 494,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1121,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 495,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1122,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 496,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1123,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 497,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1124,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 498,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1125,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 499,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1126,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 500,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1131,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 501,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1132,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 502,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1133,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 503,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1134,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 504,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1135,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 505,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1136,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 506,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1137,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 507,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1138,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 508,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1139,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 509,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1141,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 510,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1142,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 511,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1143,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 512,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1144,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 513,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1145,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 514,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1146,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 515,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1147,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 516,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1148,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 517,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1149,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 518,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1151,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 519,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1152,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 520,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1153,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 521,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1154,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 522,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1155,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 523,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1156,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 524,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1157,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 525,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1158,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 526,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1161,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 527,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1162,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 528,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1163,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 529,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1164,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 530,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1165,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 531,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1171,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 532,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1172,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 533,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1173,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 534,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1174,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 535,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1181,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 536,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1182,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 537,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1183,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 538,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1184,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 539,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1185,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 540,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1186,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 541,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1188,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 542,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1191,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 543,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1192,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 544,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1193,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 545,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1194,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 546,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1195,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 547,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1196,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 548,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1201,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 549,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1202,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 550,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1203,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 551,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1204,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 552,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1205,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 553,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1211,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 554,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1212,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 555,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1213,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 556,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1214,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 557,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1215,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 558,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1221,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 559,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1222,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 560,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1223,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 561,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1224,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 562,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1225,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 563,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1237,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 564,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1238,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 565,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1239,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 566,
        "KSH településazonosító törzsszám": 13578,
        zipCode: 1529,
        "Megye ID": 0,
        "Megye név": "F%u0151város",
        cityName: "Budapest",
        "infó:": ""
      },
      {
        "Település ID": 568,
        "KSH településazonosító törzsszám": 32823,
        zipCode: 6114,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bugac",
        "infó:": ""
      },
      {
        "Település ID": 569,
        "KSH településazonosító törzsszám": 33631,
        zipCode: 6114,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Bugacpusztaháza",
        "infó:": ""
      },
      {
        "Település ID": 570,
        "KSH településazonosító törzsszám": 32027,
        zipCode: 2347,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Bugyi",
        "infó:": ""
      },
      {
        "Település ID": 571,
        "KSH településazonosító törzsszám": 19707,
        zipCode: 4483,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Buj",
        "infó:": ""
      },
      {
        "Település ID": 572,
        "KSH településazonosító törzsszám": 14234,
        zipCode: 3047,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Buják",
        "infó:": ""
      },
      {
        "Település ID": 574,
        "KSH településazonosító törzsszám": 2431,
        zipCode: 9737,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bük",
        "infó:": ""
      },
      {
        "Település ID": 575,
        "KSH településazonosító törzsszám": 2431,
        zipCode: 9740,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Bük",
        "infó:": ""
      },
      {
        "Település ID": 576,
        "KSH településazonosító törzsszám": 13596,
        zipCode: 3422,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bükkábrány",
        "infó:": ""
      },
      {
        "Település ID": 577,
        "KSH településazonosító törzsszám": 27890,
        zipCode: 3554,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bükkaranyos",
        "infó:": ""
      },
      {
        "Település ID": 578,
        "KSH településazonosító törzsszám": 19406,
        zipCode: 3648,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bükkmogyorósd",
        "infó:": ""
      },
      {
        "Település ID": 584,
        "KSH településazonosító törzsszám": 23162,
        zipCode: 7682,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bükkösd",
        "infó:": ""
      },
      {
        "Település ID": 579,
        "KSH településazonosító törzsszám": 2963,
        zipCode: 3335,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Bükkszék",
        "infó:": ""
      },
      {
        "Település ID": 580,
        "KSH településazonosító törzsszám": 10621,
        zipCode: 3257,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Bükkszenterzsébet",
        "infó:": ""
      },
      {
        "Település ID": 581,
        "KSH településazonosító törzsszám": 8022,
        zipCode: 3557,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bükkszentkereszt",
        "infó:": ""
      },
      {
        "Település ID": 582,
        "KSH településazonosító törzsszám": 22099,
        zipCode: 3346,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Bükkszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 583,
        "KSH településazonosító törzsszám": 32887,
        zipCode: 3414,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Bükkzsérc",
        "infó:": ""
      },
      {
        "Település ID": 585,
        "KSH településazonosító törzsszám": 7533,
        zipCode: 7973,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Bürüs",
        "infó:": ""
      },
      {
        "Település ID": 586,
        "KSH településazonosító törzsszám": 8703,
        zipCode: 7273,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Büssü",
        "infó:": ""
      },
      {
        "Település ID": 587,
        "KSH településazonosító törzsszám": 23977,
        zipCode: 3821,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Büttös",
        "infó:": ""
      },
      {
        "Település ID": 573,
        "KSH településazonosító törzsszám": 17358,
        zipCode: 8695,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Buzsák",
        "infó:": ""
      },
      {
        "Település ID": 597,
        "KSH településazonosító törzsszám": 8271,
        zipCode: 9725,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Cák",
        "infó:": ""
      },
      {
        "Település ID": 598,
        "KSH településazonosító törzsszám": 27085,
        zipCode: 9165,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Cakóháza",
        "infó:": ""
      },
      {
        "Település ID": 599,
        "KSH településazonosító törzsszám": 13152,
        zipCode: 7013,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Cece",
        "infó:": ""
      },
      {
        "Település ID": 600,
        "KSH településazonosító törzsszám": 9681,
        zipCode: 4732,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Cégénydányád",
        "infó:": ""
      },
      {
        "Település ID": 601,
        "KSH településazonosító törzsszám": 11341,
        zipCode: 2700,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Cegléd",
        "infó:": ""
      },
      {
        "Település ID": 602,
        "KSH településazonosító törzsszám": 11341,
        zipCode: 2738,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Cegléd",
        "infó:": ""
      },
      {
        "Település ID": 603,
        "KSH településazonosító törzsszám": 20640,
        zipCode: 2737,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Ceglédbercel",
        "infó:": ""
      },
      {
        "Település ID": 604,
        "KSH településazonosító törzsszám": 27094,
        zipCode: 9500,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Celldömölk",
        "infó:": ""
      },
      {
        "Település ID": 605,
        "KSH településazonosító törzsszám": 27094,
        zipCode: 9541,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Celldömölk",
        "infó:": ""
      },
      {
        "Település ID": 606,
        "KSH településazonosító törzsszám": 3665,
        zipCode: 3123,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Cered",
        "infó:": ""
      },
      {
        "Település ID": 607,
        "KSH településazonosító törzsszám": 9876,
        zipCode: 9624,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Chernelházadamonya",
        "infó:": ""
      },
      {
        "Település ID": 608,
        "KSH településazonosító törzsszám": 22938,
        zipCode: 5462,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Cibakháza",
        "infó:": ""
      },
      {
        "Település ID": 609,
        "KSH településazonosító törzsszám": 3939,
        zipCode: 3973,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Cigánd",
        "infó:": ""
      },
      {
        "Település ID": 610,
        "KSH településazonosító törzsszám": 19284,
        zipCode: 7161,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Cikó",
        "infó:": ""
      },
      {
        "Település ID": 611,
        "KSH településazonosító törzsszám": 15954,
        zipCode: 9364,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Cirák",
        "infó:": ""
      },
      {
        "Település ID": 612,
        "KSH településazonosító törzsszám": 31334,
        zipCode: 5551,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Csabacs%u0171d",
        "infó:": ""
      },
      {
        "Település ID": 613,
        "KSH településazonosító törzsszám": 34078,
        zipCode: 5609,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Csabaszabadi",
        "infó:": ""
      },
      {
        "Település ID": 614,
        "KSH településazonosító törzsszám": 30544,
        zipCode: 2064,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Csabdi",
        "infó:": ""
      },
      {
        "Település ID": 615,
        "KSH településazonosító törzsszám": 30924,
        zipCode: 8474,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Csabrendek",
        "infó:": ""
      },
      {
        "Település ID": 616,
        "KSH településazonosító törzsszám": 10047,
        zipCode: 9375,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Csáfordjánosfa",
        "infó:": ""
      },
      {
        "Település ID": 617,
        "KSH településazonosító törzsszám": 12928,
        zipCode: 4967,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Csaholc",
        "infó:": ""
      },
      {
        "Település ID": 618,
        "KSH településazonosító törzsszám": 16072,
        zipCode: 8163,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Csajág",
        "infó:": ""
      },
      {
        "Település ID": 619,
        "KSH településazonosító törzsszám": 3799,
        zipCode: 8735,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Csákány",
        "infó:": ""
      },
      {
        "Település ID": 620,
        "KSH településazonosító törzsszám": 3911,
        zipCode: 9919,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csákánydoroszló",
        "infó:": ""
      },
      {
        "Település ID": 621,
        "KSH településazonosító törzsszám": 5360,
        zipCode: 8073,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Csákberény",
        "infó:": ""
      },
      {
        "Település ID": 622,
        "KSH településazonosító törzsszám": 20002,
        zipCode: 8083,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Csákvár",
        "infó:": ""
      },
      {
        "Település ID": 623,
        "KSH településazonosító törzsszám": 2121,
        zipCode: 6915,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Csanádalberti",
        "infó:": ""
      },
      {
        "Település ID": 624,
        "KSH településazonosító törzsszám": 20455,
        zipCode: 5662,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Csanádapáca",
        "infó:": ""
      },
      {
        "Település ID": 625,
        "KSH településazonosító törzsszám": 5379,
        zipCode: 6913,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Csanádpalota",
        "infó:": ""
      },
      {
        "Település ID": 626,
        "KSH településazonosító törzsszám": 26772,
        zipCode: 9654,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csánig",
        "infó:": ""
      },
      {
        "Település ID": 627,
        "KSH településazonosító törzsszám": 16841,
        zipCode: 3015,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Csány",
        "infó:": ""
      },
      {
        "Település ID": 628,
        "KSH településazonosító törzsszám": 19901,
        zipCode: 7964,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Csányoszró",
        "infó:": ""
      },
      {
        "Település ID": 629,
        "KSH településazonosító törzsszám": 22293,
        zipCode: 6647,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Csanytelek",
        "infó:": ""
      },
      {
        "Település ID": 630,
        "KSH településazonosító törzsszám": 6132,
        zipCode: 8756,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Csapi",
        "infó:": ""
      },
      {
        "Település ID": 631,
        "KSH településazonosító törzsszám": 8563,
        zipCode: 9372,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Csapod",
        "infó:": ""
      },
      {
        "Település ID": 632,
        "KSH településazonosító törzsszám": 25502,
        zipCode: 5621,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Csárdaszállás",
        "infó:": ""
      },
      {
        "Település ID": 633,
        "KSH településazonosító törzsszám": 30614,
        zipCode: 7811,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Csarnóta",
        "infó:": ""
      },
      {
        "Település ID": 634,
        "KSH településazonosító törzsszám": 29416,
        zipCode: 4844,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Csaroda",
        "infó:": ""
      },
      {
        "Település ID": 635,
        "KSH településazonosító törzsszám": 16416,
        zipCode: 2858,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Császár",
        "infó:": ""
      },
      {
        "Település ID": 636,
        "KSH településazonosító törzsszám": 10472,
        zipCode: 6239,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Császártöltés",
        "infó:": ""
      },
      {
        "Település ID": 637,
        "KSH településazonosító törzsszám": 9715,
        zipCode: 4973,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Császló",
        "infó:": ""
      },
      {
        "Település ID": 638,
        "KSH településazonosító törzsszám": 26471,
        zipCode: 6523,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Csátalja",
        "infó:": ""
      },
      {
        "Település ID": 639,
        "KSH településazonosító törzsszám": 23436,
        zipCode: 8943,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Csatár",
        "infó:": ""
      },
      {
        "Település ID": 640,
        "KSH településazonosító törzsszám": 34175,
        zipCode: 5064,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Csataszög",
        "infó:": ""
      },
      {
        "Település ID": 641,
        "KSH településazonosító törzsszám": 33109,
        zipCode: 2888,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Csatka",
        "infó:": ""
      },
      {
        "Település ID": 642,
        "KSH településazonosító törzsszám": 16373,
        zipCode: 6448,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Csávoly",
        "infó:": ""
      },
      {
        "Település ID": 643,
        "KSH településazonosító törzsszám": 21591,
        zipCode: 7935,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Csebény",
        "infó:": ""
      },
      {
        "Település ID": 644,
        "KSH településazonosító törzsszám": 30270,
        zipCode: 3052,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Csécse",
        "infó:": ""
      },
      {
        "Település ID": 645,
        "KSH településazonosító törzsszám": 26107,
        zipCode: 4742,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Csegöld",
        "infó:": ""
      },
      {
        "Település ID": 646,
        "KSH településazonosító törzsszám": 20251,
        zipCode: 8445,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Csehbánya",
        "infó:": ""
      },
      {
        "Település ID": 647,
        "KSH településazonosító törzsszám": 19488,
        zipCode: 9833,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csehi",
        "infó:": ""
      },
      {
        "Település ID": 648,
        "KSH településazonosító törzsszám": 12724,
        zipCode: 9834,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csehimindszent",
        "infó:": ""
      },
      {
        "Település ID": 649,
        "KSH településazonosító törzsszám": 33640,
        zipCode: 2949,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Csém",
        "infó:": ""
      },
      {
        "Település ID": 650,
        "KSH településazonosító törzsszám": 5184,
        zipCode: 2713,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Csem%u0151",
        "infó:": ""
      },
      {
        "Település ID": 651,
        "KSH településazonosító törzsszám": 23816,
        zipCode: 9764,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csempeszkopács",
        "infó:": ""
      },
      {
        "Település ID": 652,
        "KSH településazonosító törzsszám": 32285,
        zipCode: 6765,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Csengele",
        "infó:": ""
      },
      {
        "Település ID": 653,
        "KSH településazonosító törzsszám": 30641,
        zipCode: 4765,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Csenger",
        "infó:": ""
      },
      {
        "Település ID": 654,
        "KSH településazonosító törzsszám": 24095,
        zipCode: 4743,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Csengersima",
        "infó:": ""
      },
      {
        "Település ID": 655,
        "KSH településazonosító törzsszám": 26851,
        zipCode: 4764,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Csengerújfalu",
        "infó:": ""
      },
      {
        "Település ID": 656,
        "KSH településazonosító törzsszám": 12344,
        zipCode: 6222,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Cseng%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 657,
        "KSH településazonosító törzsszám": 9070,
        zipCode: 9611,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csénye",
        "infó:": ""
      },
      {
        "Település ID": 658,
        "KSH településazonosító törzsszám": 8493,
        zipCode: 3837,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Csenyéte",
        "infó:": ""
      },
      {
        "Település ID": 659,
        "KSH településazonosító törzsszám": 18272,
        zipCode: 2946,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Csép",
        "infó:": ""
      },
      {
        "Település ID": 660,
        "KSH településazonosító törzsszám": 13170,
        zipCode: 5475,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Csépa",
        "infó:": ""
      },
      {
        "Település ID": 661,
        "KSH településazonosító törzsszám": 12140,
        zipCode: 9735,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csepreg",
        "infó:": ""
      },
      {
        "Település ID": 662,
        "KSH településazonosító törzsszám": 30191,
        zipCode: 9375,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Csér",
        "infó:": ""
      },
      {
        "Település ID": 663,
        "KSH településazonosító törzsszám": 26082,
        zipCode: 7683,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Cserdi",
        "infó:": ""
      },
      {
        "Település ID": 664,
        "KSH településazonosító törzsszám": 25681,
        zipCode: 7472,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Cserénfa",
        "infó:": ""
      },
      {
        "Település ID": 665,
        "KSH településazonosító törzsszám": 6974,
        zipCode: 3413,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Cserépfalu",
        "infó:": ""
      },
      {
        "Település ID": 666,
        "KSH településazonosító törzsszám": 25575,
        zipCode: 3417,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Cserépváralja",
        "infó:": ""
      },
      {
        "Település ID": 667,
        "KSH településazonosító törzsszám": 21935,
        zipCode: 2694,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Cserháthaláp",
        "infó:": ""
      },
      {
        "Település ID": 668,
        "KSH településazonosító törzsszám": 22594,
        zipCode: 2676,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Cserhátsurány",
        "infó:": ""
      },
      {
        "Település ID": 669,
        "KSH településazonosító törzsszám": 32319,
        zipCode: 3066,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Cserhátszentiván",
        "infó:": ""
      },
      {
        "Település ID": 670,
        "KSH településazonosító törzsszám": 5795,
        zipCode: 5465,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Cserkesz%u0151l%u0151",
        "infó:": ""
      },
      {
        "Település ID": 671,
        "KSH településazonosító törzsszám": 3896,
        zipCode: 7673,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Cserkút",
        "infó:": ""
      },
      {
        "Település ID": 672,
        "KSH településazonosító törzsszám": 28459,
        zipCode: 3648,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Csernely",
        "infó:": ""
      },
      {
        "Település ID": 673,
        "KSH településazonosító törzsszám": 7135,
        zipCode: 8372,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Cserszegtomaj",
        "infó:": ""
      },
      {
        "Település ID": 674,
        "KSH településazonosító törzsszám": 10649,
        zipCode: 8951,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Csertalakos",
        "infó:": ""
      },
      {
        "Település ID": 675,
        "KSH településazonosító törzsszám": 13851,
        zipCode: 7900,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Csert%u0151",
        "infó:": ""
      },
      {
        "Település ID": 676,
        "KSH településazonosító törzsszám": 24642,
        zipCode: 8419,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Csesznek",
        "infó:": ""
      },
      {
        "Település ID": 677,
        "KSH településazonosító törzsszám": 2583,
        zipCode: 8973,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Csesztreg",
        "infó:": ""
      },
      {
        "Település ID": 678,
        "KSH településazonosító törzsszám": 20145,
        zipCode: 2678,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Csesztve",
        "infó:": ""
      },
      {
        "Település ID": 679,
        "KSH településazonosító törzsszám": 31699,
        zipCode: 8417,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Csetény",
        "infó:": ""
      },
      {
        "Település ID": 680,
        "KSH településazonosító törzsszám": 18476,
        zipCode: 2212,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Csévharaszt",
        "infó:": ""
      },
      {
        "Település ID": 681,
        "KSH településazonosító törzsszám": 11998,
        zipCode: 7225,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Csibrák",
        "infó:": ""
      },
      {
        "Település ID": 682,
        "KSH településazonosító törzsszám": 15699,
        zipCode: 6424,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Csikéria",
        "infó:": ""
      },
      {
        "Település ID": 683,
        "KSH településazonosító törzsszám": 30094,
        zipCode: 7341,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Csikóst%u0151tt%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 684,
        "KSH településazonosító törzsszám": 13505,
        zipCode: 9127,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Csikvánd",
        "infó:": ""
      },
      {
        "Település ID": 685,
        "KSH településazonosító törzsszám": 34111,
        zipCode: 3442,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Csincse",
        "infó:": ""
      },
      {
        "Település ID": 686,
        "KSH településazonosító törzsszám": 26064,
        zipCode: 9836,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csipkerek",
        "infó:": ""
      },
      {
        "Település ID": 687,
        "KSH településazonosító törzsszám": 5050,
        zipCode: 2673,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Csitár",
        "infó:": ""
      },
      {
        "Település ID": 688,
        "KSH településazonosító törzsszám": 5333,
        zipCode: 3848,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Csobád",
        "infó:": ""
      },
      {
        "Település ID": 689,
        "KSH településazonosító törzsszám": 20774,
        zipCode: 3927,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Csobaj",
        "infó:": ""
      },
      {
        "Település ID": 690,
        "KSH településazonosító törzsszám": 6822,
        zipCode: 2014,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Csobánka",
        "infó:": ""
      },
      {
        "Település ID": 712,
        "KSH településazonosító törzsszám": 27492,
        zipCode: 8999,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Csöde",
        "infó:": ""
      },
      {
        "Település ID": 713,
        "KSH településazonosító törzsszám": 32814,
        zipCode: 8495,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Csögle",
        "infó:": ""
      },
      {
        "Település ID": 691,
        "KSH településazonosító törzsszám": 21908,
        zipCode: 8074,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Csókak%u0151",
        "infó:": ""
      },
      {
        "Település ID": 714,
        "KSH településazonosító törzsszám": 12450,
        zipCode: 4145,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Csökm%u0151",
        "infó:": ""
      },
      {
        "Település ID": 715,
        "KSH településazonosító törzsszám": 24314,
        zipCode: 7526,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Csököly",
        "infó:": ""
      },
      {
        "Település ID": 692,
        "KSH településazonosító törzsszám": 5971,
        zipCode: 7555,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Csokonyavisonta",
        "infó:": ""
      },
      {
        "Település ID": 693,
        "KSH településazonosító törzsszám": 14289,
        zipCode: 3647,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Csokvaomány",
        "infó:": ""
      },
      {
        "Település ID": 694,
        "KSH településazonosító törzsszám": 18926,
        zipCode: 2521,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Csolnok",
        "infó:": ""
      },
      {
        "Település ID": 695,
        "KSH településazonosító törzsszám": 12025,
        zipCode: 6135,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Csólyospálos",
        "infó:": ""
      },
      {
        "Település ID": 696,
        "KSH településazonosító törzsszám": 8040,
        zipCode: 7253,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Csoma",
        "infó:": ""
      },
      {
        "Település ID": 697,
        "KSH településazonosító törzsszám": 33118,
        zipCode: 2161,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Csomád",
        "infó:": ""
      },
      {
        "Település ID": 698,
        "KSH településazonosító törzsszám": 2477,
        zipCode: 7432,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Csombárd",
        "infó:": ""
      },
      {
        "Település ID": 716,
        "KSH településazonosító törzsszám": 27270,
        zipCode: 8700,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Csömend",
        "infó:": ""
      },
      {
        "Település ID": 717,
        "KSH településazonosító törzsszám": 29364,
        zipCode: 8957,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Csömödér",
        "infó:": ""
      },
      {
        "Település ID": 718,
        "KSH településazonosító törzsszám": 22804,
        zipCode: 2141,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Csömör",
        "infó:": ""
      },
      {
        "Település ID": 719,
        "KSH településazonosító törzsszám": 22390,
        zipCode: 9513,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csönge",
        "infó:": ""
      },
      {
        "Település ID": 699,
        "KSH településazonosító törzsszám": 5111,
        zipCode: 6640,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Csongrád",
        "infó:": ""
      },
      {
        "Település ID": 700,
        "KSH településazonosító törzsszám": 5111,
        zipCode: 6648,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Csongrád",
        "infó:": ""
      },
      {
        "Település ID": 701,
        "KSH településazonosító törzsszám": 31149,
        zipCode: 8918,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Csonkahegyhát",
        "infó:": ""
      },
      {
        "Település ID": 702,
        "KSH településazonosító törzsszám": 22576,
        zipCode: 7940,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Csonkamindszent",
        "infó:": ""
      },
      {
        "Település ID": 703,
        "KSH településazonosító törzsszám": 2185,
        zipCode: 8229,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Csopak",
        "infó:": ""
      },
      {
        "Település ID": 704,
        "KSH településazonosító törzsszám": 9779,
        zipCode: 8041,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Csór",
        "infó:": ""
      },
      {
        "Település ID": 705,
        "KSH településazonosító törzsszám": 4039,
        zipCode: 9300,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Csorna",
        "infó:": ""
      },
      {
        "Település ID": 720,
        "KSH településazonosító törzsszám": 33978,
        zipCode: 8873,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Csörnyeföld",
        "infó:": ""
      },
      {
        "Település ID": 721,
        "KSH településazonosító törzsszám": 34333,
        zipCode: 2135,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Csörög",
        "infó:": ""
      },
      {
        "Település ID": 722,
        "KSH településazonosító törzsszám": 4224,
        zipCode: 9962,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Csörötnek",
        "infó:": ""
      },
      {
        "Település ID": 706,
        "KSH településazonosító törzsszám": 26709,
        zipCode: 5920,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Csorvás",
        "infó:": ""
      },
      {
        "Település ID": 707,
        "KSH településazonosító törzsszám": 6734,
        zipCode: 8122,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Cs%u0151sz",
        "infó:": ""
      },
      {
        "Település ID": 708,
        "KSH településazonosító törzsszám": 32878,
        zipCode: 8558,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Csót",
        "infó:": ""
      },
      {
        "Település ID": 709,
        "KSH településazonosító törzsszám": 26985,
        zipCode: 2615,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Cs%u0151vár",
        "infó:": ""
      },
      {
        "Település ID": 710,
        "KSH településazonosító törzsszám": 21315,
        zipCode: 8840,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Csurgó",
        "infó:": ""
      },
      {
        "Település ID": 711,
        "KSH településazonosító törzsszám": 10603,
        zipCode: 8840,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Csurgónagymarton",
        "infó:": ""
      },
      {
        "Település ID": 723,
        "KSH településazonosító törzsszám": 11086,
        zipCode: 7843,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Cún",
        "infó:": ""
      },
      {
        "Település ID": 724,
        "KSH településazonosító törzsszám": 9247,
        zipCode: 2370,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dabas",
        "infó:": ""
      },
      {
        "Település ID": 725,
        "KSH településazonosító törzsszám": 9247,
        zipCode: 2371,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dabas",
        "infó:": ""
      },
      {
        "Település ID": 726,
        "KSH településazonosító törzsszám": 9247,
        zipCode: 2373,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dabas",
        "infó:": ""
      },
      {
        "Település ID": 727,
        "KSH településazonosító törzsszám": 17172,
        zipCode: 8345,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Dabronc",
        "infó:": ""
      },
      {
        "Település ID": 728,
        "KSH településazonosító törzsszám": 28237,
        zipCode: 8485,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Dabrony",
        "infó:": ""
      },
      {
        "Település ID": 729,
        "KSH településazonosító törzsszám": 33163,
        zipCode: 2854,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Dad",
        "infó:": ""
      },
      {
        "Település ID": 730,
        "KSH településazonosító törzsszám": 22910,
        zipCode: 2522,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Dág",
        "infó:": ""
      },
      {
        "Település ID": 731,
        "KSH településazonosító törzsszám": 20154,
        zipCode: 8592,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Dáka",
        "infó:": ""
      },
      {
        "Település ID": 732,
        "KSH településazonosító törzsszám": 29230,
        zipCode: 7211,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Dalmand",
        "infó:": ""
      },
      {
        "Település ID": 733,
        "KSH településazonosító törzsszám": 11350,
        zipCode: 3780,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Damak",
        "infó:": ""
      },
      {
        "Település ID": 734,
        "KSH településazonosító törzsszám": 7719,
        zipCode: 3978,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Dámóc",
        "infó:": ""
      },
      {
        "Település ID": 735,
        "KSH településazonosító törzsszám": 31811,
        zipCode: 2735,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dánszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 736,
        "KSH településazonosító törzsszám": 18397,
        zipCode: 2118,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dány",
        "infó:": ""
      },
      {
        "Település ID": 737,
        "KSH településazonosító törzsszám": 15990,
        zipCode: 9917,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Daraboshegy",
        "infó:": ""
      },
      {
        "Település ID": 738,
        "KSH településazonosító törzsszám": 31352,
        zipCode: 7988,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Darány",
        "infó:": ""
      },
      {
        "Település ID": 739,
        "KSH településazonosító törzsszám": 18795,
        zipCode: 4737,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Darnó",
        "infó:": ""
      },
      {
        "Település ID": 740,
        "KSH településazonosító törzsszám": 21865,
        zipCode: 9232,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Darnózseli",
        "infó:": ""
      },
      {
        "Település ID": 741,
        "KSH településazonosító törzsszám": 34342,
        zipCode: 2423,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Daruszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 742,
        "KSH településazonosító törzsszám": 14678,
        zipCode: 4144,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Darvas",
        "infó:": ""
      },
      {
        "Település ID": 743,
        "KSH településazonosító törzsszám": 10533,
        zipCode: 6524,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Dávod",
        "infó:": ""
      },
      {
        "Település ID": 744,
        "KSH településazonosító törzsszám": 7320,
        zipCode: 2694,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Debercsény",
        "infó:": ""
      },
      {
        "Település ID": 745,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4000,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 746,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4002,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 747,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4024,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 748,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4025,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 749,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4026,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 750,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4027,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 751,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4028,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 752,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4029,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 753,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4030,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 754,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4031,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 755,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4032,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 756,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4033,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 757,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4034,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 758,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4063,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 759,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4078,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 760,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4079,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 761,
        "KSH településazonosító törzsszám": 15130,
        zipCode: 4225,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Debrecen",
        "infó:": ""
      },
      {
        "Település ID": 762,
        "KSH településazonosító törzsszám": 31954,
        zipCode: 3825,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Debréte",
        "infó:": ""
      },
      {
        "Település ID": 763,
        "KSH településazonosító törzsszám": 24989,
        zipCode: 7144,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Decs",
        "infó:": ""
      },
      {
        "Település ID": 764,
        "KSH településazonosító törzsszám": 4686,
        zipCode: 3643,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Dédestapolcsány",
        "infó:": ""
      },
      {
        "Település ID": 765,
        "KSH településazonosító törzsszám": 32753,
        zipCode: 8135,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Dég",
        "infó:": ""
      },
      {
        "Település ID": 766,
        "KSH településazonosító törzsszám": 12511,
        zipCode: 2649,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Dejtár",
        "infó:": ""
      },
      {
        "Település ID": 767,
        "KSH településazonosító törzsszám": 9973,
        zipCode: 2337,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Délegyháza",
        "infó:": ""
      },
      {
        "Település ID": 768,
        "KSH településazonosító törzsszám": 17756,
        zipCode: 4516,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Demecser",
        "infó:": ""
      },
      {
        "Település ID": 769,
        "KSH településazonosító törzsszám": 8660,
        zipCode: 3395,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Demjén",
        "infó:": ""
      },
      {
        "Település ID": 770,
        "KSH településazonosító törzsszám": 7773,
        zipCode: 7915,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Dencsháza",
        "infó:": ""
      },
      {
        "Település ID": 771,
        "KSH településazonosító törzsszám": 32595,
        zipCode: 9365,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Dénesfa",
        "infó:": ""
      },
      {
        "Település ID": 772,
        "KSH településazonosító törzsszám": 5573,
        zipCode: 4130,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Derecske",
        "infó:": ""
      },
      {
        "Település ID": 773,
        "KSH településazonosító törzsszám": 7834,
        zipCode: 6621,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Derekegyház",
        "infó:": ""
      },
      {
        "Település ID": 774,
        "KSH településazonosító törzsszám": 24077,
        zipCode: 6772,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Deszk",
        "infó:": ""
      },
      {
        "Település ID": 775,
        "KSH településazonosító törzsszám": 10524,
        zipCode: 3834,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Detek",
        "infó:": ""
      },
      {
        "Település ID": 776,
        "KSH településazonosító törzsszám": 9201,
        zipCode: 3275,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Detk",
        "infó:": ""
      },
      {
        "Település ID": 777,
        "KSH településazonosító törzsszám": 24819,
        zipCode: 5510,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Dévaványa",
        "infó:": ""
      },
      {
        "Település ID": 778,
        "KSH településazonosító törzsszám": 32276,
        zipCode: 8460,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Devecser",
        "infó:": ""
      },
      {
        "Település ID": 779,
        "KSH településazonosító törzsszám": 11952,
        zipCode: 7683,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Dinnyeberki",
        "infó:": ""
      },
      {
        "Település ID": 780,
        "KSH településazonosító törzsszám": 11688,
        zipCode: 7072,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Diósberény",
        "infó:": ""
      },
      {
        "Település ID": 781,
        "KSH településazonosító törzsszám": 24013,
        zipCode: 2049,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Diósd",
        "infó:": ""
      },
      {
        "Település ID": 782,
        "KSH településazonosító törzsszám": 6743,
        zipCode: 2643,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Diósjen%u0151",
        "infó:": ""
      },
      {
        "Település ID": 783,
        "KSH településazonosító törzsszám": 28778,
        zipCode: 8764,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Dióskál",
        "infó:": ""
      },
      {
        "Település ID": 784,
        "KSH településazonosító törzsszám": 32373,
        zipCode: 7817,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Diósviszló",
        "infó:": ""
      },
      {
        "Település ID": 785,
        "KSH településazonosító törzsszám": 10870,
        zipCode: 8482,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Doba",
        "infó:": ""
      },
      {
        "Település ID": 846,
        "KSH településazonosító törzsszám": 20695,
        zipCode: 9914,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Döbörhegy",
        "infó:": ""
      },
      {
        "Település ID": 786,
        "KSH településazonosító törzsszám": 33190,
        zipCode: 5624,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Doboz",
        "infó:": ""
      },
      {
        "Település ID": 787,
        "KSH településazonosító törzsszám": 25885,
        zipCode: 8874,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Dobri",
        "infó:": ""
      },
      {
        "Település ID": 843,
        "KSH településazonosító törzsszám": 7782,
        zipCode: 8357,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Döbröce",
        "infó:": ""
      },
      {
        "Település ID": 844,
        "KSH településazonosító törzsszám": 2565,
        zipCode: 7228,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Döbrököz",
        "infó:": ""
      },
      {
        "Település ID": 788,
        "KSH településazonosító törzsszám": 32708,
        zipCode: 8989,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Dobronhegy",
        "infó:": ""
      },
      {
        "Település ID": 845,
        "KSH településazonosító törzsszám": 29470,
        zipCode: 8597,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Döbrönte",
        "infó:": ""
      },
      {
        "Település ID": 789,
        "KSH településazonosító törzsszám": 30535,
        zipCode: 6766,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Dóc",
        "infó:": ""
      },
      {
        "Település ID": 847,
        "KSH településazonosító törzsszám": 3647,
        zipCode: 4495,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Döge",
        "infó:": ""
      },
      {
        "Település ID": 790,
        "KSH településazonosító törzsszám": 25690,
        zipCode: 3627,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Domaháza",
        "infó:": ""
      },
      {
        "Település ID": 791,
        "KSH településazonosító törzsszám": 13383,
        zipCode: 6781,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Domaszék",
        "infó:": ""
      },
      {
        "Település ID": 792,
        "KSH településazonosító törzsszám": 24031,
        zipCode: 5836,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Dombegyház",
        "infó:": ""
      },
      {
        "Település ID": 793,
        "KSH településazonosító törzsszám": 22132,
        zipCode: 5745,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Dombiratos",
        "infó:": ""
      },
      {
        "Település ID": 794,
        "KSH településazonosító törzsszám": 7685,
        zipCode: 7200,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Dombóvár",
        "infó:": ""
      },
      {
        "Település ID": 795,
        "KSH településazonosító törzsszám": 14508,
        zipCode: 4492,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Dombrád",
        "infó:": ""
      },
      {
        "Település ID": 796,
        "KSH településazonosító törzsszám": 4808,
        zipCode: 2182,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Domony",
        "infó:": ""
      },
      {
        "Település ID": 849,
        "KSH településazonosító törzsszám": 6594,
        zipCode: 2027,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Dömös",
        "infó:": ""
      },
      {
        "Település ID": 797,
        "KSH településazonosító törzsszám": 7515,
        zipCode: 3263,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Domoszló",
        "infó:": ""
      },
      {
        "Település ID": 848,
        "KSH településazonosító törzsszám": 29647,
        zipCode: 2344,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dömsöd",
        "infó:": ""
      },
      {
        "Település ID": 850,
        "KSH településazonosító törzsszám": 21917,
        zipCode: 9147,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Dör",
        "infó:": ""
      },
      {
        "Település ID": 851,
        "KSH településazonosító törzsszám": 6363,
        zipCode: 8244,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Dörgicse",
        "infó:": ""
      },
      {
        "Település ID": 798,
        "KSH településazonosító törzsszám": 30261,
        zipCode: 3374,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Dormánd",
        "infó:": ""
      },
      {
        "Település ID": 799,
        "KSH településazonosító törzsszám": 10490,
        zipCode: 2510,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Dorog",
        "infó:": ""
      },
      {
        "Település ID": 800,
        "KSH településazonosító törzsszám": 24439,
        zipCode: 3153,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Dorogháza",
        "infó:": ""
      },
      {
        "Település ID": 852,
        "KSH településazonosító törzsszám": 3036,
        zipCode: 9913,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Döröske",
        "infó:": ""
      },
      {
        "Település ID": 853,
        "KSH településazonosító törzsszám": 16009,
        zipCode: 8799,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Dötk",
        "infó:": ""
      },
      {
        "Település ID": 854,
        "KSH településazonosító törzsszám": 6123,
        zipCode: 3721,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Dövény",
        "infó:": ""
      },
      {
        "Település ID": 801,
        "KSH településazonosító törzsszám": 16151,
        zipCode: 9791,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Dozmat",
        "infó:": ""
      },
      {
        "Település ID": 802,
        "KSH településazonosító törzsszám": 7524,
        zipCode: 6342,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Drágszél",
        "infó:": ""
      },
      {
        "Település ID": 803,
        "KSH településazonosító törzsszám": 28617,
        zipCode: 7849,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávacsehi",
        "infó:": ""
      },
      {
        "Település ID": 804,
        "KSH településazonosító törzsszám": 28121,
        zipCode: 7846,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávacsepely",
        "infó:": ""
      },
      {
        "Település ID": 805,
        "KSH településazonosító törzsszám": 17419,
        zipCode: 7967,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávafok",
        "infó:": ""
      },
      {
        "Település ID": 806,
        "KSH településazonosító törzsszám": 13611,
        zipCode: 7977,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Drávagárdony",
        "infó:": ""
      },
      {
        "Település ID": 807,
        "KSH településazonosító törzsszám": 32391,
        zipCode: 7960,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávaiványi",
        "infó:": ""
      },
      {
        "Település ID": 808,
        "KSH településazonosító törzsszám": 9159,
        zipCode: 7967,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávakeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 809,
        "KSH településazonosító törzsszám": 22734,
        zipCode: 7850,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávapalkonya",
        "infó:": ""
      },
      {
        "Település ID": 810,
        "KSH településazonosító törzsszám": 12380,
        zipCode: 7843,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávapiski",
        "infó:": ""
      },
      {
        "Település ID": 811,
        "KSH településazonosító törzsszám": 28608,
        zipCode: 7851,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávaszabolcs",
        "infó:": ""
      },
      {
        "Település ID": 812,
        "KSH településazonosító törzsszám": 30030,
        zipCode: 7847,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávaszerdahely",
        "infó:": ""
      },
      {
        "Település ID": 813,
        "KSH településazonosító törzsszám": 21698,
        zipCode: 7960,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Drávasztára",
        "infó:": ""
      },
      {
        "Település ID": 814,
        "KSH településazonosító törzsszám": 15884,
        zipCode: 7979,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Drávatamási",
        "infó:": ""
      },
      {
        "Település ID": 815,
        "KSH településazonosító törzsszám": 8156,
        zipCode: 2646,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Drégelypalánk",
        "infó:": ""
      },
      {
        "Település ID": 816,
        "KSH településazonosító törzsszám": 27669,
        zipCode: 3635,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Dubicsány",
        "infó:": ""
      },
      {
        "Település ID": 817,
        "KSH településazonosító törzsszám": 2936,
        zipCode: 8416,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Dudar",
        "infó:": ""
      },
      {
        "Település ID": 818,
        "KSH településazonosító törzsszám": 2927,
        zipCode: 9556,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Duka",
        "infó:": ""
      },
      {
        "Település ID": 819,
        "KSH településazonosító törzsszám": 33835,
        zipCode: 2545,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Dunaalmás",
        "infó:": ""
      },
      {
        "Település ID": 820,
        "KSH településazonosító törzsszám": 25362,
        zipCode: 2023,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dunabogdány",
        "infó:": ""
      },
      {
        "Település ID": 821,
        "KSH településazonosító törzsszám": 21069,
        zipCode: 6323,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Dunaegyháza",
        "infó:": ""
      },
      {
        "Település ID": 822,
        "KSH településazonosító törzsszám": 12566,
        zipCode: 6513,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Dunafalva",
        "infó:": ""
      },
      {
        "Település ID": 823,
        "KSH településazonosító törzsszám": 31501,
        zipCode: 7020,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Dunaföldvár",
        "infó:": ""
      },
      {
        "Település ID": 824,
        "KSH településazonosító törzsszám": 9584,
        zipCode: 2330,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dunaharaszti",
        "infó:": ""
      },
      {
        "Település ID": 825,
        "KSH településazonosító törzsszám": 18616,
        zipCode: 2120,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dunakeszi",
        "infó:": ""
      },
      {
        "Település ID": 826,
        "KSH településazonosító törzsszám": 27739,
        zipCode: 9225,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Dunakiliti",
        "infó:": ""
      },
      {
        "Település ID": 827,
        "KSH településazonosító törzsszám": 7861,
        zipCode: 6328,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Dunapataj",
        "infó:": ""
      },
      {
        "Település ID": 828,
        "KSH településazonosító törzsszám": 2079,
        zipCode: 9235,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Dunaremete",
        "infó:": ""
      },
      {
        "Település ID": 829,
        "KSH településazonosító törzsszám": 21078,
        zipCode: 9174,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Dunaszeg",
        "infó:": ""
      },
      {
        "Település ID": 830,
        "KSH településazonosító törzsszám": 9186,
        zipCode: 7712,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Dunaszekcs%u0151",
        "infó:": ""
      },
      {
        "Település ID": 831,
        "KSH településazonosító törzsszám": 11606,
        zipCode: 6333,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Dunaszentbenedek",
        "infó:": ""
      },
      {
        "Település ID": 832,
        "KSH településazonosító törzsszám": 9539,
        zipCode: 7135,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Dunaszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 833,
        "KSH településazonosító törzsszám": 24101,
        zipCode: 2897,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Dunaszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 834,
        "KSH településazonosító törzsszám": 15875,
        zipCode: 9175,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Dunaszentpál",
        "infó:": ""
      },
      {
        "Település ID": 835,
        "KSH településazonosító törzsszám": 10454,
        zipCode: 9226,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Dunasziget",
        "infó:": ""
      },
      {
        "Település ID": 836,
        "KSH településazonosító törzsszám": 14766,
        zipCode: 6325,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Dunatetétlen",
        "infó:": ""
      },
      {
        "Település ID": 837,
        "KSH településazonosító törzsszám": 3115,
        zipCode: 2400,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Dunaújváros",
        "infó:": ""
      },
      {
        "Település ID": 838,
        "KSH településazonosító törzsszám": 3115,
        zipCode: 2407,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Dunaújváros",
        "infó:": ""
      },
      {
        "Település ID": 839,
        "KSH településazonosító törzsszám": 20534,
        zipCode: 2336,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Dunavarsány",
        "infó:": ""
      },
      {
        "Település ID": 840,
        "KSH településazonosító törzsszám": 7612,
        zipCode: 6087,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Dunavecse",
        "infó:": ""
      },
      {
        "Település ID": 841,
        "KSH településazonosító törzsszám": 4109,
        zipCode: 6353,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Dusnok",
        "infó:": ""
      },
      {
        "Település ID": 842,
        "KSH településazonosító törzsszám": 19202,
        zipCode: 7224,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Dúzs",
        "infó:": ""
      },
      {
        "Település ID": 855,
        "KSH településazonosító törzsszám": 26347,
        zipCode: 9451,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Eberg%u0151c",
        "infó:": ""
      },
      {
        "Település ID": 856,
        "KSH településazonosító törzsszám": 14614,
        zipCode: 4211,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Ebes",
        "infó:": ""
      },
      {
        "Település ID": 857,
        "KSH településazonosító törzsszám": 16708,
        zipCode: 9083,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Écs",
        "infó:": ""
      },
      {
        "Település ID": 858,
        "KSH településazonosító törzsszám": 17181,
        zipCode: 3013,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Ecséd",
        "infó:": ""
      },
      {
        "Település ID": 859,
        "KSH településazonosító törzsszám": 4251,
        zipCode: 3053,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Ecseg",
        "infó:": ""
      },
      {
        "Település ID": 860,
        "KSH településazonosító törzsszám": 9432,
        zipCode: 5515,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Ecsegfalva",
        "infó:": ""
      },
      {
        "Település ID": 861,
        "KSH településazonosító törzsszám": 10861,
        zipCode: 7457,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Ecseny",
        "infó:": ""
      },
      {
        "Település ID": 862,
        "KSH településazonosító törzsszám": 24518,
        zipCode: 2233,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Ecser",
        "infó:": ""
      },
      {
        "Település ID": 863,
        "KSH településazonosító törzsszám": 3489,
        zipCode: 7443,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Edde",
        "infó:": ""
      },
      {
        "Település ID": 864,
        "KSH településazonosító törzsszám": 10728,
        zipCode: 3780,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Edelény",
        "infó:": ""
      },
      {
        "Település ID": 865,
        "KSH településazonosító törzsszám": 10728,
        zipCode: 3783,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Edelény",
        "infó:": ""
      },
      {
        "Település ID": 866,
        "KSH településazonosító törzsszám": 22442,
        zipCode: 9343,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Edve",
        "infó:": ""
      },
      {
        "Település ID": 867,
        "KSH településazonosító törzsszám": 20491,
        zipCode: 3300,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Eger",
        "infó:": ""
      },
      {
        "Település ID": 868,
        "KSH településazonosító törzsszám": 20491,
        zipCode: 3304,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Eger",
        "infó:": ""
      },
      {
        "Település ID": 869,
        "KSH településazonosító törzsszám": 28918,
        zipCode: 7763,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Egerág",
        "infó:": ""
      },
      {
        "Település ID": 870,
        "KSH településazonosító törzsszám": 33871,
        zipCode: 8497,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Egeralja",
        "infó:": ""
      },
      {
        "Település ID": 871,
        "KSH településazonosító törzsszám": 24262,
        zipCode: 8765,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Egeraracsa",
        "infó:": ""
      },
      {
        "Település ID": 872,
        "KSH településazonosító törzsszám": 12821,
        zipCode: 3321,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Egerbakta",
        "infó:": ""
      },
      {
        "Település ID": 873,
        "KSH településazonosító törzsszám": 26019,
        zipCode: 3337,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Egerbocs",
        "infó:": ""
      },
      {
        "Település ID": 874,
        "KSH településazonosító törzsszám": 16610,
        zipCode: 3341,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Egercsehi",
        "infó:": ""
      },
      {
        "Település ID": 875,
        "KSH településazonosító törzsszám": 2981,
        zipCode: 3379,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Egerfarmos",
        "infó:": ""
      },
      {
        "Település ID": 876,
        "KSH településazonosító törzsszám": 5865,
        zipCode: 3461,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Egerlöv%u0151",
        "infó:": ""
      },
      {
        "Település ID": 877,
        "KSH településazonosító törzsszám": 24758,
        zipCode: 3394,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Egerszalók",
        "infó:": ""
      },
      {
        "Település ID": 879,
        "KSH településazonosító törzsszám": 13356,
        zipCode: 3757,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Égerszög",
        "infó:": ""
      },
      {
        "Település ID": 878,
        "KSH településazonosító törzsszám": 13648,
        zipCode: 3328,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Egerszólát",
        "infó:": ""
      },
      {
        "Település ID": 880,
        "KSH településazonosító törzsszám": 33428,
        zipCode: 8913,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Egervár",
        "infó:": ""
      },
      {
        "Település ID": 881,
        "KSH településazonosító törzsszám": 28796,
        zipCode: 9684,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Egervölgy",
        "infó:": ""
      },
      {
        "Település ID": 882,
        "KSH településazonosító törzsszám": 20288,
        zipCode: 9314,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Egyed",
        "infó:": ""
      },
      {
        "Település ID": 883,
        "KSH településazonosító törzsszám": 15741,
        zipCode: 4067,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Egyek",
        "infó:": ""
      },
      {
        "Település ID": 884,
        "KSH településazonosító törzsszám": 15741,
        zipCode: 4069,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Egyek",
        "infó:": ""
      },
      {
        "Település ID": 885,
        "KSH településazonosító törzsszám": 17659,
        zipCode: 3043,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Egyházasdengeleg",
        "infó:": ""
      },
      {
        "Település ID": 886,
        "KSH településazonosító törzsszám": 15237,
        zipCode: 9473,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Egyházasfalu",
        "infó:": ""
      },
      {
        "Település ID": 887,
        "KSH településazonosító törzsszám": 5980,
        zipCode: 3185,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Egyházasgerge",
        "infó:": ""
      },
      {
        "Település ID": 888,
        "KSH településazonosító törzsszám": 16498,
        zipCode: 7824,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Egyházasharaszti",
        "infó:": ""
      },
      {
        "Település ID": 889,
        "KSH településazonosító törzsszám": 10232,
        zipCode: 9554,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Egyházashetye",
        "infó:": ""
      },
      {
        "Település ID": 890,
        "KSH településazonosító törzsszám": 30429,
        zipCode: 9781,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Egyházashollós",
        "infó:": ""
      },
      {
        "Település ID": 891,
        "KSH településazonosító törzsszám": 10445,
        zipCode: 8523,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Egyházaskesz%u0151",
        "infó:": ""
      },
      {
        "Település ID": 892,
        "KSH településazonosító törzsszám": 27401,
        zipCode: 7347,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Egyházaskozár",
        "infó:": ""
      },
      {
        "Település ID": 893,
        "KSH településazonosító törzsszám": 25946,
        zipCode: 9783,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Egyházasrádóc",
        "infó:": ""
      },
      {
        "Település ID": 894,
        "KSH településazonosító törzsszám": 32957,
        zipCode: 5742,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Elek",
        "infó:": ""
      },
      {
        "Település ID": 895,
        "KSH településazonosító törzsszám": 6099,
        zipCode: 7744,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ellend",
        "infó:": ""
      },
      {
        "Település ID": 896,
        "KSH településazonosító törzsszám": 20358,
        zipCode: 2424,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "El%u0151szállás",
        "infó:": ""
      },
      {
        "Település ID": 897,
        "KSH településazonosító törzsszám": 4677,
        zipCode: 3432,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Em%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 898,
        "KSH településazonosító törzsszám": 33048,
        zipCode: 3854,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Encs",
        "infó:": ""
      },
      {
        "Település ID": 899,
        "KSH településazonosító törzsszám": 33048,
        zipCode: 3860,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Encs",
        "infó:": ""
      },
      {
        "Település ID": 900,
        "KSH településazonosító törzsszám": 32328,
        zipCode: 4374,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Encsencs",
        "infó:": ""
      },
      {
        "Település ID": 901,
        "KSH településazonosító törzsszám": 25496,
        zipCode: 3165,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Endrefalva",
        "infó:": ""
      },
      {
        "Település ID": 902,
        "KSH településazonosító törzsszám": 28273,
        zipCode: 7973,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Endr%u0151c",
        "infó:": ""
      },
      {
        "Település ID": 903,
        "KSH településazonosító törzsszám": 15033,
        zipCode: 9143,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Enese",
        "infó:": ""
      },
      {
        "Település ID": 904,
        "KSH településazonosító törzsszám": 2802,
        zipCode: 8130,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Enying",
        "infó:": ""
      },
      {
        "Település ID": 905,
        "KSH településazonosító törzsszám": 2802,
        zipCode: 8131,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Enying",
        "infó:": ""
      },
      {
        "Település ID": 906,
        "KSH településazonosító törzsszám": 22992,
        zipCode: 6624,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Eperjes",
        "infó:": ""
      },
      {
        "Település ID": 907,
        "KSH településazonosító törzsszám": 18528,
        zipCode: 4646,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Eperjeske",
        "infó:": ""
      },
      {
        "Település ID": 908,
        "KSH településazonosító törzsszám": 33941,
        zipCode: 8413,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Eplény",
        "infó:": ""
      },
      {
        "Település ID": 909,
        "KSH településazonosító törzsszám": 29638,
        zipCode: 2526,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Epöl",
        "infó:": ""
      },
      {
        "Település ID": 910,
        "KSH településazonosító törzsszám": 23603,
        zipCode: 2451,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Ercsi",
        "infó:": ""
      },
      {
        "Település ID": 911,
        "KSH településazonosító törzsszám": 23603,
        zipCode: 2453,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Ercsi",
        "infó:": ""
      },
      {
        "Település ID": 912,
        "KSH településazonosító törzsszám": 30988,
        zipCode: 2030,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Érd",
        "infó:": ""
      },
      {
        "Település ID": 913,
        "KSH településazonosító törzsszám": 30988,
        zipCode: 2035,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Érd",
        "infó:": ""
      },
      {
        "Település ID": 914,
        "KSH településazonosító törzsszám": 30988,
        zipCode: 2036,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Érd",
        "infó:": ""
      },
      {
        "Település ID": 915,
        "KSH településazonosító törzsszám": 25326,
        zipCode: 3932,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Erd%u0151bénye",
        "infó:": ""
      },
      {
        "Település ID": 916,
        "KSH településazonosító törzsszám": 22503,
        zipCode: 3935,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Erd%u0151horváti",
        "infó:": ""
      },
      {
        "Település ID": 917,
        "KSH településazonosító törzsszám": 13480,
        zipCode: 2113,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Erd%u0151kertes",
        "infó:": ""
      },
      {
        "Település ID": 919,
        "KSH településazonosító törzsszám": 28556,
        zipCode: 3252,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Erd%u0151kövesd",
        "infó:": ""
      },
      {
        "Település ID": 918,
        "KSH településazonosító törzsszám": 22655,
        zipCode: 2176,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Erd%u0151kürt",
        "infó:": ""
      },
      {
        "Település ID": 920,
        "KSH településazonosító törzsszám": 25821,
        zipCode: 7735,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Erd%u0151smárok",
        "infó:": ""
      },
      {
        "Település ID": 921,
        "KSH településazonosító törzsszám": 18704,
        zipCode: 7723,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Erd%u0151smecske",
        "infó:": ""
      },
      {
        "Település ID": 922,
        "KSH településazonosító törzsszám": 21795,
        zipCode: 2177,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Erd%u0151tarcsa",
        "infó:": ""
      },
      {
        "Település ID": 923,
        "KSH településazonosító törzsszám": 24235,
        zipCode: 3358,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Erd%u0151telek",
        "infó:": ""
      },
      {
        "Település ID": 924,
        "KSH településazonosító törzsszám": 20118,
        zipCode: 3295,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Erk",
        "infó:": ""
      },
      {
        "Település ID": 925,
        "KSH településazonosító törzsszám": 10852,
        zipCode: 4245,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Érpatak",
        "infó:": ""
      },
      {
        "Település ID": 926,
        "KSH településazonosító törzsszám": 11864,
        zipCode: 6347,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Érsekcsanád",
        "infó:": ""
      },
      {
        "Település ID": 927,
        "KSH településazonosító törzsszám": 33589,
        zipCode: 6348,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Érsekhalma",
        "infó:": ""
      },
      {
        "Település ID": 928,
        "KSH településazonosító törzsszám": 21582,
        zipCode: 2659,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Érsekvadkert",
        "infó:": ""
      },
      {
        "Település ID": 929,
        "KSH településazonosító törzsszám": 8448,
        zipCode: 7093,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Értény",
        "infó:": ""
      },
      {
        "Település ID": 930,
        "KSH településazonosító törzsszám": 13499,
        zipCode: 7661,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Erzsébet",
        "infó:": ""
      },
      {
        "Település ID": 931,
        "KSH településazonosító törzsszám": 25469,
        zipCode: 4124,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Esztár",
        "infó:": ""
      },
      {
        "Település ID": 932,
        "KSH településazonosító törzsszám": 6178,
        zipCode: 8882,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Eszteregnye",
        "infó:": ""
      },
      {
        "Település ID": 933,
        "KSH településazonosító törzsszám": 15769,
        zipCode: 8742,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Esztergályhorváti",
        "infó:": ""
      },
      {
        "Település ID": 934,
        "KSH településazonosító törzsszám": 25131,
        zipCode: 2500,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Esztergom",
        "infó:": ""
      },
      {
        "Település ID": 935,
        "KSH településazonosító törzsszám": 25131,
        zipCode: 2509,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Esztergom",
        "infó:": ""
      },
      {
        "Település ID": 936,
        "KSH településazonosító törzsszám": 6664,
        zipCode: 2947,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Ete",
        "infó:": ""
      },
      {
        "Település ID": 937,
        "KSH településazonosító törzsszám": 15370,
        zipCode: 3136,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Etes",
        "infó:": ""
      },
      {
        "Település ID": 938,
        "KSH településazonosító törzsszám": 2316,
        zipCode: 2091,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Etyek",
        "infó:": ""
      },
      {
        "Település ID": 939,
        "KSH településazonosító törzsszám": 23250,
        zipCode: 4354,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Fábiánháza",
        "infó:": ""
      },
      {
        "Település ID": 940,
        "KSH településazonosító törzsszám": 19974,
        zipCode: 6625,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Fábiánsebestyén",
        "infó:": ""
      },
      {
        "Település ID": 941,
        "KSH településazonosító törzsszám": 24192,
        zipCode: 7136,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Fácánkert",
        "infó:": ""
      },
      {
        "Település ID": 942,
        "KSH településazonosító törzsszám": 18980,
        zipCode: 7133,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Fadd",
        "infó:": ""
      },
      {
        "Település ID": 943,
        "KSH településazonosító törzsszám": 18980,
        zipCode: 7139,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Fadd",
        "infó:": ""
      },
      {
        "Település ID": 944,
        "KSH településazonosító törzsszám": 2741,
        zipCode: 3865,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fáj",
        "infó:": ""
      },
      {
        "Település ID": 945,
        "KSH településazonosító törzsszám": 3230,
        zipCode: 6352,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Fajsz",
        "infó:": ""
      },
      {
        "Település ID": 946,
        "KSH településazonosító törzsszám": 12557,
        zipCode: 3855,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fancsal",
        "infó:": ""
      },
      {
        "Település ID": 947,
        "KSH településazonosító törzsszám": 33996,
        zipCode: 9321,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Farád",
        "infó:": ""
      },
      {
        "Település ID": 948,
        "KSH településazonosító törzsszám": 10250,
        zipCode: 8582,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Farkasgyep%u0171",
        "infó:": ""
      },
      {
        "Település ID": 949,
        "KSH településazonosító törzsszám": 34272,
        zipCode: 3608,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Farkaslyuk",
        "infó:": ""
      },
      {
        "Település ID": 950,
        "KSH településazonosító törzsszám": 9122,
        zipCode: 2765,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Farmos",
        "infó:": ""
      },
      {
        "Település ID": 951,
        "KSH településazonosító törzsszám": 17835,
        zipCode: 7732,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Fazekasboda",
        "infó:": ""
      },
      {
        "Település ID": 952,
        "KSH településazonosító törzsszám": 12432,
        zipCode: 3255,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Fedémes",
        "infó:": ""
      },
      {
        "Település ID": 953,
        "KSH településazonosító törzsszám": 16647,
        zipCode: 5213,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Fegyvernek",
        "infó:": ""
      },
      {
        "Település ID": 954,
        "KSH településazonosító törzsszám": 16647,
        zipCode: 5231,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Fegyvernek",
        "infó:": ""
      },
      {
        "Település ID": 955,
        "KSH településazonosító törzsszám": 18971,
        zipCode: 4900,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Fehérgyarmat",
        "infó:": ""
      },
      {
        "Település ID": 956,
        "KSH településazonosító törzsszám": 6956,
        zipCode: 9163,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fehértó",
        "infó:": ""
      },
      {
        "Település ID": 957,
        "KSH településazonosító törzsszám": 32203,
        zipCode: 8052,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Fehérvárcsurgó",
        "infó:": ""
      },
      {
        "Település ID": 958,
        "KSH településazonosító törzsszám": 4543,
        zipCode: 7724,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Feked",
        "infó:": ""
      },
      {
        "Település ID": 959,
        "KSH településazonosító törzsszám": 32717,
        zipCode: 9211,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Feketeerd%u0151",
        "infó:": ""
      },
      {
        "Település ID": 960,
        "KSH településazonosító törzsszám": 29939,
        zipCode: 8086,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Felcsút",
        "infó:": ""
      },
      {
        "Település ID": 961,
        "KSH településazonosító törzsszám": 20747,
        zipCode: 3352,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Feldebr%u0151",
        "infó:": ""
      },
      {
        "Település ID": 962,
        "KSH településazonosító törzsszám": 22646,
        zipCode: 6645,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Felgy%u0151",
        "infó:": ""
      },
      {
        "Település ID": 963,
        "KSH településazonosító törzsszám": 33251,
        zipCode: 9122,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Felpéc",
        "infó:": ""
      },
      {
        "Település ID": 964,
        "KSH településazonosító törzsszám": 8174,
        zipCode: 3985,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151berecki",
        "infó:": ""
      },
      {
        "Település ID": 965,
        "KSH településazonosító törzsszám": 13587,
        zipCode: 9794,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Fels%u0151csatár",
        "infó:": ""
      },
      {
        "Település ID": 966,
        "KSH településazonosító törzsszám": 9742,
        zipCode: 3847,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151dobsza",
        "infó:": ""
      },
      {
        "Település ID": 967,
        "KSH településazonosító törzsszám": 13286,
        zipCode: 7370,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Fels%u0151egerszeg",
        "infó:": ""
      },
      {
        "Település ID": 968,
        "KSH településazonosító törzsszám": 29708,
        zipCode: 3837,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151gagy",
        "infó:": ""
      },
      {
        "Település ID": 969,
        "KSH településazonosító törzsszám": 8013,
        zipCode: 9934,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Fels%u0151jánosfa",
        "infó:": ""
      },
      {
        "Település ID": 970,
        "KSH településazonosító törzsszám": 31723,
        zipCode: 3722,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151kelecsény",
        "infó:": ""
      },
      {
        "Település ID": 971,
        "KSH településazonosító törzsszám": 33598,
        zipCode: 6055,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Fels%u0151lajos",
        "infó:": ""
      },
      {
        "Település ID": 972,
        "KSH településazonosító törzsszám": 29841,
        zipCode: 9918,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Fels%u0151marác",
        "infó:": ""
      },
      {
        "Település ID": 973,
        "KSH településazonosító törzsszám": 13985,
        zipCode: 7456,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Fels%u0151mocsolád",
        "infó:": ""
      },
      {
        "Település ID": 974,
        "KSH településazonosító törzsszám": 15820,
        zipCode: 7175,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Fels%u0151nána",
        "infó:": ""
      },
      {
        "Település ID": 975,
        "KSH településazonosító törzsszám": 32762,
        zipCode: 3721,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151nyárád",
        "infó:": ""
      },
      {
        "Település ID": 976,
        "KSH településazonosító törzsszám": 17914,
        zipCode: 7099,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Fels%u0151nyék",
        "infó:": ""
      },
      {
        "Település ID": 991,
        "KSH településazonosító törzsszám": 24369,
        zipCode: 8227,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Fels%u0151örs",
        "infó:": ""
      },
      {
        "Település ID": 977,
        "KSH településazonosító törzsszám": 11642,
        zipCode: 8380,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Fels%u0151páhok",
        "infó:": ""
      },
      {
        "Település ID": 978,
        "KSH településazonosító törzsszám": 6035,
        zipCode: 2363,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Fels%u0151pakony",
        "infó:": ""
      },
      {
        "Település ID": 979,
        "KSH településazonosító törzsszám": 24323,
        zipCode: 2611,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Fels%u0151petény",
        "infó:": ""
      },
      {
        "Település ID": 980,
        "KSH településazonosító törzsszám": 21476,
        zipCode: 8767,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Fels%u0151rajk",
        "infó:": ""
      },
      {
        "Település ID": 981,
        "KSH településazonosító törzsszám": 7588,
        zipCode: 3989,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151regmec",
        "infó:": ""
      },
      {
        "Település ID": 982,
        "KSH településazonosító törzsszám": 30890,
        zipCode: 8973,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Fels%u0151szenterzsébet",
        "infó:": ""
      },
      {
        "Település ID": 983,
        "KSH településazonosító törzsszám": 2954,
        zipCode: 6447,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Fels%u0151szentiván",
        "infó:": ""
      },
      {
        "Település ID": 984,
        "KSH településazonosító törzsszám": 8819,
        zipCode: 7968,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Fels%u0151szentmárton",
        "infó:": ""
      },
      {
        "Település ID": 985,
        "KSH településazonosító törzsszám": 23287,
        zipCode: 9985,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Fels%u0151szölnök",
        "infó:": ""
      },
      {
        "Település ID": 986,
        "KSH településazonosító törzsszám": 16328,
        zipCode: 3324,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Fels%u0151tárkány",
        "infó:": ""
      },
      {
        "Település ID": 987,
        "KSH településazonosító törzsszám": 31671,
        zipCode: 3735,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151telekes",
        "infó:": ""
      },
      {
        "Település ID": 988,
        "KSH településazonosító törzsszám": 33312,
        zipCode: 3067,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Fels%u0151told",
        "infó:": ""
      },
      {
        "Település ID": 989,
        "KSH településazonosító törzsszám": 23533,
        zipCode: 3814,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151vadász",
        "infó:": ""
      },
      {
        "Település ID": 990,
        "KSH településazonosító törzsszám": 2848,
        zipCode: 3561,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fels%u0151zsolca",
        "infó:": ""
      },
      {
        "Település ID": 992,
        "KSH településazonosító törzsszám": 22415,
        zipCode: 4621,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Fényeslitke",
        "infó:": ""
      },
      {
        "Település ID": 993,
        "KSH településazonosító törzsszám": 30678,
        zipCode: 8432,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Feny%u0151f%u0151",
        "infó:": ""
      },
      {
        "Település ID": 994,
        "KSH településazonosító törzsszám": 18999,
        zipCode: 6774,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Ferencszállás",
        "infó:": ""
      },
      {
        "Település ID": 995,
        "KSH településazonosító törzsszám": 11253,
        zipCode: 9493,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fert%u0151boz",
        "infó:": ""
      },
      {
        "Település ID": 996,
        "KSH településazonosító törzsszám": 9885,
        zipCode: 9431,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fert%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 997,
        "KSH településazonosító törzsszám": 9885,
        zipCode: 9433,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fert%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 998,
        "KSH településazonosító törzsszám": 9487,
        zipCode: 9442,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fert%u0151endréd",
        "infó:": ""
      },
      {
        "Település ID": 999,
        "KSH településazonosító törzsszám": 10658,
        zipCode: 9492,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fert%u0151homok",
        "infó:": ""
      },
      {
        "Település ID": 1000,
        "KSH településazonosító törzsszám": 12414,
        zipCode: 9421,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fert%u0151rákos",
        "infó:": ""
      },
      {
        "Település ID": 1001,
        "KSH településazonosító törzsszám": 15343,
        zipCode: 9444,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fert%u0151szentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 1002,
        "KSH településazonosító törzsszám": 31440,
        zipCode: 9436,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Fert%u0151széplak",
        "infó:": ""
      },
      {
        "Település ID": 1003,
        "KSH településazonosító törzsszám": 31644,
        zipCode: 7282,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Fiad",
        "infó:": ""
      },
      {
        "Település ID": 1004,
        "KSH településazonosító törzsszám": 25238,
        zipCode: 3994,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Filkeháza",
        "infó:": ""
      },
      {
        "Település ID": 1005,
        "KSH településazonosító törzsszám": 19187,
        zipCode: 8835,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Fityeház",
        "infó:": ""
      },
      {
        "Település ID": 1006,
        "KSH településazonosító törzsszám": 2149,
        zipCode: 6331,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Fokt%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1033,
        "KSH településazonosító törzsszám": 9210,
        zipCode: 6922,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Földeák",
        "infó:": ""
      },
      {
        "Település ID": 1034,
        "KSH településazonosító törzsszám": 3258,
        zipCode: 4177,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Földes",
        "infó:": ""
      },
      {
        "Település ID": 1007,
        "KSH településazonosító törzsszám": 34014,
        zipCode: 4090,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Folyás",
        "infó:": ""
      },
      {
        "Település ID": 1008,
        "KSH településazonosító törzsszám": 22026,
        zipCode: 7271,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Fonó",
        "infó:": ""
      },
      {
        "Település ID": 1009,
        "KSH településazonosító törzsszám": 17932,
        zipCode: 3893,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fony",
        "infó:": ""
      },
      {
        "Település ID": 1010,
        "KSH településazonosító törzsszám": 22707,
        zipCode: 8732,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "F%u0151nyed",
        "infó:": ""
      },
      {
        "Település ID": 1011,
        "KSH településazonosító törzsszám": 14632,
        zipCode: 8640,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Fonyód",
        "infó:": ""
      },
      {
        "Település ID": 1012,
        "KSH településazonosító törzsszám": 14632,
        zipCode: 8644,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Fonyód",
        "infó:": ""
      },
      {
        "Település ID": 1013,
        "KSH településazonosító törzsszám": 33020,
        zipCode: 6793,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Forráskút",
        "infó:": ""
      },
      {
        "Település ID": 1014,
        "KSH településazonosító törzsszám": 30483,
        zipCode: 3849,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Forró",
        "infó:": ""
      },
      {
        "Település ID": 1015,
        "KSH településazonosító törzsszám": 32610,
        zipCode: 2151,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Fót",
        "infó:": ""
      },
      {
        "Település ID": 1018,
        "KSH településazonosító törzsszám": 6114,
        zipCode: 8157,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Füle",
        "infó:": ""
      },
      {
        "Település ID": 1019,
        "KSH településazonosító törzsszám": 10791,
        zipCode: 4964,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Fülesd",
        "infó:": ""
      },
      {
        "Település ID": 1016,
        "KSH településazonosító törzsszám": 22123,
        zipCode: 3864,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Fulókércs",
        "infó:": ""
      },
      {
        "Település ID": 1021,
        "KSH településazonosító törzsszám": 22150,
        zipCode: 4266,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Fülöp",
        "infó:": ""
      },
      {
        "Település ID": 1022,
        "KSH településazonosító törzsszám": 31468,
        zipCode: 6042,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Fülöpháza",
        "infó:": ""
      },
      {
        "Település ID": 1023,
        "KSH településazonosító törzsszám": 33622,
        zipCode: 6116,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Fülöpjakab",
        "infó:": ""
      },
      {
        "Település ID": 1024,
        "KSH településazonosító törzsszám": 14058,
        zipCode: 6085,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Fülöpszállás",
        "infó:": ""
      },
      {
        "Település ID": 1020,
        "KSH településazonosító törzsszám": 14377,
        zipCode: 4754,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Fülpösdaróc",
        "infó:": ""
      },
      {
        "Település ID": 1025,
        "KSH településazonosító törzsszám": 17950,
        zipCode: 7087,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Fürged",
        "infó:": ""
      },
      {
        "Település ID": 1017,
        "KSH településazonosító törzsszám": 16993,
        zipCode: 4141,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Furta",
        "infó:": ""
      },
      {
        "Település ID": 1026,
        "KSH településazonosító törzsszám": 17109,
        zipCode: 3996,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Füzér",
        "infó:": ""
      },
      {
        "Település ID": 1027,
        "KSH településazonosító törzsszám": 6460,
        zipCode: 3994,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Füzérkajata",
        "infó:": ""
      },
      {
        "Település ID": 1028,
        "KSH településazonosító törzsszám": 11378,
        zipCode: 3997,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Füzérkomlós",
        "infó:": ""
      },
      {
        "Település ID": 1029,
        "KSH településazonosító törzsszám": 10366,
        zipCode: 3993,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Füzérradvány",
        "infó:": ""
      },
      {
        "Település ID": 1030,
        "KSH településazonosító törzsszám": 3276,
        zipCode: 3390,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Füzesabony",
        "infó:": ""
      },
      {
        "Település ID": 1031,
        "KSH településazonosító törzsszám": 12256,
        zipCode: 5525,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Füzesgyarmat",
        "infó:": ""
      },
      {
        "Település ID": 1032,
        "KSH településazonosító törzsszám": 16531,
        zipCode: 8777,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Füzvölgy",
        "infó:": ""
      },
      {
        "Település ID": 1035,
        "KSH településazonosító törzsszám": 18175,
        zipCode: 4122,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Gáborján",
        "infó:": ""
      },
      {
        "Település ID": 1036,
        "KSH településazonosító törzsszám": 17516,
        zipCode: 8969,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gáborjánháza",
        "infó:": ""
      },
      {
        "Település ID": 1037,
        "KSH településazonosító törzsszám": 13727,
        zipCode: 4972,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gacsály",
        "infó:": ""
      },
      {
        "Település ID": 1038,
        "KSH településazonosító törzsszám": 28264,
        zipCode: 7276,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Gadács",
        "infó:": ""
      },
      {
        "Település ID": 1039,
        "KSH településazonosító törzsszám": 26222,
        zipCode: 8716,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Gadány",
        "infó:": ""
      },
      {
        "Település ID": 1040,
        "KSH településazonosító törzsszám": 5494,
        zipCode: 3815,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gadna",
        "infó:": ""
      },
      {
        "Település ID": 1041,
        "KSH településazonosító törzsszám": 9511,
        zipCode: 5932,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Gádoros",
        "infó:": ""
      },
      {
        "Település ID": 1042,
        "KSH településazonosító törzsszám": 28732,
        zipCode: 3837,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gagyapáti",
        "infó:": ""
      },
      {
        "Település ID": 1043,
        "KSH településazonosító törzsszám": 28307,
        zipCode: 3817,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gagybátor",
        "infó:": ""
      },
      {
        "Település ID": 1044,
        "KSH településazonosító törzsszám": 3744,
        zipCode: 3816,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gagyvendégi",
        "infó:": ""
      },
      {
        "Település ID": 1045,
        "KSH településazonosító törzsszám": 12991,
        zipCode: 8754,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Galambok",
        "infó:": ""
      },
      {
        "Település ID": 1046,
        "KSH településazonosító törzsszám": 25663,
        zipCode: 2686,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Galgaguta",
        "infó:": ""
      },
      {
        "Település ID": 1047,
        "KSH településazonosító törzsszám": 13295,
        zipCode: 2681,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Galgagyörk",
        "infó:": ""
      },
      {
        "Település ID": 1048,
        "KSH településazonosító törzsszám": 19503,
        zipCode: 2193,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Galgahévíz",
        "infó:": ""
      },
      {
        "Település ID": 1049,
        "KSH településazonosító törzsszám": 27128,
        zipCode: 2183,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Galgamácsa",
        "infó:": ""
      },
      {
        "Település ID": 1050,
        "KSH településazonosító törzsszám": 6585,
        zipCode: 7473,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Gálosfa",
        "infó:": ""
      },
      {
        "Település ID": 1051,
        "KSH településazonosító törzsszám": 19293,
        zipCode: 3752,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Galvács",
        "infó:": ""
      },
      {
        "Település ID": 1052,
        "KSH településazonosító törzsszám": 6451,
        zipCode: 8685,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Gamás",
        "infó:": ""
      },
      {
        "Település ID": 1053,
        "KSH településazonosító törzsszám": 12742,
        zipCode: 8597,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Ganna",
        "infó:": ""
      },
      {
        "Település ID": 1054,
        "KSH településazonosító törzsszám": 15750,
        zipCode: 8082,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Gánt",
        "infó:": ""
      },
      {
        "Település ID": 1055,
        "KSH településazonosító törzsszám": 31848,
        zipCode: 6522,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Gara",
        "infó:": ""
      },
      {
        "Település ID": 1056,
        "KSH településazonosító törzsszám": 18494,
        zipCode: 3067,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Garáb",
        "infó:": ""
      },
      {
        "Település ID": 1057,
        "KSH településazonosító törzsszám": 12946,
        zipCode: 8747,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Garabonc",
        "infó:": ""
      },
      {
        "Település ID": 1058,
        "KSH településazonosító törzsszám": 10904,
        zipCode: 3873,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Garadna",
        "infó:": ""
      },
      {
        "Település ID": 1059,
        "KSH településazonosító törzsszám": 4996,
        zipCode: 4976,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Garbolc",
        "infó:": ""
      },
      {
        "Település ID": 1060,
        "KSH településazonosító törzsszám": 10296,
        zipCode: 2483,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Gárdony",
        "infó:": ""
      },
      {
        "Település ID": 1061,
        "KSH településazonosító törzsszám": 10296,
        zipCode: 2484,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Gárdony",
        "infó:": ""
      },
      {
        "Település ID": 1062,
        "KSH településazonosító törzsszám": 10296,
        zipCode: 2485,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Gárdony",
        "infó:": ""
      },
      {
        "Település ID": 1063,
        "KSH településazonosító törzsszám": 7560,
        zipCode: 7812,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Garé",
        "infó:": ""
      },
      {
        "Település ID": 1064,
        "KSH településazonosító törzsszám": 30906,
        zipCode: 9952,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Gasztony",
        "infó:": ""
      },
      {
        "Település ID": 1065,
        "KSH településazonosító törzsszám": 26383,
        zipCode: 6111,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Gátér",
        "infó:": ""
      },
      {
        "Település ID": 1066,
        "KSH településazonosító törzsszám": 5801,
        zipCode: 4471,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gávavencsell%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1067,
        "KSH településazonosító törzsszám": 5801,
        zipCode: 4472,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gávavencsell%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1068,
        "KSH településazonosító törzsszám": 3629,
        zipCode: 4754,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Géberjén",
        "infó:": ""
      },
      {
        "Település ID": 1069,
        "KSH településazonosító törzsszám": 9292,
        zipCode: 8543,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Gecse",
        "infó:": ""
      },
      {
        "Település ID": 1070,
        "KSH településazonosító törzsszám": 3577,
        zipCode: 6334,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Géderlak",
        "infó:": ""
      },
      {
        "Település ID": 1071,
        "KSH településazonosító törzsszám": 5670,
        zipCode: 4517,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gégény",
        "infó:": ""
      },
      {
        "Település ID": 1072,
        "KSH településazonosító törzsszám": 23719,
        zipCode: 3444,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gelej",
        "infó:": ""
      },
      {
        "Település ID": 1073,
        "KSH településazonosító törzsszám": 4613,
        zipCode: 4935,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gelénes",
        "infó:": ""
      },
      {
        "Település ID": 1074,
        "KSH településazonosító törzsszám": 8068,
        zipCode: 8981,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gellénháza",
        "infó:": ""
      },
      {
        "Település ID": 1075,
        "KSH településazonosító törzsszám": 12089,
        zipCode: 8774,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gelse",
        "infó:": ""
      },
      {
        "Település ID": 1076,
        "KSH településazonosító törzsszám": 9089,
        zipCode: 8774,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gelsesziget",
        "infó:": ""
      },
      {
        "Település ID": 1077,
        "KSH településazonosító törzsszám": 13000,
        zipCode: 4567,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gemzse",
        "infó:": ""
      },
      {
        "Település ID": 1078,
        "KSH településazonosító törzsszám": 24183,
        zipCode: 9721,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Gencsapáti",
        "infó:": ""
      },
      {
        "Település ID": 1079,
        "KSH településazonosító törzsszám": 26152,
        zipCode: 9672,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Gérce",
        "infó:": ""
      },
      {
        "Település ID": 1080,
        "KSH településazonosító törzsszám": 12751,
        zipCode: 7951,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gerde",
        "infó:": ""
      },
      {
        "Település ID": 1081,
        "KSH településazonosító törzsszám": 7393,
        zipCode: 5925,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Gerendás",
        "infó:": ""
      },
      {
        "Település ID": 1082,
        "KSH településazonosító törzsszám": 13347,
        zipCode: 7362,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gerényes",
        "infó:": ""
      },
      {
        "Település ID": 1083,
        "KSH településazonosító törzsszám": 2857,
        zipCode: 7733,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Geresdlak",
        "infó:": ""
      },
      {
        "Település ID": 1084,
        "KSH településazonosító törzsszám": 5731,
        zipCode: 7134,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Gerjen",
        "infó:": ""
      },
      {
        "Település ID": 1085,
        "KSH településazonosító törzsszám": 30942,
        zipCode: 9813,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Gersekarát",
        "infó:": ""
      },
      {
        "Település ID": 1086,
        "KSH településazonosító törzsszám": 17394,
        zipCode: 5734,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Geszt",
        "infó:": ""
      },
      {
        "Település ID": 1087,
        "KSH településazonosító törzsszám": 15608,
        zipCode: 3715,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gesztely",
        "infó:": ""
      },
      {
        "Település ID": 1088,
        "KSH településazonosító törzsszám": 15608,
        zipCode: 3923,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gesztely",
        "infó:": ""
      },
      {
        "Település ID": 1089,
        "KSH településazonosító törzsszám": 28893,
        zipCode: 4232,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Geszteréd",
        "infó:": ""
      },
      {
        "Település ID": 1090,
        "KSH településazonosító törzsszám": 20039,
        zipCode: 8762,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gétye",
        "infó:": ""
      },
      {
        "Település ID": 1091,
        "KSH településazonosító törzsszám": 16717,
        zipCode: 8435,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Gic",
        "infó:": ""
      },
      {
        "Település ID": 1092,
        "KSH településazonosító törzsszám": 9177,
        zipCode: 7527,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Gige",
        "infó:": ""
      },
      {
        "Település ID": 1093,
        "KSH településazonosító törzsszám": 18333,
        zipCode: 7954,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gilvánfa",
        "infó:": ""
      },
      {
        "Település ID": 1094,
        "KSH településazonosító törzsszám": 21564,
        zipCode: 3578,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Girincs",
        "infó:": ""
      },
      {
        "Település ID": 1172,
        "KSH településazonosító törzsszám": 23649,
        zipCode: 2131,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Göd",
        "infó:": ""
      },
      {
        "Település ID": 1173,
        "KSH településazonosító törzsszám": 23649,
        zipCode: 2132,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Göd",
        "infó:": ""
      },
      {
        "Település ID": 1176,
        "KSH településazonosító törzsszám": 32559,
        zipCode: 2100,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Gödöll%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1174,
        "KSH településazonosító törzsszám": 33233,
        zipCode: 7385,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gödre",
        "infó:": ""
      },
      {
        "Település ID": 1175,
        "KSH településazonosító törzsszám": 33233,
        zipCode: 7386,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gödre",
        "infó:": ""
      },
      {
        "Település ID": 1095,
        "KSH településazonosító törzsszám": 18193,
        zipCode: 8346,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Gógánfa",
        "infó:": ""
      },
      {
        "Település ID": 1177,
        "KSH településazonosító törzsszám": 30571,
        zipCode: 7272,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Gölle",
        "infó:": ""
      },
      {
        "Település ID": 1096,
        "KSH településazonosító törzsszám": 13134,
        zipCode: 3906,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Golop",
        "infó:": ""
      },
      {
        "Település ID": 1097,
        "KSH településazonosító törzsszám": 9441,
        zipCode: 2217,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Gomba",
        "infó:": ""
      },
      {
        "Település ID": 1098,
        "KSH településazonosító törzsszám": 17613,
        zipCode: 8984,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gombosszeg",
        "infó:": ""
      },
      {
        "Település ID": 1178,
        "KSH településazonosító törzsszám": 9706,
        zipCode: 3728,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gömörsz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 1179,
        "KSH településazonosító törzsszám": 15936,
        zipCode: 3895,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Gönc",
        "infó:": ""
      },
      {
        "Település ID": 1180,
        "KSH településazonosító törzsszám": 18643,
        zipCode: 3894,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Göncruszka",
        "infó:": ""
      },
      {
        "Település ID": 1181,
        "KSH településazonosító törzsszám": 2060,
        zipCode: 9071,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Göny%u0171",
        "infó:": ""
      },
      {
        "Település ID": 1099,
        "KSH településazonosító törzsszám": 11156,
        zipCode: 9625,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Gór",
        "infó:": ""
      },
      {
        "Település ID": 1182,
        "KSH településazonosító törzsszám": 16568,
        zipCode: 4075,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Görbeháza",
        "infó:": ""
      },
      {
        "Település ID": 1183,
        "KSH településazonosító törzsszám": 30438,
        zipCode: 7833,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Görcsöny",
        "infó:": ""
      },
      {
        "Település ID": 1184,
        "KSH településazonosító törzsszám": 9636,
        zipCode: 7728,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Görcsönydoboka",
        "infó:": ""
      },
      {
        "Település ID": 1100,
        "KSH településazonosító törzsszám": 33084,
        zipCode: 7853,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gordisa",
        "infó:": ""
      },
      {
        "Település ID": 1185,
        "KSH településazonosító törzsszám": 14599,
        zipCode: 7553,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Görgeteg",
        "infó:": ""
      },
      {
        "Település ID": 1101,
        "KSH településazonosító törzsszám": 29771,
        zipCode: 8913,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "G%u0151sfa",
        "infó:": ""
      },
      {
        "Település ID": 1102,
        "KSH településazonosító törzsszám": 13569,
        zipCode: 8978,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gosztola",
        "infó:": ""
      },
      {
        "Település ID": 1103,
        "KSH településazonosító törzsszám": 26727,
        zipCode: 7162,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Grábóc",
        "infó:": ""
      },
      {
        "Település ID": 1104,
        "KSH településazonosító törzsszám": 29443,
        zipCode: 4842,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gulács",
        "infó:": ""
      },
      {
        "Település ID": 1105,
        "KSH településazonosító törzsszám": 2097,
        zipCode: 8951,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gutorfölde",
        "infó:": ""
      },
      {
        "Település ID": 1106,
        "KSH településazonosító törzsszám": 25627,
        zipCode: 2360,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Gyál",
        "infó:": ""
      },
      {
        "Település ID": 1107,
        "KSH településazonosító törzsszám": 17969,
        zipCode: 9474,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gyalóka",
        "infó:": ""
      },
      {
        "Település ID": 1108,
        "KSH településazonosító törzsszám": 27030,
        zipCode: 9774,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Gyanógeregye",
        "infó:": ""
      },
      {
        "Település ID": 1109,
        "KSH településazonosító törzsszám": 26860,
        zipCode: 9126,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gyarmat",
        "infó:": ""
      },
      {
        "Település ID": 1110,
        "KSH településazonosító törzsszám": 30960,
        zipCode: 8851,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Gyékényes",
        "infó:": ""
      },
      {
        "Település ID": 1111,
        "KSH településazonosító törzsszám": 23302,
        zipCode: 8315,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gyenesdiás",
        "infó:": ""
      },
      {
        "Település ID": 1112,
        "KSH településazonosító törzsszám": 28671,
        zipCode: 8473,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Gyepükaján",
        "infó:": ""
      },
      {
        "Település ID": 1113,
        "KSH településazonosító törzsszám": 6521,
        zipCode: 2821,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Gyermely",
        "infó:": ""
      },
      {
        "Település ID": 1114,
        "KSH településazonosító törzsszám": 18315,
        zipCode: 7668,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gyód",
        "infó:": ""
      },
      {
        "Település ID": 1115,
        "KSH településazonosító törzsszám": 33455,
        zipCode: 5500,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Gyomaendr%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 1116,
        "KSH településazonosító törzsszám": 33455,
        zipCode: 5502,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Gyomaendr%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 1155,
        "KSH településazonosító törzsszám": 20400,
        zipCode: 9124,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gyömöre",
        "infó:": ""
      },
      {
        "Település ID": 1154,
        "KSH településazonosító törzsszám": 29735,
        zipCode: 2230,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Gyömr%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1156,
        "KSH településazonosító törzsszám": 28404,
        zipCode: 7954,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gyöngyfa",
        "infó:": ""
      },
      {
        "Település ID": 1157,
        "KSH településazonosító törzsszám": 5236,
        zipCode: 3200,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyös",
        "infó:": ""
      },
      {
        "Település ID": 1158,
        "KSH településazonosító törzsszám": 5236,
        zipCode: 3221,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyös",
        "infó:": ""
      },
      {
        "Település ID": 1159,
        "KSH településazonosító törzsszám": 5236,
        zipCode: 3232,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyös",
        "infó:": ""
      },
      {
        "Település ID": 1160,
        "KSH településazonosító törzsszám": 5236,
        zipCode: 3233,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyös",
        "infó:": ""
      },
      {
        "Település ID": 1161,
        "KSH településazonosító törzsszám": 11943,
        zipCode: 9723,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Gyöngyösfalu",
        "infó:": ""
      },
      {
        "Település ID": 1162,
        "KSH településazonosító törzsszám": 17534,
        zipCode: 3212,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyöshalász",
        "infó:": ""
      },
      {
        "Település ID": 1163,
        "KSH településazonosító törzsszám": 22664,
        zipCode: 7972,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Gyöngyösmellék",
        "infó:": ""
      },
      {
        "Település ID": 1164,
        "KSH településazonosító törzsszám": 13338,
        zipCode: 3211,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyösoroszi",
        "infó:": ""
      },
      {
        "Település ID": 1165,
        "KSH településazonosító törzsszám": 8323,
        zipCode: 3035,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyöspata",
        "infó:": ""
      },
      {
        "Település ID": 1166,
        "KSH településazonosító törzsszám": 19123,
        zipCode: 3231,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyössolymos",
        "infó:": ""
      },
      {
        "Település ID": 1167,
        "KSH településazonosító törzsszám": 28088,
        zipCode: 3036,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Gyöngyöstarján",
        "infó:": ""
      },
      {
        "Település ID": 1168,
        "KSH településazonosító törzsszám": 30289,
        zipCode: 7064,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Gyönk",
        "infó:": ""
      },
      {
        "Település ID": 1117,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9000,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1118,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9011,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1119,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9012,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1120,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9019,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1121,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9021,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1122,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9022,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1123,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9023,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1124,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9024,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1125,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9025,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1126,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9026,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1127,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9027,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1128,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9028,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1129,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9029,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1130,
        "KSH településazonosító törzsszám": 25584,
        zipCode: 9030,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1131,
        "KSH településazonosító törzsszám": 8721,
        zipCode: 9093,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151rasszonyfa",
        "infó:": ""
      },
      {
        "Település ID": 1169,
        "KSH településazonosító törzsszám": 25539,
        zipCode: 7352,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Györe",
        "infó:": ""
      },
      {
        "Település ID": 1170,
        "KSH településazonosító törzsszám": 5069,
        zipCode: 3954,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Györgytarló",
        "infó:": ""
      },
      {
        "Település ID": 1171,
        "KSH településazonosító törzsszám": 12326,
        zipCode: 7045,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Györköny",
        "infó:": ""
      },
      {
        "Település ID": 1132,
        "KSH településazonosító törzsszám": 13198,
        zipCode: 9173,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151rladamér",
        "infó:": ""
      },
      {
        "Település ID": 1133,
        "KSH településazonosító törzsszám": 23843,
        zipCode: 9363,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gyóró",
        "infó:": ""
      },
      {
        "Település ID": 1142,
        "KSH településazonosító törzsszám": 28945,
        zipCode: 4625,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gy%u0151röcske",
        "infó:": ""
      },
      {
        "Település ID": 1134,
        "KSH településazonosító törzsszám": 31316,
        zipCode: 9084,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151rság",
        "infó:": ""
      },
      {
        "Település ID": 1136,
        "KSH településazonosító törzsszám": 19309,
        zipCode: 9161,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151rsövényház",
        "infó:": ""
      },
      {
        "Település ID": 1135,
        "KSH településazonosító törzsszám": 15653,
        zipCode: 9121,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151rszemere",
        "infó:": ""
      },
      {
        "Település ID": 1137,
        "KSH településazonosító törzsszám": 10126,
        zipCode: 4752,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gy%u0151rtelek",
        "infó:": ""
      },
      {
        "Település ID": 1138,
        "KSH településazonosító törzsszám": 7481,
        zipCode: 9081,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151rújbarát",
        "infó:": ""
      },
      {
        "Település ID": 1139,
        "KSH településazonosító törzsszám": 31787,
        zipCode: 9171,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151rújfalu",
        "infó:": ""
      },
      {
        "Település ID": 1140,
        "KSH településazonosító törzsszám": 9724,
        zipCode: 9821,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Gy%u0151rvár",
        "infó:": ""
      },
      {
        "Település ID": 1141,
        "KSH településazonosító törzsszám": 15228,
        zipCode: 9172,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Gy%u0151rzámoly",
        "infó:": ""
      },
      {
        "Település ID": 1143,
        "KSH településazonosító törzsszám": 23904,
        zipCode: 8692,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Gyugy",
        "infó:": ""
      },
      {
        "Település ID": 1152,
        "KSH településazonosító törzsszám": 19558,
        zipCode: 4733,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gyügye",
        "infó:": ""
      },
      {
        "Település ID": 1144,
        "KSH településazonosító törzsszám": 5032,
        zipCode: 5700,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Gyula",
        "infó:": ""
      },
      {
        "Település ID": 1145,
        "KSH településazonosító törzsszám": 5032,
        zipCode: 5703,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Gyula",
        "infó:": ""
      },
      {
        "Település ID": 1146,
        "KSH településazonosító törzsszám": 5032,
        zipCode: 5711,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Gyula",
        "infó:": ""
      },
      {
        "Település ID": 1147,
        "KSH településazonosító törzsszám": 7676,
        zipCode: 4545,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gyulaháza",
        "infó:": ""
      },
      {
        "Település ID": 1148,
        "KSH településazonosító törzsszám": 30359,
        zipCode: 7227,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Gyulaj",
        "infó:": ""
      },
      {
        "Település ID": 1149,
        "KSH településazonosító törzsszám": 9520,
        zipCode: 8286,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Gyulakeszi",
        "infó:": ""
      },
      {
        "Település ID": 1153,
        "KSH településazonosító törzsszám": 33774,
        zipCode: 4813,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Gyüre",
        "infó:": ""
      },
      {
        "Település ID": 1150,
        "KSH településazonosító törzsszám": 15918,
        zipCode: 2464,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Gyúró",
        "infó:": ""
      },
      {
        "Település ID": 1151,
        "KSH településazonosító törzsszám": 12539,
        zipCode: 8932,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Gy%u0171r%u0171s",
        "infó:": ""
      },
      {
        "Település ID": 1186,
        "KSH településazonosító törzsszám": 18634,
        zipCode: 8694,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Hács",
        "infó:": ""
      },
      {
        "Település ID": 1187,
        "KSH településazonosító törzsszám": 10931,
        zipCode: 8992,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Hagyárosbörönd",
        "infó:": ""
      },
      {
        "Település ID": 1188,
        "KSH településazonosító törzsszám": 10269,
        zipCode: 8771,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Hahót",
        "infó:": ""
      },
      {
        "Település ID": 1189,
        "KSH településazonosító törzsszám": 26170,
        zipCode: 4273,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúbagos",
        "infó:": ""
      },
      {
        "Település ID": 1190,
        "KSH településazonosító törzsszám": 3045,
        zipCode: 4074,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúböszörmény",
        "infó:": ""
      },
      {
        "Település ID": 1191,
        "KSH településazonosító törzsszám": 3045,
        zipCode: 4086,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúböszörmény",
        "infó:": ""
      },
      {
        "Település ID": 1192,
        "KSH településazonosító törzsszám": 3045,
        zipCode: 4220,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúböszörmény",
        "infó:": ""
      },
      {
        "Település ID": 1193,
        "KSH településazonosító törzsszám": 3045,
        zipCode: 4224,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúböszörmény",
        "infó:": ""
      },
      {
        "Település ID": 1194,
        "KSH településazonosító törzsszám": 12803,
        zipCode: 4087,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúdorog",
        "infó:": ""
      },
      {
        "Település ID": 1195,
        "KSH településazonosító törzsszám": 10393,
        zipCode: 4242,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúhadház",
        "infó:": ""
      },
      {
        "Település ID": 1196,
        "KSH településazonosító törzsszám": 22406,
        zipCode: 4080,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúnánás",
        "infó:": ""
      },
      {
        "Település ID": 1197,
        "KSH településazonosító törzsszám": 22406,
        zipCode: 4085,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúnánás",
        "infó:": ""
      },
      {
        "Település ID": 1198,
        "KSH településazonosító törzsszám": 31097,
        zipCode: 4251,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúsámson",
        "infó:": ""
      },
      {
        "Település ID": 1199,
        "KSH településazonosító törzsszám": 5175,
        zipCode: 4200,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúszoboszló",
        "infó:": ""
      },
      {
        "Település ID": 1200,
        "KSH településazonosító törzsszám": 17473,
        zipCode: 4212,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hajdúszovát",
        "infó:": ""
      },
      {
        "Település ID": 1201,
        "KSH településazonosító törzsszám": 25830,
        zipCode: 7473,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Hajmás",
        "infó:": ""
      },
      {
        "Település ID": 1202,
        "KSH településazonosító törzsszám": 15361,
        zipCode: 8192,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Hajmáskér",
        "infó:": ""
      },
      {
        "Település ID": 1203,
        "KSH településazonosító törzsszám": 18759,
        zipCode: 6344,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Hajós",
        "infó:": ""
      },
      {
        "Település ID": 1204,
        "KSH településazonosító törzsszám": 29452,
        zipCode: 9814,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Halastó",
        "infó:": ""
      },
      {
        "Település ID": 1205,
        "KSH településazonosító törzsszám": 26790,
        zipCode: 9228,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Halászi",
        "infó:": ""
      },
      {
        "Település ID": 1206,
        "KSH településazonosító törzsszám": 9690,
        zipCode: 2314,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Halásztelek",
        "infó:": ""
      },
      {
        "Település ID": 1207,
        "KSH településazonosító törzsszám": 7898,
        zipCode: 8452,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Halimba",
        "infó:": ""
      },
      {
        "Település ID": 1208,
        "KSH településazonosító törzsszám": 27942,
        zipCode: 3842,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Halmaj",
        "infó:": ""
      },
      {
        "Település ID": 1209,
        "KSH településazonosító törzsszám": 11411,
        zipCode: 3273,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Halmajugra",
        "infó:": ""
      },
      {
        "Település ID": 1210,
        "KSH településazonosító törzsszám": 10676,
        zipCode: 9917,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Halogy",
        "infó:": ""
      },
      {
        "Település ID": 1211,
        "KSH településazonosító törzsszám": 11226,
        zipCode: 3795,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hangács",
        "infó:": ""
      },
      {
        "Település ID": 1212,
        "KSH településazonosító törzsszám": 25104,
        zipCode: 3626,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hangony",
        "infó:": ""
      },
      {
        "Település ID": 1213,
        "KSH településazonosító törzsszám": 23427,
        zipCode: 2434,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Hantos",
        "infó:": ""
      },
      {
        "Település ID": 1214,
        "KSH településazonosító törzsszám": 5713,
        zipCode: 9784,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Harasztifalu",
        "infó:": ""
      },
      {
        "Település ID": 1215,
        "KSH településazonosító törzsszám": 14164,
        zipCode: 7172,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Harc",
        "infó:": ""
      },
      {
        "Település ID": 1216,
        "KSH településazonosító törzsszám": 7649,
        zipCode: 9422,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Harka",
        "infó:": ""
      },
      {
        "Település ID": 1217,
        "KSH településazonosító törzsszám": 8350,
        zipCode: 6136,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Harkakötöny",
        "infó:": ""
      },
      {
        "Település ID": 1218,
        "KSH településazonosító törzsszám": 21528,
        zipCode: 7815,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Harkány",
        "infó:": ""
      },
      {
        "Település ID": 1219,
        "KSH településazonosító törzsszám": 8837,
        zipCode: 7585,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Háromfa",
        "infó:": ""
      },
      {
        "Település ID": 1220,
        "KSH településazonosító törzsszám": 12706,
        zipCode: 3936,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Háromhuta",
        "infó:": ""
      },
      {
        "Település ID": 1221,
        "KSH településazonosító törzsszám": 5847,
        zipCode: 3555,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Harsány",
        "infó:": ""
      },
      {
        "Település ID": 1222,
        "KSH településazonosító törzsszám": 25566,
        zipCode: 8442,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Hárskút",
        "infó:": ""
      },
      {
        "Település ID": 1223,
        "KSH településazonosító törzsszám": 18458,
        zipCode: 6326,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Harta",
        "infó:": ""
      },
      {
        "Település ID": 1224,
        "KSH településazonosító törzsszám": 18458,
        zipCode: 6327,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Harta",
        "infó:": ""
      },
      {
        "Település ID": 1225,
        "KSH településazonosító törzsszám": 27881,
        zipCode: 7745,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Hásságy",
        "infó:": ""
      },
      {
        "Település ID": 1226,
        "KSH településazonosító törzsszám": 22309,
        zipCode: 3000,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Hatvan",
        "infó:": ""
      },
      {
        "Település ID": 1227,
        "KSH településazonosító törzsszám": 12308,
        zipCode: 9178,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Hédervár",
        "infó:": ""
      },
      {
        "Település ID": 1228,
        "KSH településazonosító törzsszám": 16726,
        zipCode: 7533,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Hedrehely",
        "infó:": ""
      },
      {
        "Település ID": 1229,
        "KSH településazonosító törzsszám": 2422,
        zipCode: 8296,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Hegyesd",
        "infó:": ""
      },
      {
        "Település ID": 1230,
        "KSH településazonosító törzsszám": 17905,
        zipCode: 9222,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Hegyeshalom",
        "infó:": ""
      },
      {
        "Település ID": 1231,
        "KSH településazonosító törzsszám": 32188,
        zipCode: 9631,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Hegyfalu",
        "infó:": ""
      },
      {
        "Település ID": 1232,
        "KSH településazonosító törzsszám": 14997,
        zipCode: 9915,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Hegyháthodász",
        "infó:": ""
      },
      {
        "Település ID": 1233,
        "KSH településazonosító törzsszám": 30951,
        zipCode: 7348,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Hegyhátmaróc",
        "infó:": ""
      },
      {
        "Település ID": 1234,
        "KSH településazonosító törzsszám": 30216,
        zipCode: 9915,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Hegyhátsál",
        "infó:": ""
      },
      {
        "Település ID": 1235,
        "KSH településazonosító törzsszám": 18032,
        zipCode: 9934,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Hegyhátszentjakab",
        "infó:": ""
      },
      {
        "Település ID": 1236,
        "KSH településazonosító törzsszám": 21838,
        zipCode: 9931,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Hegyhátszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 1237,
        "KSH településazonosító törzsszám": 8226,
        zipCode: 9826,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Hegyhátszentpéter",
        "infó:": ""
      },
      {
        "Település ID": 1238,
        "KSH településazonosító törzsszám": 18403,
        zipCode: 9437,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Hegyk%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1239,
        "KSH településazonosító törzsszám": 25803,
        zipCode: 8265,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Hegymagas",
        "infó:": ""
      },
      {
        "Település ID": 1240,
        "KSH településazonosító törzsszám": 2468,
        zipCode: 3786,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hegymeg",
        "infó:": ""
      },
      {
        "Település ID": 1241,
        "KSH településazonosító törzsszám": 21023,
        zipCode: 7837,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Hegyszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 1242,
        "KSH településazonosító törzsszám": 3993,
        zipCode: 3041,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Héhalom",
        "infó:": ""
      },
      {
        "Település ID": 1243,
        "KSH településazonosító törzsszám": 22187,
        zipCode: 3892,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hejce",
        "infó:": ""
      },
      {
        "Település ID": 1244,
        "KSH településazonosító törzsszám": 6655,
        zipCode: 3593,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hej%u0151bába",
        "infó:": ""
      },
      {
        "Település ID": 1245,
        "KSH településazonosító törzsszám": 4604,
        zipCode: 3597,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hej%u0151keresztúr",
        "infó:": ""
      },
      {
        "Település ID": 1246,
        "KSH településazonosító törzsszám": 2282,
        zipCode: 3588,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hej%u0151kürt",
        "infó:": ""
      },
      {
        "Település ID": 1247,
        "KSH településazonosító törzsszám": 16780,
        zipCode: 3594,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hej%u0151papi",
        "infó:": ""
      },
      {
        "Település ID": 1248,
        "KSH településazonosító törzsszám": 12159,
        zipCode: 3595,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hej%u0151szalonta",
        "infó:": ""
      },
      {
        "Település ID": 1249,
        "KSH településazonosító törzsszám": 17190,
        zipCode: 7683,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Helesfa",
        "infó:": ""
      },
      {
        "Település ID": 1250,
        "KSH településazonosító törzsszám": 4093,
        zipCode: 6034,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Helvécia",
        "infó:": ""
      },
      {
        "Település ID": 1251,
        "KSH településazonosító törzsszám": 29391,
        zipCode: 4123,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hencida",
        "infó:": ""
      },
      {
        "Település ID": 1252,
        "KSH településazonosító törzsszám": 9946,
        zipCode: 7532,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Hencse",
        "infó:": ""
      },
      {
        "Település ID": 1253,
        "KSH településazonosító törzsszám": 33552,
        zipCode: 2053,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Herceghalom",
        "infó:": ""
      },
      {
        "Település ID": 1254,
        "KSH településazonosító törzsszám": 30137,
        zipCode: 3958,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hercegkút",
        "infó:": ""
      },
      {
        "Település ID": 1255,
        "KSH településazonosító törzsszám": 12937,
        zipCode: 6525,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Hercegszántó",
        "infó:": ""
      },
      {
        "Település ID": 1256,
        "KSH településazonosító törzsszám": 20242,
        zipCode: 3011,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Heréd",
        "infó:": ""
      },
      {
        "Település ID": 1257,
        "KSH településazonosító törzsszám": 11891,
        zipCode: 2832,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Héreg",
        "infó:": ""
      },
      {
        "Település ID": 1258,
        "KSH településazonosító törzsszám": 5324,
        zipCode: 2677,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Herencsény",
        "infó:": ""
      },
      {
        "Település ID": 1259,
        "KSH településazonosító törzsszám": 23658,
        zipCode: 8440,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Herend",
        "infó:": ""
      },
      {
        "Település ID": 1260,
        "KSH településazonosító törzsszám": 24846,
        zipCode: 7587,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Heresznye",
        "infó:": ""
      },
      {
        "Település ID": 1261,
        "KSH településazonosító törzsszám": 12061,
        zipCode: 4735,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Hermánszeg",
        "infó:": ""
      },
      {
        "Település ID": 1262,
        "KSH településazonosító törzsszám": 9849,
        zipCode: 2376,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Hernád",
        "infó:": ""
      },
      {
        "Település ID": 1263,
        "KSH településazonosító törzsszám": 15839,
        zipCode: 3853,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádb%u0171d",
        "infó:": ""
      },
      {
        "Település ID": 1264,
        "KSH településazonosító törzsszám": 9399,
        zipCode: 3887,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádcéce",
        "infó:": ""
      },
      {
        "Település ID": 1265,
        "KSH településazonosító törzsszám": 24165,
        zipCode: 3563,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádkak",
        "infó:": ""
      },
      {
        "Település ID": 1266,
        "KSH településazonosító törzsszám": 21829,
        zipCode: 3846,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádkércs",
        "infó:": ""
      },
      {
        "Település ID": 1267,
        "KSH településazonosító törzsszám": 31200,
        zipCode: 3564,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádnémeti",
        "infó:": ""
      },
      {
        "Település ID": 1268,
        "KSH településazonosító törzsszám": 24882,
        zipCode: 3874,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádpetri",
        "infó:": ""
      },
      {
        "Település ID": 1269,
        "KSH településazonosító törzsszám": 17136,
        zipCode: 3852,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádszentandrás",
        "infó:": ""
      },
      {
        "Település ID": 1270,
        "KSH településazonosító törzsszám": 24970,
        zipCode: 3875,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádszurdok",
        "infó:": ""
      },
      {
        "Település ID": 1271,
        "KSH településazonosító törzsszám": 19840,
        zipCode: 3874,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hernádvécse",
        "infó:": ""
      },
      {
        "Település ID": 1272,
        "KSH településazonosító törzsszám": 19895,
        zipCode: 8957,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Hernyék",
        "infó:": ""
      },
      {
        "Település ID": 1273,
        "KSH településazonosító törzsszám": 8004,
        zipCode: 3655,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hét",
        "infó:": ""
      },
      {
        "Település ID": 1274,
        "KSH településazonosító törzsszám": 5616,
        zipCode: 4843,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Hetefejércse",
        "infó:": ""
      },
      {
        "Település ID": 1275,
        "KSH településazonosító törzsszám": 9928,
        zipCode: 7432,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Hetes",
        "infó:": ""
      },
      {
        "Település ID": 1276,
        "KSH településazonosító törzsszám": 7126,
        zipCode: 7681,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Hetvehely",
        "infó:": ""
      },
      {
        "Település ID": 1277,
        "KSH településazonosító törzsszám": 15088,
        zipCode: 8344,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Hetyef%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1278,
        "KSH településazonosító törzsszám": 14526,
        zipCode: 3360,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Heves",
        "infó:": ""
      },
      {
        "Település ID": 1279,
        "KSH településazonosító törzsszám": 10241,
        zipCode: 3322,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Hevesaranyos",
        "infó:": ""
      },
      {
        "Település ID": 1280,
        "KSH településazonosító törzsszám": 4084,
        zipCode: 3383,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Hevesvezekény",
        "infó:": ""
      },
      {
        "Település ID": 1281,
        "KSH településazonosító törzsszám": 3814,
        zipCode: 8380,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Hévíz",
        "infó:": ""
      },
      {
        "Település ID": 1282,
        "KSH településazonosító törzsszám": 13949,
        zipCode: 2192,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Hévízgyörk",
        "infó:": ""
      },
      {
        "Település ID": 1283,
        "KSH településazonosító törzsszám": 6798,
        zipCode: 7696,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Hidas",
        "infó:": ""
      },
      {
        "Település ID": 1284,
        "KSH településazonosító törzsszám": 11697,
        zipCode: 3876,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hidasnémeti",
        "infó:": ""
      },
      {
        "Település ID": 1285,
        "KSH településazonosító törzsszám": 20756,
        zipCode: 8247,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Hidegkút",
        "infó:": ""
      },
      {
        "Település ID": 1286,
        "KSH településazonosító törzsszám": 23375,
        zipCode: 9491,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Hidegség",
        "infó:": ""
      },
      {
        "Település ID": 1287,
        "KSH településazonosító törzsszám": 25672,
        zipCode: 3768,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hidvégardó",
        "infó:": ""
      },
      {
        "Település ID": 1288,
        "KSH településazonosító törzsszám": 27933,
        zipCode: 7735,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Himesháza",
        "infó:": ""
      },
      {
        "Település ID": 1289,
        "KSH településazonosító törzsszám": 4020,
        zipCode: 9362,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Himod",
        "infó:": ""
      },
      {
        "Település ID": 1290,
        "KSH településazonosító törzsszám": 3285,
        zipCode: 7838,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Hirics",
        "infó:": ""
      },
      {
        "Település ID": 1291,
        "KSH településazonosító törzsszám": 33932,
        zipCode: 7971,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Hobol",
        "infó:": ""
      },
      {
        "Település ID": 1292,
        "KSH településazonosító törzsszám": 13019,
        zipCode: 4334,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Hodász",
        "infó:": ""
      },
      {
        "Település ID": 1293,
        "KSH településazonosító törzsszám": 8314,
        zipCode: 6800,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Hódmez%u0151vásárhely",
        "infó:": ""
      },
      {
        "Település ID": 1294,
        "KSH településazonosító törzsszám": 8314,
        zipCode: 6806,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Hódmez%u0151vásárhely",
        "infó:": ""
      },
      {
        "Település ID": 1295,
        "KSH településazonosító törzsszám": 26055,
        zipCode: 7191,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "H%u0151gyész",
        "infó:": ""
      },
      {
        "Település ID": 1296,
        "KSH településazonosító törzsszám": 6211,
        zipCode: 8731,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Hollád",
        "infó:": ""
      },
      {
        "Település ID": 1297,
        "KSH településazonosító törzsszám": 31167,
        zipCode: 3999,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Hollóháza",
        "infó:": ""
      },
      {
        "Település ID": 1298,
        "KSH településazonosító törzsszám": 33242,
        zipCode: 3176,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Hollók%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1299,
        "KSH településazonosító törzsszám": 27818,
        zipCode: 8563,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Homokbödöge",
        "infó:": ""
      },
      {
        "Település ID": 1300,
        "KSH településazonosító törzsszám": 24873,
        zipCode: 8777,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Homokkomárom",
        "infó:": ""
      },
      {
        "Település ID": 1301,
        "KSH településazonosító törzsszám": 27845,
        zipCode: 6341,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Homokmégy",
        "infó:": ""
      },
      {
        "Település ID": 1302,
        "KSH településazonosító törzsszám": 19150,
        zipCode: 7537,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Homokszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 1303,
        "KSH településazonosító törzsszám": 29966,
        zipCode: 7716,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Homorúd",
        "infó:": ""
      },
      {
        "Település ID": 1304,
        "KSH településazonosító törzsszám": 21236,
        zipCode: 3812,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Homrogd",
        "infó:": ""
      },
      {
        "Település ID": 1305,
        "KSH településazonosító törzsszám": 13204,
        zipCode: 2647,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Hont",
        "infó:": ""
      },
      {
        "Település ID": 1306,
        "KSH településazonosító törzsszám": 13718,
        zipCode: 2658,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Horpács",
        "infó:": ""
      },
      {
        "Település ID": 1307,
        "KSH településazonosító törzsszám": 4145,
        zipCode: 3014,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Hort",
        "infó:": ""
      },
      {
        "Település ID": 1308,
        "KSH településazonosító törzsszám": 4118,
        zipCode: 4071,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hortobágy",
        "infó:": ""
      },
      {
        "Település ID": 1309,
        "KSH településazonosító törzsszám": 23074,
        zipCode: 7935,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Horváthertelend",
        "infó:": ""
      },
      {
        "Település ID": 1310,
        "KSH településazonosító törzsszám": 12733,
        zipCode: 9796,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Horvátlöv%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1311,
        "KSH településazonosító törzsszám": 16887,
        zipCode: 9733,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Horvátzsidány",
        "infó:": ""
      },
      {
        "Település ID": 1312,
        "KSH településazonosító törzsszám": 30836,
        zipCode: 7694,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Hosszúhetény",
        "infó:": ""
      },
      {
        "Település ID": 1313,
        "KSH településazonosító törzsszám": 6266,
        zipCode: 4274,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Hosszúpályi",
        "infó:": ""
      },
      {
        "Település ID": 1314,
        "KSH településazonosító törzsszám": 20880,
        zipCode: 9676,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Hosszúpereszteg",
        "infó:": ""
      },
      {
        "Település ID": 1315,
        "KSH településazonosító törzsszám": 30775,
        zipCode: 8716,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Hosszúvíz",
        "infó:": ""
      },
      {
        "Település ID": 1316,
        "KSH településazonosító törzsszám": 11208,
        zipCode: 8777,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Hosszúvölgy",
        "infó:": ""
      },
      {
        "Település ID": 1317,
        "KSH településazonosító törzsszám": 7250,
        zipCode: 8475,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Hosztót",
        "infó:": ""
      },
      {
        "Település ID": 1318,
        "KSH településazonosító törzsszám": 28325,
        zipCode: 8991,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Hottó",
        "infó:": ""
      },
      {
        "Település ID": 1323,
        "KSH településazonosító törzsszám": 10029,
        zipCode: 9361,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Hövej",
        "infó:": ""
      },
      {
        "Település ID": 1319,
        "KSH településazonosító törzsszám": 16878,
        zipCode: 2672,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Hugyag",
        "infó:": ""
      },
      {
        "Település ID": 1320,
        "KSH településazonosító törzsszám": 33297,
        zipCode: 5555,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Hunya",
        "infó:": ""
      },
      {
        "Település ID": 1321,
        "KSH településazonosító törzsszám": 34050,
        zipCode: 5063,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Hunyadfalva",
        "infó:": ""
      },
      {
        "Település ID": 1322,
        "KSH településazonosító törzsszám": 31431,
        zipCode: 7678,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Husztót",
        "infó:": ""
      },
      {
        "Település ID": 1324,
        "KSH településazonosító törzsszám": 33066,
        zipCode: 7935,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ibafa",
        "infó:": ""
      },
      {
        "Település ID": 1325,
        "KSH településazonosító törzsszám": 3188,
        zipCode: 8984,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Iborfia",
        "infó:": ""
      },
      {
        "Település ID": 1326,
        "KSH településazonosító törzsszám": 25636,
        zipCode: 4484,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ibrány",
        "infó:": ""
      },
      {
        "Település ID": 1327,
        "KSH településazonosító törzsszám": 11192,
        zipCode: 7275,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Igal",
        "infó:": ""
      },
      {
        "Település ID": 1328,
        "KSH településazonosító törzsszám": 17738,
        zipCode: 7015,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Igar",
        "infó:": ""
      },
      {
        "Település ID": 1329,
        "KSH településazonosító törzsszám": 17738,
        zipCode: 7016,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Igar",
        "infó:": ""
      },
      {
        "Település ID": 1330,
        "KSH településazonosító törzsszám": 25399,
        zipCode: 3459,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Igrici",
        "infó:": ""
      },
      {
        "Település ID": 1331,
        "KSH településazonosító törzsszám": 19619,
        zipCode: 8726,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Iharos",
        "infó:": ""
      },
      {
        "Település ID": 1332,
        "KSH településazonosító törzsszám": 27784,
        zipCode: 8725,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Iharosberény",
        "infó:": ""
      },
      {
        "Település ID": 1333,
        "KSH településazonosító törzsszám": 11387,
        zipCode: 9756,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Ikervár",
        "infó:": ""
      },
      {
        "Település ID": 1334,
        "KSH településazonosító törzsszám": 3300,
        zipCode: 2181,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Iklad",
        "infó:": ""
      },
      {
        "Település ID": 1335,
        "KSH településazonosító törzsszám": 29504,
        zipCode: 9634,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Iklanberény",
        "infó:": ""
      },
      {
        "Település ID": 1336,
        "KSH településazonosító törzsszám": 13921,
        zipCode: 8958,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Iklódbörd%u0151ce",
        "infó:": ""
      },
      {
        "Település ID": 1337,
        "KSH településazonosító törzsszám": 20604,
        zipCode: 9141,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Ikrény",
        "infó:": ""
      },
      {
        "Település ID": 1338,
        "KSH településazonosító törzsszám": 26833,
        zipCode: 2675,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Iliny",
        "infó:": ""
      },
      {
        "Település ID": 1339,
        "KSH településazonosító törzsszám": 9654,
        zipCode: 4566,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ilk",
        "infó:": ""
      },
      {
        "Település ID": 1340,
        "KSH településazonosító törzsszám": 31936,
        zipCode: 7775,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Illocska",
        "infó:": ""
      },
      {
        "Település ID": 1341,
        "KSH településazonosító törzsszám": 16577,
        zipCode: 3724,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Imola",
        "infó:": ""
      },
      {
        "Település ID": 1342,
        "KSH településazonosító törzsszám": 8095,
        zipCode: 6238,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Imrehegy",
        "infó:": ""
      },
      {
        "Település ID": 1343,
        "KSH településazonosító törzsszám": 8086,
        zipCode: 3851,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ináncs",
        "infó:": ""
      },
      {
        "Település ID": 1344,
        "KSH településazonosító törzsszám": 32106,
        zipCode: 2365,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Inárcs",
        "infó:": ""
      },
      {
        "Település ID": 1345,
        "KSH településazonosító törzsszám": 26301,
        zipCode: 8724,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Inke",
        "infó:": ""
      },
      {
        "Település ID": 1346,
        "KSH településazonosító törzsszám": 29948,
        zipCode: 7847,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ipacsfa",
        "infó:": ""
      },
      {
        "Település ID": 1347,
        "KSH településazonosító törzsszám": 28097,
        zipCode: 2631,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Ipolydamásd",
        "infó:": ""
      },
      {
        "Település ID": 1348,
        "KSH településazonosító törzsszám": 3328,
        zipCode: 3138,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Ipolytarnóc",
        "infó:": ""
      },
      {
        "Település ID": 1349,
        "KSH településazonosító törzsszám": 4978,
        zipCode: 2633,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Ipolytölgyes",
        "infó:": ""
      },
      {
        "Település ID": 1350,
        "KSH településazonosító törzsszám": 29319,
        zipCode: 2669,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Ipolyvece",
        "infó:": ""
      },
      {
        "Település ID": 1351,
        "KSH településazonosító törzsszám": 4701,
        zipCode: 7095,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Iregszemcse",
        "infó:": ""
      },
      {
        "Település ID": 1352,
        "KSH településazonosító törzsszám": 5005,
        zipCode: 3786,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Irota",
        "infó:": ""
      },
      {
        "Település ID": 1353,
        "KSH településazonosító törzsszám": 7807,
        zipCode: 2117,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Isaszeg",
        "infó:": ""
      },
      {
        "Település ID": 1354,
        "KSH településazonosító törzsszám": 7977,
        zipCode: 9941,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Ispánk",
        "infó:": ""
      },
      {
        "Település ID": 1355,
        "KSH településazonosító törzsszám": 10074,
        zipCode: 3253,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Istenmezeje",
        "infó:": ""
      },
      {
        "Település ID": 1356,
        "KSH településazonosító törzsszám": 21333,
        zipCode: 7987,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Istvándi",
        "infó:": ""
      },
      {
        "Település ID": 1357,
        "KSH településazonosító törzsszám": 32018,
        zipCode: 8043,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Iszkaszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 1358,
        "KSH településazonosító törzsszám": 28015,
        zipCode: 8493,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Iszkáz",
        "infó:": ""
      },
      {
        "Település ID": 1359,
        "KSH településazonosító törzsszám": 2200,
        zipCode: 8045,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Isztimér",
        "infó:": ""
      },
      {
        "Település ID": 1360,
        "KSH településazonosító törzsszám": 13879,
        zipCode: 3248,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Ivád",
        "infó:": ""
      },
      {
        "Település ID": 1361,
        "KSH településazonosító törzsszám": 31635,
        zipCode: 9374,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Iván",
        "infó:": ""
      },
      {
        "Település ID": 1362,
        "KSH településazonosító törzsszám": 3337,
        zipCode: 7772,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ivánbattyán",
        "infó:": ""
      },
      {
        "Település ID": 1363,
        "KSH településazonosító törzsszám": 31680,
        zipCode: 9931,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Ivánc",
        "infó:": ""
      },
      {
        "Település ID": 1364,
        "KSH településazonosító törzsszám": 13462,
        zipCode: 2454,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Iváncsa",
        "infó:": ""
      },
      {
        "Település ID": 1365,
        "KSH településazonosító törzsszám": 3346,
        zipCode: 7781,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ivándárda",
        "infó:": ""
      },
      {
        "Település ID": 1366,
        "KSH településazonosító törzsszám": 27711,
        zipCode: 7353,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Izmény",
        "infó:": ""
      },
      {
        "Település ID": 1367,
        "KSH településazonosító törzsszám": 21999,
        zipCode: 6070,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Izsák",
        "infó:": ""
      },
      {
        "Település ID": 1368,
        "KSH településazonosító törzsszám": 5591,
        zipCode: 3741,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Izsófalva",
        "infó:": ""
      },
      {
        "Település ID": 1369,
        "KSH településazonosító törzsszám": 9326,
        zipCode: 7362,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Jágónak",
        "infó:": ""
      },
      {
        "Település ID": 1370,
        "KSH településazonosító törzsszám": 13958,
        zipCode: 9798,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Ják",
        "infó:": ""
      },
      {
        "Település ID": 1371,
        "KSH településazonosító törzsszám": 17923,
        zipCode: 6078,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Jakabszállás",
        "infó:": ""
      },
      {
        "Település ID": 1372,
        "KSH településazonosító törzsszám": 6406,
        zipCode: 9643,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Jákfa",
        "infó:": ""
      },
      {
        "Település ID": 1373,
        "KSH településazonosító törzsszám": 20233,
        zipCode: 3721,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Jákfalva",
        "infó:": ""
      },
      {
        "Település ID": 1374,
        "KSH településazonosító törzsszám": 15927,
        zipCode: 7525,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Jákó",
        "infó:": ""
      },
      {
        "Település ID": 1375,
        "KSH településazonosító törzsszám": 17075,
        zipCode: 4841,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Jánd",
        "infó:": ""
      },
      {
        "Település ID": 1376,
        "KSH településazonosító törzsszám": 7843,
        zipCode: 4741,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Jánkmajtis",
        "infó:": ""
      },
      {
        "Település ID": 1377,
        "KSH településazonosító törzsszám": 9469,
        zipCode: 6440,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Jánoshalma",
        "infó:": ""
      },
      {
        "Település ID": 1378,
        "KSH településazonosító törzsszám": 11679,
        zipCode: 9545,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Jánosháza",
        "infó:": ""
      },
      {
        "Település ID": 1379,
        "KSH településazonosító törzsszám": 22859,
        zipCode: 5143,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jánoshida",
        "infó:": ""
      },
      {
        "Település ID": 1380,
        "KSH településazonosító törzsszám": 29221,
        zipCode: 9241,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Jánossomorja",
        "infó:": ""
      },
      {
        "Település ID": 1381,
        "KSH településazonosító törzsszám": 29221,
        zipCode: 9242,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Jánossomorja",
        "infó:": ""
      },
      {
        "Település ID": 1382,
        "KSH településazonosító törzsszám": 26657,
        zipCode: 3664,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Járdánháza",
        "infó:": ""
      },
      {
        "Település ID": 1383,
        "KSH településazonosító törzsszám": 17589,
        zipCode: 4337,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Jármi",
        "infó:": ""
      },
      {
        "Település ID": 1384,
        "KSH településazonosító törzsszám": 17437,
        zipCode: 8424,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Jásd",
        "infó:": ""
      },
      {
        "Település ID": 1385,
        "KSH településazonosító törzsszám": 22929,
        zipCode: 5124,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászágó",
        "infó:": ""
      },
      {
        "Település ID": 1386,
        "KSH településazonosító törzsszám": 30711,
        zipCode: 5054,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászalsószentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 1387,
        "KSH településazonosító törzsszám": 22202,
        zipCode: 5130,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászapáti",
        "infó:": ""
      },
      {
        "Település ID": 1388,
        "KSH településazonosító törzsszám": 22105,
        zipCode: 5123,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászárokszállás",
        "infó:": ""
      },
      {
        "Település ID": 1389,
        "KSH településazonosító törzsszám": 18209,
        zipCode: 5100,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászberény",
        "infó:": ""
      },
      {
        "Település ID": 1390,
        "KSH településazonosító törzsszám": 18209,
        zipCode: 5152,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászberény",
        "infó:": ""
      },
      {
        "Település ID": 1391,
        "KSH településazonosító törzsszám": 15811,
        zipCode: 5144,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászboldogháza",
        "infó:": ""
      },
      {
        "Település ID": 1392,
        "KSH településazonosító törzsszám": 17978,
        zipCode: 5122,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászdózsa",
        "infó:": ""
      },
      {
        "Település ID": 1393,
        "KSH településazonosító törzsszám": 23579,
        zipCode: 5111,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászfels%u0151szentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 1394,
        "KSH településazonosító törzsszám": 23339,
        zipCode: 5126,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászfényszaru",
        "infó:": ""
      },
      {
        "Település ID": 1395,
        "KSH településazonosító törzsszám": 24086,
        zipCode: 5135,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászivány",
        "infó:": ""
      },
      {
        "Település ID": 1396,
        "KSH településazonosító törzsszám": 25186,
        zipCode: 5121,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászjákóhalma",
        "infó:": ""
      },
      {
        "Település ID": 1397,
        "KSH településazonosító törzsszám": 11004,
        zipCode: 2746,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Jászkarajen%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1398,
        "KSH településazonosító törzsszám": 22798,
        zipCode: 5137,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászkisér",
        "infó:": ""
      },
      {
        "Település ID": 1399,
        "KSH településazonosító törzsszám": 21111,
        zipCode: 5055,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászladány",
        "infó:": ""
      },
      {
        "Település ID": 1400,
        "KSH településazonosító törzsszám": 13514,
        zipCode: 5136,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jászszentandrás",
        "infó:": ""
      },
      {
        "Település ID": 1401,
        "KSH településazonosító törzsszám": 8378,
        zipCode: 6133,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Jászszentlászló",
        "infó:": ""
      },
      {
        "Település ID": 1402,
        "KSH településazonosító törzsszám": 23135,
        zipCode: 5141,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Jásztelek",
        "infó:": ""
      },
      {
        "Település ID": 1403,
        "KSH településazonosító törzsszám": 13143,
        zipCode: 4611,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Jéke",
        "infó:": ""
      },
      {
        "Település ID": 1404,
        "KSH településazonosító törzsszám": 15972,
        zipCode: 8146,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Jen%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1405,
        "KSH településazonosító törzsszám": 6646,
        zipCode: 9323,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Jobaháza",
        "infó:": ""
      },
      {
        "Település ID": 1406,
        "KSH településazonosító törzsszám": 8712,
        zipCode: 3063,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Jobbágyi",
        "infó:": ""
      },
      {
        "Település ID": 1407,
        "KSH településazonosító törzsszám": 15680,
        zipCode: 3758,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Jósvaf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1408,
        "KSH településazonosító törzsszám": 17279,
        zipCode: 7431,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Juta",
        "infó:": ""
      },
      {
        "Település ID": 1409,
        "KSH településazonosító törzsszám": 2307,
        zipCode: 4183,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Kaba",
        "infó:": ""
      },
      {
        "Település ID": 1410,
        "KSH településazonosító törzsszám": 22965,
        zipCode: 8773,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kacorlak",
        "infó:": ""
      },
      {
        "Település ID": 1411,
        "KSH településazonosító törzsszám": 32993,
        zipCode: 3424,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kács",
        "infó:": ""
      },
      {
        "Település ID": 1412,
        "KSH településazonosító törzsszám": 4297,
        zipCode: 7940,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kacsóta",
        "infó:": ""
      },
      {
        "Település ID": 1413,
        "KSH településazonosító törzsszám": 26453,
        zipCode: 7530,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kadarkút",
        "infó:": ""
      },
      {
        "Település ID": 1414,
        "KSH településazonosító törzsszám": 12113,
        zipCode: 9123,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kajárpéc",
        "infó:": ""
      },
      {
        "Település ID": 1415,
        "KSH településazonosító törzsszám": 21342,
        zipCode: 2472,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Kajászó",
        "infó:": ""
      },
      {
        "Település ID": 1416,
        "KSH településazonosító törzsszám": 14100,
        zipCode: 7051,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kajdacs",
        "infó:": ""
      },
      {
        "Település ID": 1417,
        "KSH településazonosító törzsszám": 2033,
        zipCode: 7122,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kakasd",
        "infó:": ""
      },
      {
        "Település ID": 1418,
        "KSH településazonosító törzsszám": 6415,
        zipCode: 7958,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kákics",
        "infó:": ""
      },
      {
        "Település ID": 1419,
        "KSH településazonosító törzsszám": 32230,
        zipCode: 2366,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kakucs",
        "infó:": ""
      },
      {
        "Település ID": 1420,
        "KSH településazonosító törzsszám": 32179,
        zipCode: 3350,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Kál",
        "infó:": ""
      },
      {
        "Település ID": 1421,
        "KSH településazonosító törzsszám": 23791,
        zipCode: 7194,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kalaznó",
        "infó:": ""
      },
      {
        "Település ID": 1422,
        "KSH településazonosító törzsszám": 29957,
        zipCode: 9673,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Káld",
        "infó:": ""
      },
      {
        "Település ID": 1423,
        "KSH településazonosító törzsszám": 8642,
        zipCode: 2175,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kálló",
        "infó:": ""
      },
      {
        "Település ID": 1424,
        "KSH településazonosító törzsszám": 5537,
        zipCode: 8785,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kallósd",
        "infó:": ""
      },
      {
        "Település ID": 1425,
        "KSH településazonosító törzsszám": 31404,
        zipCode: 4324,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kállósemjén",
        "infó:": ""
      },
      {
        "Település ID": 1426,
        "KSH településazonosító törzsszám": 6105,
        zipCode: 7538,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kálmáncsa",
        "infó:": ""
      },
      {
        "Település ID": 1427,
        "KSH településazonosító törzsszám": 27225,
        zipCode: 4434,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kálmánháza",
        "infó:": ""
      },
      {
        "Település ID": 1428,
        "KSH településazonosító törzsszám": 21500,
        zipCode: 8988,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kálócfa",
        "infó:": ""
      },
      {
        "Település ID": 1429,
        "KSH településazonosító törzsszám": 6442,
        zipCode: 6300,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kalocsa",
        "infó:": ""
      },
      {
        "Település ID": 1430,
        "KSH településazonosító törzsszám": 16683,
        zipCode: 8124,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Káloz",
        "infó:": ""
      },
      {
        "Település ID": 1431,
        "KSH településazonosító törzsszám": 4640,
        zipCode: 9841,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kám",
        "infó:": ""
      },
      {
        "Település ID": 1432,
        "KSH településazonosító törzsszám": 19141,
        zipCode: 8469,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kamond",
        "infó:": ""
      },
      {
        "Település ID": 1433,
        "KSH településazonosító törzsszám": 4279,
        zipCode: 5673,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kamut",
        "infó:": ""
      },
      {
        "Település ID": 1434,
        "KSH településazonosító törzsszám": 7764,
        zipCode: 3735,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kánó",
        "infó:": ""
      },
      {
        "Település ID": 1435,
        "KSH településazonosító törzsszám": 2671,
        zipCode: 4335,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kántorjánosi",
        "infó:": ""
      },
      {
        "Település ID": 1436,
        "KSH településazonosító törzsszám": 25742,
        zipCode: 3821,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kány",
        "infó:": ""
      },
      {
        "Település ID": 1437,
        "KSH településazonosító törzsszám": 5272,
        zipCode: 8667,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kánya",
        "infó:": ""
      },
      {
        "Település ID": 1438,
        "KSH településazonosító törzsszám": 4473,
        zipCode: 8956,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kányavár",
        "infó:": ""
      },
      {
        "Település ID": 1439,
        "KSH településazonosító törzsszám": 14553,
        zipCode: 8294,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kapolcs",
        "infó:": ""
      },
      {
        "Település ID": 1440,
        "KSH településazonosító törzsszám": 15307,
        zipCode: 3355,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Kápolna",
        "infó:": ""
      },
      {
        "Település ID": 1441,
        "KSH településazonosító törzsszám": 21926,
        zipCode: 2475,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Kápolnásnyék",
        "infó:": ""
      },
      {
        "Település ID": 1442,
        "KSH településazonosító törzsszám": 33394,
        zipCode: 8671,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kapoly",
        "infó:": ""
      },
      {
        "Település ID": 1443,
        "KSH településazonosító törzsszám": 9098,
        zipCode: 7523,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaposf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1444,
        "KSH településazonosító törzsszám": 28811,
        zipCode: 7473,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaposgyarmat",
        "infó:": ""
      },
      {
        "Település ID": 1445,
        "KSH településazonosító törzsszám": 25867,
        zipCode: 7261,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaposhomok",
        "infó:": ""
      },
      {
        "Település ID": 1446,
        "KSH településazonosító törzsszám": 12663,
        zipCode: 7258,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaposkeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 1447,
        "KSH településazonosító törzsszám": 18227,
        zipCode: 7521,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaposmér%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1448,
        "KSH településazonosító törzsszám": 23296,
        zipCode: 7251,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kapospula",
        "infó:": ""
      },
      {
        "Település ID": 1449,
        "KSH településazonosító törzsszám": 18962,
        zipCode: 7361,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kaposszekcs%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1450,
        "KSH településazonosító törzsszám": 6424,
        zipCode: 7476,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaposszerdahely",
        "infó:": ""
      },
      {
        "Település ID": 1451,
        "KSH településazonosító törzsszám": 15732,
        zipCode: 7522,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaposújlak",
        "infó:": ""
      },
      {
        "Település ID": 1452,
        "KSH településazonosító törzsszám": 20473,
        zipCode: 7400,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaposvár",
        "infó:": ""
      },
      {
        "Település ID": 1453,
        "KSH településazonosító törzsszám": 14270,
        zipCode: 8471,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Káptalanfa",
        "infó:": ""
      },
      {
        "Település ID": 1454,
        "KSH településazonosító törzsszám": 5634,
        zipCode: 8283,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Káptalantóti",
        "infó:": ""
      },
      {
        "Település ID": 1455,
        "KSH településazonosító törzsszám": 28334,
        zipCode: 9330,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kapuvár",
        "infó:": ""
      },
      {
        "Település ID": 1456,
        "KSH településazonosító törzsszám": 28334,
        zipCode: 9339,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kapuvár",
        "infó:": ""
      },
      {
        "Település ID": 1457,
        "KSH településazonosító törzsszám": 5351,
        zipCode: 7285,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kára",
        "infó:": ""
      },
      {
        "Település ID": 1458,
        "KSH településazonosító törzsszám": 5935,
        zipCode: 3281,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Karácsond",
        "infó:": ""
      },
      {
        "Település ID": 1459,
        "KSH településazonosító törzsszám": 5263,
        zipCode: 8676,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Karád",
        "infó:": ""
      },
      {
        "Település ID": 1460,
        "KSH településazonosító törzsszám": 10913,
        zipCode: 9547,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Karakó",
        "infó:": ""
      },
      {
        "Település ID": 1461,
        "KSH településazonosító törzsszám": 8749,
        zipCode: 8491,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Karakószörcsök",
        "infó:": ""
      },
      {
        "Település ID": 1462,
        "KSH településazonosító törzsszám": 18625,
        zipCode: 3181,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Karancsalja",
        "infó:": ""
      },
      {
        "Település ID": 1463,
        "KSH településazonosító törzsszám": 25548,
        zipCode: 3137,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Karancsberény",
        "infó:": ""
      },
      {
        "Település ID": 1464,
        "KSH településazonosító törzsszám": 8855,
        zipCode: 3183,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Karancskeszi",
        "infó:": ""
      },
      {
        "Település ID": 1465,
        "KSH településazonosító törzsszám": 21041,
        zipCode: 3182,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Karancslapujt%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1466,
        "KSH településazonosító törzsszám": 26897,
        zipCode: 3163,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Karancsság",
        "infó:": ""
      },
      {
        "Település ID": 1467,
        "KSH településazonosító törzsszám": 5999,
        zipCode: 7333,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kárász",
        "infó:": ""
      },
      {
        "Település ID": 1468,
        "KSH településazonosító törzsszám": 4923,
        zipCode: 5300,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Karcag",
        "infó:": ""
      },
      {
        "Település ID": 1469,
        "KSH településazonosító törzsszám": 21218,
        zipCode: 3963,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Karcsa",
        "infó:": ""
      },
      {
        "Település ID": 1470,
        "KSH településazonosító törzsszám": 24794,
        zipCode: 5552,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kardos",
        "infó:": ""
      },
      {
        "Település ID": 1471,
        "KSH településazonosító törzsszám": 12177,
        zipCode: 5945,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kardoskút",
        "infó:": ""
      },
      {
        "Település ID": 1472,
        "KSH településazonosító törzsszám": 18041,
        zipCode: 8354,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Karmacs",
        "infó:": ""
      },
      {
        "Település ID": 1473,
        "KSH településazonosító törzsszám": 34351,
        zipCode: 9182,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Károlyháza",
        "infó:": ""
      },
      {
        "Település ID": 1474,
        "KSH településazonosító törzsszám": 30508,
        zipCode: 3962,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Karos",
        "infó:": ""
      },
      {
        "Település ID": 1475,
        "KSH településazonosító törzsszám": 30696,
        zipCode: 2173,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kartal",
        "infó:": ""
      },
      {
        "Település ID": 1476,
        "KSH településazonosító törzsszám": 17464,
        zipCode: 7827,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kásád",
        "infó:": ""
      },
      {
        "Település ID": 1477,
        "KSH településazonosító törzsszám": 30605,
        zipCode: 6211,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kaskantyú",
        "infó:": ""
      },
      {
        "Település ID": 1478,
        "KSH településazonosító törzsszám": 8411,
        zipCode: 7977,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kastélyosdombó",
        "infó:": ""
      },
      {
        "Település ID": 1479,
        "KSH településazonosító törzsszám": 22752,
        zipCode: 5948,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kaszaper",
        "infó:": ""
      },
      {
        "Település ID": 1480,
        "KSH településazonosító törzsszám": 34193,
        zipCode: 7564,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kaszó",
        "infó:": ""
      },
      {
        "Település ID": 1481,
        "KSH településazonosító törzsszám": 6965,
        zipCode: 7914,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Katádfa",
        "infó:": ""
      },
      {
        "Település ID": 1482,
        "KSH településazonosító törzsszám": 26620,
        zipCode: 9915,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Katafa",
        "infó:": ""
      },
      {
        "Település ID": 1483,
        "KSH településazonosító törzsszám": 19132,
        zipCode: 7661,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kátoly",
        "infó:": ""
      },
      {
        "Település ID": 1484,
        "KSH településazonosító törzsszám": 11280,
        zipCode: 6455,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Katymár",
        "infó:": ""
      },
      {
        "Település ID": 1485,
        "KSH településazonosító törzsszám": 27827,
        zipCode: 2215,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Káva",
        "infó:": ""
      },
      {
        "Település ID": 1486,
        "KSH településazonosító törzsszám": 32063,
        zipCode: 8994,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kávás",
        "infó:": ""
      },
      {
        "Település ID": 1487,
        "KSH településazonosító törzsszám": 28389,
        zipCode: 3127,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kazár",
        "infó:": ""
      },
      {
        "Település ID": 1488,
        "KSH településazonosító törzsszám": 28389,
        zipCode: 3147,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kazár",
        "infó:": ""
      },
      {
        "Település ID": 1489,
        "KSH településazonosító törzsszám": 6691,
        zipCode: 3700,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kazincbarcika",
        "infó:": ""
      },
      {
        "Település ID": 1490,
        "KSH településazonosító törzsszám": 13374,
        zipCode: 3831,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kázsmárk",
        "infó:": ""
      },
      {
        "Település ID": 1491,
        "KSH településazonosító törzsszám": 26888,
        zipCode: 7274,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kazsok",
        "infó:": ""
      },
      {
        "Település ID": 1492,
        "KSH településazonosító törzsszám": 19789,
        zipCode: 6237,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kecel",
        "infó:": ""
      },
      {
        "Település ID": 1493,
        "KSH településazonosító törzsszám": 4525,
        zipCode: 2852,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Kecskéd",
        "infó:": ""
      },
      {
        "Település ID": 1494,
        "KSH településazonosító törzsszám": 26684,
        zipCode: 6000,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kecskemét",
        "infó:": ""
      },
      {
        "Település ID": 1495,
        "KSH településazonosító törzsszám": 26684,
        zipCode: 6008,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kecskemét",
        "infó:": ""
      },
      {
        "Település ID": 1496,
        "KSH településazonosító törzsszám": 26684,
        zipCode: 6044,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kecskemét",
        "infó:": ""
      },
      {
        "Település ID": 1497,
        "KSH településazonosító törzsszám": 32902,
        zipCode: 8784,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kehidakustány",
        "infó:": ""
      },
      {
        "Település ID": 1498,
        "KSH településazonosító törzsszám": 28431,
        zipCode: 4515,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kék",
        "infó:": ""
      },
      {
        "Település ID": 1499,
        "KSH településazonosító törzsszám": 14359,
        zipCode: 4494,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kékcse",
        "infó:": ""
      },
      {
        "Település ID": 1500,
        "KSH településazonosító törzsszám": 15264,
        zipCode: 3899,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kéked",
        "infó:": ""
      },
      {
        "Település ID": 1501,
        "KSH településazonosító törzsszám": 16805,
        zipCode: 7661,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kékesd",
        "infó:": ""
      },
      {
        "Település ID": 1502,
        "KSH településazonosító törzsszám": 26037,
        zipCode: 8254,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kékkút",
        "infó:": ""
      },
      {
        "Település ID": 1503,
        "KSH településazonosító törzsszám": 27571,
        zipCode: 6423,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kelebia",
        "infó:": ""
      },
      {
        "Település ID": 1504,
        "KSH településazonosító törzsszám": 32036,
        zipCode: 9549,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Keléd",
        "infó:": ""
      },
      {
        "Település ID": 1505,
        "KSH településazonosító törzsszám": 12034,
        zipCode: 3728,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kelemér",
        "infó:": ""
      },
      {
        "Település ID": 1506,
        "KSH településazonosító törzsszám": 18166,
        zipCode: 6444,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kéleshalom",
        "infó:": ""
      },
      {
        "Település ID": 1507,
        "KSH településazonosító törzsszám": 17446,
        zipCode: 8714,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kelevíz",
        "infó:": ""
      },
      {
        "Település ID": 1508,
        "KSH településazonosító törzsszám": 19992,
        zipCode: 4501,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kemecse",
        "infó:": ""
      },
      {
        "Település ID": 1509,
        "KSH településazonosító törzsszám": 22345,
        zipCode: 2638,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kemence",
        "infó:": ""
      },
      {
        "Település ID": 1510,
        "KSH településazonosító törzsszám": 25353,
        zipCode: 8931,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kemendollár",
        "infó:": ""
      },
      {
        "Település ID": 1511,
        "KSH településazonosító törzsszám": 19734,
        zipCode: 8516,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kemenesh%u0151gyész",
        "infó:": ""
      },
      {
        "Település ID": 1512,
        "KSH településazonosító törzsszám": 16911,
        zipCode: 9553,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kemeneskápolna",
        "infó:": ""
      },
      {
        "Település ID": 1513,
        "KSH településazonosító törzsszám": 12247,
        zipCode: 9522,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kemenesmagasi",
        "infó:": ""
      },
      {
        "Település ID": 1514,
        "KSH településazonosító törzsszám": 13426,
        zipCode: 9511,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kemenesmihályfa",
        "infó:": ""
      },
      {
        "Település ID": 1515,
        "KSH településazonosító törzsszám": 20996,
        zipCode: 9544,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kemenespálfa",
        "infó:": ""
      },
      {
        "Település ID": 1518,
        "KSH településazonosító törzsszám": 24484,
        zipCode: 9517,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kemenessömjén",
        "infó:": ""
      },
      {
        "Település ID": 1516,
        "KSH településazonosító törzsszám": 20084,
        zipCode: 9521,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kemenesszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 1517,
        "KSH településazonosító törzsszám": 12478,
        zipCode: 8518,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kemenesszentpéter",
        "infó:": ""
      },
      {
        "Település ID": 1519,
        "KSH településazonosító törzsszám": 6071,
        zipCode: 8995,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Keménfa",
        "infó:": ""
      },
      {
        "Település ID": 1520,
        "KSH településazonosító törzsszám": 10542,
        zipCode: 7843,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kémes",
        "infó:": ""
      },
      {
        "Település ID": 1521,
        "KSH településazonosító törzsszám": 22716,
        zipCode: 9923,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kemestaródfa",
        "infó:": ""
      },
      {
        "Település ID": 1522,
        "KSH településazonosító törzsszám": 6789,
        zipCode: 7839,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kemse",
        "infó:": ""
      },
      {
        "Település ID": 1523,
        "KSH településazonosító törzsszám": 17145,
        zipCode: 5331,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kenderes",
        "infó:": ""
      },
      {
        "Település ID": 1524,
        "KSH településazonosító törzsszám": 17145,
        zipCode: 5349,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kenderes",
        "infó:": ""
      },
      {
        "Település ID": 1525,
        "KSH településazonosító törzsszám": 2574,
        zipCode: 9752,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kenéz",
        "infó:": ""
      },
      {
        "Település ID": 1526,
        "KSH településazonosító törzsszám": 5458,
        zipCode: 3955,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kenézl%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1527,
        "KSH településazonosító törzsszám": 7418,
        zipCode: 5083,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kengyel",
        "infó:": ""
      },
      {
        "Település ID": 1528,
        "KSH településazonosító törzsszám": 9937,
        zipCode: 9514,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kenyeri",
        "infó:": ""
      },
      {
        "Település ID": 1529,
        "KSH településazonosító törzsszám": 26596,
        zipCode: 9945,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kercaszomor",
        "infó:": ""
      },
      {
        "Település ID": 1530,
        "KSH településazonosító törzsszám": 14386,
        zipCode: 7256,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kercseliget",
        "infó:": ""
      },
      {
        "Település ID": 1531,
        "KSH településazonosító törzsszám": 28079,
        zipCode: 3396,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Kerecsend",
        "infó:": ""
      },
      {
        "Település ID": 1532,
        "KSH településazonosító törzsszám": 31529,
        zipCode: 8745,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kerecseny",
        "infó:": ""
      },
      {
        "Település ID": 1533,
        "KSH településazonosító törzsszám": 22530,
        zipCode: 6041,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kerekegyháza",
        "infó:": ""
      },
      {
        "Település ID": 1534,
        "KSH településazonosító törzsszám": 4598,
        zipCode: 8618,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kereki",
        "infó:": ""
      },
      {
        "Település ID": 1535,
        "KSH településazonosító törzsszám": 10995,
        zipCode: 2882,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Kerékteleki",
        "infó:": ""
      },
      {
        "Település ID": 1536,
        "KSH településazonosító törzsszám": 34166,
        zipCode: 2144,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kerepes",
        "infó:": ""
      },
      {
        "Település ID": 1537,
        "KSH településazonosító törzsszám": 34166,
        zipCode: 2145,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kerepes",
        "infó:": ""
      },
      {
        "Település ID": 1538,
        "KSH településazonosító törzsszám": 17066,
        zipCode: 3821,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Keresztéte",
        "infó:": ""
      },
      {
        "Település ID": 1539,
        "KSH településazonosító törzsszám": 29489,
        zipCode: 8971,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kerkabarabás",
        "infó:": ""
      },
      {
        "Település ID": 1540,
        "KSH településazonosító törzsszám": 15112,
        zipCode: 8973,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kerkafalva",
        "infó:": ""
      },
      {
        "Település ID": 1541,
        "KSH településazonosító törzsszám": 22080,
        zipCode: 8973,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kerkakutas",
        "infó:": ""
      },
      {
        "Település ID": 1542,
        "KSH településazonosító törzsszám": 19761,
        zipCode: 9944,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kerkáskápolna",
        "infó:": ""
      },
      {
        "Település ID": 1543,
        "KSH településazonosító törzsszám": 9575,
        zipCode: 8874,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kerkaszentkirály",
        "infó:": ""
      },
      {
        "Település ID": 1544,
        "KSH településazonosító törzsszám": 32647,
        zipCode: 8879,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kerkateskánd",
        "infó:": ""
      },
      {
        "Település ID": 1545,
        "KSH településazonosító törzsszám": 32869,
        zipCode: 4912,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kérsemjén",
        "infó:": ""
      },
      {
        "Település ID": 1546,
        "KSH településazonosító törzsszám": 25654,
        zipCode: 8492,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kerta",
        "infó:": ""
      },
      {
        "Település ID": 1547,
        "KSH településazonosító törzsszám": 12618,
        zipCode: 5526,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kertészsziget",
        "infó:": ""
      },
      {
        "Település ID": 1548,
        "KSH településazonosító törzsszám": 31413,
        zipCode: 2616,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Keszeg",
        "infó:": ""
      },
      {
        "Település ID": 1549,
        "KSH településazonosító törzsszám": 29249,
        zipCode: 3579,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kesznyéten",
        "infó:": ""
      },
      {
        "Település ID": 1550,
        "KSH településazonosító törzsszám": 17640,
        zipCode: 7062,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kesz%u0151hidegkút",
        "infó:": ""
      },
      {
        "Település ID": 1551,
        "KSH településazonosító törzsszám": 18421,
        zipCode: 8360,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Keszthely",
        "infó:": ""
      },
      {
        "Település ID": 1552,
        "KSH településazonosító törzsszám": 29577,
        zipCode: 2517,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Kesztölc",
        "infó:": ""
      },
      {
        "Település ID": 1553,
        "KSH településazonosító törzsszám": 3832,
        zipCode: 7668,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Keszü",
        "infó:": ""
      },
      {
        "Település ID": 1554,
        "KSH településazonosító törzsszám": 11846,
        zipCode: 2655,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kétbodony",
        "infó:": ""
      },
      {
        "Település ID": 1555,
        "KSH településazonosító törzsszám": 3461,
        zipCode: 5741,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kétegyháza",
        "infó:": ""
      },
      {
        "Település ID": 1556,
        "KSH településazonosító törzsszám": 11174,
        zipCode: 8713,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kéthely",
        "infó:": ""
      },
      {
        "Település ID": 1557,
        "KSH településazonosító törzsszám": 19813,
        zipCode: 5411,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kétpó",
        "infó:": ""
      },
      {
        "Település ID": 1558,
        "KSH településazonosító törzsszám": 3106,
        zipCode: 5674,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kétsoprony",
        "infó:": ""
      },
      {
        "Település ID": 1559,
        "KSH településazonosító törzsszám": 8572,
        zipCode: 7975,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kétújfalu",
        "infó:": ""
      },
      {
        "Település ID": 1560,
        "KSH településazonosító törzsszám": 19345,
        zipCode: 9982,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kétvölgy",
        "infó:": ""
      },
      {
        "Település ID": 1561,
        "KSH településazonosító törzsszám": 21731,
        zipCode: 7174,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kéty",
        "infó:": ""
      },
      {
        "Település ID": 1562,
        "KSH településazonosító törzsszám": 31574,
        zipCode: 5744,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kevermes",
        "infó:": ""
      },
      {
        "Település ID": 1563,
        "KSH településazonosító törzsszám": 32267,
        zipCode: 8774,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kilimán",
        "infó:": ""
      },
      {
        "Település ID": 1564,
        "KSH településazonosító törzsszám": 14748,
        zipCode: 9181,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kimle",
        "infó:": ""
      },
      {
        "Település ID": 1565,
        "KSH településazonosító törzsszám": 2343,
        zipCode: 8044,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Kincsesbánya",
        "infó:": ""
      },
      {
        "Település ID": 1566,
        "KSH településazonosító törzsszám": 32090,
        zipCode: 3657,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Királd",
        "infó:": ""
      },
      {
        "Település ID": 1567,
        "KSH településazonosító törzsszám": 20552,
        zipCode: 7953,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Királyegyháza",
        "infó:": ""
      },
      {
        "Település ID": 1568,
        "KSH településazonosító törzsszám": 13666,
        zipCode: 6911,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Királyhegyes",
        "infó:": ""
      },
      {
        "Település ID": 1569,
        "KSH településazonosító törzsszám": 5421,
        zipCode: 8195,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Királyszentistván",
        "infó:": ""
      },
      {
        "Település ID": 1570,
        "KSH településazonosító törzsszám": 7931,
        zipCode: 8284,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kisapáti",
        "infó:": ""
      },
      {
        "Település ID": 1571,
        "KSH településazonosító törzsszám": 12636,
        zipCode: 2428,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Kisapostag",
        "infó:": ""
      },
      {
        "Település ID": 1572,
        "KSH településazonosító törzsszám": 19424,
        zipCode: 4921,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kisar",
        "infó:": ""
      },
      {
        "Település ID": 1573,
        "KSH településazonosító törzsszám": 19053,
        zipCode: 7523,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kisasszond",
        "infó:": ""
      },
      {
        "Település ID": 1574,
        "KSH településazonosító törzsszám": 18908,
        zipCode: 7954,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisasszonyfa",
        "infó:": ""
      },
      {
        "Település ID": 1575,
        "KSH településazonosító törzsszám": 2413,
        zipCode: 9133,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kisbabot",
        "infó:": ""
      },
      {
        "Település ID": 1576,
        "KSH településazonosító törzsszám": 27243,
        zipCode: 3046,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kisbágyon",
        "infó:": ""
      },
      {
        "Település ID": 1577,
        "KSH településazonosító törzsszám": 22886,
        zipCode: 9062,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kisbajcs",
        "infó:": ""
      },
      {
        "Település ID": 1578,
        "KSH településazonosító törzsszám": 24387,
        zipCode: 7542,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kisbajom",
        "infó:": ""
      },
      {
        "Település ID": 1579,
        "KSH településazonosító törzsszám": 24493,
        zipCode: 7282,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kisbárapáti",
        "infó:": ""
      },
      {
        "Település ID": 1580,
        "KSH településazonosító törzsszám": 26295,
        zipCode: 3075,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kisbárkány",
        "infó:": ""
      },
      {
        "Település ID": 1581,
        "KSH településazonosító törzsszám": 17330,
        zipCode: 2870,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Kisbér",
        "infó:": ""
      },
      {
        "Település ID": 1582,
        "KSH településazonosító törzsszám": 17330,
        zipCode: 2879,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Kisbér",
        "infó:": ""
      },
      {
        "Település ID": 1583,
        "KSH településazonosító törzsszám": 30827,
        zipCode: 8693,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kisberény",
        "infó:": ""
      },
      {
        "Település ID": 1584,
        "KSH településazonosító törzsszám": 29072,
        zipCode: 8477,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kisberzseny",
        "infó:": ""
      },
      {
        "Település ID": 1585,
        "KSH településazonosító törzsszám": 5722,
        zipCode: 7391,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisbeszterce",
        "infó:": ""
      },
      {
        "Település ID": 1586,
        "KSH településazonosító törzsszám": 7816,
        zipCode: 9234,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kisbodak",
        "infó:": ""
      },
      {
        "Település ID": 1587,
        "KSH településazonosító törzsszám": 21379,
        zipCode: 8925,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kisbucsa",
        "infó:": ""
      },
      {
        "Település ID": 1588,
        "KSH településazonosító törzsszám": 22868,
        zipCode: 7756,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisbudmér",
        "infó:": ""
      },
      {
        "Település ID": 1589,
        "KSH településazonosító törzsszám": 17349,
        zipCode: 3578,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kiscsécs",
        "infó:": ""
      },
      {
        "Település ID": 1590,
        "KSH településazonosító törzsszám": 16249,
        zipCode: 8888,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kiscsehi",
        "infó:": ""
      },
      {
        "Település ID": 1591,
        "KSH településazonosító törzsszám": 23700,
        zipCode: 8494,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kiscs%u0151sz",
        "infó:": ""
      },
      {
        "Település ID": 1592,
        "KSH településazonosító törzsszám": 11183,
        zipCode: 7814,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisdér",
        "infó:": ""
      },
      {
        "Település ID": 1593,
        "KSH településazonosító törzsszám": 33905,
        zipCode: 7985,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisdobsza",
        "infó:": ""
      },
      {
        "Település ID": 1594,
        "KSH településazonosító törzsszám": 18838,
        zipCode: 5837,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kisdombegyház",
        "infó:": ""
      },
      {
        "Település ID": 1595,
        "KSH településazonosító törzsszám": 17710,
        zipCode: 7159,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kisdorog",
        "infó:": ""
      },
      {
        "Település ID": 1596,
        "KSH településazonosító törzsszám": 33206,
        zipCode: 2655,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kisecset",
        "infó:": ""
      },
      {
        "Település ID": 1597,
        "KSH településazonosító törzsszám": 33695,
        zipCode: 9341,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kisfalud",
        "infó:": ""
      },
      {
        "Település ID": 1598,
        "KSH településazonosító törzsszám": 22460,
        zipCode: 3256,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Kisfüzes",
        "infó:": ""
      },
      {
        "Település ID": 1601,
        "KSH településazonosító törzsszám": 9812,
        zipCode: 8356,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kisgörb%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1599,
        "KSH településazonosító törzsszám": 4826,
        zipCode: 7279,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kisgyalán",
        "infó:": ""
      },
      {
        "Település ID": 1600,
        "KSH településazonosító törzsszám": 22840,
        zipCode: 3556,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kisgy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 1602,
        "KSH településazonosító törzsszám": 6831,
        zipCode: 7391,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kishajmás",
        "infó:": ""
      },
      {
        "Település ID": 1603,
        "KSH településazonosító törzsszám": 27021,
        zipCode: 7800,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisharsány",
        "infó:": ""
      },
      {
        "Település ID": 1604,
        "KSH településazonosító törzsszám": 33400,
        zipCode: 3161,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kishartyán",
        "infó:": ""
      },
      {
        "Település ID": 1605,
        "KSH településazonosító törzsszám": 16975,
        zipCode: 7763,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisherend",
        "infó:": ""
      },
      {
        "Település ID": 1606,
        "KSH településazonosító törzsszám": 8509,
        zipCode: 4977,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kishódos",
        "infó:": ""
      },
      {
        "Település ID": 1607,
        "KSH településazonosító törzsszám": 28875,
        zipCode: 3994,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kishuta",
        "infó:": ""
      },
      {
        "Település ID": 1608,
        "KSH településazonosító törzsszám": 20923,
        zipCode: 2948,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Kisigmánd",
        "infó:": ""
      },
      {
        "Település ID": 1609,
        "KSH településazonosító törzsszám": 12849,
        zipCode: 7773,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisjakabfalva",
        "infó:": ""
      },
      {
        "Település ID": 1610,
        "KSH településazonosító törzsszám": 19910,
        zipCode: 7766,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kiskassa",
        "infó:": ""
      },
      {
        "Település ID": 1611,
        "KSH településazonosító törzsszám": 3762,
        zipCode: 3843,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kiskinizs",
        "infó:": ""
      },
      {
        "Település ID": 1619,
        "KSH településazonosító törzsszám": 18281,
        zipCode: 3384,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Kisköre",
        "infó:": ""
      },
      {
        "Település ID": 1613,
        "KSH településazonosító törzsszám": 9344,
        zipCode: 6200,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kisk%u0151rös",
        "infó:": ""
      },
      {
        "Település ID": 1612,
        "KSH településazonosító törzsszám": 13781,
        zipCode: 7524,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kiskorpád",
        "infó:": ""
      },
      {
        "Település ID": 1614,
        "KSH településazonosító törzsszám": 20297,
        zipCode: 6100,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kiskunfélegyháza",
        "infó:": ""
      },
      {
        "Település ID": 1615,
        "KSH településazonosító törzsszám": 32434,
        zipCode: 6400,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kiskunhalas",
        "infó:": ""
      },
      {
        "Település ID": 1616,
        "KSH településazonosító törzsszám": 10816,
        zipCode: 2340,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kiskunlacháza",
        "infó:": ""
      },
      {
        "Település ID": 1617,
        "KSH településazonosító törzsszám": 24396,
        zipCode: 6120,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kiskunmajsa",
        "infó:": ""
      },
      {
        "Település ID": 1618,
        "KSH településazonosító törzsszám": 20312,
        zipCode: 8911,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kiskutas",
        "infó:": ""
      },
      {
        "Település ID": 1620,
        "KSH településazonosító törzsszám": 28990,
        zipCode: 8156,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Kisláng",
        "infó:": ""
      },
      {
        "Település ID": 1621,
        "KSH településazonosító törzsszám": 28477,
        zipCode: 4325,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kisléta",
        "infó:": ""
      },
      {
        "Település ID": 1622,
        "KSH településazonosító törzsszám": 21616,
        zipCode: 7775,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kislippó",
        "infó:": ""
      },
      {
        "Település ID": 1623,
        "KSH településazonosító törzsszám": 30173,
        zipCode: 8446,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kisl%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 1624,
        "KSH településazonosító törzsszám": 6512,
        zipCode: 7356,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kismányok",
        "infó:": ""
      },
      {
        "Település ID": 1625,
        "KSH településazonosító törzsszám": 15477,
        zipCode: 4126,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Kismarja",
        "infó:": ""
      },
      {
        "Település ID": 1626,
        "KSH településazonosító törzsszám": 33738,
        zipCode: 2623,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kismaros",
        "infó:": ""
      },
      {
        "Település ID": 1627,
        "KSH településazonosító törzsszám": 16036,
        zipCode: 4737,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kisnamény",
        "infó:": ""
      },
      {
        "Település ID": 1628,
        "KSH településazonosító törzsszám": 12502,
        zipCode: 3264,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Kisnána",
        "infó:": ""
      },
      {
        "Település ID": 1629,
        "KSH településazonosító törzsszám": 5227,
        zipCode: 2165,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kisnémedi",
        "infó:": ""
      },
      {
        "Település ID": 1630,
        "KSH településazonosító törzsszám": 33215,
        zipCode: 7759,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisnyárád",
        "infó:": ""
      },
      {
        "Település ID": 1631,
        "KSH településazonosító törzsszám": 29850,
        zipCode: 2024,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kisoroszi",
        "infó:": ""
      },
      {
        "Település ID": 1632,
        "KSH településazonosító törzsszám": 29300,
        zipCode: 4956,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kispalád",
        "infó:": ""
      },
      {
        "Település ID": 1633,
        "KSH településazonosító törzsszám": 16081,
        zipCode: 8912,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kispáli",
        "infó:": ""
      },
      {
        "Település ID": 1634,
        "KSH településazonosító törzsszám": 4288,
        zipCode: 8496,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kispirit",
        "infó:": ""
      },
      {
        "Település ID": 1635,
        "KSH településazonosító törzsszám": 11147,
        zipCode: 9936,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kisrákos",
        "infó:": ""
      },
      {
        "Település ID": 1636,
        "KSH településazonosító törzsszám": 32726,
        zipCode: 8756,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kisrécse",
        "infó:": ""
      },
      {
        "Település ID": 1637,
        "KSH településazonosító törzsszám": 11448,
        zipCode: 3965,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kisrozvágy",
        "infó:": ""
      },
      {
        "Település ID": 1638,
        "KSH településazonosító törzsszám": 14702,
        zipCode: 3627,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kissikátor",
        "infó:": ""
      },
      {
        "Település ID": 1639,
        "KSH településazonosító törzsszám": 5953,
        zipCode: 9555,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kissomlyó",
        "infó:": ""
      },
      {
        "Település ID": 1640,
        "KSH településazonosító törzsszám": 28158,
        zipCode: 6421,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kisszállás",
        "infó:": ""
      },
      {
        "Település ID": 1641,
        "KSH településazonosító törzsszám": 27766,
        zipCode: 7082,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kisszékely",
        "infó:": ""
      },
      {
        "Település ID": 1642,
        "KSH településazonosító törzsszám": 9751,
        zipCode: 4963,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kisszekeres",
        "infó:": ""
      },
      {
        "Település ID": 1643,
        "KSH településazonosító törzsszám": 8651,
        zipCode: 7841,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 1644,
        "KSH településazonosító törzsszám": 13055,
        zipCode: 8957,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kissziget",
        "infó:": ""
      },
      {
        "Település ID": 1645,
        "KSH településazonosító törzsszám": 23001,
        zipCode: 8483,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kissz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 1646,
        "KSH településazonosító törzsszám": 12353,
        zipCode: 7981,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kistamási",
        "infó:": ""
      },
      {
        "Település ID": 1647,
        "KSH településazonosító törzsszám": 3151,
        zipCode: 7823,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kistapolca",
        "infó:": ""
      },
      {
        "Település ID": 1648,
        "KSH településazonosító törzsszám": 34157,
        zipCode: 2143,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kistarcsa",
        "infó:": ""
      },
      {
        "Település ID": 1649,
        "KSH településazonosító törzsszám": 31024,
        zipCode: 6760,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Kistelek",
        "infó:": ""
      },
      {
        "Település ID": 1650,
        "KSH településazonosító törzsszám": 12399,
        zipCode: 3553,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kistokaj",
        "infó:": ""
      },
      {
        "Település ID": 1651,
        "KSH településazonosító törzsszám": 20941,
        zipCode: 8868,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kistolmács",
        "infó:": ""
      },
      {
        "Település ID": 1652,
        "KSH településazonosító törzsszám": 3869,
        zipCode: 7068,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kistormás",
        "infó:": ""
      },
      {
        "Település ID": 1653,
        "KSH településazonosító törzsszám": 10746,
        zipCode: 7768,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kistótfalu",
        "infó:": ""
      },
      {
        "Település ID": 1654,
        "KSH településazonosító törzsszám": 25919,
        zipCode: 5310,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kisújszállás",
        "infó:": ""
      },
      {
        "Település ID": 1655,
        "KSH településazonosító törzsszám": 2501,
        zipCode: 9772,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kisunyom",
        "infó:": ""
      },
      {
        "Település ID": 1656,
        "KSH településazonosító törzsszám": 9265,
        zipCode: 4600,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kisvárda",
        "infó:": ""
      },
      {
        "Település ID": 1657,
        "KSH településazonosító törzsszám": 12672,
        zipCode: 4811,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kisvarsány",
        "infó:": ""
      },
      {
        "Település ID": 1658,
        "KSH településazonosító törzsszám": 16364,
        zipCode: 8341,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kisvásárhely",
        "infó:": ""
      },
      {
        "Település ID": 1659,
        "KSH településazonosító törzsszám": 9548,
        zipCode: 7381,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kisvaszar",
        "infó:": ""
      },
      {
        "Település ID": 1660,
        "KSH településazonosító törzsszám": 31185,
        zipCode: 7183,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kisvejke",
        "infó:": ""
      },
      {
        "Település ID": 1661,
        "KSH településazonosító törzsszám": 26666,
        zipCode: 6775,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Kiszombor",
        "infó:": ""
      },
      {
        "Település ID": 1662,
        "KSH településazonosító törzsszám": 15486,
        zipCode: 9733,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kiszsidány",
        "infó:": ""
      },
      {
        "Település ID": 1663,
        "KSH településazonosító törzsszám": 8253,
        zipCode: 6773,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Klárafalva",
        "infó:": ""
      },
      {
        "Település ID": 1741,
        "KSH településazonosító törzsszám": 25247,
        zipCode: 7334,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Köblény",
        "infó:": ""
      },
      {
        "Település ID": 1664,
        "KSH településazonosító törzsszám": 2510,
        zipCode: 2898,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Kocs",
        "infó:": ""
      },
      {
        "Település ID": 1665,
        "KSH településazonosító törzsszám": 32771,
        zipCode: 2755,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kocsér",
        "infó:": ""
      },
      {
        "Település ID": 1742,
        "KSH településazonosító törzsszám": 4190,
        zipCode: 9553,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Köcsk",
        "infó:": ""
      },
      {
        "Település ID": 1666,
        "KSH településazonosító törzsszám": 22433,
        zipCode: 7212,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kocsola",
        "infó:": ""
      },
      {
        "Település ID": 1667,
        "KSH településazonosító törzsszám": 7445,
        zipCode: 4751,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kocsord",
        "infó:": ""
      },
      {
        "Település ID": 1668,
        "KSH településazonosító törzsszám": 31361,
        zipCode: 2243,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kóka",
        "infó:": ""
      },
      {
        "Település ID": 1669,
        "KSH településazonosító törzsszám": 17455,
        zipCode: 4284,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Kokad",
        "infó:": ""
      },
      {
        "Település ID": 1743,
        "KSH településazonosító törzsszám": 3540,
        zipCode: 7668,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kökény",
        "infó:": ""
      },
      {
        "Település ID": 1670,
        "KSH településazonosító törzsszám": 13745,
        zipCode: 7530,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "K%u0151kút",
        "infó:": ""
      },
      {
        "Település ID": 1744,
        "KSH településazonosító törzsszám": 16665,
        zipCode: 4965,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kölcse",
        "infó:": ""
      },
      {
        "Település ID": 1745,
        "KSH településazonosító törzsszám": 10463,
        zipCode: 7052,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kölesd",
        "infó:": ""
      },
      {
        "Település ID": 1746,
        "KSH településazonosító törzsszám": 17899,
        zipCode: 7717,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kölked",
        "infó:": ""
      },
      {
        "Település ID": 1671,
        "KSH településazonosító törzsszám": 30182,
        zipCode: 8468,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kolontár",
        "infó:": ""
      },
      {
        "Település ID": 1672,
        "KSH településazonosító törzsszám": 2167,
        zipCode: 4138,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Komádi",
        "infó:": ""
      },
      {
        "Település ID": 1673,
        "KSH településazonosító törzsszám": 5449,
        zipCode: 2900,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Komárom",
        "infó:": ""
      },
      {
        "Település ID": 1674,
        "KSH településazonosító törzsszám": 5449,
        zipCode: 2903,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Komárom",
        "infó:": ""
      },
      {
        "Település ID": 1675,
        "KSH településazonosító törzsszám": 5449,
        zipCode: 2921,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Komárom",
        "infó:": ""
      },
      {
        "Település ID": 1676,
        "KSH településazonosító törzsszám": 10612,
        zipCode: 3765,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Komjáti",
        "infó:": ""
      },
      {
        "Település ID": 1677,
        "KSH településazonosító törzsszám": 26408,
        zipCode: 7300,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Komló",
        "infó:": ""
      },
      {
        "Település ID": 1678,
        "KSH településazonosító törzsszám": 26408,
        zipCode: 7305,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Komló",
        "infó:": ""
      },
      {
        "Település ID": 1747,
        "KSH településazonosító törzsszám": 14535,
        zipCode: 3372,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Köml%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1748,
        "KSH településazonosító törzsszám": 7630,
        zipCode: 2853,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Köml%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 1679,
        "KSH településazonosító törzsszám": 22336,
        zipCode: 4765,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Komlódtótfalu",
        "infó:": ""
      },
      {
        "Település ID": 1680,
        "KSH településazonosító törzsszám": 9858,
        zipCode: 7582,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Komlósd",
        "infó:": ""
      },
      {
        "Település ID": 1681,
        "KSH településazonosító törzsszám": 16559,
        zipCode: 3937,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Komlóska",
        "infó:": ""
      },
      {
        "Település ID": 1682,
        "KSH településazonosító törzsszám": 27146,
        zipCode: 4622,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Komoró",
        "infó:": ""
      },
      {
        "Település ID": 1750,
        "KSH településazonosító törzsszám": 23612,
        zipCode: 4943,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kömör%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1749,
        "KSH településazonosító törzsszám": 13806,
        zipCode: 6134,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kömpöc",
        "infó:": ""
      },
      {
        "Település ID": 1683,
        "KSH településazonosító törzsszám": 23995,
        zipCode: 3356,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Kompolt",
        "infó:": ""
      },
      {
        "Település ID": 1684,
        "KSH településazonosító törzsszám": 32498,
        zipCode: 3775,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kondó",
        "infó:": ""
      },
      {
        "Település ID": 1685,
        "KSH településazonosító törzsszám": 13028,
        zipCode: 9943,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Kondorfa",
        "infó:": ""
      },
      {
        "Település ID": 1686,
        "KSH településazonosító törzsszám": 10287,
        zipCode: 5553,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kondoros",
        "infó:": ""
      },
      {
        "Település ID": 1687,
        "KSH településazonosító törzsszám": 11262,
        zipCode: 9144,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kóny",
        "infó:": ""
      },
      {
        "Település ID": 1688,
        "KSH településazonosító törzsszám": 25964,
        zipCode: 4133,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Konyár",
        "infó:": ""
      },
      {
        "Település ID": 1689,
        "KSH településazonosító törzsszám": 6895,
        zipCode: 9495,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kópháza",
        "infó:": ""
      },
      {
        "Település ID": 1690,
        "KSH településazonosító törzsszám": 21184,
        zipCode: 7094,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Koppányszántó",
        "infó:": ""
      },
      {
        "Település ID": 1691,
        "KSH településazonosító törzsszám": 22956,
        zipCode: 3886,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Korlát",
        "infó:": ""
      },
      {
        "Település ID": 1751,
        "KSH településazonosító törzsszám": 13532,
        zipCode: 9900,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Körmend",
        "infó:": ""
      },
      {
        "Település ID": 1752,
        "KSH településazonosító törzsszám": 13532,
        zipCode: 9909,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Körmend",
        "infó:": ""
      },
      {
        "Település ID": 1753,
        "KSH településazonosító törzsszám": 30553,
        zipCode: 2851,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Környe",
        "infó:": ""
      },
      {
        "Település ID": 1754,
        "KSH településazonosító törzsszám": 26602,
        zipCode: 3577,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Köröm",
        "infó:": ""
      },
      {
        "Település ID": 1692,
        "KSH településazonosító törzsszám": 24633,
        zipCode: 9113,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Koroncó",
        "infó:": ""
      },
      {
        "Település ID": 1693,
        "KSH településazonosító törzsszám": 8110,
        zipCode: 7841,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kórós",
        "infó:": ""
      },
      {
        "Település ID": 1694,
        "KSH településazonosító törzsszám": 15510,
        zipCode: 8617,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "K%u0151röshegy",
        "infó:": ""
      },
      {
        "Település ID": 1755,
        "KSH településazonosító törzsszám": 11615,
        zipCode: 5516,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Körösladány",
        "infó:": ""
      },
      {
        "Település ID": 1756,
        "KSH településazonosító törzsszám": 10764,
        zipCode: 5539,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Körösnagyharsány",
        "infó:": ""
      },
      {
        "Település ID": 1757,
        "KSH településazonosító törzsszám": 31130,
        zipCode: 4136,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Körösszakál",
        "infó:": ""
      },
      {
        "Település ID": 1758,
        "KSH településazonosító törzsszám": 8943,
        zipCode: 4135,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Körösszegapáti",
        "infó:": ""
      },
      {
        "Település ID": 1759,
        "KSH településazonosító törzsszám": 12900,
        zipCode: 5622,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Köröstarcsa",
        "infó:": ""
      },
      {
        "Település ID": 1695,
        "KSH településazonosító törzsszám": 32975,
        zipCode: 2745,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "K%u0151röstetétlen",
        "infó:": ""
      },
      {
        "Település ID": 1760,
        "KSH településazonosító törzsszám": 30164,
        zipCode: 5536,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Körösújfalu",
        "infó:": ""
      },
      {
        "Település ID": 1696,
        "KSH településazonosító törzsszám": 27687,
        zipCode: 2612,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kosd",
        "infó:": ""
      },
      {
        "Település ID": 1697,
        "KSH településazonosító törzsszám": 24679,
        zipCode: 2625,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Kóspallag",
        "infó:": ""
      },
      {
        "Település ID": 1698,
        "KSH településazonosító törzsszám": 30650,
        zipCode: 8152,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "K%u0151szárhegy",
        "infó:": ""
      },
      {
        "Település ID": 1699,
        "KSH településazonosító törzsszám": 16832,
        zipCode: 9730,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "K%u0151szeg",
        "infó:": ""
      },
      {
        "Település ID": 1700,
        "KSH településazonosító törzsszám": 5740,
        zipCode: 9725,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "K%u0151szegdoroszló",
        "infó:": ""
      },
      {
        "Település ID": 1701,
        "KSH településazonosító törzsszám": 26046,
        zipCode: 9739,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "K%u0151szegpaty",
        "infó:": ""
      },
      {
        "Település ID": 1702,
        "KSH településazonosító törzsszám": 20109,
        zipCode: 9725,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "K%u0151szegszerdahely",
        "infó:": ""
      },
      {
        "Település ID": 1703,
        "KSH településazonosító törzsszám": 23728,
        zipCode: 4482,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Kótaj",
        "infó:": ""
      },
      {
        "Település ID": 1761,
        "KSH településazonosító törzsszám": 18148,
        zipCode: 8627,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kötcse",
        "infó:": ""
      },
      {
        "Település ID": 1762,
        "KSH településazonosító törzsszám": 6804,
        zipCode: 5725,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kötegyán",
        "infó:": ""
      },
      {
        "Település ID": 1704,
        "KSH településazonosító törzsszám": 11235,
        zipCode: 5062,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "K%u0151telek",
        "infó:": ""
      },
      {
        "Település ID": 1705,
        "KSH településazonosító törzsszám": 24226,
        zipCode: 7847,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kovácshida",
        "infó:": ""
      },
      {
        "Település ID": 1706,
        "KSH településazonosító törzsszám": 14517,
        zipCode: 7678,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kovácsszénája",
        "infó:": ""
      },
      {
        "Település ID": 1707,
        "KSH településazonosító törzsszám": 28547,
        zipCode: 3992,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kovácsvágás",
        "infó:": ""
      },
      {
        "Település ID": 1710,
        "KSH településazonosító törzsszám": 23454,
        zipCode: 8254,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "K%u0151vágóörs",
        "infó:": ""
      },
      {
        "Település ID": 1708,
        "KSH településazonosító törzsszám": 15538,
        zipCode: 7673,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "K%u0151vágósz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 1709,
        "KSH településazonosító törzsszám": 6992,
        zipCode: 7675,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "K%u0151vágót%u0151tt%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 1763,
        "KSH településazonosító törzsszám": 9955,
        zipCode: 6912,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Kövegy",
        "infó:": ""
      },
      {
        "Település ID": 1764,
        "KSH településazonosító törzsszám": 25858,
        zipCode: 8274,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Köveskál",
        "infó:": ""
      },
      {
        "Település ID": 1711,
        "KSH településazonosító törzsszám": 13842,
        zipCode: 3053,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kozárd",
        "infó:": ""
      },
      {
        "Település ID": 1712,
        "KSH településazonosító törzsszám": 6336,
        zipCode: 7761,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Kozármisleny",
        "infó:": ""
      },
      {
        "Település ID": 1713,
        "KSH településazonosító törzsszám": 13082,
        zipCode: 8988,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kozmadombja",
        "infó:": ""
      },
      {
        "Település ID": 1714,
        "KSH településazonosító törzsszám": 19576,
        zipCode: 3821,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Krasznokvajda",
        "infó:": ""
      },
      {
        "Település ID": 1737,
        "KSH településazonosító törzsszám": 14410,
        zipCode: 6755,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Kübekháza",
        "infó:": ""
      },
      {
        "Település ID": 1715,
        "KSH településazonosító törzsszám": 34209,
        zipCode: 2458,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Kulcs",
        "infó:": ""
      },
      {
        "Település ID": 1738,
        "KSH településazonosító törzsszám": 32221,
        zipCode: 8978,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Küls%u0151sárd",
        "infó:": ""
      },
      {
        "Település ID": 1739,
        "KSH településazonosító törzsszám": 16142,
        zipCode: 9532,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Küls%u0151vat",
        "infó:": ""
      },
      {
        "Település ID": 1716,
        "KSH településazonosító törzsszám": 5856,
        zipCode: 6097,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kunadacs",
        "infó:": ""
      },
      {
        "Település ID": 1717,
        "KSH településazonosító törzsszám": 16045,
        zipCode: 5746,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Kunágota",
        "infó:": ""
      },
      {
        "Település ID": 1718,
        "KSH településazonosító törzsszám": 6044,
        zipCode: 6435,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kunbaja",
        "infó:": ""
      },
      {
        "Település ID": 1719,
        "KSH településazonosító törzsszám": 7728,
        zipCode: 6043,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kunbaracs",
        "infó:": ""
      },
      {
        "Település ID": 1720,
        "KSH településazonosító törzsszám": 5254,
        zipCode: 5412,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kuncsorba",
        "infó:": ""
      },
      {
        "Település ID": 1721,
        "KSH településazonosító törzsszám": 29027,
        zipCode: 6413,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kunfehértó",
        "infó:": ""
      },
      {
        "Település ID": 1740,
        "KSH településazonosító törzsszám": 4066,
        zipCode: 8162,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Küngös",
        "infó:": ""
      },
      {
        "Település ID": 1722,
        "KSH településazonosító törzsszám": 22567,
        zipCode: 5340,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kunhegyes",
        "infó:": ""
      },
      {
        "Település ID": 1723,
        "KSH településazonosító törzsszám": 23171,
        zipCode: 5321,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kunmadaras",
        "infó:": ""
      },
      {
        "Település ID": 1724,
        "KSH településazonosító törzsszám": 31918,
        zipCode: 6096,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kunpeszér",
        "infó:": ""
      },
      {
        "Település ID": 1725,
        "KSH településazonosító törzsszám": 31893,
        zipCode: 6115,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kunszállás",
        "infó:": ""
      },
      {
        "Település ID": 1726,
        "KSH településazonosító törzsszám": 32504,
        zipCode: 5440,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kunszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 1727,
        "KSH településazonosító törzsszám": 32504,
        zipCode: 5449,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Kunszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 1728,
        "KSH településazonosító törzsszám": 28130,
        zipCode: 6090,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Kunszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 1729,
        "KSH településazonosító törzsszám": 31626,
        zipCode: 9184,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Kunsziget",
        "infó:": ""
      },
      {
        "Település ID": 1730,
        "KSH településazonosító törzsszám": 26541,
        zipCode: 8595,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Kup",
        "infó:": ""
      },
      {
        "Település ID": 1731,
        "KSH településazonosító törzsszám": 18722,
        zipCode: 3813,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kupa",
        "infó:": ""
      },
      {
        "Település ID": 1732,
        "KSH településazonosító törzsszám": 20507,
        zipCode: 7226,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Kurd",
        "infó:": ""
      },
      {
        "Település ID": 1733,
        "KSH településazonosító törzsszám": 11819,
        zipCode: 3732,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Kurityán",
        "infó:": ""
      },
      {
        "Település ID": 1734,
        "KSH településazonosító törzsszám": 19479,
        zipCode: 8919,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Kustánszeg",
        "infó:": ""
      },
      {
        "Település ID": 1735,
        "KSH településazonosító törzsszám": 28857,
        zipCode: 7541,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Kutas",
        "infó:": ""
      },
      {
        "Település ID": 1736,
        "KSH településazonosító törzsszám": 19451,
        zipCode: 3066,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Kutasó",
        "infó:": ""
      },
      {
        "Település ID": 1765,
        "KSH településazonosító törzsszám": 15255,
        zipCode: 2541,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Lábatlan",
        "infó:": ""
      },
      {
        "Település ID": 1766,
        "KSH településazonosító törzsszám": 28291,
        zipCode: 7551,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Lábod",
        "infó:": ""
      },
      {
        "Település ID": 1767,
        "KSH településazonosító törzsszám": 20844,
        zipCode: 3967,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Lácacséke",
        "infó:": ""
      },
      {
        "Település ID": 1768,
        "KSH településazonosító törzsszám": 16258,
        zipCode: 7535,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Lad",
        "infó:": ""
      },
      {
        "Település ID": 1769,
        "KSH településazonosító törzsszám": 5786,
        zipCode: 6045,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Ladánybene",
        "infó:": ""
      },
      {
        "Település ID": 1770,
        "KSH településazonosító törzsszám": 26231,
        zipCode: 3780,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ládbeseny%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1771,
        "KSH településazonosító törzsszám": 7506,
        zipCode: 8136,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Lajoskomárom",
        "infó:": ""
      },
      {
        "Település ID": 1772,
        "KSH településazonosító törzsszám": 17677,
        zipCode: 6050,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Lajosmizse",
        "infó:": ""
      },
      {
        "Település ID": 1773,
        "KSH településazonosító törzsszám": 15857,
        zipCode: 3786,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Lak",
        "infó:": ""
      },
      {
        "Település ID": 1774,
        "KSH településazonosító törzsszám": 33792,
        zipCode: 8913,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lakhegy",
        "infó:": ""
      },
      {
        "Település ID": 1775,
        "KSH településazonosító törzsszám": 6202,
        zipCode: 6065,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Lakitelek",
        "infó:": ""
      },
      {
        "Település ID": 1776,
        "KSH településazonosító törzsszám": 11040,
        zipCode: 7918,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Lakócsa",
        "infó:": ""
      },
      {
        "Település ID": 1777,
        "KSH településazonosító törzsszám": 33330,
        zipCode: 7759,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Lánycsók",
        "infó:": ""
      },
      {
        "Település ID": 1778,
        "KSH településazonosító törzsszám": 14605,
        zipCode: 7214,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Lápaf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1779,
        "KSH településazonosító törzsszám": 6479,
        zipCode: 7775,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Lapáncsa",
        "infó:": ""
      },
      {
        "Település ID": 1780,
        "KSH településazonosító törzsszám": 21290,
        zipCode: 4543,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Laskod",
        "infó:": ""
      },
      {
        "Település ID": 1781,
        "KSH településazonosító törzsszám": 3434,
        zipCode: 8887,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lasztonya",
        "infó:": ""
      },
      {
        "Település ID": 1782,
        "KSH településazonosító törzsszám": 14863,
        zipCode: 8681,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Látrány",
        "infó:": ""
      },
      {
        "Település ID": 1783,
        "KSH településazonosító törzsszám": 4376,
        zipCode: 9089,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Lázi",
        "infó:": ""
      },
      {
        "Település ID": 1784,
        "KSH településazonosító törzsszám": 30809,
        zipCode: 2016,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Leányfalu",
        "infó:": ""
      },
      {
        "Település ID": 1785,
        "KSH településazonosító törzsszám": 25487,
        zipCode: 2518,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Leányvár",
        "infó:": ""
      },
      {
        "Település ID": 1786,
        "KSH településazonosító törzsszám": 33668,
        zipCode: 9155,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Lébény",
        "infó:": ""
      },
      {
        "Település ID": 1787,
        "KSH településazonosító törzsszám": 30395,
        zipCode: 2619,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Legénd",
        "infó:": ""
      },
      {
        "Település ID": 1788,
        "KSH településazonosító törzsszám": 11660,
        zipCode: 3904,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Legyesbénye",
        "infó:": ""
      },
      {
        "Település ID": 1789,
        "KSH településazonosító törzsszám": 3531,
        zipCode: 3832,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Léh",
        "infó:": ""
      },
      {
        "Település ID": 1790,
        "KSH településazonosító törzsszám": 7038,
        zipCode: 3648,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Lénárddaróc",
        "infó:": ""
      },
      {
        "Település ID": 1791,
        "KSH településazonosító törzsszám": 27483,
        zipCode: 8978,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lendvadedes",
        "infó:": ""
      },
      {
        "Település ID": 1792,
        "KSH településazonosító törzsszám": 11475,
        zipCode: 8977,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lendvajakabfa",
        "infó:": ""
      },
      {
        "Település ID": 1793,
        "KSH településazonosító törzsszám": 24411,
        zipCode: 7184,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Lengyel",
        "infó:": ""
      },
      {
        "Település ID": 1794,
        "KSH településazonosító törzsszám": 26675,
        zipCode: 8693,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Lengyeltóti",
        "infó:": ""
      },
      {
        "Település ID": 1795,
        "KSH településazonosító törzsszám": 12575,
        zipCode: 8960,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lenti",
        "infó:": ""
      },
      {
        "Település ID": 1796,
        "KSH településazonosító törzsszám": 12575,
        zipCode: 8966,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lenti",
        "infó:": ""
      },
      {
        "Település ID": 1797,
        "KSH településazonosító törzsszám": 7269,
        zipCode: 8132,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Lepsény",
        "infó:": ""
      },
      {
        "Település ID": 1798,
        "KSH településazonosító törzsszám": 17570,
        zipCode: 8318,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Lesencefalu",
        "infó:": ""
      },
      {
        "Település ID": 1799,
        "KSH településazonosító törzsszám": 21962,
        zipCode: 8319,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Lesenceistvánd",
        "infó:": ""
      },
      {
        "Település ID": 1800,
        "KSH településazonosító törzsszám": 17871,
        zipCode: 8318,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Lesencetomaj",
        "infó:": ""
      },
      {
        "Település ID": 1801,
        "KSH településazonosító törzsszám": 5768,
        zipCode: 4281,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Létavértes",
        "infó:": ""
      },
      {
        "Település ID": 1802,
        "KSH településazonosító törzsszám": 5768,
        zipCode: 4283,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Létavértes",
        "infó:": ""
      },
      {
        "Település ID": 1803,
        "KSH településazonosító törzsszám": 12122,
        zipCode: 8868,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Letenye",
        "infó:": ""
      },
      {
        "Település ID": 1804,
        "KSH településazonosító törzsszám": 22682,
        zipCode: 2632,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Letkés",
        "infó:": ""
      },
      {
        "Település ID": 1805,
        "KSH településazonosító törzsszám": 19239,
        zipCode: 9221,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Levél",
        "infó:": ""
      },
      {
        "Település ID": 1806,
        "KSH településazonosító törzsszám": 30979,
        zipCode: 4555,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Levelek",
        "infó:": ""
      },
      {
        "Település ID": 1807,
        "KSH településazonosító törzsszám": 32355,
        zipCode: 8707,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Libickozma",
        "infó:": ""
      },
      {
        "Település ID": 1808,
        "KSH településazonosító törzsszám": 3054,
        zipCode: 8981,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lickóvadamos",
        "infó:": ""
      },
      {
        "Település ID": 1809,
        "KSH településazonosító törzsszám": 17604,
        zipCode: 7331,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Liget",
        "infó:": ""
      },
      {
        "Település ID": 1810,
        "KSH településazonosító törzsszám": 28200,
        zipCode: 8782,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Ligetfalva",
        "infó:": ""
      },
      {
        "Település ID": 1811,
        "KSH településazonosító törzsszám": 16221,
        zipCode: 9233,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Lipót",
        "infó:": ""
      },
      {
        "Település ID": 1812,
        "KSH településazonosító törzsszám": 22974,
        zipCode: 7781,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Lippó",
        "infó:": ""
      },
      {
        "Település ID": 1813,
        "KSH településazonosító törzsszám": 10038,
        zipCode: 7757,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Liptód",
        "infó:": ""
      },
      {
        "Település ID": 1814,
        "KSH településazonosító törzsszám": 10889,
        zipCode: 8888,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lispeszentadorján",
        "infó:": ""
      },
      {
        "Település ID": 1815,
        "KSH településazonosító törzsszám": 25025,
        zipCode: 8831,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Liszó",
        "infó:": ""
      },
      {
        "Település ID": 1816,
        "KSH településazonosító törzsszám": 4552,
        zipCode: 8196,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Litér",
        "infó:": ""
      },
      {
        "Település ID": 1817,
        "KSH településazonosító törzsszám": 28219,
        zipCode: 3866,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Litka",
        "infó:": ""
      },
      {
        "Település ID": 1818,
        "KSH településazonosító törzsszám": 4871,
        zipCode: 3186,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Litke",
        "infó:": ""
      },
      {
        "Település ID": 1819,
        "KSH településazonosító törzsszám": 29081,
        zipCode: 9634,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Lócs",
        "infó:": ""
      },
      {
        "Település ID": 1821,
        "KSH településazonosító törzsszám": 21209,
        zipCode: 5743,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "L%u0151kösháza",
        "infó:": ""
      },
      {
        "Település ID": 1820,
        "KSH településazonosító törzsszám": 18856,
        zipCode: 8425,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Lókút",
        "infó:": ""
      },
      {
        "Település ID": 1822,
        "KSH településazonosító törzsszám": 26091,
        zipCode: 4836,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Lónya",
        "infó:": ""
      },
      {
        "Település ID": 1823,
        "KSH településazonosító törzsszám": 9140,
        zipCode: 2309,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Lórév",
        "infó:": ""
      },
      {
        "Település ID": 1824,
        "KSH településazonosító törzsszám": 30401,
        zipCode: 3021,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "L%u0151rinci",
        "infó:": ""
      },
      {
        "Település ID": 1825,
        "KSH településazonosító törzsszám": 30401,
        zipCode: 3022,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "L%u0151rinci",
        "infó:": ""
      },
      {
        "Település ID": 1826,
        "KSH településazonosító törzsszám": 30401,
        zipCode: 3024,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "L%u0151rinci",
        "infó:": ""
      },
      {
        "Település ID": 1827,
        "KSH településazonosító törzsszám": 31389,
        zipCode: 7761,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Lothárd",
        "infó:": ""
      },
      {
        "Település ID": 1828,
        "KSH településazonosító törzsszám": 5564,
        zipCode: 8228,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Lovas",
        "infó:": ""
      },
      {
        "Település ID": 1829,
        "KSH településazonosító törzsszám": 19114,
        zipCode: 8093,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Lovasberény",
        "infó:": ""
      },
      {
        "Település ID": 1830,
        "KSH településazonosító törzsszám": 24624,
        zipCode: 7720,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Lovászhetény",
        "infó:": ""
      },
      {
        "Település ID": 1831,
        "KSH településazonosító törzsszám": 28167,
        zipCode: 8878,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Lovászi",
        "infó:": ""
      },
      {
        "Település ID": 1832,
        "KSH településazonosító törzsszám": 5087,
        zipCode: 8553,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Lovászpatona",
        "infó:": ""
      },
      {
        "Település ID": 1839,
        "KSH településazonosító törzsszám": 31194,
        zipCode: 9461,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Löv%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1840,
        "KSH településazonosító törzsszám": 7995,
        zipCode: 4633,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Löv%u0151petri",
        "infó:": ""
      },
      {
        "Település ID": 1833,
        "KSH településazonosító törzsszám": 20190,
        zipCode: 3129,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Lucfalva",
        "infó:": ""
      },
      {
        "Település ID": 1834,
        "KSH településazonosító törzsszám": 2778,
        zipCode: 3188,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Ludányhalászi",
        "infó:": ""
      },
      {
        "Település ID": 1835,
        "KSH településazonosító törzsszám": 15796,
        zipCode: 3274,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Ludas",
        "infó:": ""
      },
      {
        "Település ID": 1836,
        "KSH településazonosító törzsszám": 14021,
        zipCode: 9724,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Lukácsháza",
        "infó:": ""
      },
      {
        "Település ID": 1837,
        "KSH településazonosító törzsszám": 10010,
        zipCode: 8660,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Lulla",
        "infó:": ""
      },
      {
        "Település ID": 1838,
        "KSH településazonosító törzsszám": 18865,
        zipCode: 7838,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Lúzsok",
        "infó:": ""
      },
      {
        "Település ID": 1841,
        "KSH településazonosító törzsszám": 3902,
        zipCode: 3909,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mád",
        "infó:": ""
      },
      {
        "Település ID": 1842,
        "KSH településazonosító törzsszám": 23357,
        zipCode: 6456,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Madaras",
        "infó:": ""
      },
      {
        "Település ID": 1843,
        "KSH településazonosító törzsszám": 29337,
        zipCode: 7026,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Madocsa",
        "infó:": ""
      },
      {
        "Település ID": 1844,
        "KSH településazonosító törzsszám": 26444,
        zipCode: 9169,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Maglóca",
        "infó:": ""
      },
      {
        "Település ID": 1845,
        "KSH településazonosító törzsszám": 10922,
        zipCode: 2234,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Maglód",
        "infó:": ""
      },
      {
        "Település ID": 1846,
        "KSH településazonosító törzsszám": 6813,
        zipCode: 7342,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mágocs",
        "infó:": ""
      },
      {
        "Település ID": 1847,
        "KSH településazonosító törzsszám": 29984,
        zipCode: 4953,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Magosliget",
        "infó:": ""
      },
      {
        "Település ID": 1848,
        "KSH településazonosító törzsszám": 16629,
        zipCode: 4556,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Magy",
        "infó:": ""
      },
      {
        "Település ID": 1849,
        "KSH településazonosító törzsszám": 27678,
        zipCode: 8071,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Magyaralmás",
        "infó:": ""
      },
      {
        "Település ID": 1850,
        "KSH településazonosító törzsszám": 10427,
        zipCode: 7463,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Magyaratád",
        "infó:": ""
      },
      {
        "Település ID": 1851,
        "KSH településazonosító törzsszám": 27906,
        zipCode: 5667,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Magyarbánhegyes",
        "infó:": ""
      },
      {
        "Település ID": 1852,
        "KSH településazonosító törzsszám": 25177,
        zipCode: 7775,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Magyarbóly",
        "infó:": ""
      },
      {
        "Település ID": 1853,
        "KSH településazonosító törzsszám": 5962,
        zipCode: 6932,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Magyarcsanád",
        "infó:": ""
      },
      {
        "Település ID": 1854,
        "KSH településazonosító törzsszám": 11536,
        zipCode: 5838,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Magyardombegyház",
        "infó:": ""
      },
      {
        "Település ID": 1855,
        "KSH településazonosító törzsszám": 29753,
        zipCode: 7332,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Magyaregregy",
        "infó:": ""
      },
      {
        "Település ID": 1856,
        "KSH településazonosító törzsszám": 25371,
        zipCode: 7441,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Magyaregres",
        "infó:": ""
      },
      {
        "Település ID": 1857,
        "KSH településazonosító törzsszám": 8916,
        zipCode: 8973,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Magyarföld",
        "infó:": ""
      },
      {
        "Település ID": 1858,
        "KSH településazonosító törzsszám": 26967,
        zipCode: 3133,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Magyargéc",
        "infó:": ""
      },
      {
        "Település ID": 1859,
        "KSH településazonosító törzsszám": 26374,
        zipCode: 8517,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Magyargencs",
        "infó:": ""
      },
      {
        "Település ID": 1860,
        "KSH településazonosító törzsszám": 5430,
        zipCode: 7394,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Magyarhertelend",
        "infó:": ""
      },
      {
        "Település ID": 1861,
        "KSH településazonosító törzsszám": 3683,
        zipCode: 4137,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Magyarhomorog",
        "infó:": ""
      },
      {
        "Település ID": 1862,
        "KSH településazonosító törzsszám": 13912,
        zipCode: 9346,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Magyarkeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 1863,
        "KSH településazonosító törzsszám": 6017,
        zipCode: 7098,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Magyarkeszi",
        "infó:": ""
      },
      {
        "Település ID": 1864,
        "KSH településazonosító törzsszám": 3221,
        zipCode: 9962,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Magyarlak",
        "infó:": ""
      },
      {
        "Település ID": 1865,
        "KSH településazonosító törzsszám": 23542,
        zipCode: 7925,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Magyarlukafa",
        "infó:": ""
      },
      {
        "Település ID": 1866,
        "KSH településazonosító törzsszám": 5412,
        zipCode: 7954,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Magyarmecske",
        "infó:": ""
      },
      {
        "Település ID": 1867,
        "KSH településazonosító törzsszám": 27289,
        zipCode: 9909,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Magyarnádalja",
        "infó:": ""
      },
      {
        "Település ID": 1868,
        "KSH településazonosító törzsszám": 32407,
        zipCode: 2694,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Magyarnándor",
        "infó:": ""
      },
      {
        "Település ID": 1869,
        "KSH településazonosító törzsszám": 20437,
        zipCode: 8449,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Magyarpolány",
        "infó:": ""
      },
      {
        "Település ID": 1870,
        "KSH településazonosító törzsszám": 27067,
        zipCode: 7761,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Magyarsarlós",
        "infó:": ""
      },
      {
        "Település ID": 1871,
        "KSH településazonosító törzsszám": 17288,
        zipCode: 9912,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Magyarszecs%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 1872,
        "KSH településazonosító törzsszám": 22600,
        zipCode: 7396,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Magyarszék",
        "infó:": ""
      },
      {
        "Település ID": 1873,
        "KSH településazonosító törzsszám": 2909,
        zipCode: 8776,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Magyarszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 1874,
        "KSH településazonosító törzsszám": 13064,
        zipCode: 8776,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Magyarszerdahely",
        "infó:": ""
      },
      {
        "Település ID": 1875,
        "KSH településazonosító törzsszám": 25423,
        zipCode: 9946,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Magyarszombatfa",
        "infó:": ""
      },
      {
        "Település ID": 1876,
        "KSH településazonosító törzsszám": 4385,
        zipCode: 7954,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Magyartelek",
        "infó:": ""
      },
      {
        "Település ID": 1877,
        "KSH településazonosító törzsszám": 10755,
        zipCode: 2339,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Majosháza",
        "infó:": ""
      },
      {
        "Település ID": 1878,
        "KSH településazonosító törzsszám": 27863,
        zipCode: 7783,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Majs",
        "infó:": ""
      },
      {
        "Település ID": 1879,
        "KSH településazonosító törzsszám": 4394,
        zipCode: 2322,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Makád",
        "infó:": ""
      },
      {
        "Település ID": 1880,
        "KSH településazonosító törzsszám": 19600,
        zipCode: 3959,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Makkoshotyka",
        "infó:": ""
      },
      {
        "Település ID": 1881,
        "KSH településazonosító törzsszám": 27696,
        zipCode: 3397,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Maklár",
        "infó:": ""
      },
      {
        "Település ID": 1882,
        "KSH településazonosító törzsszám": 7357,
        zipCode: 6900,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Makó",
        "infó:": ""
      },
      {
        "Település ID": 1883,
        "KSH településazonosító törzsszám": 7357,
        zipCode: 6903,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Makó",
        "infó:": ""
      },
      {
        "Település ID": 1884,
        "KSH településazonosító törzsszám": 3610,
        zipCode: 8533,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Malomsok",
        "infó:": ""
      },
      {
        "Település ID": 1885,
        "KSH településazonosító törzsszám": 27395,
        zipCode: 3434,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mályi",
        "infó:": ""
      },
      {
        "Település ID": 1886,
        "KSH településazonosító törzsszám": 14915,
        zipCode: 3645,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mályinka",
        "infó:": ""
      },
      {
        "Település ID": 1887,
        "KSH településazonosító törzsszám": 2088,
        zipCode: 4942,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Mánd",
        "infó:": ""
      },
      {
        "Település ID": 1888,
        "KSH településazonosító törzsszám": 17826,
        zipCode: 4644,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Mándok",
        "infó:": ""
      },
      {
        "Település ID": 1889,
        "KSH településazonosító törzsszám": 33923,
        zipCode: 7304,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mánfa",
        "infó:": ""
      },
      {
        "Település ID": 1890,
        "KSH településazonosító törzsszám": 23490,
        zipCode: 2065,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Mány",
        "infó:": ""
      },
      {
        "Település ID": 1891,
        "KSH településazonosító törzsszám": 29920,
        zipCode: 7733,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Maráza",
        "infó:": ""
      },
      {
        "Település ID": 1892,
        "KSH településazonosító törzsszám": 29294,
        zipCode: 9534,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Marcalgergelyi",
        "infó:": ""
      },
      {
        "Település ID": 1893,
        "KSH településazonosító törzsszám": 18500,
        zipCode: 8700,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Marcali",
        "infó:": ""
      },
      {
        "Település ID": 1894,
        "KSH településazonosító törzsszám": 18500,
        zipCode: 8709,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Marcali",
        "infó:": ""
      },
      {
        "Település ID": 1895,
        "KSH településazonosító törzsszám": 18500,
        zipCode: 8714,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Marcali",
        "infó:": ""
      },
      {
        "Település ID": 1896,
        "KSH településazonosító törzsszám": 22220,
        zipCode: 8531,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Marcalt%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1897,
        "KSH településazonosító törzsszám": 22220,
        zipCode: 8532,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Marcalt%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1898,
        "KSH településazonosító törzsszám": 9450,
        zipCode: 7817,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Márfa",
        "infó:": ""
      },
      {
        "Település ID": 1899,
        "KSH településazonosító törzsszám": 22637,
        zipCode: 2527,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Máriahalom",
        "infó:": ""
      },
      {
        "Település ID": 1900,
        "KSH településazonosító törzsszám": 12283,
        zipCode: 9231,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Máriakálnok",
        "infó:": ""
      },
      {
        "Település ID": 1901,
        "KSH településazonosító törzsszám": 14483,
        zipCode: 7663,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Máriakéménd",
        "infó:": ""
      },
      {
        "Település ID": 1902,
        "KSH településazonosító törzsszám": 4570,
        zipCode: 2629,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Márianosztra",
        "infó:": ""
      },
      {
        "Település ID": 1903,
        "KSH településazonosító törzsszám": 19655,
        zipCode: 4326,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Máriapócs",
        "infó:": ""
      },
      {
        "Település ID": 1904,
        "KSH településazonosító törzsszám": 16540,
        zipCode: 3262,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Markaz",
        "infó:": ""
      },
      {
        "Település ID": 1905,
        "KSH településazonosító törzsszám": 14641,
        zipCode: 3075,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Márkháza",
        "infó:": ""
      },
      {
        "Település ID": 1906,
        "KSH településazonosító törzsszám": 32212,
        zipCode: 8441,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Márkó",
        "infó:": ""
      },
      {
        "Település ID": 1907,
        "KSH településazonosító törzsszám": 16443,
        zipCode: 7967,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Markóc",
        "infó:": ""
      },
      {
        "Település ID": 1908,
        "KSH településazonosító törzsszám": 6770,
        zipCode: 9164,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Markotabödöge",
        "infó:": ""
      },
      {
        "Település ID": 1909,
        "KSH településazonosító törzsszám": 2103,
        zipCode: 8888,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Maróc",
        "infó:": ""
      },
      {
        "Település ID": 1910,
        "KSH településazonosító törzsszám": 15219,
        zipCode: 7960,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Marócsa",
        "infó:": ""
      },
      {
        "Település ID": 1911,
        "KSH településazonosító törzsszám": 20659,
        zipCode: 7774,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Márok",
        "infó:": ""
      },
      {
        "Település ID": 1912,
        "KSH településazonosító törzsszám": 31981,
        zipCode: 8976,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Márokföld",
        "infó:": ""
      },
      {
        "Település ID": 1913,
        "KSH településazonosító törzsszám": 33224,
        zipCode: 4932,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Márokpapi",
        "infó:": ""
      },
      {
        "Település ID": 1914,
        "KSH településazonosító törzsszám": 10515,
        zipCode: 6921,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Maroslele",
        "infó:": ""
      },
      {
        "Település ID": 1915,
        "KSH településazonosító törzsszám": 25733,
        zipCode: 6636,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Mártély",
        "infó:": ""
      },
      {
        "Település ID": 1916,
        "KSH településazonosító törzsszám": 2626,
        zipCode: 5435,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Martf%u0171",
        "infó:": ""
      },
      {
        "Település ID": 1917,
        "KSH településazonosító törzsszám": 2228,
        zipCode: 7720,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Martonfa",
        "infó:": ""
      },
      {
        "Település ID": 1918,
        "KSH településazonosító törzsszám": 4659,
        zipCode: 2462,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Martonvásár",
        "infó:": ""
      },
      {
        "Település ID": 1919,
        "KSH településazonosító törzsszám": 2024,
        zipCode: 3755,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Martonyi",
        "infó:": ""
      },
      {
        "Település ID": 1920,
        "KSH településazonosító törzsszám": 18874,
        zipCode: 4700,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Mátészalka",
        "infó:": ""
      },
      {
        "Település ID": 1921,
        "KSH településazonosító törzsszám": 27809,
        zipCode: 6452,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Mátételke",
        "infó:": ""
      },
      {
        "Település ID": 1922,
        "KSH településazonosító törzsszám": 19965,
        zipCode: 3247,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Mátraballa",
        "infó:": ""
      },
      {
        "Település ID": 1923,
        "KSH településazonosító törzsszám": 14872,
        zipCode: 3246,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Mátraderecske",
        "infó:": ""
      },
      {
        "Település ID": 1924,
        "KSH településazonosító törzsszám": 20075,
        zipCode: 3155,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mátramindszent",
        "infó:": ""
      },
      {
        "Település ID": 1925,
        "KSH településazonosító törzsszám": 19372,
        zipCode: 3143,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mátranovák",
        "infó:": ""
      },
      {
        "Település ID": 1926,
        "KSH településazonosító törzsszám": 19372,
        zipCode: 3144,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mátranovák",
        "infó:": ""
      },
      {
        "Település ID": 1927,
        "KSH településazonosító törzsszám": 24332,
        zipCode: 3142,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mátraszele",
        "infó:": ""
      },
      {
        "Település ID": 1928,
        "KSH településazonosító törzsszám": 29045,
        zipCode: 3234,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Mátraszentimre",
        "infó:": ""
      },
      {
        "Település ID": 1929,
        "KSH településazonosító törzsszám": 29045,
        zipCode: 3235,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Mátraszentimre",
        "infó:": ""
      },
      {
        "Település ID": 1930,
        "KSH településazonosító törzsszám": 4330,
        zipCode: 3068,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mátrasz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 1931,
        "KSH településazonosító törzsszám": 33525,
        zipCode: 3145,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mátraterenye",
        "infó:": ""
      },
      {
        "Település ID": 1932,
        "KSH településazonosító törzsszám": 33525,
        zipCode: 3146,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mátraterenye",
        "infó:": ""
      },
      {
        "Település ID": 1933,
        "KSH településazonosító törzsszám": 30100,
        zipCode: 3077,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mátraverebély",
        "infó:": ""
      },
      {
        "Település ID": 1934,
        "KSH településazonosító törzsszám": 15051,
        zipCode: 7854,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Matty",
        "infó:": ""
      },
      {
        "Település ID": 1935,
        "KSH településazonosító törzsszám": 16948,
        zipCode: 8134,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Mátyásdomb",
        "infó:": ""
      },
      {
        "Település ID": 1936,
        "KSH településazonosító törzsszám": 20668,
        zipCode: 4835,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Mátyus",
        "infó:": ""
      },
      {
        "Település ID": 1937,
        "KSH településazonosító törzsszám": 33756,
        zipCode: 7351,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Máza",
        "infó:": ""
      },
      {
        "Település ID": 1938,
        "KSH településazonosító törzsszám": 13444,
        zipCode: 7695,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mecseknádasd",
        "infó:": ""
      },
      {
        "Település ID": 1939,
        "KSH településazonosító törzsszám": 11402,
        zipCode: 7300,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mecsekpölöske",
        "infó:": ""
      },
      {
        "Település ID": 1940,
        "KSH településazonosító törzsszám": 27359,
        zipCode: 9176,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mecsér",
        "infó:": ""
      },
      {
        "Település ID": 1941,
        "KSH településazonosító törzsszám": 20765,
        zipCode: 5663,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Medgyesbodzás",
        "infó:": ""
      },
      {
        "Település ID": 1942,
        "KSH településazonosító törzsszám": 20765,
        zipCode: 5664,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Medgyesbodzás",
        "infó:": ""
      },
      {
        "Település ID": 1943,
        "KSH településazonosító törzsszám": 30128,
        zipCode: 5666,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Medgyesegyháza",
        "infó:": ""
      },
      {
        "Település ID": 1944,
        "KSH településazonosító törzsszám": 30128,
        zipCode: 5752,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Medgyesegyháza",
        "infó:": ""
      },
      {
        "Település ID": 1945,
        "KSH településazonosító törzsszám": 30562,
        zipCode: 7057,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Medina",
        "infó:": ""
      },
      {
        "Település ID": 1946,
        "KSH településazonosító törzsszám": 25760,
        zipCode: 9757,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Meggyeskovácsi",
        "infó:": ""
      },
      {
        "Település ID": 1947,
        "KSH településazonosító törzsszám": 21768,
        zipCode: 3718,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Megyaszó",
        "infó:": ""
      },
      {
        "Település ID": 1948,
        "KSH településazonosító törzsszám": 30872,
        zipCode: 9754,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Megyehid",
        "infó:": ""
      },
      {
        "Település ID": 1949,
        "KSH településazonosító törzsszám": 4987,
        zipCode: 8348,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Megyer",
        "infó:": ""
      },
      {
        "Település ID": 1950,
        "KSH településazonosító törzsszám": 23931,
        zipCode: 5726,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Méhkerék",
        "infó:": ""
      },
      {
        "Település ID": 1951,
        "KSH településazonosító törzsszám": 29799,
        zipCode: 4975,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Méhtelek",
        "infó:": ""
      },
      {
        "Település ID": 1952,
        "KSH településazonosító törzsszám": 30492,
        zipCode: 7344,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mekényes",
        "infó:": ""
      },
      {
        "Település ID": 1953,
        "KSH településazonosító törzsszám": 16018,
        zipCode: 6449,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Mélykút",
        "infó:": ""
      },
      {
        "Település ID": 1954,
        "KSH településazonosító törzsszám": 27137,
        zipCode: 8271,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Mencshely",
        "infó:": ""
      },
      {
        "Település ID": 1955,
        "KSH településazonosító törzsszám": 3692,
        zipCode: 2235,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Mende",
        "infó:": ""
      },
      {
        "Település ID": 1956,
        "KSH településazonosító törzsszám": 25618,
        zipCode: 3871,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Méra",
        "infó:": ""
      },
      {
        "Település ID": 1957,
        "KSH településazonosító törzsszám": 7737,
        zipCode: 7981,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Merenye",
        "infó:": ""
      },
      {
        "Település ID": 1958,
        "KSH településazonosító törzsszám": 20385,
        zipCode: 9136,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mérges",
        "infó:": ""
      },
      {
        "Település ID": 1959,
        "KSH településazonosító törzsszám": 7463,
        zipCode: 4352,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Mérk",
        "infó:": ""
      },
      {
        "Település ID": 1960,
        "KSH településazonosító törzsszám": 23630,
        zipCode: 7453,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Mernye",
        "infó:": ""
      },
      {
        "Település ID": 1961,
        "KSH településazonosító törzsszám": 14809,
        zipCode: 9531,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Mersevát",
        "infó:": ""
      },
      {
        "Település ID": 1962,
        "KSH településazonosító törzsszám": 31875,
        zipCode: 9662,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Mesterháza",
        "infó:": ""
      },
      {
        "Település ID": 1963,
        "KSH településazonosító törzsszám": 15723,
        zipCode: 9551,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Mesteri",
        "infó:": ""
      },
      {
        "Település ID": 1964,
        "KSH településazonosító törzsszám": 30234,
        zipCode: 5452,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Mesterszállás",
        "infó:": ""
      },
      {
        "Település ID": 1965,
        "KSH településazonosító törzsszám": 11758,
        zipCode: 3754,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Meszes",
        "infó:": ""
      },
      {
        "Település ID": 1966,
        "KSH településazonosító törzsszám": 32832,
        zipCode: 9745,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Meszlen",
        "infó:": ""
      },
      {
        "Település ID": 1967,
        "KSH településazonosító törzsszám": 16106,
        zipCode: 8716,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Mesztegny%u0151",
        "infó:": ""
      },
      {
        "Település ID": 1968,
        "KSH településazonosító törzsszám": 19628,
        zipCode: 5650,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Mez%u0151berény",
        "infó:": ""
      },
      {
        "Település ID": 1969,
        "KSH településazonosító törzsszám": 13833,
        zipCode: 3450,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mez%u0151csát",
        "infó:": ""
      },
      {
        "Település ID": 1970,
        "KSH településazonosító törzsszám": 30854,
        zipCode: 7434,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Mez%u0151csokonya",
        "infó:": ""
      },
      {
        "Település ID": 1971,
        "KSH településazonosító törzsszám": 3470,
        zipCode: 7370,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mez%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 1972,
        "KSH településazonosító törzsszám": 17552,
        zipCode: 2422,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Mez%u0151falva",
        "infó:": ""
      },
      {
        "Település ID": 1973,
        "KSH településazonosító törzsszám": 4206,
        zipCode: 5732,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Mez%u0151gyán",
        "infó:": ""
      },
      {
        "Település ID": 1974,
        "KSH településazonosító törzsszám": 11873,
        zipCode: 5820,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Mez%u0151hegyes",
        "infó:": ""
      },
      {
        "Település ID": 1975,
        "KSH településazonosító törzsszám": 26286,
        zipCode: 5453,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Mez%u0151hék",
        "infó:": ""
      },
      {
        "Település ID": 1976,
        "KSH településazonosító törzsszám": 11323,
        zipCode: 3441,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mez%u0151keresztes",
        "infó:": ""
      },
      {
        "Település ID": 1977,
        "KSH településazonosító törzsszám": 5689,
        zipCode: 8137,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Mez%u0151komárom",
        "infó:": ""
      },
      {
        "Település ID": 1978,
        "KSH településazonosító törzsszám": 30322,
        zipCode: 5800,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Mez%u0151kovácsháza",
        "infó:": ""
      },
      {
        "Település ID": 1979,
        "KSH településazonosító törzsszám": 19433,
        zipCode: 3400,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mez%u0151kövesd",
        "infó:": ""
      },
      {
        "Település ID": 1980,
        "KSH településazonosító törzsszám": 32656,
        zipCode: 4641,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Mez%u0151ladány",
        "infó:": ""
      },
      {
        "Település ID": 1981,
        "KSH településazonosító törzsszám": 23560,
        zipCode: 8514,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Mez%u0151lak",
        "infó:": ""
      },
      {
        "Település ID": 1982,
        "KSH településazonosító törzsszám": 18379,
        zipCode: 3443,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mez%u0151nagymihály",
        "infó:": ""
      },
      {
        "Település ID": 1983,
        "KSH településazonosító törzsszám": 11749,
        zipCode: 3421,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mez%u0151nyárád",
        "infó:": ""
      },
      {
        "Település ID": 1992,
        "KSH településazonosító törzsszám": 12812,
        zipCode: 9097,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mez%u0151örs",
        "infó:": ""
      },
      {
        "Település ID": 1993,
        "KSH településazonosító törzsszám": 12812,
        zipCode: 9098,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mez%u0151örs",
        "infó:": ""
      },
      {
        "Település ID": 1984,
        "KSH településazonosító törzsszám": 31033,
        zipCode: 4118,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Mez%u0151peterd",
        "infó:": ""
      },
      {
        "Település ID": 1985,
        "KSH településazonosító törzsszám": 18847,
        zipCode: 4134,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Mez%u0151sas",
        "infó:": ""
      },
      {
        "Település ID": 1986,
        "KSH településazonosító törzsszám": 25089,
        zipCode: 3378,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Mez%u0151szemere",
        "infó:": ""
      },
      {
        "Település ID": 1987,
        "KSH településazonosító törzsszám": 6576,
        zipCode: 8133,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Mez%u0151szentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 1988,
        "KSH településazonosító törzsszám": 29036,
        zipCode: 7017,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Mez%u0151szilas",
        "infó:": ""
      },
      {
        "Település ID": 1989,
        "KSH településazonosító törzsszám": 31662,
        zipCode: 3375,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Mez%u0151tárkány",
        "infó:": ""
      },
      {
        "Település ID": 1990,
        "KSH településazonosító törzsszám": 4260,
        zipCode: 5400,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Mez%u0151túr",
        "infó:": ""
      },
      {
        "Település ID": 1991,
        "KSH településazonosító törzsszám": 3443,
        zipCode: 3931,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mez%u0151zombor",
        "infó:": ""
      },
      {
        "Település ID": 1994,
        "KSH településazonosító törzsszám": 27526,
        zipCode: 8825,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Miháld",
        "infó:": ""
      },
      {
        "Település ID": 1995,
        "KSH településazonosító törzsszám": 2130,
        zipCode: 8341,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Mihályfa",
        "infó:": ""
      },
      {
        "Település ID": 1996,
        "KSH településazonosító törzsszám": 13222,
        zipCode: 3184,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mihálygerge",
        "infó:": ""
      },
      {
        "Település ID": 1997,
        "KSH településazonosító törzsszám": 4668,
        zipCode: 8513,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Mihályháza",
        "infó:": ""
      },
      {
        "Település ID": 1998,
        "KSH településazonosító törzsszám": 21980,
        zipCode: 9342,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mihályi",
        "infó:": ""
      },
      {
        "Település ID": 1999,
        "KSH településazonosító törzsszám": 20905,
        zipCode: 7512,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Mike",
        "infó:": ""
      },
      {
        "Település ID": 2000,
        "KSH településazonosító törzsszám": 24466,
        zipCode: 2736,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Mikebuda",
        "infó:": ""
      },
      {
        "Település ID": 2001,
        "KSH településazonosító törzsszám": 22141,
        zipCode: 8949,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Mikekarácsonyfa",
        "infó:": ""
      },
      {
        "Település ID": 2002,
        "KSH településazonosító törzsszám": 24217,
        zipCode: 4271,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Mikepércs",
        "infó:": ""
      },
      {
        "Település ID": 2003,
        "KSH településazonosító törzsszám": 33376,
        zipCode: 7286,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Miklósi",
        "infó:": ""
      },
      {
        "Település ID": 2004,
        "KSH településazonosító törzsszám": 31282,
        zipCode: 3344,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Mikófalva",
        "infó:": ""
      },
      {
        "Település ID": 2005,
        "KSH településazonosító törzsszám": 24253,
        zipCode: 3989,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mikóháza",
        "infó:": ""
      },
      {
        "Település ID": 2006,
        "KSH településazonosító törzsszám": 30599,
        zipCode: 9835,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Mikosszéplak",
        "infó:": ""
      },
      {
        "Település ID": 2007,
        "KSH településazonosító törzsszám": 2662,
        zipCode: 8917,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Milejszeg",
        "infó:": ""
      },
      {
        "Település ID": 2008,
        "KSH településazonosító törzsszám": 31750,
        zipCode: 4948,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Milota",
        "infó:": ""
      },
      {
        "Település ID": 2009,
        "KSH településazonosító törzsszám": 21555,
        zipCode: 6630,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Mindszent",
        "infó:": ""
      },
      {
        "Település ID": 2010,
        "KSH településazonosító törzsszám": 16285,
        zipCode: 7391,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mindszentgodisa",
        "infó:": ""
      },
      {
        "Település ID": 2011,
        "KSH településazonosító törzsszám": 4534,
        zipCode: 8282,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Mindszentkálla",
        "infó:": ""
      },
      {
        "Település ID": 2012,
        "KSH településazonosító törzsszám": 4756,
        zipCode: 8935,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Misefa",
        "infó:": ""
      },
      {
        "Település ID": 2013,
        "KSH településazonosító törzsszám": 30632,
        zipCode: 6343,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Miske",
        "infó:": ""
      },
      {
        "Település ID": 2014,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3500,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2015,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3501,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2016,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3508,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2017,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3510,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2018,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3515,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2019,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3516,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2020,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3517,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2021,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3518,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2022,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3519,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2023,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3521,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2024,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3524,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2025,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3525,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2026,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3526,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2027,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3527,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2028,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3528,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2029,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3529,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2030,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3530,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2031,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3531,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2032,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3532,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2033,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3533,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2034,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3534,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2035,
        "KSH településazonosító törzsszám": 30456,
        zipCode: 3535,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Miskolc",
        "infó:": ""
      },
      {
        "Település ID": 2036,
        "KSH településazonosító törzsszám": 2811,
        zipCode: 7065,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Miszla",
        "infó:": ""
      },
      {
        "Település ID": 2037,
        "KSH településazonosító törzsszám": 26930,
        zipCode: 2911,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Mocsa",
        "infó:": ""
      },
      {
        "Település ID": 2038,
        "KSH településazonosító törzsszám": 33349,
        zipCode: 7163,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "M%u0151csény",
        "infó:": ""
      },
      {
        "Település ID": 2039,
        "KSH településazonosító törzsszám": 17783,
        zipCode: 2146,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Mogyoród",
        "infó:": ""
      },
      {
        "Település ID": 2040,
        "KSH településazonosító törzsszám": 28255,
        zipCode: 2535,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Mogyorósbánya",
        "infó:": ""
      },
      {
        "Település ID": 2041,
        "KSH településazonosító törzsszám": 10968,
        zipCode: 3893,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Mogyoróska",
        "infó:": ""
      },
      {
        "Település ID": 2042,
        "KSH településazonosító törzsszám": 25715,
        zipCode: 8042,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Moha",
        "infó:": ""
      },
      {
        "Település ID": 2043,
        "KSH településazonosító törzsszám": 23959,
        zipCode: 7700,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mohács",
        "infó:": ""
      },
      {
        "Település ID": 2044,
        "KSH településazonosító törzsszám": 23959,
        zipCode: 7714,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mohács",
        "infó:": ""
      },
      {
        "Település ID": 2045,
        "KSH településazonosító törzsszám": 23959,
        zipCode: 7715,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mohács",
        "infó:": ""
      },
      {
        "Település ID": 2046,
        "KSH településazonosító törzsszám": 27915,
        zipCode: 2698,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Mohora",
        "infó:": ""
      },
      {
        "Település ID": 2047,
        "KSH településazonosító törzsszám": 21014,
        zipCode: 8863,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Molnári",
        "infó:": ""
      },
      {
        "Település ID": 2048,
        "KSH településazonosító törzsszám": 3294,
        zipCode: 9912,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Molnaszecs%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 2049,
        "KSH településazonosító törzsszám": 29522,
        zipCode: 7981,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Molvány",
        "infó:": ""
      },
      {
        "Település ID": 2050,
        "KSH településazonosító törzsszám": 9016,
        zipCode: 3812,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Monaj",
        "infó:": ""
      },
      {
        "Település ID": 2051,
        "KSH településazonosító törzsszám": 7825,
        zipCode: 3905,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Monok",
        "infó:": ""
      },
      {
        "Település ID": 2052,
        "KSH településazonosító törzsszám": 10551,
        zipCode: 2200,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Monor",
        "infó:": ""
      },
      {
        "Település ID": 2053,
        "KSH településazonosító törzsszám": 10551,
        zipCode: 2213,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Monor",
        "infó:": ""
      },
      {
        "Település ID": 2054,
        "KSH településazonosító törzsszám": 31565,
        zipCode: 3345,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Mónosbél",
        "infó:": ""
      },
      {
        "Település ID": 2055,
        "KSH településazonosító törzsszám": 24040,
        zipCode: 8296,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Monostorapáti",
        "infó:": ""
      },
      {
        "Település ID": 2056,
        "KSH településazonosító törzsszám": 25894,
        zipCode: 4275,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Monostorpályi",
        "infó:": ""
      },
      {
        "Település ID": 2057,
        "KSH településazonosító törzsszám": 22512,
        zipCode: 8273,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Monoszló",
        "infó:": ""
      },
      {
        "Település ID": 2058,
        "KSH településazonosító törzsszám": 16027,
        zipCode: 7751,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Monyoród",
        "infó:": ""
      },
      {
        "Település ID": 2059,
        "KSH településazonosító törzsszám": 18485,
        zipCode: 8060,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Mór",
        "infó:": ""
      },
      {
        "Település ID": 2060,
        "KSH településazonosító törzsszám": 20701,
        zipCode: 7165,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Mórágy",
        "infó:": ""
      },
      {
        "Település ID": 2061,
        "KSH településazonosító törzsszám": 4349,
        zipCode: 6782,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Mórahalom",
        "infó:": ""
      },
      {
        "Település ID": 2062,
        "KSH településazonosító törzsszám": 34096,
        zipCode: 6132,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Móricgát",
        "infó:": ""
      },
      {
        "Település ID": 2063,
        "KSH településazonosító törzsszám": 2556,
        zipCode: 9131,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mórichida",
        "infó:": ""
      },
      {
        "Település ID": 2064,
        "KSH településazonosító törzsszám": 31343,
        zipCode: 7257,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Mosdós",
        "infó:": ""
      },
      {
        "Település ID": 2065,
        "KSH településazonosító törzsszám": 4783,
        zipCode: 9200,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mosonmagyaróvár",
        "infó:": ""
      },
      {
        "Település ID": 2066,
        "KSH településazonosító törzsszám": 33677,
        zipCode: 9154,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mosonszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 2067,
        "KSH településazonosító törzsszám": 33677,
        zipCode: 9183,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mosonszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 2068,
        "KSH településazonosító törzsszám": 28149,
        zipCode: 9245,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Mosonszolnok",
        "infó:": ""
      },
      {
        "Település ID": 2069,
        "KSH településazonosító törzsszám": 29540,
        zipCode: 7932,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Mozsgó",
        "infó:": ""
      },
      {
        "Település ID": 2070,
        "KSH településazonosító törzsszám": 13620,
        zipCode: 7185,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Mucsfa",
        "infó:": ""
      },
      {
        "Település ID": 2071,
        "KSH településazonosító törzsszám": 7029,
        zipCode: 7195,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Mucsi",
        "infó:": ""
      },
      {
        "Település ID": 2072,
        "KSH településazonosító törzsszám": 21546,
        zipCode: 3744,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Múcsony",
        "infó:": ""
      },
      {
        "Település ID": 2073,
        "KSH településazonosító törzsszám": 2158,
        zipCode: 3552,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Muhi",
        "infó:": ""
      },
      {
        "Település ID": 2074,
        "KSH településazonosító törzsszám": 25210,
        zipCode: 8834,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Murakeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 2075,
        "KSH településazonosító törzsszám": 7968,
        zipCode: 8868,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Murarátka",
        "infó:": ""
      },
      {
        "Település ID": 2076,
        "KSH településazonosító törzsszám": 33987,
        zipCode: 8872,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Muraszemenye",
        "infó:": ""
      },
      {
        "Település ID": 2077,
        "KSH településazonosító törzsszám": 18917,
        zipCode: 7176,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Murga",
        "infó:": ""
      },
      {
        "Település ID": 2078,
        "KSH településazonosító törzsszám": 11989,
        zipCode: 5672,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Murony",
        "infó:": ""
      },
      {
        "Település ID": 2079,
        "KSH településazonosító törzsszám": 19211,
        zipCode: 4911,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nábrád",
        "infó:": ""
      },
      {
        "Település ID": 2080,
        "KSH településazonosító törzsszám": 27599,
        zipCode: 8097,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Nadap",
        "infó:": ""
      },
      {
        "Település ID": 2081,
        "KSH településazonosító törzsszám": 6716,
        zipCode: 9915,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nádasd",
        "infó:": ""
      },
      {
        "Település ID": 2082,
        "KSH településazonosító törzsszám": 13903,
        zipCode: 8145,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Nádasdladány",
        "infó:": ""
      },
      {
        "Település ID": 2083,
        "KSH településazonosító törzsszám": 28103,
        zipCode: 4181,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nádudvar",
        "infó:": ""
      },
      {
        "Település ID": 2084,
        "KSH településazonosító törzsszám": 15909,
        zipCode: 8674,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nágocs",
        "infó:": ""
      },
      {
        "Település ID": 2085,
        "KSH településazonosító törzsszám": 23551,
        zipCode: 8521,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nagyacsád",
        "infó:": ""
      },
      {
        "Település ID": 2086,
        "KSH településazonosító törzsszám": 27979,
        zipCode: 8484,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nagyalásony",
        "infó:": ""
      },
      {
        "Település ID": 2087,
        "KSH településazonosító törzsszám": 4710,
        zipCode: 4922,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagyar",
        "infó:": ""
      },
      {
        "Település ID": 2088,
        "KSH településazonosító törzsszám": 17941,
        zipCode: 7500,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nagyatád",
        "infó:": ""
      },
      {
        "Település ID": 2089,
        "KSH településazonosító törzsszám": 2361,
        zipCode: 9063,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Nagybajcs",
        "infó:": ""
      },
      {
        "Település ID": 2090,
        "KSH településazonosító törzsszám": 21652,
        zipCode: 7561,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nagybajom",
        "infó:": ""
      },
      {
        "Település ID": 2091,
        "KSH településazonosító törzsszám": 12760,
        zipCode: 8821,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagybakónak",
        "infó:": ""
      },
      {
        "Település ID": 2092,
        "KSH településazonosító törzsszám": 26028,
        zipCode: 5668,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Nagybánhegyes",
        "infó:": ""
      },
      {
        "Település ID": 2093,
        "KSH településazonosító törzsszám": 25955,
        zipCode: 6527,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Nagybaracska",
        "infó:": ""
      },
      {
        "Település ID": 2094,
        "KSH településazonosító törzsszám": 29188,
        zipCode: 3641,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nagybarca",
        "infó:": ""
      },
      {
        "Település ID": 2095,
        "KSH településazonosító törzsszám": 16391,
        zipCode: 3075,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nagybárkány",
        "infó:": ""
      },
      {
        "Település ID": 2096,
        "KSH településazonosító törzsszám": 29063,
        zipCode: 8656,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nagyberény",
        "infó:": ""
      },
      {
        "Település ID": 2097,
        "KSH településazonosító törzsszám": 21449,
        zipCode: 7255,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nagyberki",
        "infó:": ""
      },
      {
        "Település ID": 2099,
        "KSH településazonosító törzsszám": 14775,
        zipCode: 2634,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Nagybörzsöny",
        "infó:": ""
      },
      {
        "Település ID": 2098,
        "KSH településazonosító törzsszám": 3984,
        zipCode: 7756,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagybudmér",
        "infó:": ""
      },
      {
        "Település ID": 2100,
        "KSH településazonosító törzsszám": 2495,
        zipCode: 9485,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Nagycenk",
        "infó:": ""
      },
      {
        "Település ID": 2101,
        "KSH településazonosító törzsszám": 18111,
        zipCode: 7838,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagycsány",
        "infó:": ""
      },
      {
        "Település ID": 2102,
        "KSH településazonosító törzsszám": 5582,
        zipCode: 3598,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nagycsécs",
        "infó:": ""
      },
      {
        "Település ID": 2103,
        "KSH településazonosító törzsszám": 20598,
        zipCode: 8628,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nagycsepely",
        "infó:": ""
      },
      {
        "Település ID": 2104,
        "KSH településazonosító törzsszám": 22743,
        zipCode: 4445,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagycserkesz",
        "infó:": ""
      },
      {
        "Település ID": 2105,
        "KSH településazonosító törzsszám": 10001,
        zipCode: 8554,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nagydém",
        "infó:": ""
      },
      {
        "Település ID": 2106,
        "KSH településazonosító törzsszám": 21485,
        zipCode: 4823,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagydobos",
        "infó:": ""
      },
      {
        "Település ID": 2107,
        "KSH településazonosító törzsszám": 33899,
        zipCode: 7985,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagydobsza",
        "infó:": ""
      },
      {
        "Település ID": 2108,
        "KSH településazonosító törzsszám": 18388,
        zipCode: 7044,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Nagydorog",
        "infó:": ""
      },
      {
        "Település ID": 2109,
        "KSH településazonosító törzsszám": 6488,
        zipCode: 4355,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagyecsed",
        "infó:": ""
      },
      {
        "Település ID": 2110,
        "KSH településazonosító törzsszám": 20914,
        zipCode: 6917,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Nagyér",
        "infó:": ""
      },
      {
        "Település ID": 2111,
        "KSH településazonosító törzsszám": 23180,
        zipCode: 8415,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nagyesztergár",
        "infó:": ""
      },
      {
        "Település ID": 2112,
        "KSH településazonosító törzsszám": 26879,
        zipCode: 3282,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Nagyfüged",
        "infó:": ""
      },
      {
        "Település ID": 2113,
        "KSH településazonosító törzsszám": 9283,
        zipCode: 9664,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nagygeresd",
        "infó:": ""
      },
      {
        "Település ID": 2115,
        "KSH településazonosító törzsszám": 5218,
        zipCode: 8356,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagygörb%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2114,
        "KSH településazonosító törzsszám": 8262,
        zipCode: 8551,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nagygyimót",
        "infó:": ""
      },
      {
        "Település ID": 2116,
        "KSH településazonosító törzsszám": 12858,
        zipCode: 7343,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagyhajmás",
        "infó:": ""
      },
      {
        "Település ID": 2117,
        "KSH településazonosító törzsszám": 27155,
        zipCode: 4485,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagyhalász",
        "infó:": ""
      },
      {
        "Település ID": 2118,
        "KSH településazonosító törzsszám": 2653,
        zipCode: 7822,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagyharsány",
        "infó:": ""
      },
      {
        "Település ID": 2119,
        "KSH településazonosító törzsszám": 9478,
        zipCode: 4064,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nagyhegyes",
        "infó:": ""
      },
      {
        "Település ID": 2120,
        "KSH településazonosító törzsszám": 26976,
        zipCode: 4977,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagyhódos",
        "infó:": ""
      },
      {
        "Település ID": 2121,
        "KSH településazonosító törzsszám": 15468,
        zipCode: 3994,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nagyhuta",
        "infó:": ""
      },
      {
        "Település ID": 2122,
        "KSH településazonosító törzsszám": 22372,
        zipCode: 2942,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Nagyigmánd",
        "infó:": ""
      },
      {
        "Település ID": 2123,
        "KSH településazonosító törzsszám": 21689,
        zipCode: 5363,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Nagyiván",
        "infó:": ""
      },
      {
        "Település ID": 2124,
        "KSH településazonosító törzsszám": 24785,
        zipCode: 4320,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagykálló",
        "infó:": ""
      },
      {
        "Település ID": 2125,
        "KSH településazonosító törzsszám": 4242,
        zipCode: 5751,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Nagykamarás",
        "infó:": ""
      },
      {
        "Település ID": 2126,
        "KSH településazonosító törzsszám": 30933,
        zipCode: 8800,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagykanizsa",
        "infó:": ""
      },
      {
        "Település ID": 2127,
        "KSH településazonosító törzsszám": 30933,
        zipCode: 8808,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagykanizsa",
        "infó:": ""
      },
      {
        "Település ID": 2128,
        "KSH településazonosító törzsszám": 30933,
        zipCode: 8809,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagykanizsa",
        "infó:": ""
      },
      {
        "Település ID": 2129,
        "KSH településazonosító törzsszám": 30933,
        zipCode: 8831,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagykanizsa",
        "infó:": ""
      },
      {
        "Település ID": 2130,
        "KSH településazonosító törzsszám": 20589,
        zipCode: 8935,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagykapornak",
        "infó:": ""
      },
      {
        "Település ID": 2131,
        "KSH településazonosító törzsszám": 7001,
        zipCode: 2425,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Nagykarácsony",
        "infó:": ""
      },
      {
        "Település ID": 2132,
        "KSH településazonosító törzsszám": 13435,
        zipCode: 2760,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Nagykáta",
        "infó:": ""
      },
      {
        "Település ID": 2133,
        "KSH településazonosító törzsszám": 8907,
        zipCode: 4127,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nagykereki",
        "infó:": ""
      },
      {
        "Település ID": 2134,
        "KSH településazonosító törzsszám": 34281,
        zipCode: 3129,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nagykeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 2135,
        "KSH településazonosító törzsszám": 26505,
        zipCode: 3844,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nagykinizs",
        "infó:": ""
      },
      {
        "Település ID": 2142,
        "KSH településazonosító törzsszám": 24943,
        zipCode: 3012,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Nagykökényes",
        "infó:": ""
      },
      {
        "Település ID": 2143,
        "KSH településazonosító törzsszám": 32920,
        zipCode: 9784,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nagykölked",
        "infó:": ""
      },
      {
        "Település ID": 2136,
        "KSH településazonosító törzsszám": 27182,
        zipCode: 7092,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Nagykónyi",
        "infó:": ""
      },
      {
        "Település ID": 2138,
        "KSH településazonosító törzsszám": 19716,
        zipCode: 2750,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Nagyk%u0151rös",
        "infó:": ""
      },
      {
        "Település ID": 2137,
        "KSH településazonosító törzsszám": 32805,
        zipCode: 7545,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nagykorpád",
        "infó:": ""
      },
      {
        "Település ID": 2144,
        "KSH településazonosító törzsszám": 15574,
        zipCode: 5065,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Nagykör%u0171",
        "infó:": ""
      },
      {
        "Település ID": 2139,
        "KSH településazonosító törzsszám": 9991,
        zipCode: 2094,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Nagykovácsi",
        "infó:": ""
      },
      {
        "Település ID": 2140,
        "KSH településazonosító törzsszám": 10940,
        zipCode: 7741,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagykozár",
        "infó:": ""
      },
      {
        "Település ID": 2141,
        "KSH településazonosító törzsszám": 22178,
        zipCode: 8911,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagykutas",
        "infó:": ""
      },
      {
        "Település ID": 2145,
        "KSH településazonosító törzsszám": 12779,
        zipCode: 6933,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Nagylak",
        "infó:": ""
      },
      {
        "Település ID": 2146,
        "KSH településazonosító törzsszám": 4455,
        zipCode: 8983,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagylengyel",
        "infó:": ""
      },
      {
        "Település ID": 2147,
        "KSH településazonosító törzsszám": 21102,
        zipCode: 3175,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nagylóc",
        "infó:": ""
      },
      {
        "Település ID": 2148,
        "KSH településazonosító törzsszám": 32364,
        zipCode: 2435,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Nagylók",
        "infó:": ""
      },
      {
        "Település ID": 2149,
        "KSH településazonosító törzsszám": 32939,
        zipCode: 9482,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Nagylózs",
        "infó:": ""
      },
      {
        "Település ID": 2150,
        "KSH településazonosító törzsszám": 17233,
        zipCode: 6622,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Nagymágocs",
        "infó:": ""
      },
      {
        "Település ID": 2151,
        "KSH településazonosító törzsszám": 14030,
        zipCode: 7355,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Nagymányok",
        "infó:": ""
      },
      {
        "Település ID": 2152,
        "KSH településazonosító törzsszám": 31732,
        zipCode: 2626,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Nagymaros",
        "infó:": ""
      },
      {
        "Település ID": 2153,
        "KSH településazonosító törzsszám": 15060,
        zipCode: 9913,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nagymizdó",
        "infó:": ""
      },
      {
        "Település ID": 2154,
        "KSH településazonosító törzsszám": 14650,
        zipCode: 7784,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagynyárád",
        "infó:": ""
      },
      {
        "Település ID": 2155,
        "KSH településazonosító törzsszám": 23986,
        zipCode: 2645,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nagyoroszi",
        "infó:": ""
      },
      {
        "Település ID": 2156,
        "KSH településazonosító törzsszám": 28468,
        zipCode: 8912,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagypáli",
        "infó:": ""
      },
      {
        "Település ID": 2157,
        "KSH településazonosító törzsszám": 19877,
        zipCode: 7731,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagypall",
        "infó:": ""
      },
      {
        "Település ID": 2158,
        "KSH településazonosító törzsszám": 27164,
        zipCode: 7912,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagypeterd",
        "infó:": ""
      },
      {
        "Település ID": 2159,
        "KSH településazonosító törzsszám": 21403,
        zipCode: 8496,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nagypirit",
        "infó:": ""
      },
      {
        "Település ID": 2160,
        "KSH településazonosító törzsszám": 6309,
        zipCode: 4173,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nagyrábé",
        "infó:": ""
      },
      {
        "Település ID": 2161,
        "KSH településazonosító törzsszám": 16513,
        zipCode: 8746,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagyrada",
        "infó:": ""
      },
      {
        "Település ID": 2162,
        "KSH településazonosító törzsszám": 29869,
        zipCode: 9938,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nagyrákos",
        "infó:": ""
      },
      {
        "Település ID": 2163,
        "KSH településazonosító törzsszám": 14979,
        zipCode: 8756,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nagyrécse",
        "infó:": ""
      },
      {
        "Település ID": 2164,
        "KSH településazonosító törzsszám": 31486,
        zipCode: 3214,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Nagyréde",
        "infó:": ""
      },
      {
        "Település ID": 2165,
        "KSH településazonosító törzsszám": 6318,
        zipCode: 5463,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Nagyrév",
        "infó:": ""
      },
      {
        "Település ID": 2166,
        "KSH településazonosító törzsszám": 33181,
        zipCode: 3965,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nagyrozvágy",
        "infó:": ""
      },
      {
        "Település ID": 2167,
        "KSH településazonosító törzsszám": 27076,
        zipCode: 2524,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Nagysáp",
        "infó:": ""
      },
      {
        "Település ID": 2168,
        "KSH településazonosító törzsszám": 26143,
        zipCode: 9561,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nagysimonyi",
        "infó:": ""
      },
      {
        "Település ID": 2169,
        "KSH településazonosító törzsszám": 25520,
        zipCode: 8739,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nagyszakácsi",
        "infó:": ""
      },
      {
        "Település ID": 2170,
        "KSH településazonosító törzsszám": 6761,
        zipCode: 7085,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Nagyszékely",
        "infó:": ""
      },
      {
        "Település ID": 2171,
        "KSH településazonosító törzsszám": 27988,
        zipCode: 4962,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagyszekeres",
        "infó:": ""
      },
      {
        "Település ID": 2172,
        "KSH településazonosító törzsszám": 8244,
        zipCode: 5931,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Nagyszénás",
        "infó:": ""
      },
      {
        "Település ID": 2173,
        "KSH településazonosító törzsszám": 33543,
        zipCode: 9072,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Nagyszentjános",
        "infó:": ""
      },
      {
        "Település ID": 2174,
        "KSH településazonosító törzsszám": 13709,
        zipCode: 7097,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Nagyszokoly",
        "infó:": ""
      },
      {
        "Település ID": 2175,
        "KSH településazonosító törzsszám": 27605,
        zipCode: 3398,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Nagytálya",
        "infó:": ""
      },
      {
        "Település ID": 2176,
        "KSH településazonosító törzsszám": 23409,
        zipCode: 2142,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Nagytarcsa",
        "infó:": ""
      },
      {
        "Település ID": 2177,
        "KSH településazonosító törzsszám": 25201,
        zipCode: 8562,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nagytevel",
        "infó:": ""
      },
      {
        "Település ID": 2178,
        "KSH településazonosító törzsszám": 24837,
        zipCode: 9832,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nagytilaj",
        "infó:": ""
      },
      {
        "Település ID": 2179,
        "KSH településazonosító törzsszám": 29179,
        zipCode: 6612,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Nagyt%u0151ke",
        "infó:": ""
      },
      {
        "Település ID": 2180,
        "KSH településazonosító törzsszám": 28927,
        zipCode: 7800,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagytótfalu",
        "infó:": ""
      },
      {
        "Település ID": 2181,
        "KSH településazonosító törzsszám": 10418,
        zipCode: 3357,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Nagyút",
        "infó:": ""
      },
      {
        "Település ID": 2182,
        "KSH településazonosító törzsszám": 33783,
        zipCode: 4812,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nagyvarsány",
        "infó:": ""
      },
      {
        "Település ID": 2183,
        "KSH településazonosító törzsszám": 14191,
        zipCode: 7912,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nagyváty",
        "infó:": ""
      },
      {
        "Település ID": 2184,
        "KSH településazonosító törzsszám": 19196,
        zipCode: 8291,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nagyvázsony",
        "infó:": ""
      },
      {
        "Település ID": 2185,
        "KSH településazonosító törzsszám": 16452,
        zipCode: 7186,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Nagyvejke",
        "infó:": ""
      },
      {
        "Település ID": 2186,
        "KSH településazonosító törzsszám": 23588,
        zipCode: 8065,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Nagyveleg",
        "infó:": ""
      },
      {
        "Település ID": 2187,
        "KSH településazonosító törzsszám": 26134,
        zipCode: 2421,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Nagyvenyim",
        "infó:": ""
      },
      {
        "Település ID": 2188,
        "KSH településazonosító törzsszám": 28282,
        zipCode: 3349,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Nagyvisnyó",
        "infó:": ""
      },
      {
        "Település ID": 2189,
        "KSH településazonosító törzsszám": 2796,
        zipCode: 7215,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Nak",
        "infó:": ""
      },
      {
        "Település ID": 2190,
        "KSH településazonosító törzsszám": 8420,
        zipCode: 4552,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Napkor",
        "infó:": ""
      },
      {
        "Település ID": 2191,
        "KSH településazonosító törzsszám": 17367,
        zipCode: 9797,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nárai",
        "infó:": ""
      },
      {
        "Település ID": 2192,
        "KSH településazonosító törzsszám": 2042,
        zipCode: 9793,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Narda",
        "infó:": ""
      },
      {
        "Település ID": 2193,
        "KSH településazonosító törzsszám": 20163,
        zipCode: 2899,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Naszály",
        "infó:": ""
      },
      {
        "Település ID": 2194,
        "KSH településazonosító törzsszám": 24156,
        zipCode: 3463,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Négyes",
        "infó:": ""
      },
      {
        "Település ID": 2195,
        "KSH településazonosító törzsszám": 28033,
        zipCode: 3646,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nekézseny",
        "infó:": ""
      },
      {
        "Település ID": 2196,
        "KSH településazonosító törzsszám": 6169,
        zipCode: 8923,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nemesapáti",
        "infó:": ""
      },
      {
        "Település ID": 2197,
        "KSH településazonosító törzsszám": 5245,
        zipCode: 3592,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nemesbikk",
        "infó:": ""
      },
      {
        "Település ID": 2198,
        "KSH településazonosító törzsszám": 24509,
        zipCode: 9749,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nemesb%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 2199,
        "KSH településazonosító törzsszám": 27119,
        zipCode: 4942,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nemesborzova",
        "infó:": ""
      },
      {
        "Település ID": 2200,
        "KSH településazonosító törzsszám": 32948,
        zipCode: 8371,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nemesbük",
        "infó:": ""
      },
      {
        "Település ID": 2201,
        "KSH településazonosító törzsszám": 23320,
        zipCode: 9739,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nemescsó",
        "infó:": ""
      },
      {
        "Település ID": 2202,
        "KSH településazonosító törzsszám": 7913,
        zipCode: 8722,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nemesdéd",
        "infó:": ""
      },
      {
        "Település ID": 2204,
        "KSH településazonosító törzsszám": 5652,
        zipCode: 8522,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nemesgörzsöny",
        "infó:": ""
      },
      {
        "Település ID": 2203,
        "KSH településazonosító törzsszám": 2787,
        zipCode: 8284,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nemesgulács",
        "infó:": ""
      },
      {
        "Település ID": 2205,
        "KSH településazonosító törzsszám": 5555,
        zipCode: 8471,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nemeshany",
        "infó:": ""
      },
      {
        "Település ID": 2206,
        "KSH településazonosító törzsszám": 7658,
        zipCode: 8925,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nemeshetés",
        "infó:": ""
      },
      {
        "Település ID": 2207,
        "KSH településazonosító törzsszám": 18236,
        zipCode: 7981,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nemeske",
        "infó:": ""
      },
      {
        "Település ID": 2208,
        "KSH településazonosító törzsszám": 29328,
        zipCode: 9471,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Nemeskér",
        "infó:": ""
      },
      {
        "Település ID": 2209,
        "KSH településazonosító törzsszám": 3674,
        zipCode: 9548,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nemeskeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 2210,
        "KSH településazonosító törzsszám": 25511,
        zipCode: 8717,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nemeskisfalud",
        "infó:": ""
      },
      {
        "Település ID": 2211,
        "KSH településazonosító törzsszám": 2839,
        zipCode: 9542,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nemeskocs",
        "infó:": ""
      },
      {
        "Település ID": 2212,
        "KSH településazonosító törzsszám": 24059,
        zipCode: 9775,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nemeskolta",
        "infó:": ""
      },
      {
        "Település ID": 2213,
        "KSH településazonosító törzsszám": 26240,
        zipCode: 9663,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nemesládony",
        "infó:": ""
      },
      {
        "Település ID": 2214,
        "KSH településazonosító törzsszám": 31556,
        zipCode: 9953,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nemesmedves",
        "infó:": ""
      },
      {
        "Település ID": 2215,
        "KSH településazonosító törzsszám": 32540,
        zipCode: 6345,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Nemesnádudvar",
        "infó:": ""
      },
      {
        "Település ID": 2216,
        "KSH településazonosító törzsszám": 32665,
        zipCode: 8976,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nemesnép",
        "infó:": ""
      },
      {
        "Település ID": 2217,
        "KSH településazonosító törzsszám": 19859,
        zipCode: 8856,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nemespátró",
        "infó:": ""
      },
      {
        "Település ID": 2218,
        "KSH településazonosító törzsszám": 18023,
        zipCode: 8915,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nemesrádó",
        "infó:": ""
      },
      {
        "Település ID": 2219,
        "KSH településazonosító törzsszám": 9672,
        zipCode: 9782,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nemesrempehollós",
        "infó:": ""
      },
      {
        "Település ID": 2220,
        "KSH településazonosító törzsszám": 25609,
        zipCode: 8925,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nemessándorháza",
        "infó:": ""
      },
      {
        "Település ID": 2221,
        "KSH településazonosító törzsszám": 21759,
        zipCode: 9533,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nemesszalók",
        "infó:": ""
      },
      {
        "Település ID": 2222,
        "KSH településazonosító törzsszám": 8059,
        zipCode: 8925,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nemesszentandrás",
        "infó:": ""
      },
      {
        "Település ID": 2223,
        "KSH településazonosító törzsszám": 2194,
        zipCode: 8248,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nemesvámos",
        "infó:": ""
      },
      {
        "Település ID": 2224,
        "KSH településazonosító törzsszám": 17561,
        zipCode: 8738,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nemesvid",
        "infó:": ""
      },
      {
        "Település ID": 2225,
        "KSH településazonosító törzsszám": 28422,
        zipCode: 8311,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nemesvita",
        "infó:": ""
      },
      {
        "Település ID": 2226,
        "KSH településazonosító törzsszám": 10409,
        zipCode: 8581,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Németbánya",
        "infó:": ""
      },
      {
        "Település ID": 2227,
        "KSH településazonosító törzsszám": 25414,
        zipCode: 8918,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Németfalu",
        "infó:": ""
      },
      {
        "Település ID": 2228,
        "KSH településazonosító törzsszám": 15006,
        zipCode: 7039,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Németkér",
        "infó:": ""
      },
      {
        "Település ID": 2229,
        "KSH településazonosító törzsszám": 27580,
        zipCode: 3152,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nemti",
        "infó:": ""
      },
      {
        "Település ID": 2230,
        "KSH településazonosító törzsszám": 33826,
        zipCode: 2544,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Neszmély",
        "infó:": ""
      },
      {
        "Település ID": 2231,
        "KSH településazonosító törzsszám": 9797,
        zipCode: 2618,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nézsa",
        "infó:": ""
      },
      {
        "Település ID": 2232,
        "KSH településazonosító törzsszám": 10843,
        zipCode: 9652,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Nick",
        "infó:": ""
      },
      {
        "Település ID": 2233,
        "KSH településazonosító törzsszám": 10959,
        zipCode: 8706,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nikla",
        "infó:": ""
      },
      {
        "Település ID": 2234,
        "KSH településazonosító törzsszám": 4358,
        zipCode: 2642,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nógrád",
        "infó:": ""
      },
      {
        "Település ID": 2235,
        "KSH településazonosító törzsszám": 32300,
        zipCode: 2691,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nógrádkövesd",
        "infó:": ""
      },
      {
        "Település ID": 2236,
        "KSH településazonosító törzsszám": 29832,
        zipCode: 2675,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nógrádmarcal",
        "infó:": ""
      },
      {
        "Település ID": 2237,
        "KSH településazonosító törzsszám": 12131,
        zipCode: 3132,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nógrádmegyer",
        "infó:": ""
      },
      {
        "Település ID": 2238,
        "KSH településazonosító törzsszám": 8387,
        zipCode: 2685,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nógrádsáp",
        "infó:": ""
      },
      {
        "Település ID": 2239,
        "KSH településazonosító törzsszám": 19497,
        zipCode: 3179,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nógrádsipek",
        "infó:": ""
      },
      {
        "Település ID": 2240,
        "KSH településazonosító törzsszám": 27340,
        zipCode: 3187,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Nógrádszakál",
        "infó:": ""
      },
      {
        "Település ID": 2241,
        "KSH településazonosító törzsszám": 26523,
        zipCode: 8591,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nóráp",
        "infó:": ""
      },
      {
        "Település ID": 2242,
        "KSH településazonosító törzsszám": 14757,
        zipCode: 8456,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Noszlop",
        "infó:": ""
      },
      {
        "Település ID": 2243,
        "KSH településazonosító törzsszám": 18810,
        zipCode: 3325,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Noszvaj",
        "infó:": ""
      },
      {
        "Település ID": 2244,
        "KSH településazonosító törzsszám": 29425,
        zipCode: 2610,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "N%u0151tincs",
        "infó:": ""
      },
      {
        "Település ID": 2245,
        "KSH településazonosító törzsszám": 3355,
        zipCode: 8948,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Nova",
        "infó:": ""
      },
      {
        "Település ID": 2246,
        "KSH településazonosító törzsszám": 29276,
        zipCode: 3327,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Novaj",
        "infó:": ""
      },
      {
        "Település ID": 2247,
        "KSH településazonosító törzsszám": 27191,
        zipCode: 3872,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Novajidrány",
        "infó:": ""
      },
      {
        "Település ID": 2248,
        "KSH településazonosító törzsszám": 19752,
        zipCode: 9096,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Nyalka",
        "infó:": ""
      },
      {
        "Település ID": 2249,
        "KSH településazonosító törzsszám": 29009,
        zipCode: 8512,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nyárád",
        "infó:": ""
      },
      {
        "Település ID": 2250,
        "KSH településazonosító törzsszám": 23038,
        zipCode: 2723,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Nyáregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2251,
        "KSH településazonosító törzsszám": 23056,
        zipCode: 6032,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Nyárl%u0151rinc",
        "infó:": ""
      },
      {
        "Település ID": 2252,
        "KSH településazonosító törzsszám": 20066,
        zipCode: 2712,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Nyársapát",
        "infó:": ""
      },
      {
        "Település ID": 2253,
        "KSH településazonosító törzsszám": 12885,
        zipCode: 3433,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nyékládháza",
        "infó:": ""
      },
      {
        "Település ID": 2254,
        "KSH településazonosító törzsszám": 15352,
        zipCode: 2536,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Nyergesújfalu",
        "infó:": ""
      },
      {
        "Település ID": 2255,
        "KSH településazonosító törzsszám": 15352,
        zipCode: 2537,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Nyergesújfalu",
        "infó:": ""
      },
      {
        "Település ID": 2256,
        "KSH településazonosító törzsszám": 4419,
        zipCode: 3809,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nyésta",
        "infó:": ""
      },
      {
        "Település ID": 2257,
        "KSH településazonosító törzsszám": 3203,
        zipCode: 8612,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Nyim",
        "infó:": ""
      },
      {
        "Település ID": 2258,
        "KSH településazonosító törzsszám": 32294,
        zipCode: 4264,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nyírábrány",
        "infó:": ""
      },
      {
        "Település ID": 2259,
        "KSH településazonosító törzsszám": 14003,
        zipCode: 4262,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nyíracsád",
        "infó:": ""
      },
      {
        "Település ID": 2260,
        "KSH településazonosító törzsszám": 24004,
        zipCode: 8454,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Nyirád",
        "infó:": ""
      },
      {
        "Település ID": 2261,
        "KSH településazonosító törzsszám": 6187,
        zipCode: 4252,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nyíradony",
        "infó:": ""
      },
      {
        "Település ID": 2262,
        "KSH településazonosító törzsszám": 6187,
        zipCode: 4253,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nyíradony",
        "infó:": ""
      },
      {
        "Település ID": 2263,
        "KSH településazonosító törzsszám": 6187,
        zipCode: 4254,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nyíradony",
        "infó:": ""
      },
      {
        "Település ID": 2264,
        "KSH településazonosító törzsszám": 14845,
        zipCode: 4300,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírbátor",
        "infó:": ""
      },
      {
        "Település ID": 2265,
        "KSH településazonosító törzsszám": 15802,
        zipCode: 4372,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírbéltek",
        "infó:": ""
      },
      {
        "Település ID": 2266,
        "KSH településazonosító törzsszám": 31158,
        zipCode: 4361,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírbogát",
        "infó:": ""
      },
      {
        "Település ID": 2267,
        "KSH településazonosító törzsszám": 28802,
        zipCode: 4511,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírbogdány",
        "infó:": ""
      },
      {
        "Település ID": 2268,
        "KSH településazonosító törzsszám": 7904,
        zipCode: 4356,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírcsaholy",
        "infó:": ""
      },
      {
        "Település ID": 2269,
        "KSH településazonosító törzsszám": 25973,
        zipCode: 4331,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírcsászári",
        "infó:": ""
      },
      {
        "Település ID": 2270,
        "KSH településazonosító törzsszám": 5041,
        zipCode: 4332,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírderzs",
        "infó:": ""
      },
      {
        "Település ID": 2271,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4246,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2272,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4400,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2273,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4405,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2274,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4412,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2275,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4431,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2276,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4432,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2277,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4433,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2278,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4481,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2279,
        "KSH településazonosító törzsszám": 17206,
        zipCode: 4551,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíregyháza",
        "infó:": ""
      },
      {
        "Település ID": 2280,
        "KSH településazonosító törzsszám": 28440,
        zipCode: 4362,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírgelse",
        "infó:": ""
      },
      {
        "Település ID": 2281,
        "KSH településazonosító törzsszám": 9238,
        zipCode: 4311,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírgyulaj",
        "infó:": ""
      },
      {
        "Település ID": 2282,
        "KSH településazonosító törzsszám": 26435,
        zipCode: 3997,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nyíri",
        "infó:": ""
      },
      {
        "Település ID": 2283,
        "KSH településazonosító törzsszám": 14696,
        zipCode: 4535,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyíribrony",
        "infó:": ""
      },
      {
        "Település ID": 2284,
        "KSH településazonosító törzsszám": 31477,
        zipCode: 4541,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírjákó",
        "infó:": ""
      },
      {
        "Település ID": 2285,
        "KSH településazonosító törzsszám": 18290,
        zipCode: 4544,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírkarász",
        "infó:": ""
      },
      {
        "Település ID": 2286,
        "KSH településazonosító törzsszám": 32452,
        zipCode: 4333,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírkáta",
        "infó:": ""
      },
      {
        "Település ID": 2287,
        "KSH településazonosító törzsszám": 25928,
        zipCode: 4537,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírkércs",
        "infó:": ""
      },
      {
        "Település ID": 2289,
        "KSH településazonosító törzsszám": 11095,
        zipCode: 4632,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírlöv%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2288,
        "KSH településazonosító törzsszám": 11271,
        zipCode: 4371,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírlugos",
        "infó:": ""
      },
      {
        "Település ID": 2290,
        "KSH településazonosító törzsszám": 12274,
        zipCode: 4564,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírmada",
        "infó:": ""
      },
      {
        "Település ID": 2291,
        "KSH településazonosító törzsszám": 32382,
        zipCode: 4263,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Nyírmártonfalva",
        "infó:": ""
      },
      {
        "Település ID": 2292,
        "KSH településazonosító törzsszám": 23269,
        zipCode: 4722,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírmeggyes",
        "infó:": ""
      },
      {
        "Település ID": 2293,
        "KSH településazonosító törzsszám": 26365,
        zipCode: 4363,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírmihálydi",
        "infó:": ""
      },
      {
        "Település ID": 2294,
        "KSH településazonosító törzsszám": 10807,
        zipCode: 4822,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírparasznya",
        "infó:": ""
      },
      {
        "Település ID": 2295,
        "KSH településazonosító törzsszám": 33145,
        zipCode: 4531,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírpazony",
        "infó:": ""
      },
      {
        "Település ID": 2296,
        "KSH településazonosító törzsszám": 3878,
        zipCode: 4376,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírpilis",
        "infó:": ""
      },
      {
        "Település ID": 2297,
        "KSH településazonosító törzsszám": 28060,
        zipCode: 4522,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírtass",
        "infó:": ""
      },
      {
        "Település ID": 2298,
        "KSH településazonosító törzsszám": 13550,
        zipCode: 4461,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírtelek",
        "infó:": ""
      },
      {
        "Település ID": 2299,
        "KSH településazonosító törzsszám": 9256,
        zipCode: 4554,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírtét",
        "infó:": ""
      },
      {
        "Település ID": 2300,
        "KSH településazonosító törzsszám": 12098,
        zipCode: 4532,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírtura",
        "infó:": ""
      },
      {
        "Település ID": 2301,
        "KSH településazonosító törzsszám": 16522,
        zipCode: 4341,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Nyírvasvári",
        "infó:": ""
      },
      {
        "Település ID": 2302,
        "KSH településazonosító törzsszám": 22318,
        zipCode: 9682,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Ny%u0151gér",
        "infó:": ""
      },
      {
        "Település ID": 2303,
        "KSH településazonosító törzsszám": 4215,
        zipCode: 3795,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Nyomár",
        "infó:": ""
      },
      {
        "Település ID": 2304,
        "KSH településazonosító törzsszám": 26329,
        zipCode: 7912,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Nyugotszenterzsébet",
        "infó:": ""
      },
      {
        "Település ID": 2305,
        "KSH településazonosító törzsszám": 12955,
        zipCode: 9082,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Nyúl",
        "infó:": ""
      },
      {
        "Település ID": 2306,
        "KSH településazonosító törzsszám": 12645,
        zipCode: 7695,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Óbánya",
        "infó:": ""
      },
      {
        "Település ID": 2307,
        "KSH településazonosító törzsszám": 34306,
        zipCode: 2063,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Óbarok",
        "infó:": ""
      },
      {
        "Település ID": 2308,
        "KSH településazonosító törzsszám": 23913,
        zipCode: 8272,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Óbudavár",
        "infó:": ""
      },
      {
        "Település ID": 3564,
        "KSH településazonosító törzsszám": 28112,
        zipCode: 8292,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Öcs",
        "infó:": ""
      },
      {
        "Település ID": 2309,
        "KSH településazonosító törzsszám": 4075,
        zipCode: 2364,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Ócsa",
        "infó:": ""
      },
      {
        "Település ID": 2310,
        "KSH településazonosító törzsszám": 3780,
        zipCode: 7814,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ócsárd",
        "infó:": ""
      },
      {
        "Település ID": 2311,
        "KSH településazonosító törzsszám": 8961,
        zipCode: 7143,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "%u0150csény",
        "infó:": ""
      },
      {
        "Település ID": 3565,
        "KSH településazonosító törzsszám": 28006,
        zipCode: 5451,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Öcsöd",
        "infó:": ""
      },
      {
        "Település ID": 2312,
        "KSH településazonosító törzsszám": 19646,
        zipCode: 7695,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ófalu",
        "infó:": ""
      },
      {
        "Település ID": 2313,
        "KSH településazonosító törzsszám": 22284,
        zipCode: 4558,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ófehértó",
        "infó:": ""
      },
      {
        "Település ID": 2314,
        "KSH településazonosító törzsszám": 7755,
        zipCode: 6923,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Óföldeák",
        "infó:": ""
      },
      {
        "Település ID": 2315,
        "KSH településazonosító törzsszám": 27775,
        zipCode: 8342,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Óhid",
        "infó:": ""
      },
      {
        "Település ID": 2316,
        "KSH településazonosító törzsszám": 19257,
        zipCode: 5534,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Okány",
        "infó:": ""
      },
      {
        "Település ID": 2317,
        "KSH településazonosító törzsszám": 20686,
        zipCode: 7957,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Okorág",
        "infó:": ""
      },
      {
        "Település ID": 3566,
        "KSH településazonosító törzsszám": 31769,
        zipCode: 4755,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ököritófülpös",
        "infó:": ""
      },
      {
        "Település ID": 2318,
        "KSH településazonosító törzsszám": 8341,
        zipCode: 7681,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Okorvölgy",
        "infó:": ""
      },
      {
        "Település ID": 2319,
        "KSH településazonosító törzsszám": 18555,
        zipCode: 7745,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Olasz",
        "infó:": ""
      },
      {
        "Település ID": 2320,
        "KSH településazonosító törzsszám": 19743,
        zipCode: 9824,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Olaszfa",
        "infó:": ""
      },
      {
        "Település ID": 2321,
        "KSH településazonosító törzsszám": 26514,
        zipCode: 8414,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Olaszfalu",
        "infó:": ""
      },
      {
        "Település ID": 2322,
        "KSH településazonosító törzsszám": 31778,
        zipCode: 3933,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Olaszliszka",
        "infó:": ""
      },
      {
        "Település ID": 3567,
        "KSH településazonosító törzsszám": 12043,
        zipCode: 9621,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Ölb%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2323,
        "KSH településazonosító törzsszám": 11129,
        zipCode: 4826,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Olcsva",
        "infó:": ""
      },
      {
        "Település ID": 2324,
        "KSH településazonosító törzsszám": 10834,
        zipCode: 4914,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Olcsvaapáti",
        "infó:": ""
      },
      {
        "Település ID": 2325,
        "KSH településazonosító törzsszám": 21704,
        zipCode: 7824,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Old",
        "infó:": ""
      },
      {
        "Település ID": 2326,
        "KSH településazonosító törzsszám": 22044,
        zipCode: 9733,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Ólmod",
        "infó:": ""
      },
      {
        "Település ID": 2327,
        "KSH településazonosító törzsszám": 25478,
        zipCode: 8886,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Oltárc",
        "infó:": ""
      },
      {
        "Település ID": 3568,
        "KSH településazonosító törzsszám": 26550,
        zipCode: 4373,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ömböly",
        "infó:": ""
      },
      {
        "Település ID": 2328,
        "KSH településazonosító törzsszám": 22558,
        zipCode: 3562,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Onga",
        "infó:": ""
      },
      {
        "Település ID": 2329,
        "KSH településazonosító törzsszám": 22628,
        zipCode: 3551,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ónod",
        "infó:": ""
      },
      {
        "Település ID": 2330,
        "KSH településazonosító törzsszám": 27924,
        zipCode: 4821,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ópályi",
        "infó:": ""
      },
      {
        "Település ID": 2331,
        "KSH településazonosító törzsszám": 12797,
        zipCode: 6767,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Ópusztaszer",
        "infó:": ""
      },
      {
        "Település ID": 2332,
        "KSH településazonosító törzsszám": 9025,
        zipCode: 4336,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "%u0150r",
        "infó:": ""
      },
      {
        "Település ID": 2333,
        "KSH településazonosító törzsszám": 20835,
        zipCode: 8935,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Orbányosfa",
        "infó:": ""
      },
      {
        "Település ID": 2334,
        "KSH településazonosító törzsszám": 8545,
        zipCode: 2162,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "%u0150rbottyán",
        "infó:": ""
      },
      {
        "Település ID": 2335,
        "KSH településazonosító törzsszám": 24536,
        zipCode: 7400,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Orci",
        "infó:": ""
      },
      {
        "Település ID": 2336,
        "KSH településazonosító törzsszám": 16823,
        zipCode: 8635,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Ordacsehi",
        "infó:": ""
      },
      {
        "Település ID": 2337,
        "KSH településazonosító törzsszám": 16276,
        zipCode: 6335,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Ordas",
        "infó:": ""
      },
      {
        "Település ID": 3569,
        "KSH településazonosító törzsszám": 8679,
        zipCode: 6311,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Öregcsert%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3570,
        "KSH településazonosító törzsszám": 7056,
        zipCode: 8697,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Öreglak",
        "infó:": ""
      },
      {
        "Település ID": 2338,
        "KSH településazonosító törzsszám": 17093,
        zipCode: 9982,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Orfalu",
        "infó:": ""
      },
      {
        "Település ID": 2339,
        "KSH településazonosító törzsszám": 11730,
        zipCode: 7677,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Orf%u0171",
        "infó:": ""
      },
      {
        "Település ID": 2340,
        "KSH településazonosító törzsszám": 16939,
        zipCode: 6077,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Orgovány",
        "infó:": ""
      },
      {
        "Település ID": 2341,
        "KSH településazonosító törzsszám": 3249,
        zipCode: 2671,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "%u0150rhalom",
        "infó:": ""
      },
      {
        "Település ID": 2342,
        "KSH településazonosító törzsszám": 13453,
        zipCode: 9933,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "%u0150rimagyarósd",
        "infó:": ""
      },
      {
        "Település ID": 2343,
        "KSH településazonosító törzsszám": 10630,
        zipCode: 9941,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "%u0150riszentpéter",
        "infó:": ""
      },
      {
        "Település ID": 3571,
        "KSH településazonosító törzsszám": 5281,
        zipCode: 2377,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Örkény",
        "infó:": ""
      },
      {
        "Település ID": 2344,
        "KSH településazonosító törzsszám": 22497,
        zipCode: 8983,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Ormándlak",
        "infó:": ""
      },
      {
        "Település ID": 3572,
        "KSH településazonosító törzsszám": 29382,
        zipCode: 5222,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Örményes",
        "infó:": ""
      },
      {
        "Település ID": 3573,
        "KSH településazonosító törzsszám": 27438,
        zipCode: 5556,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Örménykút",
        "infó:": ""
      },
      {
        "Település ID": 2345,
        "KSH településazonosító törzsszám": 34069,
        zipCode: 3743,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ormosbánya",
        "infó:": ""
      },
      {
        "Település ID": 2346,
        "KSH településazonosító törzsszám": 23065,
        zipCode: 5900,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Orosháza",
        "infó:": ""
      },
      {
        "Település ID": 2347,
        "KSH településazonosító törzsszám": 23065,
        zipCode: 5903,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Orosháza",
        "infó:": ""
      },
      {
        "Település ID": 2348,
        "KSH településazonosító törzsszám": 23065,
        zipCode: 5904,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Orosháza",
        "infó:": ""
      },
      {
        "Település ID": 2349,
        "KSH településazonosító törzsszám": 23065,
        zipCode: 5905,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Orosháza",
        "infó:": ""
      },
      {
        "Település ID": 2350,
        "KSH településazonosító törzsszám": 16595,
        zipCode: 8458,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Oroszi",
        "infó:": ""
      },
      {
        "Település ID": 2351,
        "KSH településazonosító törzsszám": 30766,
        zipCode: 2840,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Oroszlány",
        "infó:": ""
      },
      {
        "Település ID": 2352,
        "KSH településazonosító törzsszám": 4482,
        zipCode: 7370,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Oroszló",
        "infó:": ""
      },
      {
        "Település ID": 2353,
        "KSH településazonosító törzsszám": 25052,
        zipCode: 8744,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Orosztony",
        "infó:": ""
      },
      {
        "Település ID": 2354,
        "KSH településazonosító törzsszám": 4950,
        zipCode: 8954,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Ortaháza",
        "infó:": ""
      },
      {
        "Település ID": 2355,
        "KSH településazonosító törzsszám": 14012,
        zipCode: 8854,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "%u0150rtilos",
        "infó:": ""
      },
      {
        "Település ID": 3574,
        "KSH településazonosító törzsszám": 10092,
        zipCode: 8242,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Örvényes",
        "infó:": ""
      },
      {
        "Település ID": 2356,
        "KSH településazonosító törzsszám": 19318,
        zipCode: 2610,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "%u0150sagárd",
        "infó:": ""
      },
      {
        "Település ID": 2357,
        "KSH településazonosító törzsszám": 24068,
        zipCode: 8161,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "%u0150si",
        "infó:": ""
      },
      {
        "Település ID": 3575,
        "KSH településazonosító törzsszám": 25450,
        zipCode: 8191,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Öskü",
        "infó:": ""
      },
      {
        "Település ID": 2358,
        "KSH településazonosító törzsszám": 15121,
        zipCode: 9354,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Osli",
        "infó:": ""
      },
      {
        "Település ID": 2359,
        "KSH településazonosító törzsszám": 32629,
        zipCode: 9512,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Ostffyasszonyfa",
        "infó:": ""
      },
      {
        "Település ID": 2360,
        "KSH településazonosító törzsszám": 27216,
        zipCode: 3326,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Ostoros",
        "infó:": ""
      },
      {
        "Település ID": 2361,
        "KSH településazonosító törzsszám": 7667,
        zipCode: 9825,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Oszkó",
        "infó:": ""
      },
      {
        "Település ID": 2362,
        "KSH településazonosító törzsszám": 2866,
        zipCode: 3591,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Oszlár",
        "infó:": ""
      },
      {
        "Település ID": 2363,
        "KSH településazonosító törzsszám": 19770,
        zipCode: 7444,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Osztopán",
        "infó:": ""
      },
      {
        "Település ID": 3576,
        "KSH településazonosító törzsszám": 2635,
        zipCode: 9153,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Öttevény",
        "infó:": ""
      },
      {
        "Település ID": 3577,
        "KSH településazonosító törzsszám": 31079,
        zipCode: 6784,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Öttömös",
        "infó:": ""
      },
      {
        "Település ID": 3578,
        "KSH településazonosító törzsszám": 32115,
        zipCode: 7511,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Ötvöskónyi",
        "infó:": ""
      },
      {
        "Település ID": 2364,
        "KSH településazonosító törzsszám": 14492,
        zipCode: 3600,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ózd",
        "infó:": ""
      },
      {
        "Település ID": 2365,
        "KSH településazonosító törzsszám": 14492,
        zipCode: 3603,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ózd",
        "infó:": ""
      },
      {
        "Település ID": 2366,
        "KSH településazonosító törzsszám": 14492,
        zipCode: 3604,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ózd",
        "infó:": ""
      },
      {
        "Település ID": 2367,
        "KSH településazonosító törzsszám": 14492,
        zipCode: 3621,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ózd",
        "infó:": ""
      },
      {
        "Település ID": 2368,
        "KSH településazonosító törzsszám": 14492,
        zipCode: 3625,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ózd",
        "infó:": ""
      },
      {
        "Település ID": 2369,
        "KSH településazonosító törzsszám": 14492,
        zipCode: 3651,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ózd",
        "infó:": ""
      },
      {
        "Település ID": 2370,
        "KSH településazonosító törzsszám": 14492,
        zipCode: 3661,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ózd",
        "infó:": ""
      },
      {
        "Település ID": 2371,
        "KSH településazonosító törzsszám": 14492,
        zipCode: 3662,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ózd",
        "infó:": ""
      },
      {
        "Település ID": 2372,
        "KSH településazonosító törzsszám": 18661,
        zipCode: 7836,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Ózdfalu",
        "infó:": ""
      },
      {
        "Település ID": 2373,
        "KSH településazonosító törzsszám": 18953,
        zipCode: 8998,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Ozmánbük",
        "infó:": ""
      },
      {
        "Település ID": 2374,
        "KSH településazonosító törzsszám": 5661,
        zipCode: 7086,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Ozora",
        "infó:": ""
      },
      {
        "Település ID": 2375,
        "KSH településazonosító törzsszám": 15893,
        zipCode: 3964,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Pácin",
        "infó:": ""
      },
      {
        "Település ID": 2376,
        "KSH településazonosító törzsszám": 31741,
        zipCode: 8761,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pacsa",
        "infó:": ""
      },
      {
        "Település ID": 2377,
        "KSH településazonosító törzsszám": 7162,
        zipCode: 9823,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pácsony",
        "infó:": ""
      },
      {
        "Település ID": 2378,
        "KSH településazonosító törzsszám": 24776,
        zipCode: 8935,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Padár",
        "infó:": ""
      },
      {
        "Település ID": 2379,
        "KSH településazonosító törzsszám": 18670,
        zipCode: 6075,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Páhi",
        "infó:": ""
      },
      {
        "Település ID": 2380,
        "KSH településazonosító törzsszám": 29160,
        zipCode: 8956,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Páka",
        "infó:": ""
      },
      {
        "Település ID": 2381,
        "KSH településazonosító törzsszám": 24271,
        zipCode: 8799,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pakod",
        "infó:": ""
      },
      {
        "Település ID": 2382,
        "KSH településazonosító törzsszám": 25751,
        zipCode: 8095,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Pákozd",
        "infó:": ""
      },
      {
        "Település ID": 2383,
        "KSH településazonosító törzsszám": 4862,
        zipCode: 7027,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Paks",
        "infó:": ""
      },
      {
        "Település ID": 2384,
        "KSH településazonosító törzsszám": 4862,
        zipCode: 7030,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Paks",
        "infó:": ""
      },
      {
        "Település ID": 2385,
        "KSH településazonosító törzsszám": 23764,
        zipCode: 7370,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Palé",
        "infó:": ""
      },
      {
        "Település ID": 2386,
        "KSH településazonosító törzsszám": 9371,
        zipCode: 7042,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Pálfa",
        "infó:": ""
      },
      {
        "Település ID": 2387,
        "KSH településazonosító törzsszám": 14720,
        zipCode: 8990,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pálfiszeg",
        "infó:": ""
      },
      {
        "Település ID": 2388,
        "KSH településazonosító törzsszám": 12362,
        zipCode: 3994,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Pálháza",
        "infó:": ""
      },
      {
        "Település ID": 2389,
        "KSH településazonosító törzsszám": 19637,
        zipCode: 9345,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Páli",
        "infó:": ""
      },
      {
        "Település ID": 2390,
        "KSH településazonosító törzsszám": 7153,
        zipCode: 7771,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Palkonya",
        "infó:": ""
      },
      {
        "Település ID": 2391,
        "KSH településazonosító törzsszám": 34041,
        zipCode: 7561,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Pálmajor",
        "infó:": ""
      },
      {
        "Település ID": 2392,
        "KSH településazonosító törzsszám": 2705,
        zipCode: 6112,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Pálmonostora",
        "infó:": ""
      },
      {
        "Település ID": 2393,
        "KSH településazonosító törzsszám": 10135,
        zipCode: 7727,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Palotabozsok",
        "infó:": ""
      },
      {
        "Település ID": 2394,
        "KSH településazonosító törzsszám": 5883,
        zipCode: 3042,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Palotás",
        "infó:": ""
      },
      {
        "Település ID": 2395,
        "KSH településazonosító törzsszám": 17011,
        zipCode: 8229,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Paloznak",
        "infó:": ""
      },
      {
        "Település ID": 2396,
        "KSH településazonosító törzsszám": 12371,
        zipCode: 3821,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Pamlény",
        "infó:": ""
      },
      {
        "Település ID": 2397,
        "KSH településazonosító törzsszám": 26392,
        zipCode: 8698,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Pamuk",
        "infó:": ""
      },
      {
        "Település ID": 2398,
        "KSH településazonosító törzsszám": 22248,
        zipCode: 2214,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pánd",
        "infó:": ""
      },
      {
        "Település ID": 2399,
        "KSH településazonosító törzsszám": 23108,
        zipCode: 9937,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pankasz",
        "infó:": ""
      },
      {
        "Település ID": 2400,
        "KSH településazonosító törzsszám": 24305,
        zipCode: 9090,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Pannonhalma",
        "infó:": ""
      },
      {
        "Település ID": 2401,
        "KSH településazonosító törzsszám": 24730,
        zipCode: 3898,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Pányok",
        "infó:": ""
      },
      {
        "Település ID": 2402,
        "KSH településazonosító törzsszám": 29559,
        zipCode: 4913,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Panyola",
        "infó:": ""
      },
      {
        "Település ID": 2403,
        "KSH településazonosító törzsszám": 27748,
        zipCode: 4631,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Pap",
        "infó:": ""
      },
      {
        "Település ID": 2404,
        "KSH településazonosító törzsszám": 31945,
        zipCode: 8500,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pápa",
        "infó:": ""
      },
      {
        "Település ID": 2405,
        "KSH településazonosító törzsszám": 31945,
        zipCode: 8511,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pápa",
        "infó:": ""
      },
      {
        "Település ID": 2406,
        "KSH településazonosító törzsszám": 31945,
        zipCode: 8591,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pápa",
        "infó:": ""
      },
      {
        "Település ID": 2407,
        "KSH településazonosító törzsszám": 31945,
        zipCode: 8598,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pápa",
        "infó:": ""
      },
      {
        "Település ID": 2408,
        "KSH településazonosító törzsszám": 19868,
        zipCode: 8593,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pápadereske",
        "infó:": ""
      },
      {
        "Település ID": 2409,
        "KSH településazonosító törzsszám": 11855,
        zipCode: 8596,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pápakovácsi",
        "infó:": ""
      },
      {
        "Település ID": 2410,
        "KSH településazonosító törzsszám": 21607,
        zipCode: 8594,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pápasalamon",
        "infó:": ""
      },
      {
        "Település ID": 2411,
        "KSH településazonosító törzsszám": 31255,
        zipCode: 8556,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pápateszér",
        "infó:": ""
      },
      {
        "Település ID": 2412,
        "KSH településazonosító törzsszám": 7348,
        zipCode: 8183,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Papkeszi",
        "infó:": ""
      },
      {
        "Település ID": 2413,
        "KSH településazonosító törzsszám": 14067,
        zipCode: 9515,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pápoc",
        "infó:": ""
      },
      {
        "Település ID": 2414,
        "KSH településazonosító törzsszám": 32577,
        zipCode: 4338,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Papos",
        "infó:": ""
      },
      {
        "Település ID": 2415,
        "KSH településazonosító törzsszám": 7010,
        zipCode: 7838,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Páprád",
        "infó:": ""
      },
      {
        "Település ID": 2416,
        "KSH településazonosító törzsszám": 7436,
        zipCode: 3240,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Parád",
        "infó:": ""
      },
      {
        "Település ID": 2417,
        "KSH településazonosító törzsszám": 7436,
        zipCode: 3244,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Parád",
        "infó:": ""
      },
      {
        "Település ID": 2418,
        "KSH településazonosító törzsszám": 20215,
        zipCode: 3242,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Parádsasvár",
        "infó:": ""
      },
      {
        "Település ID": 2419,
        "KSH településazonosító törzsszám": 26745,
        zipCode: 3777,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Parasznya",
        "infó:": ""
      },
      {
        "Település ID": 2420,
        "KSH településazonosító törzsszám": 31972,
        zipCode: 4475,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Paszab",
        "infó:": ""
      },
      {
        "Település ID": 2421,
        "KSH településazonosító törzsszám": 7409,
        zipCode: 3060,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Pásztó",
        "infó:": ""
      },
      {
        "Település ID": 2422,
        "KSH településazonosító törzsszám": 7409,
        zipCode: 3065,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Pásztó",
        "infó:": ""
      },
      {
        "Település ID": 2423,
        "KSH településazonosító törzsszám": 7409,
        zipCode: 3082,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Pásztó",
        "infó:": ""
      },
      {
        "Település ID": 2424,
        "KSH településazonosító törzsszám": 23481,
        zipCode: 9311,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Pásztori",
        "infó:": ""
      },
      {
        "Település ID": 2425,
        "KSH településazonosító törzsszám": 29656,
        zipCode: 8825,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pat",
        "infó:": ""
      },
      {
        "Település ID": 2426,
        "KSH településazonosító törzsszám": 7199,
        zipCode: 2648,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Patak",
        "infó:": ""
      },
      {
        "Település ID": 2427,
        "KSH településazonosító törzsszám": 6141,
        zipCode: 7463,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Patalom",
        "infó:": ""
      },
      {
        "Település ID": 2428,
        "KSH településazonosító törzsszám": 17792,
        zipCode: 7923,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Patapoklosi",
        "infó:": ""
      },
      {
        "Település ID": 2429,
        "KSH településazonosító törzsszám": 12168,
        zipCode: 7477,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Patca",
        "infó:": ""
      },
      {
        "Település ID": 2430,
        "KSH településazonosító törzsszám": 28848,
        zipCode: 8092,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Pátka",
        "infó:": ""
      },
      {
        "Település ID": 2431,
        "KSH településazonosító törzsszám": 15592,
        zipCode: 7536,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Patosfa",
        "infó:": ""
      },
      {
        "Település ID": 2432,
        "KSH településazonosító törzsszám": 12186,
        zipCode: 4523,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Pátroha",
        "infó:": ""
      },
      {
        "Település ID": 2433,
        "KSH településazonosító törzsszám": 33880,
        zipCode: 2668,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Patvarc",
        "infó:": ""
      },
      {
        "Település ID": 2434,
        "KSH településazonosító törzsszám": 15024,
        zipCode: 2071,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Páty",
        "infó:": ""
      },
      {
        "Település ID": 2435,
        "KSH településazonosító törzsszám": 23685,
        zipCode: 4766,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Pátyod",
        "infó:": ""
      },
      {
        "Település ID": 2436,
        "KSH településazonosító törzsszám": 21786,
        zipCode: 2476,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Pázmánd",
        "infó:": ""
      },
      {
        "Település ID": 2437,
        "KSH településazonosító törzsszám": 12715,
        zipCode: 9085,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Pázmándfalu",
        "infó:": ""
      },
      {
        "Település ID": 2438,
        "KSH településazonosító törzsszám": 4057,
        zipCode: 2119,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pécel",
        "infó:": ""
      },
      {
        "Település ID": 2463,
        "KSH településazonosító törzsszám": 14988,
        zipCode: 9754,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pecöl",
        "infó:": ""
      },
      {
        "Település ID": 2439,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7600,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2440,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7621,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2441,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7622,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2442,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7623,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2443,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7624,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2444,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7625,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2445,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7626,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2446,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7627,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2447,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7628,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2448,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7629,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2449,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7630,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2450,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7631,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2451,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7632,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2452,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7633,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2453,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7634,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2454,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7635,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2455,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7636,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2456,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7691,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2457,
        "KSH településazonosító törzsszám": 19415,
        zipCode: 7693,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécs",
        "infó:": ""
      },
      {
        "Település ID": 2458,
        "KSH településazonosító törzsszám": 21519,
        zipCode: 7951,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécsbagota",
        "infó:": ""
      },
      {
        "Település ID": 2459,
        "KSH településazonosító törzsszám": 15389,
        zipCode: 7766,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécsdevecser",
        "infó:": ""
      },
      {
        "Település ID": 2460,
        "KSH településazonosító törzsszám": 22451,
        zipCode: 8245,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pécsely",
        "infó:": ""
      },
      {
        "Település ID": 2461,
        "KSH településazonosító törzsszám": 21096,
        zipCode: 7762,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécsudvard",
        "infó:": ""
      },
      {
        "Település ID": 2462,
        "KSH településazonosító törzsszám": 10825,
        zipCode: 7720,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pécsvárad",
        "infó:": ""
      },
      {
        "Település ID": 2464,
        "KSH településazonosító törzsszám": 16115,
        zipCode: 7831,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pellérd",
        "infó:": ""
      },
      {
        "Település ID": 2465,
        "KSH településazonosító törzsszám": 19567,
        zipCode: 3381,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Pély",
        "infó:": ""
      },
      {
        "Település ID": 2466,
        "KSH településazonosító törzsszám": 18689,
        zipCode: 2614,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Penc",
        "infó:": ""
      },
      {
        "Település ID": 2467,
        "KSH településazonosító törzsszám": 17084,
        zipCode: 4267,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Penészlek",
        "infó:": ""
      },
      {
        "Település ID": 2468,
        "KSH településazonosító törzsszám": 32692,
        zipCode: 4941,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Penyige",
        "infó:": ""
      },
      {
        "Település ID": 2469,
        "KSH településazonosító törzsszám": 15547,
        zipCode: 8426,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pénzesgy%u0151r",
        "infó:": ""
      },
      {
        "Település ID": 2470,
        "KSH településazonosító törzsszám": 15529,
        zipCode: 9099,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Pér",
        "infó:": ""
      },
      {
        "Település ID": 2471,
        "KSH településazonosító törzsszám": 28185,
        zipCode: 2074,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Perbál",
        "infó:": ""
      },
      {
        "Település ID": 2472,
        "KSH településazonosító törzsszám": 24420,
        zipCode: 3853,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Pere",
        "infó:": ""
      },
      {
        "Település ID": 2473,
        "KSH településazonosító törzsszám": 32683,
        zipCode: 3821,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Perecse",
        "infó:": ""
      },
      {
        "Település ID": 2474,
        "KSH településazonosító törzsszám": 12867,
        zipCode: 7664,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pereked",
        "infó:": ""
      },
      {
        "Település ID": 2475,
        "KSH településazonosító törzsszám": 13684,
        zipCode: 9722,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Perenye",
        "infó:": ""
      },
      {
        "Település ID": 2476,
        "KSH településazonosító törzsszám": 8882,
        zipCode: 9734,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Peresznye",
        "infó:": ""
      },
      {
        "Település ID": 2477,
        "KSH településazonosító törzsszám": 23773,
        zipCode: 9484,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Pereszteg",
        "infó:": ""
      },
      {
        "Település ID": 2478,
        "KSH településazonosító törzsszám": 19354,
        zipCode: 2431,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Perkáta",
        "infó:": ""
      },
      {
        "Település ID": 2479,
        "KSH településazonosító törzsszám": 33419,
        zipCode: 3756,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Perkupa",
        "infó:": ""
      },
      {
        "Település ID": 2480,
        "KSH településazonosító törzsszám": 4181,
        zipCode: 2637,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Per%u0151csény",
        "infó:": ""
      },
      {
        "Település ID": 2481,
        "KSH településazonosító törzsszám": 32425,
        zipCode: 7766,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Peterd",
        "infó:": ""
      },
      {
        "Település ID": 2482,
        "KSH településazonosító törzsszám": 29197,
        zipCode: 7582,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Péterhida",
        "infó:": ""
      },
      {
        "Település ID": 2483,
        "KSH településazonosító törzsszám": 21847,
        zipCode: 2209,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Péteri",
        "infó:": ""
      },
      {
        "Település ID": 2484,
        "KSH településazonosító törzsszám": 12070,
        zipCode: 3250,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Pétervására",
        "infó:": ""
      },
      {
        "Település ID": 2485,
        "KSH településazonosító törzsszám": 34254,
        zipCode: 8105,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pétfürd%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2486,
        "KSH településazonosító törzsszám": 5704,
        zipCode: 8921,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Peth%u0151henye",
        "infó:": ""
      },
      {
        "Település ID": 2487,
        "KSH településazonosító törzsszám": 17224,
        zipCode: 4542,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Petneháza",
        "infó:": ""
      },
      {
        "Település ID": 2488,
        "KSH településazonosító törzsszám": 33686,
        zipCode: 3023,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Pet%u0151fibánya",
        "infó:": ""
      },
      {
        "Település ID": 2489,
        "KSH településazonosító törzsszám": 15431,
        zipCode: 6113,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Pet%u0151fiszállás",
        "infó:": ""
      },
      {
        "Település ID": 2490,
        "KSH településazonosító törzsszám": 22831,
        zipCode: 9443,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Pet%u0151háza",
        "infó:": ""
      },
      {
        "Település ID": 2491,
        "KSH településazonosító törzsszám": 11572,
        zipCode: 9826,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pet%u0151mihályfa",
        "infó:": ""
      },
      {
        "Település ID": 2492,
        "KSH településazonosító törzsszám": 19178,
        zipCode: 8984,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Petrikeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 2493,
        "KSH településazonosító törzsszám": 30757,
        zipCode: 8866,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Petrivente",
        "infó:": ""
      },
      {
        "Település ID": 2494,
        "KSH településazonosító törzsszám": 29762,
        zipCode: 7980,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pettend",
        "infó:": ""
      },
      {
        "Település ID": 2495,
        "KSH településazonosító törzsszám": 11590,
        zipCode: 3134,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Piliny",
        "infó:": ""
      },
      {
        "Település ID": 2496,
        "KSH településazonosító törzsszám": 9821,
        zipCode: 2721,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilis",
        "infó:": ""
      },
      {
        "Település ID": 2497,
        "KSH településazonosító törzsszám": 29601,
        zipCode: 2097,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilisborosjen%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2498,
        "KSH településazonosító törzsszám": 7144,
        zipCode: 2081,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Piliscsaba",
        "infó:": ""
      },
      {
        "Település ID": 2499,
        "KSH településazonosító törzsszám": 7144,
        zipCode: 2087,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Piliscsaba",
        "infó:": ""
      },
      {
        "Település ID": 2500,
        "KSH településazonosító törzsszám": 21874,
        zipCode: 2519,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Piliscsév",
        "infó:": ""
      },
      {
        "Település ID": 2501,
        "KSH településazonosító törzsszám": 34148,
        zipCode: 2080,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilisjászfalu",
        "infó:": ""
      },
      {
        "Település ID": 2502,
        "KSH településazonosító törzsszám": 14669,
        zipCode: 2028,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Pilismarót",
        "infó:": ""
      },
      {
        "Település ID": 2503,
        "KSH településazonosító törzsszám": 5290,
        zipCode: 2095,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilisszántó",
        "infó:": ""
      },
      {
        "Település ID": 2504,
        "KSH településazonosító törzsszám": 11396,
        zipCode: 2084,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilisszentiván",
        "infó:": ""
      },
      {
        "Település ID": 2505,
        "KSH településazonosító törzsszám": 18731,
        zipCode: 2098,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilisszentkereszt",
        "infó:": ""
      },
      {
        "Település ID": 2506,
        "KSH településazonosító törzsszám": 18731,
        zipCode: 2099,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilisszentkereszt",
        "infó:": ""
      },
      {
        "Település ID": 2507,
        "KSH településazonosító törzsszám": 8457,
        zipCode: 2009,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilisszentlászló",
        "infó:": ""
      },
      {
        "Település ID": 2508,
        "KSH településazonosító törzsszám": 14340,
        zipCode: 2085,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pilisvörösvár",
        "infó:": ""
      },
      {
        "Település ID": 2509,
        "KSH településazonosító törzsszám": 19585,
        zipCode: 7084,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Pincehely",
        "infó:": ""
      },
      {
        "Település ID": 2510,
        "KSH településazonosító törzsszám": 6248,
        zipCode: 9922,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pinkamindszent",
        "infó:": ""
      },
      {
        "Település ID": 2511,
        "KSH településazonosító törzsszám": 14687,
        zipCode: 9481,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Pinnye",
        "infó:": ""
      },
      {
        "Település ID": 2512,
        "KSH településazonosító törzsszám": 3391,
        zipCode: 4375,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Piricse",
        "infó:": ""
      },
      {
        "Település ID": 2513,
        "KSH településazonosító törzsszám": 15398,
        zipCode: 6414,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Pirtó",
        "infó:": ""
      },
      {
        "Település ID": 2514,
        "KSH településazonosító törzsszám": 23506,
        zipCode: 7838,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Piskó",
        "infó:": ""
      },
      {
        "Település ID": 2515,
        "KSH településazonosító törzsszám": 6284,
        zipCode: 6914,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Pitvaros",
        "infó:": ""
      },
      {
        "Település ID": 2516,
        "KSH településazonosító törzsszám": 22266,
        zipCode: 7756,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pócsa",
        "infó:": ""
      },
      {
        "Település ID": 2517,
        "KSH településazonosító törzsszám": 11837,
        zipCode: 4125,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Pocsaj",
        "infó:": ""
      },
      {
        "Település ID": 2518,
        "KSH településazonosító törzsszám": 4905,
        zipCode: 2017,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pócsmegyer",
        "infó:": ""
      },
      {
        "Település ID": 2519,
        "KSH településazonosító törzsszám": 11244,
        zipCode: 4327,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Pócspetri",
        "infó:": ""
      },
      {
        "Település ID": 2520,
        "KSH településazonosító törzsszám": 17242,
        zipCode: 7666,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Pogány",
        "infó:": ""
      },
      {
        "Település ID": 2521,
        "KSH településazonosító törzsszám": 27553,
        zipCode: 8728,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Pogányszentpéter",
        "infó:": ""
      },
      {
        "Település ID": 2522,
        "KSH településazonosító törzsszám": 21050,
        zipCode: 8932,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pókaszepetk",
        "infó:": ""
      },
      {
        "Település ID": 2523,
        "KSH településazonosító törzsszám": 13790,
        zipCode: 7458,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Polány",
        "infó:": ""
      },
      {
        "Település ID": 2524,
        "KSH településazonosító törzsszám": 23117,
        zipCode: 4090,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Polgár",
        "infó:": ""
      },
      {
        "Település ID": 2525,
        "KSH településazonosító törzsszám": 17525,
        zipCode: 8153,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Polgárdi",
        "infó:": ""
      },
      {
        "Település ID": 2526,
        "KSH településazonosító törzsszám": 17525,
        zipCode: 8154,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Polgárdi",
        "infó:": ""
      },
      {
        "Település ID": 2527,
        "KSH településazonosító törzsszám": 17525,
        zipCode: 8155,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Polgárdi",
        "infó:": ""
      },
      {
        "Település ID": 2572,
        "KSH településazonosító törzsszám": 9867,
        zipCode: 8929,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pölöske",
        "infó:": ""
      },
      {
        "Település ID": 2573,
        "KSH településazonosító törzsszám": 31112,
        zipCode: 8773,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pölöskef%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2528,
        "KSH településazonosító törzsszám": 6372,
        zipCode: 2013,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pomáz",
        "infó:": ""
      },
      {
        "Település ID": 2574,
        "KSH településazonosító törzsszám": 33570,
        zipCode: 7142,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Pörböly",
        "infó:": ""
      },
      {
        "Település ID": 2529,
        "KSH településazonosító törzsszám": 17215,
        zipCode: 4761,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Porcsalma",
        "infó:": ""
      },
      {
        "Település ID": 2575,
        "KSH településazonosító törzsszám": 32513,
        zipCode: 8956,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pördefölde",
        "infó:": ""
      },
      {
        "Település ID": 2530,
        "KSH településazonosító törzsszám": 20367,
        zipCode: 9796,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pornóapáti",
        "infó:": ""
      },
      {
        "Település ID": 2531,
        "KSH településazonosító törzsszám": 22196,
        zipCode: 3388,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Poroszló",
        "infó:": ""
      },
      {
        "Település ID": 2532,
        "KSH településazonosító törzsszám": 29896,
        zipCode: 9612,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Porpác",
        "infó:": ""
      },
      {
        "Település ID": 2533,
        "KSH településazonosító törzsszám": 11828,
        zipCode: 8858,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Porrog",
        "infó:": ""
      },
      {
        "Település ID": 2534,
        "KSH településazonosító törzsszám": 13930,
        zipCode: 8858,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Porrogszentkirály",
        "infó:": ""
      },
      {
        "Település ID": 2535,
        "KSH településazonosító törzsszám": 28510,
        zipCode: 8858,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Porrogszentpál",
        "infó:": ""
      },
      {
        "Település ID": 2536,
        "KSH településazonosító törzsszám": 16850,
        zipCode: 8986,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pórszombat",
        "infó:": ""
      },
      {
        "Település ID": 2537,
        "KSH településazonosító törzsszám": 23515,
        zipCode: 8429,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Porva",
        "infó:": ""
      },
      {
        "Település ID": 2538,
        "KSH településazonosító törzsszám": 15671,
        zipCode: 9636,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pósfa",
        "infó:": ""
      },
      {
        "Település ID": 2539,
        "KSH településazonosító törzsszám": 28361,
        zipCode: 7977,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Potony",
        "infó:": ""
      },
      {
        "Település ID": 2576,
        "KSH településazonosító törzsszám": 24660,
        zipCode: 8767,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pötréte",
        "infó:": ""
      },
      {
        "Település ID": 2540,
        "KSH településazonosító törzsszám": 27304,
        zipCode: 9324,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Potyond",
        "infó:": ""
      },
      {
        "Település ID": 2541,
        "KSH településazonosító törzsszám": 21272,
        zipCode: 3925,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Prügy",
        "infó:": ""
      },
      {
        "Település ID": 2542,
        "KSH településazonosító törzsszám": 11420,
        zipCode: 8291,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pula",
        "infó:": ""
      },
      {
        "Település ID": 2567,
        "KSH településazonosító törzsszám": 12964,
        zipCode: 9235,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Püski",
        "infó:": ""
      },
      {
        "Település ID": 2568,
        "KSH településazonosító törzsszám": 21388,
        zipCode: 2682,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Püspökhatvan",
        "infó:": ""
      },
      {
        "Település ID": 2569,
        "KSH településazonosító törzsszám": 10162,
        zipCode: 4150,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Püspökladány",
        "infó:": ""
      },
      {
        "Település ID": 2570,
        "KSH településazonosító törzsszám": 7278,
        zipCode: 9776,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Püspökmolnári",
        "infó:": ""
      },
      {
        "Település ID": 2571,
        "KSH településazonosító törzsszám": 4303,
        zipCode: 2166,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Püspökszilágy",
        "infó:": ""
      },
      {
        "Település ID": 2543,
        "KSH településazonosító törzsszám": 16920,
        zipCode: 8986,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pusztaapáti",
        "infó:": ""
      },
      {
        "Település ID": 2544,
        "KSH településazonosító törzsszám": 17862,
        zipCode: 2658,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Pusztaberki",
        "infó:": ""
      },
      {
        "Település ID": 2545,
        "KSH településazonosító törzsszám": 31370,
        zipCode: 9373,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Pusztacsalád",
        "infó:": ""
      },
      {
        "Település ID": 2546,
        "KSH településazonosító törzsszám": 10311,
        zipCode: 9739,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Pusztacsó",
        "infó:": ""
      },
      {
        "Település ID": 2547,
        "KSH településazonosító törzsszám": 13860,
        zipCode: 4565,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Pusztadobos",
        "infó:": ""
      },
      {
        "Település ID": 2548,
        "KSH településazonosító törzsszám": 27465,
        zipCode: 8946,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pusztaederics",
        "infó:": ""
      },
      {
        "Település ID": 2549,
        "KSH településazonosító törzsszám": 17048,
        zipCode: 3995,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Pusztafalu",
        "infó:": ""
      },
      {
        "Település ID": 2550,
        "KSH településazonosító törzsszám": 5397,
        zipCode: 5919,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Pusztaföldvár",
        "infó:": ""
      },
      {
        "Település ID": 2551,
        "KSH településazonosító törzsszám": 19938,
        zipCode: 7038,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Pusztahencse",
        "infó:": ""
      },
      {
        "Település ID": 2552,
        "KSH településazonosító törzsszám": 19026,
        zipCode: 8707,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Pusztakovácsi",
        "infó:": ""
      },
      {
        "Település ID": 2553,
        "KSH településazonosító törzsszám": 6530,
        zipCode: 8895,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pusztamagyaród",
        "infó:": ""
      },
      {
        "Település ID": 2554,
        "KSH településazonosító törzsszám": 6354,
        zipCode: 6785,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Pusztamérges",
        "infó:": ""
      },
      {
        "Település ID": 2555,
        "KSH településazonosító törzsszám": 28972,
        zipCode: 8455,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Pusztamiske",
        "infó:": ""
      },
      {
        "Település ID": 2556,
        "KSH településazonosító törzsszám": 15246,
        zipCode: 5125,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Pusztamonostor",
        "infó:": ""
      },
      {
        "Település ID": 2557,
        "KSH településazonosító törzsszám": 19594,
        zipCode: 5665,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Pusztaottlaka",
        "infó:": ""
      },
      {
        "Település ID": 2558,
        "KSH településazonosító törzsszám": 15413,
        zipCode: 3874,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Pusztaradvány",
        "infó:": ""
      },
      {
        "Település ID": 2559,
        "KSH településazonosító törzsszám": 29018,
        zipCode: 2490,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Pusztaszabolcs",
        "infó:": ""
      },
      {
        "Település ID": 2560,
        "KSH településazonosító törzsszám": 23311,
        zipCode: 8619,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Pusztaszemes",
        "infó:": ""
      },
      {
        "Település ID": 2561,
        "KSH településazonosító törzsszám": 26639,
        zipCode: 8896,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Pusztaszentlászló",
        "infó:": ""
      },
      {
        "Település ID": 2562,
        "KSH településazonosító törzsszám": 28592,
        zipCode: 6769,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Pusztaszer",
        "infó:": ""
      },
      {
        "Település ID": 2563,
        "KSH településazonosító törzsszám": 23083,
        zipCode: 2378,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pusztavacs",
        "infó:": ""
      },
      {
        "Település ID": 2564,
        "KSH településazonosító törzsszám": 17774,
        zipCode: 8066,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Pusztavám",
        "infó:": ""
      },
      {
        "Település ID": 2565,
        "KSH településazonosító törzsszám": 15583,
        zipCode: 2039,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Pusztazámor",
        "infó:": ""
      },
      {
        "Település ID": 2566,
        "KSH településazonosító törzsszám": 27410,
        zipCode: 3630,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Putnok",
        "infó:": ""
      },
      {
        "Település ID": 2577,
        "KSH településazonosító törzsszám": 4792,
        zipCode: 9313,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábacsanak",
        "infó:": ""
      },
      {
        "Település ID": 2578,
        "KSH településazonosító törzsszám": 33701,
        zipCode: 9136,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábacsécsény",
        "infó:": ""
      },
      {
        "Település ID": 2579,
        "KSH településazonosító törzsszám": 26736,
        zipCode: 9961,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Rábagyarmat",
        "infó:": ""
      },
      {
        "Település ID": 2580,
        "KSH településazonosító törzsszám": 3197,
        zipCode: 9777,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Rábahidvég",
        "infó:": ""
      },
      {
        "Település ID": 2581,
        "KSH településazonosító törzsszám": 14793,
        zipCode: 9344,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábakecöl",
        "infó:": ""
      },
      {
        "Település ID": 2582,
        "KSH településazonosító törzsszám": 25335,
        zipCode: 9142,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábapatona",
        "infó:": ""
      },
      {
        "Település ID": 2583,
        "KSH településazonosító törzsszám": 26073,
        zipCode: 9641,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Rábapaty",
        "infó:": ""
      },
      {
        "Település ID": 2584,
        "KSH településazonosító törzsszám": 24721,
        zipCode: 9146,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábapordány",
        "infó:": ""
      },
      {
        "Település ID": 2585,
        "KSH településazonosító törzsszám": 20136,
        zipCode: 9327,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábasebes",
        "infó:": ""
      },
      {
        "Település ID": 2586,
        "KSH településazonosító törzsszám": 15273,
        zipCode: 9316,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábaszentandrás",
        "infó:": ""
      },
      {
        "Település ID": 2587,
        "KSH településazonosító törzsszám": 33710,
        zipCode: 9135,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábaszentmihály",
        "infó:": ""
      },
      {
        "Település ID": 2588,
        "KSH településazonosító törzsszám": 17297,
        zipCode: 9133,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábaszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 2589,
        "KSH településazonosító törzsszám": 15422,
        zipCode: 9322,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábatamási",
        "infó:": ""
      },
      {
        "Település ID": 2590,
        "KSH településazonosító törzsszám": 17996,
        zipCode: 9766,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Rábatöttös",
        "infó:": ""
      },
      {
        "Település ID": 2591,
        "KSH településazonosító törzsszám": 3753,
        zipCode: 9165,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rábcakapi",
        "infó:": ""
      },
      {
        "Település ID": 2592,
        "KSH településazonosító törzsszám": 9900,
        zipCode: 2459,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Rácalmás",
        "infó:": ""
      },
      {
        "Település ID": 2593,
        "KSH településazonosító törzsszám": 2015,
        zipCode: 2465,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Ráckeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 2594,
        "KSH településazonosító törzsszám": 17260,
        zipCode: 2300,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Ráckeve",
        "infó:": ""
      },
      {
        "Település ID": 2595,
        "KSH településazonosító törzsszám": 2370,
        zipCode: 2613,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Rád",
        "infó:": ""
      },
      {
        "Település ID": 2596,
        "KSH településazonosító törzsszám": 11518,
        zipCode: 7817,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Rádfalva",
        "infó:": ""
      },
      {
        "Település ID": 2597,
        "KSH településazonosító törzsszám": 7551,
        zipCode: 9784,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Rádóckölked",
        "infó:": ""
      },
      {
        "Település ID": 2598,
        "KSH településazonosító törzsszám": 21193,
        zipCode: 3776,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Radostyán",
        "infó:": ""
      },
      {
        "Település ID": 2599,
        "KSH településazonosító törzsszám": 6053,
        zipCode: 3724,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ragály",
        "infó:": ""
      },
      {
        "Település ID": 2600,
        "KSH településazonosító törzsszám": 26587,
        zipCode: 9224,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rajka",
        "infó:": ""
      },
      {
        "Település ID": 2601,
        "KSH településazonosító törzsszám": 29717,
        zipCode: 3825,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Rakaca",
        "infó:": ""
      },
      {
        "Település ID": 2602,
        "KSH településazonosító törzsszám": 16133,
        zipCode: 3826,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Rakacaszend",
        "infó:": ""
      },
      {
        "Település ID": 2603,
        "KSH településazonosító törzsszám": 14739,
        zipCode: 4465,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Rakamaz",
        "infó:": ""
      },
      {
        "Település ID": 2604,
        "KSH településazonosító törzsszám": 34360,
        zipCode: 3151,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Rákóczibánya",
        "infó:": ""
      },
      {
        "Település ID": 2605,
        "KSH településazonosító törzsszám": 14207,
        zipCode: 5085,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Rákóczifalva",
        "infó:": ""
      },
      {
        "Település ID": 2606,
        "KSH településazonosító törzsszám": 12423,
        zipCode: 5084,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Rákócziújfalu",
        "infó:": ""
      },
      {
        "Település ID": 2607,
        "KSH településazonosító törzsszám": 30863,
        zipCode: 7464,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Ráksi",
        "infó:": ""
      },
      {
        "Település ID": 2608,
        "KSH településazonosító törzsszám": 14298,
        zipCode: 8973,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Ramocsa",
        "infó:": ""
      },
      {
        "Település ID": 2609,
        "KSH településazonosító törzsszám": 31857,
        zipCode: 4536,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ramocsaháza",
        "infó:": ""
      },
      {
        "Település ID": 2610,
        "KSH településazonosító törzsszám": 9061,
        zipCode: 4756,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Rápolt",
        "infó:": ""
      },
      {
        "Település ID": 2611,
        "KSH településazonosító törzsszám": 3948,
        zipCode: 8300,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Raposka",
        "infó:": ""
      },
      {
        "Település ID": 2612,
        "KSH településazonosító törzsszám": 31909,
        zipCode: 3833,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Rásonysápberencs",
        "infó:": ""
      },
      {
        "Település ID": 2613,
        "KSH településazonosító törzsszám": 12469,
        zipCode: 3908,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Rátka",
        "infó:": ""
      },
      {
        "Település ID": 2614,
        "KSH településazonosító törzsszám": 23861,
        zipCode: 9951,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Rátót",
        "infó:": ""
      },
      {
        "Település ID": 2615,
        "KSH településazonosító törzsszám": 21801,
        zipCode: 9091,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Ravazd",
        "infó:": ""
      },
      {
        "Település ID": 2616,
        "KSH településazonosító törzsszám": 9609,
        zipCode: 3245,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Recsk",
        "infó:": ""
      },
      {
        "Település ID": 2617,
        "KSH településazonosító törzsszám": 30012,
        zipCode: 2886,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Réde",
        "infó:": ""
      },
      {
        "Település ID": 2618,
        "KSH településazonosító törzsszám": 31592,
        zipCode: 8978,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Rédics",
        "infó:": ""
      },
      {
        "Település ID": 2619,
        "KSH településazonosító törzsszám": 8402,
        zipCode: 3893,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Regéc",
        "infó:": ""
      },
      {
        "Település ID": 2620,
        "KSH településazonosító törzsszám": 25292,
        zipCode: 7833,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Regenye",
        "infó:": ""
      },
      {
        "Település ID": 2621,
        "KSH településazonosító törzsszám": 15459,
        zipCode: 7193,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Regöly",
        "infó:": ""
      },
      {
        "Település ID": 2622,
        "KSH településazonosító törzsszám": 26310,
        zipCode: 6446,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Rém",
        "infó:": ""
      },
      {
        "Település ID": 2623,
        "KSH településazonosító törzsszám": 34315,
        zipCode: 2090,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Remetesz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 2624,
        "KSH településazonosító törzsszám": 31884,
        zipCode: 3559,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Répáshuta",
        "infó:": ""
      },
      {
        "Település ID": 2625,
        "KSH településazonosító törzsszám": 30881,
        zipCode: 9653,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Répcelak",
        "infó:": ""
      },
      {
        "Település ID": 2626,
        "KSH településazonosító törzsszám": 14438,
        zipCode: 9375,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Répceszemere",
        "infó:": ""
      },
      {
        "Település ID": 2627,
        "KSH településazonosító törzsszám": 28626,
        zipCode: 9623,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Répceszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 2628,
        "KSH településazonosító törzsszám": 7746,
        zipCode: 9475,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Répcevis",
        "infó:": ""
      },
      {
        "Település ID": 2629,
        "KSH településazonosító törzsszám": 17118,
        zipCode: 8977,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Resznek",
        "infó:": ""
      },
      {
        "Település ID": 2630,
        "KSH településazonosító törzsszám": 33969,
        zipCode: 9074,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Rétalap",
        "infó:": ""
      },
      {
        "Település ID": 2631,
        "KSH településazonosító törzsszám": 21573,
        zipCode: 4525,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Rétközberencs",
        "infó:": ""
      },
      {
        "Település ID": 2632,
        "KSH településazonosító törzsszám": 23825,
        zipCode: 2651,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Rétság",
        "infó:": ""
      },
      {
        "Település ID": 2633,
        "KSH településazonosító törzsszám": 5625,
        zipCode: 8253,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Révfülöp",
        "infó:": ""
      },
      {
        "Település ID": 2634,
        "KSH településazonosító törzsszám": 9317,
        zipCode: 3976,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Révleányvár",
        "infó:": ""
      },
      {
        "Település ID": 2635,
        "KSH településazonosító törzsszám": 23898,
        zipCode: 8373,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Rezi",
        "infó:": ""
      },
      {
        "Település ID": 2636,
        "KSH településazonosító törzsszám": 19220,
        zipCode: 3974,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ricse",
        "infó:": ""
      },
      {
        "Település ID": 2637,
        "KSH településazonosító törzsszám": 25849,
        zipCode: 8348,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Rigács",
        "infó:": ""
      },
      {
        "Település ID": 2638,
        "KSH településazonosító törzsszám": 24138,
        zipCode: 8883,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Rigyác",
        "infó:": ""
      },
      {
        "Település ID": 2639,
        "KSH településazonosító törzsszám": 28884,
        zipCode: 3177,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Rimóc",
        "infó:": ""
      },
      {
        "Település ID": 2640,
        "KSH településazonosító törzsszám": 5078,
        zipCode: 7552,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Rinyabeseny%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2641,
        "KSH településazonosító törzsszám": 32674,
        zipCode: 7527,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Rinyakovácsi",
        "infó:": ""
      },
      {
        "Település ID": 2642,
        "KSH településazonosító törzsszám": 26754,
        zipCode: 7513,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Rinyaszentkirály",
        "infó:": ""
      },
      {
        "Település ID": 2643,
        "KSH településazonosító törzsszám": 20622,
        zipCode: 7556,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Rinyaújlak",
        "infó:": ""
      },
      {
        "Település ID": 2644,
        "KSH településazonosító törzsszám": 20321,
        zipCode: 7584,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Rinyaújnép",
        "infó:": ""
      },
      {
        "Település ID": 2645,
        "KSH településazonosító törzsszám": 24581,
        zipCode: 4563,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Rohod",
        "infó:": ""
      },
      {
        "Település ID": 2656,
        "KSH településazonosító törzsszám": 11068,
        zipCode: 9451,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Röjtökmuzsaj",
        "infó:": ""
      },
      {
        "Település ID": 2646,
        "KSH településazonosító törzsszám": 17507,
        zipCode: 8434,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Románd",
        "infó:": ""
      },
      {
        "Település ID": 2647,
        "KSH településazonosító törzsszám": 12195,
        zipCode: 2654,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Romhány",
        "infó:": ""
      },
      {
        "Település ID": 2648,
        "KSH településazonosító törzsszám": 24855,
        zipCode: 7743,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Romonya",
        "infó:": ""
      },
      {
        "Település ID": 2657,
        "KSH településazonosító törzsszám": 26806,
        zipCode: 9954,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Rönök",
        "infó:": ""
      },
      {
        "Település ID": 2658,
        "KSH településazonosító törzsszám": 13161,
        zipCode: 6758,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Röszke",
        "infó:": ""
      },
      {
        "Település ID": 2649,
        "KSH településazonosító törzsszám": 4516,
        zipCode: 7914,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Rózsafa",
        "infó:": ""
      },
      {
        "Település ID": 2650,
        "KSH településazonosító törzsszám": 17428,
        zipCode: 4971,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Rozsály",
        "infó:": ""
      },
      {
        "Település ID": 2651,
        "KSH településazonosító törzsszám": 27650,
        zipCode: 3033,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Rózsaszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 2652,
        "KSH településazonosító törzsszám": 23029,
        zipCode: 3733,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Rudabánya",
        "infó:": ""
      },
      {
        "Település ID": 2653,
        "KSH településazonosító törzsszám": 34120,
        zipCode: 3742,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Rudolftelep",
        "infó:": ""
      },
      {
        "Település ID": 2654,
        "KSH településazonosító törzsszám": 10597,
        zipCode: 9766,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Rum",
        "infó:": ""
      },
      {
        "Település ID": 2655,
        "KSH településazonosító törzsszám": 3966,
        zipCode: 6786,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Ruzsa",
        "infó:": ""
      },
      {
        "Település ID": 2659,
        "KSH településazonosító törzsszám": 12520,
        zipCode: 3162,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Ságújfalu",
        "infó:": ""
      },
      {
        "Település ID": 2660,
        "KSH településazonosító törzsszám": 14942,
        zipCode: 8654,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Ságvár",
        "infó:": ""
      },
      {
        "Település ID": 2661,
        "KSH településazonosító törzsszám": 3504,
        zipCode: 3792,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajóbábony",
        "infó:": ""
      },
      {
        "Település ID": 2662,
        "KSH településazonosító törzsszám": 27331,
        zipCode: 3793,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajóecseg",
        "infó:": ""
      },
      {
        "Település ID": 2663,
        "KSH településazonosító törzsszám": 10171,
        zipCode: 3636,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajógalgóc",
        "infó:": ""
      },
      {
        "Település ID": 2664,
        "KSH településazonosító törzsszám": 3081,
        zipCode: 3576,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajóhidvég",
        "infó:": ""
      },
      {
        "Település ID": 2665,
        "KSH településazonosító törzsszám": 3212,
        zipCode: 3720,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajóivánka",
        "infó:": ""
      },
      {
        "Település ID": 2666,
        "KSH településazonosító törzsszám": 21670,
        zipCode: 3773,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajókápolna",
        "infó:": ""
      },
      {
        "Település ID": 2667,
        "KSH településazonosító törzsszám": 14313,
        zipCode: 3720,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajókaza",
        "infó:": ""
      },
      {
        "Település ID": 2668,
        "KSH településazonosító törzsszám": 26949,
        zipCode: 3791,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajókeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 2669,
        "KSH településazonosító törzsszám": 27173,
        zipCode: 3572,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajólád",
        "infó:": ""
      },
      {
        "Település ID": 2670,
        "KSH településazonosító törzsszám": 22479,
        zipCode: 3773,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajólászlófalva",
        "infó:": ""
      },
      {
        "Település ID": 2671,
        "KSH településazonosító törzsszám": 15945,
        zipCode: 3656,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajómercse",
        "infó:": ""
      },
      {
        "Település ID": 2672,
        "KSH településazonosító törzsszám": 11332,
        zipCode: 3652,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajónémeti",
        "infó:": ""
      },
      {
        "Település ID": 2681,
        "KSH településazonosító törzsszám": 8129,
        zipCode: 3586,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajóörös",
        "infó:": ""
      },
      {
        "Település ID": 2673,
        "KSH településazonosító törzsszám": 18537,
        zipCode: 3714,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajópálfala",
        "infó:": ""
      },
      {
        "Település ID": 2674,
        "KSH településazonosító törzsszám": 16638,
        zipCode: 3573,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajópetri",
        "infó:": ""
      },
      {
        "Település ID": 2675,
        "KSH településazonosító törzsszám": 23782,
        zipCode: 3653,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajópüspöki",
        "infó:": ""
      },
      {
        "Település ID": 2676,
        "KSH településazonosító törzsszám": 8970,
        zipCode: 3712,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajósenye",
        "infó:": ""
      },
      {
        "Település ID": 2677,
        "KSH településazonosító törzsszám": 16054,
        zipCode: 3770,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajószentpéter",
        "infó:": ""
      },
      {
        "Település ID": 2678,
        "KSH településazonosító törzsszám": 30340,
        zipCode: 3599,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajószöged",
        "infó:": ""
      },
      {
        "Település ID": 2679,
        "KSH településazonosító törzsszám": 20738,
        zipCode: 3712,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajóvámos",
        "infó:": ""
      },
      {
        "Település ID": 2680,
        "KSH településazonosító törzsszám": 27757,
        zipCode: 3656,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sajóvelezd",
        "infó:": ""
      },
      {
        "Település ID": 2682,
        "KSH településazonosító törzsszám": 10579,
        zipCode: 9632,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sajtoskál",
        "infó:": ""
      },
      {
        "Település ID": 2683,
        "KSH településazonosító törzsszám": 30793,
        zipCode: 8256,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Salföld",
        "infó:": ""
      },
      {
        "Település ID": 2684,
        "KSH településazonosító törzsszám": 25788,
        zipCode: 3100,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Salgótarján",
        "infó:": ""
      },
      {
        "Település ID": 2685,
        "KSH településazonosító törzsszám": 25788,
        zipCode: 3102,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Salgótarján",
        "infó:": ""
      },
      {
        "Település ID": 2686,
        "KSH településazonosító törzsszám": 25788,
        zipCode: 3104,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Salgótarján",
        "infó:": ""
      },
      {
        "Település ID": 2687,
        "KSH településazonosító törzsszám": 25788,
        zipCode: 3109,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Salgótarján",
        "infó:": ""
      },
      {
        "Település ID": 2688,
        "KSH településazonosító törzsszám": 25788,
        zipCode: 3121,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Salgótarján",
        "infó:": ""
      },
      {
        "Település ID": 2689,
        "KSH településazonosító törzsszám": 25788,
        zipCode: 3141,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Salgótarján",
        "infó:": ""
      },
      {
        "Település ID": 2690,
        "KSH településazonosító törzsszám": 18883,
        zipCode: 9742,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Salköveskút",
        "infó:": ""
      },
      {
        "Település ID": 2691,
        "KSH településazonosító törzsszám": 27720,
        zipCode: 8995,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Salomvár",
        "infó:": ""
      },
      {
        "Település ID": 2692,
        "KSH településazonosító törzsszám": 4729,
        zipCode: 3425,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sály",
        "infó:": ""
      },
      {
        "Település ID": 2693,
        "KSH településazonosító törzsszám": 18050,
        zipCode: 7841,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sámod",
        "infó:": ""
      },
      {
        "Település ID": 2694,
        "KSH településazonosító törzsszám": 24572,
        zipCode: 3074,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Sámsonháza",
        "infó:": ""
      },
      {
        "Település ID": 2695,
        "KSH településazonosító törzsszám": 15556,
        zipCode: 8824,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Sand",
        "infó:": ""
      },
      {
        "Település ID": 2696,
        "KSH településazonosító törzsszám": 31705,
        zipCode: 6762,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Sándorfalva",
        "infó:": ""
      },
      {
        "Település ID": 2697,
        "KSH településazonosító törzsszám": 31121,
        zipCode: 7479,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Sántos",
        "infó:": ""
      },
      {
        "Település ID": 2698,
        "KSH településazonosító törzsszám": 26116,
        zipCode: 4176,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Sáp",
        "infó:": ""
      },
      {
        "Település ID": 2699,
        "KSH településazonosító törzsszám": 25007,
        zipCode: 4272,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Sáránd",
        "infó:": ""
      },
      {
        "Település ID": 2700,
        "KSH településazonosító törzsszám": 20516,
        zipCode: 3942,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sárazsadány",
        "infó:": ""
      },
      {
        "Település ID": 2701,
        "KSH településazonosító törzsszám": 23694,
        zipCode: 7000,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárbogárd",
        "infó:": ""
      },
      {
        "Település ID": 2702,
        "KSH településazonosító törzsszám": 23694,
        zipCode: 7003,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárbogárd",
        "infó:": ""
      },
      {
        "Település ID": 2703,
        "KSH településazonosító törzsszám": 23694,
        zipCode: 7018,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárbogárd",
        "infó:": ""
      },
      {
        "Település ID": 2704,
        "KSH településazonosító törzsszám": 23694,
        zipCode: 7019,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárbogárd",
        "infó:": ""
      },
      {
        "Település ID": 2705,
        "KSH településazonosító törzsszám": 2723,
        zipCode: 7014,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sáregres",
        "infó:": ""
      },
      {
        "Település ID": 2706,
        "KSH településazonosító törzsszám": 9788,
        zipCode: 9813,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sárfimizdó",
        "infó:": ""
      },
      {
        "Település ID": 2707,
        "KSH településazonosító törzsszám": 8101,
        zipCode: 8944,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Sárhida",
        "infó:": ""
      },
      {
        "Település ID": 2708,
        "KSH településazonosító törzsszám": 26903,
        zipCode: 2523,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Sárisáp",
        "infó:": ""
      },
      {
        "Település ID": 2709,
        "KSH településazonosító törzsszám": 28565,
        zipCode: 5720,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Sarkad",
        "infó:": ""
      },
      {
        "Település ID": 2710,
        "KSH településazonosító törzsszám": 25168,
        zipCode: 5731,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Sarkadkeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 2711,
        "KSH településazonosító törzsszám": 31802,
        zipCode: 8051,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárkeresztes",
        "infó:": ""
      },
      {
        "Település ID": 2712,
        "KSH településazonosító törzsszám": 25344,
        zipCode: 8125,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárkeresztúr",
        "infó:": ""
      },
      {
        "Település ID": 2713,
        "KSH településazonosító törzsszám": 2699,
        zipCode: 8144,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárkeszi",
        "infó:": ""
      },
      {
        "Település ID": 2714,
        "KSH településazonosító törzsszám": 14906,
        zipCode: 8391,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Sármellék",
        "infó:": ""
      },
      {
        "Település ID": 2715,
        "KSH településazonosító törzsszám": 20862,
        zipCode: 7781,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sárok",
        "infó:": ""
      },
      {
        "Település ID": 2716,
        "KSH településazonosító törzsszám": 25140,
        zipCode: 2433,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárosd",
        "infó:": ""
      },
      {
        "Település ID": 2717,
        "KSH településazonosító törzsszám": 27474,
        zipCode: 3950,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sárospatak",
        "infó:": ""
      },
      {
        "Település ID": 2718,
        "KSH településazonosító törzsszám": 27474,
        zipCode: 3952,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sárospatak",
        "infó:": ""
      },
      {
        "Település ID": 2719,
        "KSH településazonosító törzsszám": 4747,
        zipCode: 7145,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Sárpilis",
        "infó:": ""
      },
      {
        "Település ID": 2720,
        "KSH településazonosító törzsszám": 23940,
        zipCode: 4171,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Sárrétudvari",
        "infó:": ""
      },
      {
        "Település ID": 2721,
        "KSH településazonosító törzsszám": 30021,
        zipCode: 9434,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sarród",
        "infó:": ""
      },
      {
        "Település ID": 2722,
        "KSH településazonosító törzsszám": 30021,
        zipCode: 9435,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sarród",
        "infó:": ""
      },
      {
        "Település ID": 2723,
        "KSH településazonosító törzsszám": 31538,
        zipCode: 8126,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárszentágota",
        "infó:": ""
      },
      {
        "Település ID": 2724,
        "KSH településazonosító törzsszám": 20817,
        zipCode: 7047,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Sárszentl%u0151rinc",
        "infó:": ""
      },
      {
        "Település ID": 2725,
        "KSH településazonosító törzsszám": 11776,
        zipCode: 8141,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárszentmihály",
        "infó:": ""
      },
      {
        "Település ID": 2726,
        "KSH településazonosító törzsszám": 11776,
        zipCode: 8143,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sárszentmihály",
        "infó:": ""
      },
      {
        "Település ID": 2727,
        "KSH településazonosító törzsszám": 7180,
        zipCode: 3386,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Sarud",
        "infó:": ""
      },
      {
        "Település ID": 2728,
        "KSH településazonosító törzsszám": 21306,
        zipCode: 9600,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sárvár",
        "infó:": ""
      },
      {
        "Település ID": 2729,
        "KSH településazonosító törzsszám": 21306,
        zipCode: 9608,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sárvár",
        "infó:": ""
      },
      {
        "Település ID": 2730,
        "KSH településazonosító törzsszám": 21306,
        zipCode: 9609,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sárvár",
        "infó:": ""
      },
      {
        "Település ID": 2731,
        "KSH településazonosító törzsszám": 32160,
        zipCode: 7370,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sásd",
        "infó:": ""
      },
      {
        "Település ID": 2732,
        "KSH településazonosító törzsszám": 13772,
        zipCode: 8308,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Sáska",
        "infó:": ""
      },
      {
        "Település ID": 2733,
        "KSH településazonosító törzsszám": 2875,
        zipCode: 3659,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sáta",
        "infó:": ""
      },
      {
        "Település ID": 2734,
        "KSH településazonosító törzsszám": 5120,
        zipCode: 3944,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sátoraljaújhely",
        "infó:": ""
      },
      {
        "Település ID": 2735,
        "KSH településazonosító törzsszám": 5120,
        zipCode: 3945,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sátoraljaújhely",
        "infó:": ""
      },
      {
        "Település ID": 2736,
        "KSH településazonosító törzsszám": 5120,
        zipCode: 3980,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sátoraljaújhely",
        "infó:": ""
      },
      {
        "Település ID": 2737,
        "KSH településazonosító törzsszám": 5120,
        zipCode: 3988,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sátoraljaújhely",
        "infó:": ""
      },
      {
        "Település ID": 2738,
        "KSH településazonosító törzsszám": 33482,
        zipCode: 7785,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sátorhely",
        "infó:": ""
      },
      {
        "Település ID": 2739,
        "KSH településazonosító törzsszám": 2051,
        zipCode: 8732,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Sávoly",
        "infó:": ""
      },
      {
        "Település ID": 2740,
        "KSH településazonosító törzsszám": 10278,
        zipCode: 9789,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sé",
        "infó:": ""
      },
      {
        "Település ID": 2741,
        "KSH településazonosító törzsszám": 27368,
        zipCode: 7562,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Segesd",
        "infó:": ""
      },
      {
        "Település ID": 2742,
        "KSH településazonosító törzsszám": 28741,
        zipCode: 7960,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sellye",
        "infó:": ""
      },
      {
        "Település ID": 2743,
        "KSH településazonosító törzsszám": 25380,
        zipCode: 3809,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Selyeb",
        "infó:": ""
      },
      {
        "Település ID": 2744,
        "KSH településazonosító törzsszám": 23755,
        zipCode: 3974,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Semjén",
        "infó:": ""
      },
      {
        "Település ID": 2745,
        "KSH településazonosító törzsszám": 30997,
        zipCode: 8862,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Semjénháza",
        "infó:": ""
      },
      {
        "Település ID": 2746,
        "KSH településazonosító törzsszám": 14085,
        zipCode: 8788,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Sénye",
        "infó:": ""
      },
      {
        "Település ID": 2747,
        "KSH településazonosító törzsszám": 4491,
        zipCode: 4533,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Sény%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2748,
        "KSH településazonosító törzsszám": 20206,
        zipCode: 8111,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Seregélyes",
        "infó:": ""
      },
      {
        "Település ID": 2749,
        "KSH településazonosító törzsszám": 32531,
        zipCode: 3729,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Serényfalva",
        "infó:": ""
      },
      {
        "Település ID": 2750,
        "KSH településazonosító törzsszám": 32133,
        zipCode: 8660,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Sérseksz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 2751,
        "KSH településazonosító törzsszám": 8606,
        zipCode: 8439,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sikátor",
        "infó:": ""
      },
      {
        "Település ID": 2752,
        "KSH településazonosító törzsszám": 5519,
        zipCode: 7800,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Siklós",
        "infó:": ""
      },
      {
        "Település ID": 2753,
        "KSH településazonosító törzsszám": 5519,
        zipCode: 7818,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Siklós",
        "infó:": ""
      },
      {
        "Település ID": 2754,
        "KSH településazonosító törzsszám": 23205,
        zipCode: 7814,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Siklósbodony",
        "infó:": ""
      },
      {
        "Település ID": 2755,
        "KSH településazonosító törzsszám": 8800,
        zipCode: 7823,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Siklósnagyfalu",
        "infó:": ""
      },
      {
        "Település ID": 2756,
        "KSH településazonosító törzsszám": 23418,
        zipCode: 3881,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sima",
        "infó:": ""
      },
      {
        "Település ID": 2757,
        "KSH településazonosító törzsszám": 26763,
        zipCode: 9633,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Simaság",
        "infó:": ""
      },
      {
        "Település ID": 2758,
        "KSH településazonosító törzsszám": 32780,
        zipCode: 7474,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Simonfa",
        "infó:": ""
      },
      {
        "Település ID": 2759,
        "KSH településazonosító törzsszám": 20783,
        zipCode: 7081,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Simontornya",
        "infó:": ""
      },
      {
        "Település ID": 2760,
        "KSH településazonosító törzsszám": 25645,
        zipCode: 7171,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Sióagárd",
        "infó:": ""
      },
      {
        "Település ID": 2761,
        "KSH településazonosító törzsszám": 17631,
        zipCode: 8600,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Siófok",
        "infó:": ""
      },
      {
        "Település ID": 2762,
        "KSH településazonosító törzsszám": 17631,
        zipCode: 8609,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Siófok",
        "infó:": ""
      },
      {
        "Település ID": 2763,
        "KSH településazonosító törzsszám": 17631,
        zipCode: 8611,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Siófok",
        "infó:": ""
      },
      {
        "Település ID": 2764,
        "KSH településazonosító törzsszám": 23092,
        zipCode: 8652,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Siójut",
        "infó:": ""
      },
      {
        "Település ID": 2765,
        "KSH településazonosító törzsszám": 8527,
        zipCode: 3332,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Sirok",
        "infó:": ""
      },
      {
        "Település ID": 2766,
        "KSH településazonosító törzsszám": 30748,
        zipCode: 9671,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sitke",
        "infó:": ""
      },
      {
        "Település ID": 2767,
        "KSH településazonosító törzsszám": 24208,
        zipCode: 9315,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sobor",
        "infó:": ""
      },
      {
        "Település ID": 3052,
        "KSH településazonosító törzsszám": 19080,
        zipCode: 8897,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Söjtör",
        "infó:": ""
      },
      {
        "Település ID": 2768,
        "KSH településazonosító törzsszám": 12627,
        zipCode: 9112,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sokorópátka",
        "infó:": ""
      },
      {
        "Település ID": 2769,
        "KSH településazonosító törzsszám": 29115,
        zipCode: 6320,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Solt",
        "infó:": ""
      },
      {
        "Település ID": 2770,
        "KSH településazonosító törzsszám": 18218,
        zipCode: 6223,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Soltszentimre",
        "infó:": ""
      },
      {
        "Település ID": 2771,
        "KSH településazonosító törzsszám": 19983,
        zipCode: 6230,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Soltvadkert",
        "infó:": ""
      },
      {
        "Település ID": 2772,
        "KSH településazonosító törzsszám": 3601,
        zipCode: 8193,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Sóly",
        "infó:": ""
      },
      {
        "Település ID": 2773,
        "KSH településazonosító törzsszám": 7384,
        zipCode: 2083,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Solymár",
        "infó:": ""
      },
      {
        "Település ID": 2774,
        "KSH településazonosító törzsszám": 4127,
        zipCode: 8655,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Som",
        "infó:": ""
      },
      {
        "Település ID": 2775,
        "KSH településazonosító törzsszám": 23472,
        zipCode: 7728,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Somberek",
        "infó:": ""
      },
      {
        "Település ID": 2776,
        "KSH településazonosító törzsszám": 5157,
        zipCode: 8478,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Somlójen%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2777,
        "KSH településazonosító törzsszám": 25779,
        zipCode: 8483,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Somlósz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 2778,
        "KSH településazonosító törzsszám": 26569,
        zipCode: 8481,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Somlóvásárhely",
        "infó:": ""
      },
      {
        "Település ID": 2779,
        "KSH településazonosító törzsszám": 14243,
        zipCode: 8484,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Somlóvecse",
        "infó:": ""
      },
      {
        "Település ID": 2780,
        "KSH településazonosító törzsszám": 13824,
        zipCode: 7454,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somodor",
        "infó:": ""
      },
      {
        "Település ID": 2781,
        "KSH településazonosító törzsszám": 30580,
        zipCode: 7283,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyacsa",
        "infó:": ""
      },
      {
        "Település ID": 2782,
        "KSH településazonosító törzsszám": 23807,
        zipCode: 7922,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Somogyapáti",
        "infó:": ""
      },
      {
        "Település ID": 2783,
        "KSH településazonosító törzsszám": 5500,
        zipCode: 7584,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyaracs",
        "infó:": ""
      },
      {
        "Település ID": 2784,
        "KSH településazonosító törzsszám": 31219,
        zipCode: 7452,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyaszaló",
        "infó:": ""
      },
      {
        "Település ID": 2785,
        "KSH településazonosító törzsszám": 32470,
        zipCode: 8684,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogybabod",
        "infó:": ""
      },
      {
        "Település ID": 2786,
        "KSH településazonosító törzsszám": 25043,
        zipCode: 8858,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogybükkösd",
        "infó:": ""
      },
      {
        "Település ID": 2787,
        "KSH településazonosító törzsszám": 11484,
        zipCode: 8726,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogycsicsó",
        "infó:": ""
      },
      {
        "Település ID": 2788,
        "KSH településazonosító törzsszám": 15981,
        zipCode: 7284,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogydöröcske",
        "infó:": ""
      },
      {
        "Település ID": 2789,
        "KSH településazonosító törzsszám": 20057,
        zipCode: 8666,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyegres",
        "infó:": ""
      },
      {
        "Település ID": 2790,
        "KSH településazonosító törzsszám": 18078,
        zipCode: 8708,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyfajsz",
        "infó:": ""
      },
      {
        "Település ID": 2791,
        "KSH településazonosító törzsszám": 20330,
        zipCode: 7455,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogygeszti",
        "infó:": ""
      },
      {
        "Település ID": 2792,
        "KSH településazonosító törzsszám": 29142,
        zipCode: 7925,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Somogyhárságy",
        "infó:": ""
      },
      {
        "Település ID": 2793,
        "KSH településazonosító törzsszám": 25070,
        zipCode: 7921,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Somogyhatvan",
        "infó:": ""
      },
      {
        "Település ID": 2794,
        "KSH településazonosító törzsszám": 28963,
        zipCode: 7443,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyjád",
        "infó:": ""
      },
      {
        "Település ID": 2795,
        "KSH településazonosító törzsszám": 12876,
        zipCode: 8673,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogymeggyes",
        "infó:": ""
      },
      {
        "Település ID": 2796,
        "KSH településazonosító törzsszám": 15626,
        zipCode: 8733,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogysámson",
        "infó:": ""
      },
      {
        "Település ID": 2797,
        "KSH településazonosító törzsszám": 28723,
        zipCode: 7435,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogysárd",
        "infó:": ""
      },
      {
        "Település ID": 2798,
        "KSH településazonosító törzsszám": 27535,
        zipCode: 8737,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogysimonyi",
        "infó:": ""
      },
      {
        "Település ID": 2799,
        "KSH településazonosító törzsszám": 6600,
        zipCode: 8705,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyszentpál",
        "infó:": ""
      },
      {
        "Település ID": 2800,
        "KSH településazonosító törzsszám": 3416,
        zipCode: 7276,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyszil",
        "infó:": ""
      },
      {
        "Település ID": 2801,
        "KSH településazonosító törzsszám": 18546,
        zipCode: 7563,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyszob",
        "infó:": ""
      },
      {
        "Település ID": 2802,
        "KSH településazonosító törzsszám": 32601,
        zipCode: 8683,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogytúr",
        "infó:": ""
      },
      {
        "Település ID": 2803,
        "KSH településazonosító törzsszám": 4853,
        zipCode: 7515,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyudvarhely",
        "infó:": ""
      },
      {
        "Település ID": 2804,
        "KSH településazonosító törzsszám": 21856,
        zipCode: 8699,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyvámos",
        "infó:": ""
      },
      {
        "Település ID": 2805,
        "KSH településazonosító törzsszám": 19442,
        zipCode: 8698,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyvár",
        "infó:": ""
      },
      {
        "Település ID": 2806,
        "KSH településazonosító törzsszám": 21281,
        zipCode: 7924,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Somogyviszló",
        "infó:": ""
      },
      {
        "Település ID": 2807,
        "KSH településazonosító törzsszám": 4835,
        zipCode: 8734,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Somogyzsitfa",
        "infó:": ""
      },
      {
        "Település ID": 2808,
        "KSH településazonosító törzsszám": 23889,
        zipCode: 4954,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Sonkád",
        "infó:": ""
      },
      {
        "Település ID": 2809,
        "KSH településazonosító törzsszám": 33321,
        zipCode: 8123,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Soponya",
        "infó:": ""
      },
      {
        "Település ID": 2810,
        "KSH településazonosító törzsszám": 8518,
        zipCode: 9400,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sopron",
        "infó:": ""
      },
      {
        "Település ID": 2811,
        "KSH településazonosító törzsszám": 8518,
        zipCode: 9407,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sopron",
        "infó:": ""
      },
      {
        "Település ID": 2812,
        "KSH településazonosító törzsszám": 8518,
        zipCode: 9408,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sopron",
        "infó:": ""
      },
      {
        "Település ID": 2813,
        "KSH településazonosító törzsszám": 8518,
        zipCode: 9494,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sopron",
        "infó:": ""
      },
      {
        "Település ID": 2814,
        "KSH településazonosító törzsszám": 29090,
        zipCode: 9463,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sopronhorpács",
        "infó:": ""
      },
      {
        "Település ID": 2815,
        "KSH településazonosító törzsszám": 25724,
        zipCode: 9483,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sopronkövesd",
        "infó:": ""
      },
      {
        "Település ID": 2816,
        "KSH településazonosító törzsszám": 2617,
        zipCode: 9325,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Sopronnémeti",
        "infó:": ""
      },
      {
        "Település ID": 3053,
        "KSH településazonosító törzsszám": 24800,
        zipCode: 9743,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Söpte",
        "infó:": ""
      },
      {
        "Település ID": 3054,
        "KSH településazonosító törzsszám": 2893,
        zipCode: 8072,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Söréd",
        "infó:": ""
      },
      {
        "Település ID": 2817,
        "KSH településazonosító törzsszám": 7171,
        zipCode: 9774,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sorkifalud",
        "infó:": ""
      },
      {
        "Település ID": 2818,
        "KSH településazonosító törzsszám": 27960,
        zipCode: 9774,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sorkikápolna",
        "infó:": ""
      },
      {
        "Település ID": 2819,
        "KSH településazonosító törzsszám": 6567,
        zipCode: 8881,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Sormás",
        "infó:": ""
      },
      {
        "Település ID": 2820,
        "KSH településazonosító törzsszám": 29692,
        zipCode: 9773,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sorokpolány",
        "infó:": ""
      },
      {
        "Település ID": 2821,
        "KSH településazonosító törzsszám": 14881,
        zipCode: 3131,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Sóshartyán",
        "infó:": ""
      },
      {
        "Település ID": 2822,
        "KSH településazonosító törzsszám": 6840,
        zipCode: 2038,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Sóskút",
        "infó:": ""
      },
      {
        "Település ID": 2823,
        "KSH településazonosító törzsszám": 13268,
        zipCode: 3716,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sóstófalva",
        "infó:": ""
      },
      {
        "Település ID": 2824,
        "KSH településazonosító törzsszám": 31714,
        zipCode: 7960,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sósvertike",
        "infó:": ""
      },
      {
        "Település ID": 2825,
        "KSH településazonosító törzsszám": 22983,
        zipCode: 9681,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sótony",
        "infó:": ""
      },
      {
        "Település ID": 2826,
        "KSH településazonosító törzsszám": 14951,
        zipCode: 8096,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Sukoró",
        "infó:": ""
      },
      {
        "Település ID": 2830,
        "KSH településazonosító törzsszám": 21245,
        zipCode: 6346,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Sükösd",
        "infó:": ""
      },
      {
        "Település ID": 2831,
        "KSH településazonosító törzsszám": 21713,
        zipCode: 2241,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Sülysáp",
        "infó:": ""
      },
      {
        "Település ID": 2832,
        "KSH településazonosító törzsszám": 21713,
        zipCode: 2242,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Sülysáp",
        "infó:": ""
      },
      {
        "Település ID": 2833,
        "KSH településazonosító törzsszám": 25593,
        zipCode: 8330,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Sümeg",
        "infó:": ""
      },
      {
        "Település ID": 2834,
        "KSH településazonosító törzsszám": 21397,
        zipCode: 8357,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Sümegcsehi",
        "infó:": ""
      },
      {
        "Település ID": 2835,
        "KSH településazonosító törzsszám": 5388,
        zipCode: 8351,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Sümegprága",
        "infó:": ""
      },
      {
        "Település ID": 2827,
        "KSH településazonosító törzsszám": 2547,
        zipCode: 7960,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sumony",
        "infó:": ""
      },
      {
        "Település ID": 2828,
        "KSH településazonosító törzsszám": 31990,
        zipCode: 2889,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Súr",
        "infó:": ""
      },
      {
        "Település ID": 2829,
        "KSH településazonosító törzsszám": 2404,
        zipCode: 8856,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Surd",
        "infó:": ""
      },
      {
        "Település ID": 2836,
        "KSH településazonosító törzsszám": 8688,
        zipCode: 2543,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Sütt%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2837,
        "KSH településazonosító törzsszám": 28705,
        zipCode: 8151,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Szabadbattyán",
        "infó:": ""
      },
      {
        "Település ID": 2838,
        "KSH településazonosító törzsszám": 13259,
        zipCode: 2432,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Szabadegyháza",
        "infó:": ""
      },
      {
        "Település ID": 2839,
        "KSH településazonosító törzsszám": 18740,
        zipCode: 8138,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Szabadhidvég",
        "infó:": ""
      },
      {
        "Település ID": 2840,
        "KSH településazonosító törzsszám": 18740,
        zipCode: 8139,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Szabadhidvég",
        "infó:": ""
      },
      {
        "Település ID": 2841,
        "KSH településazonosító törzsszám": 14854,
        zipCode: 7253,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szabadi",
        "infó:": ""
      },
      {
        "Település ID": 2842,
        "KSH településazonosító törzsszám": 31325,
        zipCode: 5712,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Szabadkígyós",
        "infó:": ""
      },
      {
        "Település ID": 2843,
        "KSH településazonosító törzsszám": 25061,
        zipCode: 6080,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Szabadszállás",
        "infó:": ""
      },
      {
        "Település ID": 2844,
        "KSH településazonosító törzsszám": 15079,
        zipCode: 7951,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szabadszentkirály",
        "infó:": ""
      },
      {
        "Település ID": 2845,
        "KSH településazonosító törzsszám": 28574,
        zipCode: 7544,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szabás",
        "infó:": ""
      },
      {
        "Település ID": 2846,
        "KSH településazonosító törzsszám": 19169,
        zipCode: 4467,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szabolcs",
        "infó:": ""
      },
      {
        "Település ID": 2847,
        "KSH településazonosító törzsszám": 22053,
        zipCode: 4547,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szabolcsbáka",
        "infó:": ""
      },
      {
        "Település ID": 2848,
        "KSH településazonosító törzsszám": 3586,
        zipCode: 4496,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szabolcsveresmart",
        "infó:": ""
      },
      {
        "Település ID": 2849,
        "KSH településazonosító törzsszám": 21458,
        zipCode: 2111,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szada",
        "infó:": ""
      },
      {
        "Település ID": 2850,
        "KSH településazonosító törzsszám": 27508,
        zipCode: 7383,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szágy",
        "infó:": ""
      },
      {
        "Település ID": 2851,
        "KSH településazonosító törzsszám": 8475,
        zipCode: 7753,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szajk",
        "infó:": ""
      },
      {
        "Település ID": 2852,
        "KSH településazonosító törzsszám": 16063,
        zipCode: 3334,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Szajla",
        "infó:": ""
      },
      {
        "Település ID": 2853,
        "KSH településazonosító törzsszám": 5874,
        zipCode: 5081,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Szajol",
        "infó:": ""
      },
      {
        "Település ID": 2854,
        "KSH településazonosító törzsszám": 32258,
        zipCode: 3786,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szakácsi",
        "infó:": ""
      },
      {
        "Település ID": 2855,
        "KSH településazonosító törzsszám": 15316,
        zipCode: 7071,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Szakadát",
        "infó:": ""
      },
      {
        "Település ID": 2856,
        "KSH településazonosító törzsszám": 3805,
        zipCode: 3596,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szakáld",
        "infó:": ""
      },
      {
        "Település ID": 2857,
        "KSH településazonosító törzsszám": 4464,
        zipCode: 7192,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Szakály",
        "infó:": ""
      },
      {
        "Település ID": 2858,
        "KSH településazonosító törzsszám": 10083,
        zipCode: 7213,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Szakcs",
        "infó:": ""
      },
      {
        "Település ID": 2859,
        "KSH településazonosító törzsszám": 19530,
        zipCode: 6336,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Szakmár",
        "infó:": ""
      },
      {
        "Település ID": 2860,
        "KSH településazonosító törzsszám": 16504,
        zipCode: 9934,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szaknyér",
        "infó:": ""
      },
      {
        "Település ID": 2861,
        "KSH településazonosító törzsszám": 4774,
        zipCode: 4234,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szakoly",
        "infó:": ""
      },
      {
        "Település ID": 2862,
        "KSH településazonosító törzsszám": 11369,
        zipCode: 9474,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Szakony",
        "infó:": ""
      },
      {
        "Település ID": 2863,
        "KSH településazonosító törzsszám": 20932,
        zipCode: 9983,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szakonyfalu",
        "infó:": ""
      },
      {
        "Település ID": 2864,
        "KSH településazonosító törzsszám": 33516,
        zipCode: 2856,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Szákszend",
        "infó:": ""
      },
      {
        "Település ID": 2865,
        "KSH településazonosító törzsszám": 10223,
        zipCode: 9942,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szalaf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2866,
        "KSH településazonosító törzsszám": 22257,
        zipCode: 7811,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szalánta",
        "infó:": ""
      },
      {
        "Település ID": 2867,
        "KSH településazonosító törzsszám": 20543,
        zipCode: 8341,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szalapa",
        "infó:": ""
      },
      {
        "Település ID": 2868,
        "KSH településazonosító törzsszám": 31015,
        zipCode: 3863,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szalaszend",
        "infó:": ""
      },
      {
        "Település ID": 2869,
        "KSH településazonosító törzsszám": 9007,
        zipCode: 7334,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szalatnak",
        "infó:": ""
      },
      {
        "Település ID": 2870,
        "KSH településazonosító törzsszám": 14711,
        zipCode: 7121,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Szálka",
        "infó:": ""
      },
      {
        "Település ID": 2871,
        "KSH településazonosító törzsszám": 19947,
        zipCode: 6086,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Szalkszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 2872,
        "KSH településazonosító törzsszám": 19798,
        zipCode: 3163,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szalmatercs",
        "infó:": ""
      },
      {
        "Település ID": 2873,
        "KSH településazonosító törzsszám": 16753,
        zipCode: 3754,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szalonna",
        "infó:": ""
      },
      {
        "Település ID": 2874,
        "KSH településazonosító törzsszám": 18005,
        zipCode: 4767,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szamosangyalos",
        "infó:": ""
      },
      {
        "Település ID": 2875,
        "KSH településazonosító törzsszám": 22017,
        zipCode: 4745,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szamosbecs",
        "infó:": ""
      },
      {
        "Település ID": 2876,
        "KSH településazonosító törzsszám": 16300,
        zipCode: 4721,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szamoskér",
        "infó:": ""
      },
      {
        "Település ID": 2877,
        "KSH településazonosító törzsszám": 10436,
        zipCode: 4735,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szamossályi",
        "infó:": ""
      },
      {
        "Település ID": 2878,
        "KSH településazonosító törzsszám": 13046,
        zipCode: 4824,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szamosszeg",
        "infó:": ""
      },
      {
        "Település ID": 2879,
        "KSH településazonosító törzsszám": 30085,
        zipCode: 4746,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szamostatárfalva",
        "infó:": ""
      },
      {
        "Település ID": 2880,
        "KSH településazonosító törzsszám": 31273,
        zipCode: 4734,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szamosújlak",
        "infó:": ""
      },
      {
        "Település ID": 2881,
        "KSH településazonosító törzsszám": 13754,
        zipCode: 2697,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szanda",
        "infó:": ""
      },
      {
        "Település ID": 2882,
        "KSH településazonosító törzsszám": 11794,
        zipCode: 6131,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Szank",
        "infó:": ""
      },
      {
        "Település ID": 2883,
        "KSH településazonosító törzsszám": 34236,
        zipCode: 8622,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szántód",
        "infó:": ""
      },
      {
        "Település ID": 2884,
        "KSH településazonosító törzsszám": 8536,
        zipCode: 9317,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Szany",
        "infó:": ""
      },
      {
        "Település ID": 2885,
        "KSH településazonosító törzsszám": 16489,
        zipCode: 8423,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Szápár",
        "infó:": ""
      },
      {
        "Település ID": 2886,
        "KSH településazonosító törzsszám": 34032,
        zipCode: 7843,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szaporca",
        "infó:": ""
      },
      {
        "Település ID": 2887,
        "KSH településazonosító törzsszám": 19549,
        zipCode: 2066,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Szár",
        "infó:": ""
      },
      {
        "Település ID": 2888,
        "KSH településazonosító törzsszám": 7366,
        zipCode: 7184,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szárász",
        "infó:": ""
      },
      {
        "Település ID": 2889,
        "KSH településazonosító törzsszám": 18263,
        zipCode: 7063,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Szárazd",
        "infó:": ""
      },
      {
        "Település ID": 2890,
        "KSH településazonosító törzsszám": 15714,
        zipCode: 9353,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Szárföld",
        "infó:": ""
      },
      {
        "Település ID": 2891,
        "KSH településazonosító törzsszám": 33491,
        zipCode: 2067,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Szárliget",
        "infó:": ""
      },
      {
        "Település ID": 2892,
        "KSH településazonosító törzsszám": 23870,
        zipCode: 5540,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Szarvas",
        "infó:": ""
      },
      {
        "Település ID": 2893,
        "KSH településazonosító törzsszám": 10199,
        zipCode: 3051,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szarvasgede",
        "infó:": ""
      },
      {
        "Település ID": 2894,
        "KSH településazonosító törzsszám": 17729,
        zipCode: 9913,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szarvaskend",
        "infó:": ""
      },
      {
        "Település ID": 2895,
        "KSH településazonosító törzsszám": 3382,
        zipCode: 3323,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Szarvask%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2896,
        "KSH településazonosító törzsszám": 5777,
        zipCode: 5053,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Szászberek",
        "infó:": ""
      },
      {
        "Település ID": 2897,
        "KSH településazonosító törzsszám": 8147,
        zipCode: 3821,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szászfa",
        "infó:": ""
      },
      {
        "Település ID": 2898,
        "KSH településazonosító törzsszám": 33765,
        zipCode: 7349,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szászvár",
        "infó:": ""
      },
      {
        "Település ID": 2899,
        "KSH településazonosító törzsszám": 31237,
        zipCode: 4945,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szatmárcseke",
        "infó:": ""
      },
      {
        "Település ID": 2900,
        "KSH településazonosító törzsszám": 15325,
        zipCode: 2656,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szátok",
        "infó:": ""
      },
      {
        "Település ID": 2901,
        "KSH településazonosító törzsszám": 2440,
        zipCode: 9938,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szatta",
        "infó:": ""
      },
      {
        "Település ID": 2902,
        "KSH településazonosító törzsszám": 12007,
        zipCode: 6763,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szatymaz",
        "infó:": ""
      },
      {
        "Település ID": 2903,
        "KSH településazonosító törzsszám": 31060,
        zipCode: 7813,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szava",
        "infó:": ""
      },
      {
        "Település ID": 2904,
        "KSH településazonosító törzsszám": 17312,
        zipCode: 2440,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Százhalombatta",
        "infó:": ""
      },
      {
        "Település ID": 2905,
        "KSH településazonosító törzsszám": 3018,
        zipCode: 7725,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szebény",
        "infó:": ""
      },
      {
        "Település ID": 2906,
        "KSH településazonosító törzsszám": 23047,
        zipCode: 2692,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szécsénke",
        "infó:": ""
      },
      {
        "Település ID": 2907,
        "KSH településazonosító törzsszám": 6628,
        zipCode: 3170,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szécsény",
        "infó:": ""
      },
      {
        "Település ID": 2908,
        "KSH településazonosító törzsszám": 33011,
        zipCode: 3135,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szécsényfelfalu",
        "infó:": ""
      },
      {
        "Település ID": 2909,
        "KSH településazonosító törzsszám": 11554,
        zipCode: 8879,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szécsisziget",
        "infó:": ""
      },
      {
        "Település ID": 2910,
        "KSH településazonosító törzsszám": 5607,
        zipCode: 7751,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szederkény",
        "infó:": ""
      },
      {
        "Település ID": 2911,
        "KSH településazonosító törzsszám": 16814,
        zipCode: 7056,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Szedres",
        "infó:": ""
      },
      {
        "Település ID": 2912,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6700,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2913,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6710,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2914,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6720,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2915,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6721,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2916,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6722,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2917,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6723,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2918,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6724,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2919,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6725,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2920,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6726,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2921,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6727,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2922,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6728,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2923,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6729,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2924,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6753,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2925,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6757,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2926,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6771,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2927,
        "KSH településazonosító törzsszám": 33367,
        zipCode: 6791,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szeged",
        "infó:": ""
      },
      {
        "Település ID": 2928,
        "KSH településazonosító törzsszám": 18607,
        zipCode: 8732,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szegerd%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2929,
        "KSH településazonosító törzsszám": 21883,
        zipCode: 5520,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Szeghalom",
        "infó:": ""
      },
      {
        "Település ID": 2930,
        "KSH településazonosító törzsszám": 33817,
        zipCode: 3918,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szegi",
        "infó:": ""
      },
      {
        "Település ID": 2931,
        "KSH településazonosító törzsszám": 31510,
        zipCode: 3918,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szegilong",
        "infó:": ""
      },
      {
        "Település ID": 2932,
        "KSH településazonosító törzsszám": 32489,
        zipCode: 6635,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szegvár",
        "infó:": ""
      },
      {
        "Település ID": 2933,
        "KSH településazonosító törzsszám": 31088,
        zipCode: 4534,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Székely",
        "infó:": ""
      },
      {
        "Település ID": 2934,
        "KSH településazonosító törzsszám": 16771,
        zipCode: 7737,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Székelyszabar",
        "infó:": ""
      },
      {
        "Település ID": 2935,
        "KSH településazonosító törzsszám": 14827,
        zipCode: 8000,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Székesfehérvár",
        "infó:": ""
      },
      {
        "Település ID": 2936,
        "KSH településazonosító törzsszám": 14827,
        zipCode: 8019,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Székesfehérvár",
        "infó:": ""
      },
      {
        "Település ID": 2937,
        "KSH településazonosító törzsszám": 12265,
        zipCode: 6821,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Székkutas",
        "infó:": ""
      },
      {
        "Település ID": 2938,
        "KSH településazonosító törzsszám": 22761,
        zipCode: 7100,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Szekszárd",
        "infó:": ""
      },
      {
        "Település ID": 2939,
        "KSH településazonosító törzsszám": 33172,
        zipCode: 9622,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szeleste",
        "infó:": ""
      },
      {
        "Település ID": 2940,
        "KSH településazonosító törzsszám": 20428,
        zipCode: 5476,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Szelevény",
        "infó:": ""
      },
      {
        "Település ID": 2941,
        "KSH településazonosító törzsszám": 16984,
        zipCode: 7661,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szell%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2942,
        "KSH településazonosító törzsszám": 9308,
        zipCode: 7763,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szemely",
        "infó:": ""
      },
      {
        "Település ID": 2943,
        "KSH településazonosító törzsszám": 27997,
        zipCode: 9685,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szemenye",
        "infó:": ""
      },
      {
        "Település ID": 2944,
        "KSH településazonosító törzsszám": 9830,
        zipCode: 3866,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szemere",
        "infó:": ""
      },
      {
        "Település ID": 2945,
        "KSH településazonosító törzsszám": 4507,
        zipCode: 2640,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szendehely",
        "infó:": ""
      },
      {
        "Település ID": 2946,
        "KSH településazonosító törzsszám": 8077,
        zipCode: 3752,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szendr%u0151",
        "infó:": ""
      },
      {
        "Település ID": 2947,
        "KSH településazonosító törzsszám": 27456,
        zipCode: 3751,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szendr%u0151lád",
        "infó:": ""
      },
      {
        "Település ID": 2948,
        "KSH településazonosító törzsszám": 15103,
        zipCode: 7477,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szenna",
        "infó:": ""
      },
      {
        "Település ID": 2949,
        "KSH településazonosító törzsszám": 11509,
        zipCode: 8849,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szenta",
        "infó:": ""
      },
      {
        "Település ID": 2950,
        "KSH településazonosító törzsszám": 3568,
        zipCode: 8272,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Szentantalfa",
        "infó:": ""
      },
      {
        "Település ID": 2951,
        "KSH településazonosító törzsszám": 25706,
        zipCode: 7472,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szentbalázs",
        "infó:": ""
      },
      {
        "Település ID": 2952,
        "KSH településazonosító törzsszám": 7092,
        zipCode: 8281,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Szentbékkálla",
        "infó:": ""
      },
      {
        "Település ID": 2953,
        "KSH településazonosító törzsszám": 32841,
        zipCode: 7918,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szentborbás",
        "infó:": ""
      },
      {
        "Település ID": 2954,
        "KSH településazonosító törzsszám": 32009,
        zipCode: 7913,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szentdénes",
        "infó:": ""
      },
      {
        "Település ID": 2955,
        "KSH településazonosító törzsszám": 13231,
        zipCode: 3259,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Szentdomonkos",
        "infó:": ""
      },
      {
        "Település ID": 2956,
        "KSH településazonosító törzsszám": 9043,
        zipCode: 2655,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szente",
        "infó:": ""
      },
      {
        "Település ID": 2957,
        "KSH településazonosító törzsszám": 33613,
        zipCode: 7915,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szentegát",
        "infó:": ""
      },
      {
        "Település ID": 2958,
        "KSH településazonosító törzsszám": 15440,
        zipCode: 2000,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szentendre",
        "infó:": ""
      },
      {
        "Település ID": 2959,
        "KSH településazonosító törzsszám": 14456,
        zipCode: 6600,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Szentes",
        "infó:": ""
      },
      {
        "Település ID": 2960,
        "KSH településazonosító törzsszám": 7922,
        zipCode: 8444,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Szentgál",
        "infó:": ""
      },
      {
        "Település ID": 2961,
        "KSH településazonosító törzsszám": 12973,
        zipCode: 7465,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szentgáloskér",
        "infó:": ""
      },
      {
        "Település ID": 2962,
        "KSH településazonosító törzsszám": 31583,
        zipCode: 9955,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szentgotthárd",
        "infó:": ""
      },
      {
        "Település ID": 2963,
        "KSH településazonosító törzsszám": 31583,
        zipCode: 9970,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szentgotthárd",
        "infó:": ""
      },
      {
        "Település ID": 2964,
        "KSH településazonosító törzsszám": 31583,
        zipCode: 9981,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szentgotthárd",
        "infó:": ""
      },
      {
        "Település ID": 2965,
        "KSH településazonosító törzsszám": 23126,
        zipCode: 8393,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szentgyörgyvár",
        "infó:": ""
      },
      {
        "Település ID": 2966,
        "KSH településazonosító törzsszám": 18652,
        zipCode: 8975,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szentgyörgyvölgy",
        "infó:": ""
      },
      {
        "Település ID": 2967,
        "KSH településazonosító törzsszám": 10065,
        zipCode: 8475,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Szentimrefalva",
        "infó:": ""
      },
      {
        "Település ID": 2968,
        "KSH településazonosító törzsszám": 22169,
        zipCode: 3418,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szentistván",
        "infó:": ""
      },
      {
        "Település ID": 2969,
        "KSH településazonosító törzsszám": 8484,
        zipCode: 3844,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szentistvánbaksa",
        "infó:": ""
      },
      {
        "Település ID": 2970,
        "KSH településazonosító törzsszám": 11545,
        zipCode: 8272,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Szentjakabfa",
        "infó:": ""
      },
      {
        "Település ID": 2971,
        "KSH településazonosító törzsszám": 9803,
        zipCode: 7681,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szentkatalin",
        "infó:": ""
      },
      {
        "Település ID": 2972,
        "KSH településazonosító törzsszám": 21120,
        zipCode: 6031,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Szentkirály",
        "infó:": ""
      },
      {
        "Település ID": 2973,
        "KSH településazonosító törzsszám": 16267,
        zipCode: 8225,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Szentkirályszabadja",
        "infó:": ""
      },
      {
        "Település ID": 2974,
        "KSH településazonosító törzsszám": 3124,
        zipCode: 8947,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szentkozmadombja",
        "infó:": ""
      },
      {
        "Település ID": 2975,
        "KSH településazonosító törzsszám": 16355,
        zipCode: 7936,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szentlászló",
        "infó:": ""
      },
      {
        "Település ID": 2976,
        "KSH településazonosító törzsszám": 8615,
        zipCode: 8893,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szentliszló",
        "infó:": ""
      },
      {
        "Település ID": 2977,
        "KSH településazonosító törzsszám": 15866,
        zipCode: 7940,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szentl%u0151rinc",
        "infó:": ""
      },
      {
        "Település ID": 2978,
        "KSH településazonosító törzsszám": 7542,
        zipCode: 2255,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szentl%u0151rinckáta",
        "infó:": ""
      },
      {
        "Település ID": 2979,
        "KSH településazonosító törzsszám": 14225,
        zipCode: 8872,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szentmargitfalva",
        "infó:": ""
      },
      {
        "Település ID": 2980,
        "KSH településazonosító törzsszám": 28653,
        zipCode: 2254,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szentmártonkáta",
        "infó:": ""
      },
      {
        "Település ID": 2981,
        "KSH településazonosító törzsszám": 21254,
        zipCode: 9799,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szentpéterfa",
        "infó:": ""
      },
      {
        "Település ID": 2982,
        "KSH településazonosító törzsszám": 30067,
        zipCode: 8953,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szentpéterfölde",
        "infó:": ""
      },
      {
        "Település ID": 2983,
        "KSH településazonosító törzsszám": 19099,
        zipCode: 4121,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Szentpéterszeg",
        "infó:": ""
      },
      {
        "Település ID": 2984,
        "KSH településazonosító törzsszám": 11165,
        zipCode: 8762,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szentpéterúr",
        "infó:": ""
      },
      {
        "Település ID": 2985,
        "KSH településazonosító törzsszám": 30146,
        zipCode: 8717,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szenyér",
        "infó:": ""
      },
      {
        "Település ID": 2986,
        "KSH településazonosító törzsszám": 7700,
        zipCode: 8861,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szepetnek",
        "infó:": ""
      },
      {
        "Település ID": 2987,
        "KSH településazonosító törzsszám": 3887,
        zipCode: 9125,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Szerecseny",
        "infó:": ""
      },
      {
        "Település ID": 2988,
        "KSH településazonosító törzsszám": 28820,
        zipCode: 6512,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Szeremle",
        "infó:": ""
      },
      {
        "Település ID": 2989,
        "KSH településazonosító törzsszám": 30739,
        zipCode: 3900,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szerencs",
        "infó:": ""
      },
      {
        "Település ID": 2990,
        "KSH településazonosító törzsszám": 33437,
        zipCode: 4162,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Szerep",
        "infó:": ""
      },
      {
        "Település ID": 2991,
        "KSH településazonosító törzsszám": 33437,
        zipCode: 4163,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Szerep",
        "infó:": ""
      },
      {
        "Település ID": 2992,
        "KSH településazonosító törzsszám": 27793,
        zipCode: 9523,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szergény",
        "infó:": ""
      },
      {
        "Település ID": 2993,
        "KSH településazonosító törzsszám": 26259,
        zipCode: 2321,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szigetbecse",
        "infó:": ""
      },
      {
        "Település ID": 2994,
        "KSH településazonosító törzsszám": 7870,
        zipCode: 2317,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szigetcsép",
        "infó:": ""
      },
      {
        "Település ID": 2995,
        "KSH településazonosító törzsszám": 13277,
        zipCode: 2315,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szigethalom",
        "infó:": ""
      },
      {
        "Település ID": 2996,
        "KSH településazonosító törzsszám": 26213,
        zipCode: 2015,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szigetmonostor",
        "infó:": ""
      },
      {
        "Település ID": 2997,
        "KSH településazonosító törzsszám": 15185,
        zipCode: 2318,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szigetszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 2998,
        "KSH településazonosító törzsszám": 28954,
        zipCode: 2310,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szigetszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 2999,
        "KSH településazonosító törzsszám": 22114,
        zipCode: 2319,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szigetújfalu",
        "infó:": ""
      },
      {
        "Település ID": 3000,
        "KSH településazonosító törzsszám": 26578,
        zipCode: 7900,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szigetvár",
        "infó:": ""
      },
      {
        "Település ID": 3001,
        "KSH településazonosító törzsszám": 24891,
        zipCode: 8264,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Szigliget",
        "infó:": ""
      },
      {
        "Település ID": 3002,
        "KSH településazonosító törzsszám": 11013,
        zipCode: 3377,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Szihalom",
        "infó:": ""
      },
      {
        "Település ID": 3003,
        "KSH településazonosító törzsszám": 26161,
        zipCode: 8969,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szijártóháza",
        "infó:": ""
      },
      {
        "Település ID": 3004,
        "KSH településazonosító törzsszám": 21351,
        zipCode: 3800,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szikszó",
        "infó:": ""
      },
      {
        "Település ID": 3005,
        "KSH településazonosító törzsszám": 19266,
        zipCode: 9326,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Szil",
        "infó:": ""
      },
      {
        "Település ID": 3006,
        "KSH településazonosító törzsszám": 19831,
        zipCode: 7664,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szilágy",
        "infó:": ""
      },
      {
        "Település ID": 3007,
        "KSH településazonosító törzsszám": 7959,
        zipCode: 3125,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szilaspogony",
        "infó:": ""
      },
      {
        "Település ID": 3008,
        "KSH településazonosító törzsszám": 3364,
        zipCode: 9312,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Szilsárkány",
        "infó:": ""
      },
      {
        "Település ID": 3009,
        "KSH településazonosító törzsszám": 17853,
        zipCode: 8986,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Szilvágy",
        "infó:": ""
      },
      {
        "Település ID": 3010,
        "KSH településazonosító törzsszám": 24712,
        zipCode: 7811,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szilvás",
        "infó:": ""
      },
      {
        "Település ID": 3011,
        "KSH településazonosító törzsszám": 32461,
        zipCode: 7477,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szilvásszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 3012,
        "KSH településazonosító törzsszám": 5643,
        zipCode: 3348,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Szilvásvárad",
        "infó:": ""
      },
      {
        "Település ID": 3013,
        "KSH településazonosító törzsszám": 20871,
        zipCode: 3761,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szin",
        "infó:": ""
      },
      {
        "Település ID": 3014,
        "KSH településazonosító törzsszám": 11493,
        zipCode: 3761,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szinpetri",
        "infó:": ""
      },
      {
        "Település ID": 3015,
        "KSH településazonosító törzsszám": 21634,
        zipCode: 3044,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szirák",
        "infó:": ""
      },
      {
        "Település ID": 3016,
        "KSH településazonosító törzsszám": 9496,
        zipCode: 3711,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szirmabeseny%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3017,
        "KSH településazonosító törzsszám": 24916,
        zipCode: 2628,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szob",
        "infó:": ""
      },
      {
        "Település ID": 3018,
        "KSH településazonosító törzsszám": 16692,
        zipCode: 8452,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Sz%u0151c",
        "infó:": ""
      },
      {
        "Település ID": 3019,
        "KSH településazonosító törzsszám": 19521,
        zipCode: 9935,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Sz%u0151ce",
        "infó:": ""
      },
      {
        "Település ID": 3020,
        "KSH településazonosító törzsszám": 12690,
        zipCode: 2134,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Sz%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 3021,
        "KSH településazonosító törzsszám": 28866,
        zipCode: 2133,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Sz%u0151dliget",
        "infó:": ""
      },
      {
        "Település ID": 3050,
        "KSH településazonosító törzsszám": 16179,
        zipCode: 3762,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szögliget",
        "infó:": ""
      },
      {
        "Település ID": 3022,
        "KSH településazonosító törzsszám": 22211,
        zipCode: 7833,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sz%u0151ke",
        "infó:": ""
      },
      {
        "Település ID": 3023,
        "KSH településazonosító törzsszám": 7694,
        zipCode: 7763,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sz%u0151kéd",
        "infó:": ""
      },
      {
        "Település ID": 3024,
        "KSH településazonosító törzsszám": 5810,
        zipCode: 8736,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Sz%u0151kedencs",
        "infó:": ""
      },
      {
        "Település ID": 3025,
        "KSH településazonosító törzsszám": 6947,
        zipCode: 2624,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Szokolya",
        "infó:": ""
      },
      {
        "Település ID": 3026,
        "KSH településazonosító törzsszám": 15194,
        zipCode: 8625,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szólád",
        "infó:": ""
      },
      {
        "Település ID": 3027,
        "KSH településazonosító törzsszám": 27854,
        zipCode: 5000,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Szolnok",
        "infó:": ""
      },
      {
        "Település ID": 3028,
        "KSH településazonosító törzsszám": 27854,
        zipCode: 5008,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Szolnok",
        "infó:": ""
      },
      {
        "Település ID": 3029,
        "KSH településazonosító törzsszám": 7889,
        zipCode: 3757,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Sz%u0151l%u0151sardó",
        "infó:": ""
      },
      {
        "Település ID": 3030,
        "KSH településazonosító törzsszám": 11101,
        zipCode: 8692,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Sz%u0151l%u0151sgyörök",
        "infó:": ""
      },
      {
        "Település ID": 3031,
        "KSH településazonosító törzsszám": 3009,
        zipCode: 9700,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szombathely",
        "infó:": ""
      },
      {
        "Település ID": 3032,
        "KSH településazonosító törzsszám": 3009,
        zipCode: 9707,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szombathely",
        "infó:": ""
      },
      {
        "Település ID": 3033,
        "KSH településazonosító törzsszám": 3009,
        zipCode: 9719,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Szombathely",
        "infó:": ""
      },
      {
        "Település ID": 3034,
        "KSH településazonosító törzsszám": 22619,
        zipCode: 2896,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Szomód",
        "infó:": ""
      },
      {
        "Település ID": 3035,
        "KSH településazonosító törzsszám": 18892,
        zipCode: 3411,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szomolya",
        "infó:": ""
      },
      {
        "Település ID": 3036,
        "KSH településazonosító törzsszám": 21421,
        zipCode: 2822,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Szomor",
        "infó:": ""
      },
      {
        "Település ID": 3051,
        "KSH településazonosító törzsszám": 5528,
        zipCode: 7976,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szörény",
        "infó:": ""
      },
      {
        "Település ID": 3037,
        "KSH településazonosító törzsszám": 34388,
        zipCode: 4441,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Szorgalmatos",
        "infó:": ""
      },
      {
        "Település ID": 3038,
        "KSH településazonosító törzsszám": 5193,
        zipCode: 7285,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szorosad",
        "infó:": ""
      },
      {
        "Település ID": 3039,
        "KSH településazonosító törzsszám": 13523,
        zipCode: 3341,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Szúcs",
        "infó:": ""
      },
      {
        "Település ID": 3040,
        "KSH településazonosító törzsszám": 9982,
        zipCode: 3034,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Sz%u0171csi",
        "infó:": ""
      },
      {
        "Település ID": 3049,
        "KSH településazonosító törzsszám": 18786,
        zipCode: 2699,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szügy",
        "infó:": ""
      },
      {
        "Település ID": 3041,
        "KSH településazonosító törzsszám": 28194,
        zipCode: 3154,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szuha",
        "infó:": ""
      },
      {
        "Település ID": 3042,
        "KSH településazonosító törzsszám": 3160,
        zipCode: 3726,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szuhaf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3043,
        "KSH településazonosító törzsszám": 11110,
        zipCode: 3731,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szuhakálló",
        "infó:": ""
      },
      {
        "Település ID": 3044,
        "KSH településazonosító törzsszám": 24606,
        zipCode: 3734,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Szuhogy",
        "infó:": ""
      },
      {
        "Település ID": 3045,
        "KSH településazonosító törzsszám": 15404,
        zipCode: 7932,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Szulimán",
        "infó:": ""
      },
      {
        "Település ID": 3046,
        "KSH településazonosító törzsszám": 10986,
        zipCode: 7539,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Szulok",
        "infó:": ""
      },
      {
        "Település ID": 3047,
        "KSH településazonosító törzsszám": 11217,
        zipCode: 7735,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Sz%u0171r",
        "infó:": ""
      },
      {
        "Település ID": 3048,
        "KSH településazonosító törzsszám": 19044,
        zipCode: 3064,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Szurdokpüspöki",
        "infó:": ""
      },
      {
        "Település ID": 3055,
        "KSH településazonosító törzsszám": 8590,
        zipCode: 8660,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Tab",
        "infó:": ""
      },
      {
        "Település ID": 3056,
        "KSH településazonosító törzsszám": 14465,
        zipCode: 8088,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Tabajd",
        "infó:": ""
      },
      {
        "Település ID": 3057,
        "KSH településazonosító törzsszám": 25432,
        zipCode: 6224,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tabdi",
        "infó:": ""
      },
      {
        "Település ID": 3058,
        "KSH településazonosító törzsszám": 8332,
        zipCode: 2381,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Táborfalva",
        "infó:": ""
      },
      {
        "Település ID": 3059,
        "KSH településazonosító törzsszám": 29267,
        zipCode: 8121,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Tác",
        "infó:": ""
      },
      {
        "Település ID": 3060,
        "KSH településazonosító törzsszám": 17039,
        zipCode: 8272,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Tagyon",
        "infó:": ""
      },
      {
        "Település ID": 3061,
        "KSH településazonosító törzsszám": 31963,
        zipCode: 2021,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tahitótfalu",
        "infó:": ""
      },
      {
        "Település ID": 3062,
        "KSH településazonosító törzsszám": 31963,
        zipCode: 2022,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tahitótfalu",
        "infó:": ""
      },
      {
        "Település ID": 3063,
        "KSH településazonosító törzsszám": 13125,
        zipCode: 8541,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Takácsi",
        "infó:": ""
      },
      {
        "Település ID": 3064,
        "KSH településazonosító törzsszám": 29911,
        zipCode: 4845,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tákos",
        "infó:": ""
      },
      {
        "Település ID": 3065,
        "KSH településazonosító törzsszám": 30720,
        zipCode: 2335,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Taksony",
        "infó:": ""
      },
      {
        "Település ID": 3066,
        "KSH településazonosító törzsszám": 3708,
        zipCode: 3926,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Taktabáj",
        "infó:": ""
      },
      {
        "Település ID": 3067,
        "KSH településazonosító törzsszám": 18245,
        zipCode: 3922,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Taktaharkány",
        "infó:": ""
      },
      {
        "Település ID": 3068,
        "KSH településazonosító törzsszám": 28787,
        zipCode: 3924,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Taktakenéz",
        "infó:": ""
      },
      {
        "Település ID": 3069,
        "KSH településazonosító törzsszám": 3133,
        zipCode: 3921,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Taktaszada",
        "infó:": ""
      },
      {
        "Település ID": 3070,
        "KSH településazonosító törzsszám": 17321,
        zipCode: 8295,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Taliándörögd",
        "infó:": ""
      },
      {
        "Település ID": 3071,
        "KSH településazonosító törzsszám": 12210,
        zipCode: 3907,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tállya",
        "infó:": ""
      },
      {
        "Település ID": 3072,
        "KSH településazonosító törzsszám": 24563,
        zipCode: 7090,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Tamási",
        "infó:": ""
      },
      {
        "Település ID": 3073,
        "KSH településazonosító törzsszám": 24563,
        zipCode: 7091,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Tamási",
        "infó:": ""
      },
      {
        "Település ID": 3074,
        "KSH településazonosító törzsszám": 16212,
        zipCode: 9762,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Tanakajd",
        "infó:": ""
      },
      {
        "Település ID": 3075,
        "KSH településazonosító törzsszám": 14261,
        zipCode: 9095,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Táp",
        "infó:": ""
      },
      {
        "Település ID": 3076,
        "KSH településazonosító törzsszám": 15015,
        zipCode: 2764,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tápióbicske",
        "infó:": ""
      },
      {
        "Település ID": 3077,
        "KSH településazonosító törzsszám": 17303,
        zipCode: 2767,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tápiógyörgye",
        "infó:": ""
      },
      {
        "Település ID": 3078,
        "KSH településazonosító törzsszám": 9405,
        zipCode: 2253,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tápióság",
        "infó:": ""
      },
      {
        "Település ID": 3079,
        "KSH településazonosító törzsszám": 31796,
        zipCode: 2251,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tápiószecs%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3080,
        "KSH településazonosító törzsszám": 14146,
        zipCode: 2766,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tápiószele",
        "infó:": ""
      },
      {
        "Település ID": 3081,
        "KSH településazonosító törzsszám": 14571,
        zipCode: 2711,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tápiószentmárton",
        "infó:": ""
      },
      {
        "Település ID": 3082,
        "KSH településazonosító törzsszám": 2769,
        zipCode: 2769,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tápiósz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 3083,
        "KSH településazonosító törzsszám": 32045,
        zipCode: 9761,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Táplánszentkereszt",
        "infó:": ""
      },
      {
        "Település ID": 3084,
        "KSH településazonosító törzsszám": 29434,
        zipCode: 8297,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Tapolca",
        "infó:": ""
      },
      {
        "Település ID": 3085,
        "KSH településazonosító törzsszám": 29434,
        zipCode: 8300,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Tapolca",
        "infó:": ""
      },
      {
        "Település ID": 3086,
        "KSH településazonosító törzsszám": 24615,
        zipCode: 8718,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Tapsony",
        "infó:": ""
      },
      {
        "Település ID": 3087,
        "KSH településazonosító törzsszám": 21971,
        zipCode: 9094,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Tápszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 3088,
        "KSH településazonosító törzsszám": 32896,
        zipCode: 3073,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Tar",
        "infó:": ""
      },
      {
        "Település ID": 3089,
        "KSH településazonosító törzsszám": 16735,
        zipCode: 7514,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Tarany",
        "infó:": ""
      },
      {
        "Település ID": 3090,
        "KSH településazonosító törzsszám": 21740,
        zipCode: 3915,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tarcal",
        "infó:": ""
      },
      {
        "Település ID": 3091,
        "KSH településazonosító törzsszám": 8165,
        zipCode: 3416,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tard",
        "infó:": ""
      },
      {
        "Település ID": 3092,
        "KSH településazonosító törzsszám": 14784,
        zipCode: 3644,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tardona",
        "infó:": ""
      },
      {
        "Település ID": 3093,
        "KSH településazonosító törzsszám": 30225,
        zipCode: 2834,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tardos",
        "infó:": ""
      },
      {
        "Település ID": 3094,
        "KSH településazonosító törzsszám": 33075,
        zipCode: 5641,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Tarhos",
        "infó:": ""
      },
      {
        "Település ID": 3095,
        "KSH településazonosító törzsszám": 18935,
        zipCode: 2831,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tarján",
        "infó:": ""
      },
      {
        "Település ID": 3096,
        "KSH településazonosító törzsszám": 33914,
        zipCode: 9092,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Tarjánpuszta",
        "infó:": ""
      },
      {
        "Település ID": 3097,
        "KSH településazonosító törzsszám": 20987,
        zipCode: 2945,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tárkány",
        "infó:": ""
      },
      {
        "Település ID": 3098,
        "KSH településazonosító törzsszám": 32966,
        zipCode: 3369,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tarnabod",
        "infó:": ""
      },
      {
        "Település ID": 3099,
        "KSH településazonosító törzsszám": 13240,
        zipCode: 3258,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tarnalelesz",
        "infó:": ""
      },
      {
        "Település ID": 3100,
        "KSH településazonosító törzsszám": 23348,
        zipCode: 3284,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tarnaméra",
        "infó:": ""
      },
      {
        "Település ID": 3104,
        "KSH településazonosító törzsszám": 14128,
        zipCode: 3294,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tarnaörs",
        "infó:": ""
      },
      {
        "Település ID": 3101,
        "KSH településazonosító törzsszám": 9052,
        zipCode: 3331,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tarnaszentmária",
        "infó:": ""
      },
      {
        "Település ID": 3102,
        "KSH településazonosító törzsszám": 16160,
        zipCode: 3382,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tarnaszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 3103,
        "KSH településazonosító törzsszám": 17163,
        zipCode: 3283,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tarnazsadány",
        "infó:": ""
      },
      {
        "Település ID": 3105,
        "KSH településazonosító törzsszám": 4154,
        zipCode: 2461,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tárnok",
        "infó:": ""
      },
      {
        "Település ID": 3106,
        "KSH településazonosító törzsszám": 4172,
        zipCode: 9165,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Tárnokréti",
        "infó:": ""
      },
      {
        "Település ID": 3107,
        "KSH településazonosító törzsszám": 4312,
        zipCode: 4931,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tarpa",
        "infó:": ""
      },
      {
        "Település ID": 3108,
        "KSH településazonosító törzsszám": 15635,
        zipCode: 7362,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Tarrós",
        "infó:": ""
      },
      {
        "Település ID": 3109,
        "KSH településazonosító törzsszám": 13693,
        zipCode: 8696,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Táska",
        "infó:": ""
      },
      {
        "Település ID": 3110,
        "KSH településazonosító törzsszám": 20525,
        zipCode: 6098,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tass",
        "infó:": ""
      },
      {
        "Település ID": 3111,
        "KSH településazonosító törzsszám": 4932,
        zipCode: 7261,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Taszár",
        "infó:": ""
      },
      {
        "Település ID": 3112,
        "KSH településazonosító törzsszám": 8758,
        zipCode: 2534,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tát",
        "infó:": ""
      },
      {
        "Település ID": 3113,
        "KSH településazonosító törzsszám": 20127,
        zipCode: 2835,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tata",
        "infó:": ""
      },
      {
        "Település ID": 3114,
        "KSH településazonosító törzsszám": 20127,
        zipCode: 2890,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tata",
        "infó:": ""
      },
      {
        "Település ID": 3115,
        "KSH településazonosító törzsszám": 18157,
        zipCode: 2800,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tatabánya",
        "infó:": ""
      },
      {
        "Település ID": 3116,
        "KSH településazonosító törzsszám": 14544,
        zipCode: 6451,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tataháza",
        "infó:": ""
      },
      {
        "Település ID": 3117,
        "KSH településazonosító törzsszám": 27386,
        zipCode: 2375,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tatárszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 3118,
        "KSH településazonosító törzsszám": 24998,
        zipCode: 6236,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tázlár",
        "infó:": ""
      },
      {
        "Település ID": 3119,
        "KSH településazonosító törzsszám": 23214,
        zipCode: 4243,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Téglás",
        "infó:": ""
      },
      {
        "Település ID": 3120,
        "KSH településazonosító törzsszám": 27702,
        zipCode: 7381,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Tékes",
        "infó:": ""
      },
      {
        "Település ID": 3121,
        "KSH településazonosító törzsszám": 32072,
        zipCode: 7973,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Teklafalu",
        "infó:": ""
      },
      {
        "Település ID": 3122,
        "KSH településazonosító törzsszám": 29568,
        zipCode: 9812,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Telekes",
        "infó:": ""
      },
      {
        "Település ID": 3123,
        "KSH településazonosító törzsszám": 12681,
        zipCode: 5675,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Telekgerendás",
        "infó:": ""
      },
      {
        "Település ID": 3124,
        "KSH településazonosító törzsszám": 23968,
        zipCode: 8626,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Teleki",
        "infó:": ""
      },
      {
        "Település ID": 3125,
        "KSH településazonosító törzsszám": 8280,
        zipCode: 2089,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Telki",
        "infó:": ""
      },
      {
        "Település ID": 3126,
        "KSH településazonosító törzsszám": 13763,
        zipCode: 3896,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Telkibánya",
        "infó:": ""
      },
      {
        "Település ID": 3127,
        "KSH településazonosító törzsszám": 6901,
        zipCode: 7054,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Tengelic",
        "infó:": ""
      },
      {
        "Település ID": 3128,
        "KSH településazonosító törzsszám": 13675,
        zipCode: 7834,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Tengeri",
        "infó:": ""
      },
      {
        "Település ID": 3129,
        "KSH településazonosító törzsszám": 32416,
        zipCode: 8668,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Teng%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 3130,
        "KSH településazonosító törzsszám": 14076,
        zipCode: 3359,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tenk",
        "infó:": ""
      },
      {
        "Település ID": 3131,
        "KSH településazonosító törzsszám": 11457,
        zipCode: 9111,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Tény%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3132,
        "KSH településazonosító törzsszám": 31042,
        zipCode: 4132,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Tépe",
        "infó:": ""
      },
      {
        "Település ID": 3133,
        "KSH településazonosító törzsszám": 33358,
        zipCode: 4342,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Terem",
        "infó:": ""
      },
      {
        "Település ID": 3134,
        "KSH településazonosító törzsszám": 4844,
        zipCode: 2696,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Terény",
        "infó:": ""
      },
      {
        "Település ID": 3135,
        "KSH településazonosító törzsszám": 24174,
        zipCode: 2652,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Tereske",
        "infó:": ""
      },
      {
        "Település ID": 3136,
        "KSH településazonosító törzsszám": 28635,
        zipCode: 3757,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Teresztenye",
        "infó:": ""
      },
      {
        "Település ID": 3137,
        "KSH településazonosító törzsszám": 12229,
        zipCode: 3334,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Terpes",
        "infó:": ""
      },
      {
        "Település ID": 3138,
        "KSH településazonosító törzsszám": 20570,
        zipCode: 8109,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Tés",
        "infó:": ""
      },
      {
        "Település ID": 3139,
        "KSH településazonosító törzsszám": 19248,
        zipCode: 2635,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tésa",
        "infó:": ""
      },
      {
        "Település ID": 3140,
        "KSH településazonosító törzsszám": 32744,
        zipCode: 7843,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Tésenfa",
        "infó:": ""
      },
      {
        "Település ID": 3141,
        "KSH településazonosító törzsszám": 20978,
        zipCode: 7834,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Téseny",
        "infó:": ""
      },
      {
        "Település ID": 3142,
        "KSH településazonosító törzsszám": 8828,
        zipCode: 8991,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Teskánd",
        "infó:": ""
      },
      {
        "Település ID": 3143,
        "KSH településazonosító törzsszám": 19035,
        zipCode: 9100,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Tét",
        "infó:": ""
      },
      {
        "Település ID": 3144,
        "KSH településazonosító törzsszám": 19691,
        zipCode: 4184,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Tetétlen",
        "infó:": ""
      },
      {
        "Település ID": 3145,
        "KSH településazonosító törzsszám": 31459,
        zipCode: 7181,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Tevel",
        "infó:": ""
      },
      {
        "Település ID": 3146,
        "KSH településazonosító törzsszám": 30447,
        zipCode: 3423,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tibolddaróc",
        "infó:": ""
      },
      {
        "Település ID": 3147,
        "KSH településazonosító törzsszám": 8952,
        zipCode: 4353,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiborszállás",
        "infó:": ""
      },
      {
        "Település ID": 3148,
        "KSH településazonosító törzsszám": 30465,
        zipCode: 8237,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Tihany",
        "infó:": ""
      },
      {
        "Település ID": 3149,
        "KSH településazonosító törzsszám": 17844,
        zipCode: 8731,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Tikos",
        "infó:": ""
      },
      {
        "Település ID": 3150,
        "KSH településazonosító törzsszám": 27951,
        zipCode: 8782,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Tilaj",
        "infó:": ""
      },
      {
        "Település ID": 3151,
        "KSH településazonosító törzsszám": 24475,
        zipCode: 4466,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Timár",
        "infó:": ""
      },
      {
        "Település ID": 3152,
        "KSH településazonosító törzsszám": 7108,
        zipCode: 2086,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tinnye",
        "infó:": ""
      },
      {
        "Település ID": 3153,
        "KSH településazonosító törzsszám": 9423,
        zipCode: 4833,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszaadony",
        "infó:": ""
      },
      {
        "Település ID": 3154,
        "KSH településazonosító törzsszám": 24545,
        zipCode: 6066,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tiszaalpár",
        "infó:": ""
      },
      {
        "Település ID": 3155,
        "KSH településazonosító törzsszám": 24545,
        zipCode: 6067,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tiszaalpár",
        "infó:": ""
      },
      {
        "Település ID": 3156,
        "KSH településazonosító törzsszám": 2291,
        zipCode: 3465,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszabábolna",
        "infó:": ""
      },
      {
        "Település ID": 3157,
        "KSH településazonosító törzsszám": 17817,
        zipCode: 4951,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszabecs",
        "infó:": ""
      },
      {
        "Település ID": 3158,
        "KSH településazonosító törzsszám": 3850,
        zipCode: 4474,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszabercel",
        "infó:": ""
      },
      {
        "Település ID": 3159,
        "KSH településazonosító törzsszám": 20172,
        zipCode: 4624,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszabezdéd",
        "infó:": ""
      },
      {
        "Település ID": 3160,
        "KSH településazonosító törzsszám": 10773,
        zipCode: 5232,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszab%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3161,
        "KSH településazonosító törzsszám": 22770,
        zipCode: 5235,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszabura",
        "infó:": ""
      },
      {
        "Település ID": 3162,
        "KSH településazonosító törzsszám": 24448,
        zipCode: 4947,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszacsécse",
        "infó:": ""
      },
      {
        "Település ID": 3163,
        "KSH településazonosító törzsszám": 15644,
        zipCode: 4066,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Tiszacsege",
        "infó:": ""
      },
      {
        "Település ID": 3164,
        "KSH településazonosító törzsszám": 29133,
        zipCode: 3972,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszacsermely",
        "infó:": ""
      },
      {
        "Település ID": 3165,
        "KSH településazonosító törzsszám": 6433,
        zipCode: 4455,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszadada",
        "infó:": ""
      },
      {
        "Település ID": 3166,
        "KSH településazonosító törzsszám": 16230,
        zipCode: 5243,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszaderzs",
        "infó:": ""
      },
      {
        "Település ID": 3167,
        "KSH településazonosító törzsszám": 12593,
        zipCode: 4456,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszadob",
        "infó:": ""
      },
      {
        "Település ID": 3168,
        "KSH településazonosító törzsszám": 3717,
        zipCode: 3466,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszadorogma",
        "infó:": ""
      },
      {
        "Település ID": 3169,
        "KSH településazonosító törzsszám": 9113,
        zipCode: 4446,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszaeszlár",
        "infó:": ""
      },
      {
        "Település ID": 3170,
        "KSH településazonosító törzsszám": 9113,
        zipCode: 4464,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszaeszlár",
        "infó:": ""
      },
      {
        "Település ID": 3174,
        "KSH településazonosító törzsszám": 13815,
        zipCode: 5430,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszaföldvár",
        "infó:": ""
      },
      {
        "Település ID": 3175,
        "KSH településazonosító törzsszám": 13815,
        zipCode: 5461,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszaföldvár",
        "infó:": ""
      },
      {
        "Település ID": 3171,
        "KSH településazonosító törzsszám": 29726,
        zipCode: 5350,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszafüred",
        "infó:": ""
      },
      {
        "Település ID": 3172,
        "KSH településazonosító törzsszám": 29726,
        zipCode: 5358,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszafüred",
        "infó:": ""
      },
      {
        "Település ID": 3173,
        "KSH településazonosító törzsszám": 29726,
        zipCode: 5359,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszafüred",
        "infó:": ""
      },
      {
        "Település ID": 3176,
        "KSH településazonosító törzsszám": 30304,
        zipCode: 5233,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszagyenda",
        "infó:": ""
      },
      {
        "Település ID": 3177,
        "KSH településazonosító törzsszám": 30845,
        zipCode: 4097,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Tiszagyulaháza",
        "infó:": ""
      },
      {
        "Település ID": 3178,
        "KSH településazonosító törzsszám": 28699,
        zipCode: 5361,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszaigar",
        "infó:": ""
      },
      {
        "Település ID": 3179,
        "KSH településazonosító törzsszám": 20446,
        zipCode: 5464,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszainoka",
        "infó:": ""
      },
      {
        "Település ID": 3180,
        "KSH településazonosító törzsszám": 29346,
        zipCode: 5094,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszajen%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3181,
        "KSH településazonosító törzsszám": 8554,
        zipCode: 4493,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszakanyár",
        "infó:": ""
      },
      {
        "Település ID": 3182,
        "KSH településazonosító törzsszám": 13976,
        zipCode: 3971,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszakarád",
        "infó:": ""
      },
      {
        "Település ID": 3183,
        "KSH településazonosító törzsszám": 30623,
        zipCode: 6060,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tiszakécske",
        "infó:": ""
      },
      {
        "Település ID": 3184,
        "KSH településazonosító törzsszám": 30623,
        zipCode: 6062,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tiszakécske",
        "infó:": ""
      },
      {
        "Település ID": 3185,
        "KSH településazonosító törzsszám": 4446,
        zipCode: 4834,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszakerecseny",
        "infó:": ""
      },
      {
        "Település ID": 3186,
        "KSH településazonosító törzsszám": 13888,
        zipCode: 3458,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszakeszi",
        "infó:": ""
      },
      {
        "Település ID": 3187,
        "KSH településazonosító törzsszám": 8794,
        zipCode: 4946,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszakóród",
        "infó:": ""
      },
      {
        "Település ID": 3188,
        "KSH településazonosító törzsszám": 30386,
        zipCode: 5471,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszakürt",
        "infó:": ""
      },
      {
        "Település ID": 3189,
        "KSH településazonosító törzsszám": 30386,
        zipCode: 5472,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszakürt",
        "infó:": ""
      },
      {
        "Település ID": 3190,
        "KSH településazonosító törzsszám": 19381,
        zipCode: 3929,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszaladány",
        "infó:": ""
      },
      {
        "Település ID": 3192,
        "KSH településazonosító törzsszám": 23524,
        zipCode: 4447,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszalök",
        "infó:": ""
      },
      {
        "Település ID": 3193,
        "KSH településazonosító törzsszám": 23524,
        zipCode: 4450,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszalök",
        "infó:": ""
      },
      {
        "Település ID": 3191,
        "KSH településazonosító törzsszám": 28398,
        zipCode: 3565,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszalúc",
        "infó:": ""
      },
      {
        "Település ID": 3194,
        "KSH településazonosító törzsszám": 11907,
        zipCode: 4645,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszamogyorós",
        "infó:": ""
      },
      {
        "Település ID": 3195,
        "KSH településazonosító törzsszám": 27252,
        zipCode: 4463,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszanagyfalu",
        "infó:": ""
      },
      {
        "Település ID": 3196,
        "KSH településazonosító törzsszám": 7083,
        zipCode: 3385,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tiszanána",
        "infó:": ""
      },
      {
        "Település ID": 3221,
        "KSH településazonosító törzsszám": 15787,
        zipCode: 5362,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszaörs",
        "infó:": ""
      },
      {
        "Település ID": 3197,
        "KSH településazonosító törzsszám": 8633,
        zipCode: 3587,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszapalkonya",
        "infó:": ""
      },
      {
        "Település ID": 3198,
        "KSH településazonosító törzsszám": 3373,
        zipCode: 5211,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszapüspöki",
        "infó:": ""
      },
      {
        "Település ID": 3199,
        "KSH településazonosító törzsszám": 10205,
        zipCode: 4503,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszarád",
        "infó:": ""
      },
      {
        "Település ID": 3200,
        "KSH településazonosító törzsszám": 20181,
        zipCode: 5234,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszaroff",
        "infó:": ""
      },
      {
        "Település ID": 3201,
        "KSH településazonosító törzsszám": 21494,
        zipCode: 5474,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszasas",
        "infó:": ""
      },
      {
        "Település ID": 3202,
        "KSH településazonosító törzsszám": 17695,
        zipCode: 5061,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszasüly",
        "infó:": ""
      },
      {
        "Település ID": 3203,
        "KSH településazonosító törzsszám": 13541,
        zipCode: 4831,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszaszalka",
        "infó:": ""
      },
      {
        "Település ID": 3204,
        "KSH településazonosító törzsszám": 22789,
        zipCode: 5322,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszaszentimre",
        "infó:": ""
      },
      {
        "Település ID": 3205,
        "KSH településazonosító törzsszám": 22789,
        zipCode: 5323,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszaszentimre",
        "infó:": ""
      },
      {
        "Település ID": 3206,
        "KSH településazonosító törzsszám": 27544,
        zipCode: 4628,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszaszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 3207,
        "KSH településazonosító törzsszám": 16966,
        zipCode: 6756,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Tiszasziget",
        "infó:": ""
      },
      {
        "Település ID": 3208,
        "KSH településazonosító törzsszám": 7852,
        zipCode: 5244,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszasz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 3209,
        "KSH településazonosító törzsszám": 30298,
        zipCode: 3928,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszatardos",
        "infó:": ""
      },
      {
        "Település ID": 3210,
        "KSH településazonosító törzsszám": 30377,
        zipCode: 3589,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszatarján",
        "infó:": ""
      },
      {
        "Település ID": 3211,
        "KSH településazonosító törzsszám": 14447,
        zipCode: 4486,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszatelek",
        "infó:": ""
      },
      {
        "Település ID": 3212,
        "KSH településazonosító törzsszám": 14447,
        zipCode: 4487,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszatelek",
        "infó:": ""
      },
      {
        "Település ID": 3213,
        "KSH településazonosító törzsszám": 9627,
        zipCode: 5082,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszateny%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3214,
        "KSH településazonosító törzsszám": 14094,
        zipCode: 6064,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tiszaug",
        "infó:": ""
      },
      {
        "Település ID": 3215,
        "KSH településazonosító törzsszám": 28352,
        zipCode: 3580,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszaújváros",
        "infó:": ""
      },
      {
        "Település ID": 3216,
        "KSH településazonosító törzsszám": 10977,
        zipCode: 3464,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tiszavalk",
        "infó:": ""
      },
      {
        "Település ID": 3217,
        "KSH településazonosító törzsszám": 31866,
        zipCode: 5092,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszavárkony",
        "infó:": ""
      },
      {
        "Település ID": 3218,
        "KSH településazonosító törzsszám": 31866,
        zipCode: 5095,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tiszavárkony",
        "infó:": ""
      },
      {
        "Település ID": 3219,
        "KSH településazonosító törzsszám": 7597,
        zipCode: 4440,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszavasvári",
        "infó:": ""
      },
      {
        "Település ID": 3220,
        "KSH településazonosító törzsszám": 33747,
        zipCode: 4832,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tiszavid",
        "infó:": ""
      },
      {
        "Település ID": 3222,
        "KSH településazonosító törzsszám": 27261,
        zipCode: 4969,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tisztaberek",
        "infó:": ""
      },
      {
        "Település ID": 3223,
        "KSH településazonosító törzsszám": 20260,
        zipCode: 4921,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tivadar",
        "infó:": ""
      },
      {
        "Település ID": 3224,
        "KSH településazonosító törzsszám": 21467,
        zipCode: 2252,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tóalmás",
        "infó:": ""
      },
      {
        "Település ID": 3225,
        "KSH településazonosító törzsszám": 9964,
        zipCode: 3354,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Tófalu",
        "infó:": ""
      },
      {
        "Település ID": 3226,
        "KSH településazonosító törzsszám": 5342,
        zipCode: 8946,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Tófej",
        "infó:": ""
      },
      {
        "Település ID": 3227,
        "KSH településazonosító törzsszám": 4048,
        zipCode: 7348,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Tóf%u0171",
        "infó:": ""
      },
      {
        "Település ID": 3274,
        "KSH településazonosító törzsszám": 24527,
        zipCode: 2073,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tök",
        "infó:": ""
      },
      {
        "Település ID": 3228,
        "KSH településazonosító törzsszám": 18306,
        zipCode: 3910,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tokaj",
        "infó:": ""
      },
      {
        "Település ID": 3229,
        "KSH településazonosító törzsszám": 14155,
        zipCode: 2531,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tokod",
        "infó:": ""
      },
      {
        "Település ID": 3230,
        "KSH településazonosító törzsszám": 34023,
        zipCode: 2532,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Tokodaltáró",
        "infó:": ""
      },
      {
        "Település ID": 3275,
        "KSH településazonosító törzsszám": 29823,
        zipCode: 2316,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tököl",
        "infó:": ""
      },
      {
        "Település ID": 3231,
        "KSH településazonosító törzsszám": 9229,
        zipCode: 9561,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Tokorcs",
        "infó:": ""
      },
      {
        "Település ID": 3232,
        "KSH településazonosító törzsszám": 28051,
        zipCode: 3934,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tolcsva",
        "infó:": ""
      },
      {
        "Település ID": 3233,
        "KSH településazonosító törzsszám": 25876,
        zipCode: 4117,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Told",
        "infó:": ""
      },
      {
        "Település ID": 3234,
        "KSH településazonosító törzsszám": 6381,
        zipCode: 2657,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Tolmács",
        "infó:": ""
      },
      {
        "Település ID": 3235,
        "KSH településazonosító törzsszám": 25274,
        zipCode: 7130,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Tolna",
        "infó:": ""
      },
      {
        "Település ID": 3236,
        "KSH településazonosító törzsszám": 25274,
        zipCode: 7131,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Tolna",
        "infó:": ""
      },
      {
        "Település ID": 3237,
        "KSH településazonosító törzsszám": 11031,
        zipCode: 7083,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Tolnanémedi",
        "infó:": ""
      },
      {
        "Település ID": 3276,
        "KSH településazonosító törzsszám": 16674,
        zipCode: 9086,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Töltéstava",
        "infó:": ""
      },
      {
        "Település ID": 3238,
        "KSH településazonosító törzsszám": 9557,
        zipCode: 5324,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tomajmonostora",
        "infó:": ""
      },
      {
        "Település ID": 3239,
        "KSH településazonosító törzsszám": 14890,
        zipCode: 3787,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tomor",
        "infó:": ""
      },
      {
        "Település ID": 3277,
        "KSH településazonosító törzsszám": 5166,
        zipCode: 9738,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Tömörd",
        "infó:": ""
      },
      {
        "Település ID": 3278,
        "KSH településazonosító törzsszám": 25900,
        zipCode: 6646,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Tömörkény",
        "infó:": ""
      },
      {
        "Település ID": 3240,
        "KSH településazonosító törzsszám": 28486,
        zipCode: 6422,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Tompa",
        "infó:": ""
      },
      {
        "Település ID": 3241,
        "KSH településazonosító törzsszám": 12335,
        zipCode: 9662,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Tompaládony",
        "infó:": ""
      },
      {
        "Település ID": 3242,
        "KSH településazonosító törzsszám": 21005,
        zipCode: 2463,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Tordas",
        "infó:": ""
      },
      {
        "Település ID": 3243,
        "KSH településazonosító törzsszám": 4969,
        zipCode: 8876,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Tormafölde",
        "infó:": ""
      },
      {
        "Település ID": 3244,
        "KSH településazonosító törzsszám": 22424,
        zipCode: 7383,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Tormás",
        "infó:": ""
      },
      {
        "Település ID": 3245,
        "KSH településazonosító törzsszám": 34087,
        zipCode: 9736,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Tormásliget",
        "infó:": ""
      },
      {
        "Település ID": 3246,
        "KSH településazonosító törzsszám": 16902,
        zipCode: 3765,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tornabarakony",
        "infó:": ""
      },
      {
        "Település ID": 3247,
        "KSH településazonosító törzsszám": 10375,
        zipCode: 3761,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tornakápolna",
        "infó:": ""
      },
      {
        "Település ID": 3248,
        "KSH településazonosító törzsszám": 18801,
        zipCode: 3767,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tornanádaska",
        "infó:": ""
      },
      {
        "Település ID": 3249,
        "KSH településazonosító törzsszám": 27836,
        zipCode: 3765,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tornaszentandrás",
        "infó:": ""
      },
      {
        "Település ID": 3250,
        "KSH településazonosító törzsszám": 30517,
        zipCode: 3769,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tornaszentjakab",
        "infó:": ""
      },
      {
        "Település ID": 3251,
        "KSH településazonosító törzsszám": 32638,
        zipCode: 8877,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Tornyiszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 3252,
        "KSH településazonosító törzsszám": 29054,
        zipCode: 3877,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Tornyosnémeti",
        "infó:": ""
      },
      {
        "Település ID": 3253,
        "KSH településazonosító törzsszám": 16957,
        zipCode: 4642,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tornyospálca",
        "infó:": ""
      },
      {
        "Település ID": 3280,
        "KSH településazonosító törzsszám": 6859,
        zipCode: 2045,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Törökbálint",
        "infó:": ""
      },
      {
        "Település ID": 3281,
        "KSH településazonosító törzsszám": 20093,
        zipCode: 7285,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Törökkoppány",
        "infó:": ""
      },
      {
        "Település ID": 3282,
        "KSH településazonosító törzsszám": 27313,
        zipCode: 5200,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Törökszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 3283,
        "KSH településazonosító törzsszám": 27313,
        zipCode: 5212,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Törökszentmiklós",
        "infó:": ""
      },
      {
        "Település ID": 3254,
        "KSH településazonosító törzsszám": 29878,
        zipCode: 9791,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Torony",
        "infó:": ""
      },
      {
        "Település ID": 3279,
        "KSH településazonosító törzsszám": 22008,
        zipCode: 2747,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Törtel",
        "infó:": ""
      },
      {
        "Település ID": 3255,
        "KSH településazonosító törzsszám": 10153,
        zipCode: 8660,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Torvaj",
        "infó:": ""
      },
      {
        "Település ID": 3256,
        "KSH településazonosító törzsszám": 7490,
        zipCode: 5091,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Tószeg",
        "infó:": ""
      },
      {
        "Település ID": 3257,
        "KSH településazonosító törzsszám": 16434,
        zipCode: 5940,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Tótkomlós",
        "infó:": ""
      },
      {
        "Település ID": 3258,
        "KSH településazonosító törzsszám": 26994,
        zipCode: 7981,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Tótszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 3259,
        "KSH településazonosító törzsszám": 16382,
        zipCode: 8865,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Tótszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 3260,
        "KSH településazonosító törzsszám": 25113,
        zipCode: 8864,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Tótszerdahely",
        "infó:": ""
      },
      {
        "Település ID": 3284,
        "KSH településazonosító törzsszám": 4136,
        zipCode: 7755,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Töttös",
        "infó:": ""
      },
      {
        "Település ID": 3261,
        "KSH településazonosító törzsszám": 16407,
        zipCode: 7918,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Tótújfalu",
        "infó:": ""
      },
      {
        "Település ID": 3262,
        "KSH településazonosító törzsszám": 2714,
        zipCode: 8246,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Tótvázsony",
        "infó:": ""
      },
      {
        "Település ID": 3263,
        "KSH településazonosító törzsszám": 4914,
        zipCode: 3724,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Trizs",
        "infó:": ""
      },
      {
        "Település ID": 3264,
        "KSH településazonosító törzsszám": 13213,
        zipCode: 4731,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tunyogmatolcs",
        "infó:": ""
      },
      {
        "Település ID": 3265,
        "KSH településazonosító törzsszám": 9593,
        zipCode: 2194,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Tura",
        "infó:": ""
      },
      {
        "Település ID": 3266,
        "KSH településazonosító törzsszám": 13602,
        zipCode: 4944,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Túristvándi",
        "infó:": ""
      },
      {
        "Település ID": 3271,
        "KSH településazonosító törzsszám": 12609,
        zipCode: 8796,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Türje",
        "infó:": ""
      },
      {
        "Település ID": 3267,
        "KSH településazonosító törzsszám": 28228,
        zipCode: 5420,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Túrkeve",
        "infó:": ""
      },
      {
        "Település ID": 3268,
        "KSH településazonosító törzsszám": 18582,
        zipCode: 7811,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Túrony",
        "infó:": ""
      },
      {
        "Település ID": 3269,
        "KSH településazonosító törzsszám": 8998,
        zipCode: 4968,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Túrricse",
        "infó:": ""
      },
      {
        "Település ID": 3272,
        "KSH településazonosító törzsszám": 4631,
        zipCode: 8477,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Tüskevár",
        "infó:": ""
      },
      {
        "Település ID": 3270,
        "KSH településazonosító törzsszám": 9919,
        zipCode: 4623,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tuzsér",
        "infó:": ""
      },
      {
        "Település ID": 3273,
        "KSH településazonosító törzsszám": 31398,
        zipCode: 4762,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Tyukod",
        "infó:": ""
      },
      {
        "Település ID": 3285,
        "KSH településazonosító törzsszám": 4437,
        zipCode: 7718,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Udvar",
        "infó:": ""
      },
      {
        "Település ID": 3286,
        "KSH településazonosító törzsszám": 21360,
        zipCode: 7066,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Udvari",
        "infó:": ""
      },
      {
        "Település ID": 3287,
        "KSH településazonosító törzsszám": 24767,
        zipCode: 8564,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Ugod",
        "infó:": ""
      },
      {
        "Település ID": 3288,
        "KSH településazonosító törzsszám": 17482,
        zipCode: 2066,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Újbarok",
        "infó:": ""
      },
      {
        "Település ID": 3289,
        "KSH településazonosító törzsszám": 12487,
        zipCode: 3716,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Újcsanálos",
        "infó:": ""
      },
      {
        "Település ID": 3290,
        "KSH településazonosító törzsszám": 33659,
        zipCode: 4491,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Újdombrád",
        "infó:": ""
      },
      {
        "Település ID": 3291,
        "KSH településazonosító törzsszám": 26611,
        zipCode: 4244,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Újfehértó",
        "infó:": ""
      },
      {
        "Település ID": 3292,
        "KSH településazonosító törzsszám": 6293,
        zipCode: 2367,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Újhartyán",
        "infó:": ""
      },
      {
        "Település ID": 3293,
        "KSH településazonosító törzsszám": 23393,
        zipCode: 4146,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Újiráz",
        "infó:": ""
      },
      {
        "Település ID": 3294,
        "KSH településazonosító törzsszám": 20561,
        zipCode: 7095,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Újireg",
        "infó:": ""
      },
      {
        "Település ID": 3295,
        "KSH településazonosító törzsszám": 10117,
        zipCode: 4635,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Újkenéz",
        "infó:": ""
      },
      {
        "Település ID": 3296,
        "KSH településazonosító törzsszám": 19673,
        zipCode: 9472,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Újkér",
        "infó:": ""
      },
      {
        "Település ID": 3297,
        "KSH településazonosító törzsszám": 2352,
        zipCode: 5661,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Újkígyós",
        "infó:": ""
      },
      {
        "Település ID": 3298,
        "KSH településazonosító törzsszám": 19682,
        zipCode: 2724,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Újlengyel",
        "infó:": ""
      },
      {
        "Település ID": 3299,
        "KSH településazonosító törzsszám": 20419,
        zipCode: 4288,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Újléta",
        "infó:": ""
      },
      {
        "Település ID": 3300,
        "KSH településazonosító törzsszám": 27623,
        zipCode: 3387,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Újl%u0151rincfalva",
        "infó:": ""
      },
      {
        "Település ID": 3301,
        "KSH településazonosító törzsszám": 6062,
        zipCode: 7766,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Újpetre",
        "infó:": ""
      },
      {
        "Település ID": 3302,
        "KSH településazonosító törzsszám": 31839,
        zipCode: 9244,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Újrónaf%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3303,
        "KSH településazonosító törzsszám": 8785,
        zipCode: 6320,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Újsolt",
        "infó:": ""
      },
      {
        "Település ID": 3304,
        "KSH településazonosító törzsszám": 24350,
        zipCode: 5727,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Újszalonta",
        "infó:": ""
      },
      {
        "Település ID": 3305,
        "KSH településazonosító törzsszám": 15291,
        zipCode: 5052,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Újszász",
        "infó:": ""
      },
      {
        "Település ID": 3306,
        "KSH településazonosító törzsszám": 14924,
        zipCode: 6754,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Újszentiván",
        "infó:": ""
      },
      {
        "Település ID": 3307,
        "KSH településazonosító törzsszám": 32568,
        zipCode: 4065,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Újszentmargita",
        "infó:": ""
      },
      {
        "Település ID": 3308,
        "KSH településazonosító törzsszám": 17808,
        zipCode: 2768,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Újszilvás",
        "infó:": ""
      },
      {
        "Település ID": 3309,
        "KSH településazonosító törzsszám": 33604,
        zipCode: 6337,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Újtelek",
        "infó:": ""
      },
      {
        "Település ID": 3310,
        "KSH településazonosító törzsszám": 11925,
        zipCode: 4096,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Újtikos",
        "infó:": ""
      },
      {
        "Település ID": 3311,
        "KSH településazonosító törzsszám": 32197,
        zipCode: 8778,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Újudvar",
        "infó:": ""
      },
      {
        "Település ID": 3312,
        "KSH településazonosító törzsszám": 7205,
        zipCode: 7436,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Újvárfalva",
        "infó:": ""
      },
      {
        "Település ID": 3313,
        "KSH településazonosító törzsszám": 23010,
        zipCode: 8347,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Ukk",
        "infó:": ""
      },
      {
        "Település ID": 3472,
        "KSH településazonosító törzsszám": 21412,
        zipCode: 6794,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Üllés",
        "infó:": ""
      },
      {
        "Település ID": 3473,
        "KSH településazonosító törzsszám": 12894,
        zipCode: 2225,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Üll%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3314,
        "KSH településazonosító törzsszám": 20792,
        zipCode: 9464,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Und",
        "infó:": ""
      },
      {
        "Település ID": 3315,
        "KSH településazonosító törzsszám": 27632,
        zipCode: 2528,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Úny",
        "infó:": ""
      },
      {
        "Település ID": 3316,
        "KSH településazonosító törzsszám": 18351,
        zipCode: 3622,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Uppony",
        "infó:": ""
      },
      {
        "Település ID": 3317,
        "KSH településazonosító törzsszám": 28981,
        zipCode: 4763,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Ura",
        "infó:": ""
      },
      {
        "Település ID": 3318,
        "KSH településazonosító törzsszám": 21537,
        zipCode: 9651,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Uraiújfalu",
        "infó:": ""
      },
      {
        "Település ID": 3319,
        "KSH településazonosító törzsszám": 17622,
        zipCode: 8142,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Úrhida",
        "infó:": ""
      },
      {
        "Település ID": 3320,
        "KSH településazonosító törzsszám": 28644,
        zipCode: 2244,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Úri",
        "infó:": ""
      },
      {
        "Település ID": 3321,
        "KSH településazonosító törzsszám": 20853,
        zipCode: 8409,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Úrkút",
        "infó:": ""
      },
      {
        "Település ID": 3474,
        "KSH településazonosító törzsszám": 11934,
        zipCode: 2096,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Üröm",
        "infó:": ""
      },
      {
        "Település ID": 3322,
        "KSH településazonosító törzsszám": 31820,
        zipCode: 4952,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Uszka",
        "infó:": ""
      },
      {
        "Település ID": 3323,
        "KSH településazonosító törzsszám": 16294,
        zipCode: 6332,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Uszód",
        "infó:": ""
      },
      {
        "Település ID": 3324,
        "KSH településazonosító törzsszám": 34218,
        zipCode: 8321,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Uzsa",
        "infó:": ""
      },
      {
        "Település ID": 3325,
        "KSH településazonosító törzsszám": 24934,
        zipCode: 2600,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Vác",
        "infó:": ""
      },
      {
        "Település ID": 3326,
        "KSH településazonosító törzsszám": 5917,
        zipCode: 2167,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Vácduka",
        "infó:": ""
      },
      {
        "Település ID": 3327,
        "KSH településazonosító törzsszám": 30331,
        zipCode: 2184,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Vácegres",
        "infó:": ""
      },
      {
        "Település ID": 3328,
        "KSH településazonosító törzsszám": 19886,
        zipCode: 2164,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Váchartyán",
        "infó:": ""
      },
      {
        "Település ID": 3329,
        "KSH településazonosító törzsszám": 5698,
        zipCode: 2185,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Váckisújfalu",
        "infó:": ""
      },
      {
        "Település ID": 3330,
        "KSH településazonosító törzsszám": 17668,
        zipCode: 2163,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Vácrátót",
        "infó:": ""
      },
      {
        "Település ID": 3331,
        "KSH településazonosító törzsszám": 9104,
        zipCode: 2115,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Vácszentlászló",
        "infó:": ""
      },
      {
        "Település ID": 3332,
        "KSH településazonosító törzsszám": 7223,
        zipCode: 3636,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Vadna",
        "infó:": ""
      },
      {
        "Település ID": 3333,
        "KSH településazonosító törzsszám": 6239,
        zipCode: 9346,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Vadosfa",
        "infó:": ""
      },
      {
        "Település ID": 3334,
        "KSH településazonosító törzsszám": 2237,
        zipCode: 9327,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Vág",
        "infó:": ""
      },
      {
        "Település ID": 3335,
        "KSH településazonosító törzsszám": 29151,
        zipCode: 3992,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Vágáshuta",
        "infó:": ""
      },
      {
        "Település ID": 3336,
        "KSH településazonosító törzsszám": 18591,
        zipCode: 4562,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Vaja",
        "infó:": ""
      },
      {
        "Település ID": 3337,
        "KSH településazonosító törzsszám": 30003,
        zipCode: 3961,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Vajdácska",
        "infó:": ""
      },
      {
        "Település ID": 3338,
        "KSH településazonosító törzsszám": 28538,
        zipCode: 7838,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Vajszló",
        "infó:": ""
      },
      {
        "Település ID": 3339,
        "KSH településazonosító törzsszám": 2459,
        zipCode: 7041,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Vajta",
        "infó:": ""
      },
      {
        "Település ID": 3340,
        "KSH településazonosító törzsszám": 5829,
        zipCode: 2473,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Vál",
        "infó:": ""
      },
      {
        "Település ID": 3341,
        "KSH településazonosító törzsszám": 17598,
        zipCode: 2114,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Valkó",
        "infó:": ""
      },
      {
        "Település ID": 3342,
        "KSH településazonosító törzsszám": 26268,
        zipCode: 8885,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Valkonya",
        "infó:": ""
      },
      {
        "Település ID": 3343,
        "KSH településazonosító törzsszám": 6938,
        zipCode: 4351,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Vállaj",
        "infó:": ""
      },
      {
        "Település ID": 3344,
        "KSH településazonosító törzsszám": 10685,
        zipCode: 8316,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Vállus",
        "infó:": ""
      },
      {
        "Település ID": 3345,
        "KSH településazonosító törzsszám": 27322,
        zipCode: 4936,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Vámosatya",
        "infó:": ""
      },
      {
        "Település ID": 3346,
        "KSH településazonosító törzsszám": 31051,
        zipCode: 9665,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vámoscsalád",
        "infó:": ""
      },
      {
        "Település ID": 3347,
        "KSH településazonosító törzsszám": 14580,
        zipCode: 3291,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Vámosgyörk",
        "infó:": ""
      },
      {
        "Település ID": 3348,
        "KSH településazonosító törzsszám": 10737,
        zipCode: 2635,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Vámosmikola",
        "infó:": ""
      },
      {
        "Település ID": 3349,
        "KSH településazonosító törzsszám": 8934,
        zipCode: 4966,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Vámosoroszi",
        "infó:": ""
      },
      {
        "Település ID": 3350,
        "KSH településazonosító törzsszám": 8989,
        zipCode: 4287,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Vámospércs",
        "infó:": ""
      },
      {
        "Település ID": 3351,
        "KSH településazonosító törzsszám": 12405,
        zipCode: 9061,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Vámosszabadi",
        "infó:": ""
      },
      {
        "Település ID": 3352,
        "KSH településazonosító törzsszám": 15149,
        zipCode: 3941,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Vámosújfalu",
        "infó:": ""
      },
      {
        "Település ID": 3353,
        "KSH településazonosító törzsszám": 16762,
        zipCode: 4119,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Váncsod",
        "infó:": ""
      },
      {
        "Település ID": 3354,
        "KSH településazonosító törzsszám": 30915,
        zipCode: 2688,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Vanyarc",
        "infó:": ""
      },
      {
        "Település ID": 3355,
        "KSH településazonosító törzsszám": 21777,
        zipCode: 8552,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Vanyola",
        "infó:": ""
      },
      {
        "Település ID": 3356,
        "KSH településazonosító törzsszám": 8138,
        zipCode: 7973,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Várad",
        "infó:": ""
      },
      {
        "Település ID": 3357,
        "KSH településazonosító törzsszám": 32850,
        zipCode: 7354,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Váralja",
        "infó:": ""
      },
      {
        "Település ID": 3358,
        "KSH településazonosító törzsszám": 9274,
        zipCode: 8723,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Varászló",
        "infó:": ""
      },
      {
        "Település ID": 3359,
        "KSH településazonosító törzsszám": 27012,
        zipCode: 3254,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Váraszó",
        "infó:": ""
      },
      {
        "Település ID": 3360,
        "KSH településazonosító törzsszám": 18412,
        zipCode: 9243,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Várbalog",
        "infó:": ""
      },
      {
        "Település ID": 3361,
        "KSH településazonosító törzsszám": 21810,
        zipCode: 3778,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Varbó",
        "infó:": ""
      },
      {
        "Település ID": 3362,
        "KSH településazonosító törzsszám": 10144,
        zipCode: 3756,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Varbóc",
        "infó:": ""
      },
      {
        "Település ID": 3363,
        "KSH településazonosító törzsszám": 26781,
        zipCode: 7442,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Várda",
        "infó:": ""
      },
      {
        "Település ID": 3364,
        "KSH településazonosító törzsszám": 9414,
        zipCode: 7146,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Várdomb",
        "infó:": ""
      },
      {
        "Település ID": 3365,
        "KSH településazonosító törzsszám": 2529,
        zipCode: 8891,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Várfölde",
        "infó:": ""
      },
      {
        "Település ID": 3366,
        "KSH településazonosító törzsszám": 28529,
        zipCode: 7370,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Varga",
        "infó:": ""
      },
      {
        "Település ID": 3367,
        "KSH településazonosító törzsszám": 17251,
        zipCode: 2824,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Várgesztes",
        "infó:": ""
      },
      {
        "Település ID": 3368,
        "KSH településazonosító törzsszám": 26204,
        zipCode: 8523,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Várkesz%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3369,
        "KSH településazonosító törzsszám": 29124,
        zipCode: 7214,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Várong",
        "infó:": ""
      },
      {
        "Település ID": 3370,
        "KSH településazonosító törzsszám": 10667,
        zipCode: 6033,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Városföld",
        "infó:": ""
      },
      {
        "Település ID": 3371,
        "KSH településazonosító törzsszám": 7065,
        zipCode: 8445,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Városl%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 3372,
        "KSH településazonosító törzsszám": 11439,
        zipCode: 8100,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Várpalota",
        "infó:": ""
      },
      {
        "Település ID": 3373,
        "KSH településazonosító törzsszám": 11439,
        zipCode: 8103,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Várpalota",
        "infó:": ""
      },
      {
        "Település ID": 3374,
        "KSH településazonosító törzsszám": 11439,
        zipCode: 8104,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Várpalota",
        "infó:": ""
      },
      {
        "Település ID": 3375,
        "KSH településazonosító törzsszám": 6637,
        zipCode: 7067,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Varsád",
        "infó:": ""
      },
      {
        "Település ID": 3376,
        "KSH településazonosító törzsszám": 29498,
        zipCode: 3178,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Varsány",
        "infó:": ""
      },
      {
        "Település ID": 3377,
        "KSH településazonosító törzsszám": 14182,
        zipCode: 8316,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Várvölgy",
        "infó:": ""
      },
      {
        "Település ID": 3378,
        "KSH településazonosító törzsszám": 22585,
        zipCode: 2211,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Vasad",
        "infó:": ""
      },
      {
        "Település ID": 3379,
        "KSH településazonosító törzsszám": 2884,
        zipCode: 9921,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vasalja",
        "infó:": ""
      },
      {
        "Település ID": 3380,
        "KSH településazonosító törzsszám": 24952,
        zipCode: 7926,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Vásárosbéc",
        "infó:": ""
      },
      {
        "Település ID": 3381,
        "KSH településazonosító törzsszám": 2264,
        zipCode: 7362,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Vásárosdombó",
        "infó:": ""
      },
      {
        "Település ID": 3382,
        "KSH településazonosító törzsszám": 17880,
        zipCode: 9343,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Vásárosfalu",
        "infó:": ""
      },
      {
        "Település ID": 3383,
        "KSH településazonosító törzsszám": 9195,
        zipCode: 9552,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vásárosmiske",
        "infó:": ""
      },
      {
        "Település ID": 3384,
        "KSH településazonosító törzsszám": 18324,
        zipCode: 4800,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Vásárosnamény",
        "infó:": ""
      },
      {
        "Település ID": 3385,
        "KSH településazonosító törzsszám": 18324,
        zipCode: 4803,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Vásárosnamény",
        "infó:": ""
      },
      {
        "Település ID": 3386,
        "KSH településazonosító törzsszám": 18324,
        zipCode: 4804,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Vásárosnamény",
        "infó:": ""
      },
      {
        "Település ID": 3387,
        "KSH településazonosító törzsszám": 20394,
        zipCode: 9744,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vasasszonyfa",
        "infó:": ""
      },
      {
        "Település ID": 3388,
        "KSH településazonosító törzsszám": 10302,
        zipCode: 8914,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Vasboldogasszony",
        "infó:": ""
      },
      {
        "Település ID": 3389,
        "KSH településazonosító törzsszám": 15334,
        zipCode: 9661,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vasegerszeg",
        "infó:": ""
      },
      {
        "Település ID": 3390,
        "KSH településazonosító törzsszám": 20349,
        zipCode: 9674,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vashosszúfalu",
        "infó:": ""
      },
      {
        "Település ID": 3391,
        "KSH településazonosító törzsszám": 30702,
        zipCode: 9795,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vaskeresztes",
        "infó:": ""
      },
      {
        "Település ID": 3392,
        "KSH településazonosító törzsszám": 28343,
        zipCode: 6521,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Vaskút",
        "infó:": ""
      },
      {
        "Település ID": 3393,
        "KSH településazonosító törzsszám": 27100,
        zipCode: 4502,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Vasmegyer",
        "infó:": ""
      },
      {
        "Település ID": 3394,
        "KSH településazonosító törzsszám": 14322,
        zipCode: 8998,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Vaspör",
        "infó:": ""
      },
      {
        "Település ID": 3395,
        "KSH településazonosító törzsszám": 25982,
        zipCode: 9741,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vassurány",
        "infó:": ""
      },
      {
        "Település ID": 3396,
        "KSH településazonosító törzsszám": 29373,
        zipCode: 9763,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vasszécseny",
        "infó:": ""
      },
      {
        "Település ID": 3397,
        "KSH településazonosító törzsszám": 11633,
        zipCode: 9953,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vasszentmihály",
        "infó:": ""
      },
      {
        "Település ID": 3398,
        "KSH településazonosító törzsszám": 12104,
        zipCode: 9747,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vasszilvágy",
        "infó:": ""
      },
      {
        "Település ID": 3399,
        "KSH településazonosító törzsszám": 4695,
        zipCode: 9800,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vasvár",
        "infó:": ""
      },
      {
        "Település ID": 3400,
        "KSH településazonosító törzsszám": 26648,
        zipCode: 8542,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Vaszar",
        "infó:": ""
      },
      {
        "Település ID": 3401,
        "KSH településazonosító törzsszám": 16656,
        zipCode: 8245,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Vászoly",
        "infó:": ""
      },
      {
        "Település ID": 3402,
        "KSH településazonosító törzsszám": 2246,
        zipCode: 9748,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vát",
        "infó:": ""
      },
      {
        "Település ID": 3403,
        "KSH településazonosító törzsszám": 30076,
        zipCode: 3431,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Vatta",
        "infó:": ""
      },
      {
        "Település ID": 3404,
        "KSH településazonosító törzsszám": 7074,
        zipCode: 7370,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Vázsnok",
        "infó:": ""
      },
      {
        "Település ID": 3405,
        "KSH településazonosító törzsszám": 5759,
        zipCode: 3265,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Vécs",
        "infó:": ""
      },
      {
        "Település ID": 3406,
        "KSH településazonosító törzsszám": 26815,
        zipCode: 2220,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Vecsés",
        "infó:": ""
      },
      {
        "Település ID": 3407,
        "KSH településazonosító törzsszám": 31228,
        zipCode: 5811,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Végegyháza",
        "infó:": ""
      },
      {
        "Település ID": 3408,
        "KSH településazonosító törzsszám": 18519,
        zipCode: 7838,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Vejti",
        "infó:": ""
      },
      {
        "Település ID": 3409,
        "KSH településazonosító törzsszám": 24402,
        zipCode: 7333,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Vékény",
        "infó:": ""
      },
      {
        "Település ID": 3410,
        "KSH településazonosító törzsszám": 11138,
        zipCode: 4143,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Vekerd",
        "infó:": ""
      },
      {
        "Település ID": 3411,
        "KSH településazonosító törzsszám": 26000,
        zipCode: 9726,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Velem",
        "infó:": ""
      },
      {
        "Település ID": 3412,
        "KSH településazonosító törzsszám": 18430,
        zipCode: 9946,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Velemér",
        "infó:": ""
      },
      {
        "Település ID": 3413,
        "KSH településazonosító törzsszám": 25016,
        zipCode: 2481,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Velence",
        "infó:": ""
      },
      {
        "Település ID": 3414,
        "KSH településazonosító törzsszám": 22071,
        zipCode: 7951,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Velény",
        "infó:": ""
      },
      {
        "Település ID": 3415,
        "KSH településazonosító törzsszám": 20279,
        zipCode: 7726,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Véménd",
        "infó:": ""
      },
      {
        "Település ID": 3416,
        "KSH településazonosító törzsszám": 5014,
        zipCode: 9062,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Vének",
        "infó:": ""
      },
      {
        "Település ID": 3417,
        "KSH településazonosító törzsszám": 26426,
        zipCode: 9751,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vép",
        "infó:": ""
      },
      {
        "Település ID": 3418,
        "KSH településazonosító törzsszám": 3498,
        zipCode: 2477,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Vereb",
        "infó:": ""
      },
      {
        "Település ID": 3419,
        "KSH településazonosító törzsszám": 18342,
        zipCode: 2112,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Veresegyház",
        "infó:": ""
      },
      {
        "Település ID": 3420,
        "KSH településazonosító törzsszám": 33729,
        zipCode: 2621,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Ver%u0151ce",
        "infó:": ""
      },
      {
        "Település ID": 3421,
        "KSH településazonosító törzsszám": 24147,
        zipCode: 3351,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Verpelét",
        "infó:": ""
      },
      {
        "Település ID": 3422,
        "KSH településazonosító törzsszám": 22488,
        zipCode: 2174,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Verseg",
        "infó:": ""
      },
      {
        "Település ID": 3423,
        "KSH településazonosító törzsszám": 19725,
        zipCode: 7752,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Versend",
        "infó:": ""
      },
      {
        "Település ID": 3424,
        "KSH településazonosító törzsszám": 2750,
        zipCode: 8089,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Vértesacsa",
        "infó:": ""
      },
      {
        "Település ID": 3425,
        "KSH településazonosító törzsszám": 13897,
        zipCode: 8085,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Vértesboglár",
        "infó:": ""
      },
      {
        "Település ID": 3426,
        "KSH településazonosító törzsszám": 32586,
        zipCode: 2859,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Vérteskethely",
        "infó:": ""
      },
      {
        "Település ID": 3427,
        "KSH településazonosító törzsszám": 15282,
        zipCode: 2823,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Vértessomló",
        "infó:": ""
      },
      {
        "Település ID": 3428,
        "KSH településazonosító törzsszám": 31264,
        zipCode: 2837,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Vértessz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 3429,
        "KSH településazonosító törzsszám": 29629,
        zipCode: 2833,
        "Megye ID": 11,
        "Megye név": "Komárom-Esztergom",
        cityName: "Vértestolna",
        "infó:": ""
      },
      {
        "Település ID": 3430,
        "KSH településazonosító törzsszám": 8183,
        zipCode: 8721,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Vése",
        "infó:": ""
      },
      {
        "Település ID": 3431,
        "KSH településazonosító törzsszám": 4589,
        zipCode: 9352,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Veszkény",
        "infó:": ""
      },
      {
        "Település ID": 3432,
        "KSH településazonosító törzsszám": 11767,
        zipCode: 8200,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Veszprém",
        "infó:": ""
      },
      {
        "Település ID": 3433,
        "KSH településazonosító törzsszám": 11767,
        zipCode: 8411,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Veszprém",
        "infó:": ""
      },
      {
        "Település ID": 3434,
        "KSH településazonosító törzsszám": 11767,
        zipCode: 8412,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Veszprém",
        "infó:": ""
      },
      {
        "Település ID": 3435,
        "KSH településazonosító törzsszám": 21430,
        zipCode: 8248,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Veszprémfajsz",
        "infó:": ""
      },
      {
        "Település ID": 3436,
        "KSH településazonosító törzsszám": 19336,
        zipCode: 8475,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Veszprémgalsa",
        "infó:": ""
      },
      {
        "Település ID": 3437,
        "KSH településazonosító törzsszám": 22691,
        zipCode: 8438,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Veszprémvarsány",
        "infó:": ""
      },
      {
        "Település ID": 3438,
        "KSH településazonosító törzsszám": 29531,
        zipCode: 5530,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Vészt%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3439,
        "KSH településazonosító törzsszám": 21157,
        zipCode: 5093,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Vezseny",
        "infó:": ""
      },
      {
        "Település ID": 3440,
        "KSH településazonosító törzsszám": 9502,
        zipCode: 8484,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Vid",
        "infó:": ""
      },
      {
        "Település ID": 3441,
        "KSH településazonosító törzsszám": 9733,
        zipCode: 8294,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Vigántpetend",
        "infó:": ""
      },
      {
        "Település ID": 3442,
        "KSH településazonosító törzsszám": 28024,
        zipCode: 7773,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Villány",
        "infó:": ""
      },
      {
        "Település ID": 3443,
        "KSH településazonosító törzsszám": 5209,
        zipCode: 7772,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Villánykövesd",
        "infó:": ""
      },
      {
        "Település ID": 3444,
        "KSH településazonosító törzsszám": 11581,
        zipCode: 3891,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Vilmány",
        "infó:": ""
      },
      {
        "Település ID": 3445,
        "KSH településazonosító törzsszám": 15705,
        zipCode: 8194,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Vilonya",
        "infó:": ""
      },
      {
        "Település ID": 3446,
        "KSH településazonosító törzsszám": 12982,
        zipCode: 3991,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Vilyvitány",
        "infó:": ""
      },
      {
        "Település ID": 3447,
        "KSH településazonosító törzsszám": 24651,
        zipCode: 9535,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Vinár",
        "infó:": ""
      },
      {
        "Település ID": 3448,
        "KSH településazonosító törzsszám": 32142,
        zipCode: 8354,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Vindornyafok",
        "infó:": ""
      },
      {
        "Település ID": 3449,
        "KSH településazonosító törzsszám": 6549,
        zipCode: 8353,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Vindornyalak",
        "infó:": ""
      },
      {
        "Település ID": 3450,
        "KSH településazonosító törzsszám": 11800,
        zipCode: 8355,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Vindornyasz%u0151l%u0151s",
        "infó:": ""
      },
      {
        "Település ID": 3451,
        "KSH településazonosító törzsszám": 28413,
        zipCode: 2025,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Visegrád",
        "infó:": ""
      },
      {
        "Település ID": 3452,
        "KSH településazonosító törzsszám": 28413,
        zipCode: 2026,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Visegrád",
        "infó:": ""
      },
      {
        "Település ID": 3453,
        "KSH településazonosító törzsszám": 19017,
        zipCode: 7533,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Visnye",
        "infó:": ""
      },
      {
        "Település ID": 3454,
        "KSH településazonosító törzsszám": 31246,
        zipCode: 3271,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Visonta",
        "infó:": ""
      },
      {
        "Település ID": 3455,
        "KSH településazonosító törzsszám": 31246,
        zipCode: 3272,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Visonta",
        "infó:": ""
      },
      {
        "Település ID": 3456,
        "KSH településazonosító törzsszám": 5096,
        zipCode: 3956,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Viss",
        "infó:": ""
      },
      {
        "Település ID": 3457,
        "KSH településazonosító törzsszám": 6877,
        zipCode: 8681,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Visz",
        "infó:": ""
      },
      {
        "Település ID": 3458,
        "KSH településazonosító törzsszám": 7940,
        zipCode: 9932,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Viszák",
        "infó:": ""
      },
      {
        "Település ID": 3459,
        "KSH településazonosító törzsszám": 3957,
        zipCode: 3825,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Viszló",
        "infó:": ""
      },
      {
        "Település ID": 3460,
        "KSH településazonosító törzsszám": 3513,
        zipCode: 3293,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Visznek",
        "infó:": ""
      },
      {
        "Település ID": 3461,
        "KSH településazonosító törzsszám": 25797,
        zipCode: 9371,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Vitnyéd",
        "infó:": ""
      },
      {
        "Település ID": 3462,
        "KSH településazonosító törzsszám": 10320,
        zipCode: 3128,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Vizslás",
        "infó:": ""
      },
      {
        "Település ID": 3463,
        "KSH településazonosító törzsszám": 21087,
        zipCode: 3888,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Vizsoly",
        "infó:": ""
      },
      {
        "Település ID": 3464,
        "KSH településazonosító törzsszám": 29780,
        zipCode: 7588,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Vízvár",
        "infó:": ""
      },
      {
        "Település ID": 3467,
        "KSH településazonosító törzsszám": 8369,
        zipCode: 8931,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Vöckönd",
        "infó:": ""
      },
      {
        "Település ID": 3465,
        "KSH településazonosító törzsszám": 5892,
        zipCode: 7768,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Vokány",
        "infó:": ""
      },
      {
        "Település ID": 3468,
        "KSH településazonosító törzsszám": 16319,
        zipCode: 9462,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Völcsej",
        "infó:": ""
      },
      {
        "Település ID": 3469,
        "KSH településazonosító törzsszám": 3142,
        zipCode: 9516,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Vönöck",
        "infó:": ""
      },
      {
        "Település ID": 3466,
        "KSH településazonosító törzsszám": 12919,
        zipCode: 8314,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Vonyarcvashegy",
        "infó:": ""
      },
      {
        "Település ID": 3471,
        "KSH településazonosító törzsszám": 11703,
        zipCode: 8291,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Vöröstó",
        "infó:": ""
      },
      {
        "Település ID": 3470,
        "KSH településazonosító törzsszám": 9645,
        zipCode: 8711,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Vörs",
        "infó:": ""
      },
      {
        "Település ID": 3475,
        "KSH településazonosító törzsszám": 21661,
        zipCode: 3124,
        "Megye ID": 12,
        "Megye név": "Nógrád",
        cityName: "Zabar",
        "infó:": ""
      },
      {
        "Település ID": 3476,
        "KSH településazonosító törzsszám": 17747,
        zipCode: 7976,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Zádor",
        "infó:": ""
      },
      {
        "Település ID": 3477,
        "KSH településazonosító törzsszám": 3063,
        zipCode: 3726,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Zádorfalva",
        "infó:": ""
      },
      {
        "Település ID": 3478,
        "KSH településazonosító törzsszám": 14836,
        zipCode: 5051,
        "Megye ID": 10,
        "Megye név": "Jász-Nagykun-Szolnok",
        cityName: "Zagyvarékas",
        "infó:": ""
      },
      {
        "Település ID": 3479,
        "KSH településazonosító törzsszám": 21722,
        zipCode: 3031,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Zagyvaszántó",
        "infó:": ""
      },
      {
        "Település ID": 3480,
        "KSH településazonosító törzsszám": 16203,
        zipCode: 4625,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Záhony",
        "infó:": ""
      },
      {
        "Település ID": 3481,
        "KSH településazonosító törzsszám": 24590,
        zipCode: 8868,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zajk",
        "infó:": ""
      },
      {
        "Település ID": 3482,
        "KSH településazonosító törzsszám": 6275,
        zipCode: 4974,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Zajta",
        "infó:": ""
      },
      {
        "Település ID": 3483,
        "KSH településazonosító törzsszám": 14623,
        zipCode: 8852,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zákány",
        "infó:": ""
      },
      {
        "Település ID": 3484,
        "KSH településazonosító törzsszám": 34403,
        zipCode: 8853,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zákányfalu",
        "infó:": ""
      },
      {
        "Település ID": 3485,
        "KSH településazonosító törzsszám": 5546,
        zipCode: 6787,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Zákányszék",
        "infó:": ""
      },
      {
        "Település ID": 3486,
        "KSH településazonosító törzsszám": 11466,
        zipCode: 8660,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zala",
        "infó:": ""
      },
      {
        "Település ID": 3487,
        "KSH településazonosító törzsszám": 17400,
        zipCode: 8741,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaapáti",
        "infó:": ""
      },
      {
        "Település ID": 3488,
        "KSH településazonosító törzsszám": 7579,
        zipCode: 8971,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalabaksa",
        "infó:": ""
      },
      {
        "Település ID": 3489,
        "KSH településazonosító törzsszám": 24280,
        zipCode: 8798,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalabér",
        "infó:": ""
      },
      {
        "Település ID": 3490,
        "KSH településazonosító törzsszám": 28495,
        zipCode: 8992,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaboldogfa",
        "infó:": ""
      },
      {
        "Település ID": 3491,
        "KSH településazonosító törzsszám": 4002,
        zipCode: 8782,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalacsány",
        "infó:": ""
      },
      {
        "Település ID": 3492,
        "KSH településazonosító törzsszám": 2486,
        zipCode: 8996,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalacséb",
        "infó:": ""
      },
      {
        "Település ID": 3493,
        "KSH településazonosító törzsszám": 32054,
        zipCode: 8900,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaegerszeg",
        "infó:": ""
      },
      {
        "Település ID": 3494,
        "KSH településazonosító törzsszám": 29993,
        zipCode: 8344,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Zalaerd%u0151d",
        "infó:": ""
      },
      {
        "Település ID": 3495,
        "KSH településazonosító törzsszám": 27207,
        zipCode: 8349,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Zalagyömör%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3496,
        "KSH településazonosító törzsszám": 12654,
        zipCode: 8308,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Zalahaláp",
        "infó:": ""
      },
      {
        "Település ID": 3497,
        "KSH településazonosító törzsszám": 23834,
        zipCode: 8997,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaháshágy",
        "infó:": ""
      },
      {
        "Település ID": 3498,
        "KSH településazonosító törzsszám": 16896,
        zipCode: 8761,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaigrice",
        "infó:": ""
      },
      {
        "Település ID": 3499,
        "KSH településazonosító törzsszám": 12496,
        zipCode: 8932,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaistvánd",
        "infó:": ""
      },
      {
        "Település ID": 3500,
        "KSH településazonosító törzsszám": 11785,
        zipCode: 8749,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalakaros",
        "infó:": ""
      },
      {
        "Település ID": 3501,
        "KSH településazonosító törzsszám": 10348,
        zipCode: 8751,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalakomár",
        "infó:": ""
      },
      {
        "Település ID": 3502,
        "KSH településazonosító törzsszám": 10348,
        zipCode: 8752,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalakomár",
        "infó:": ""
      },
      {
        "Település ID": 3503,
        "KSH településazonosító törzsszám": 18768,
        zipCode: 8354,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaköveskút",
        "infó:": ""
      },
      {
        "Település ID": 3504,
        "KSH településazonosító törzsszám": 30313,
        zipCode: 8999,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalalöv%u0151",
        "infó:": ""
      },
      {
        "Település ID": 3505,
        "KSH településazonosító törzsszám": 33039,
        zipCode: 8348,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Zalameggyes",
        "infó:": ""
      },
      {
        "Település ID": 3506,
        "KSH településazonosító törzsszám": 31617,
        zipCode: 8747,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalamerenye",
        "infó:": ""
      },
      {
        "Település ID": 3507,
        "KSH településazonosító törzsszám": 2972,
        zipCode: 8756,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalasárszeg",
        "infó:": ""
      },
      {
        "Település ID": 3508,
        "KSH településazonosító törzsszám": 22947,
        zipCode: 8743,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszabar",
        "infó:": ""
      },
      {
        "Település ID": 3509,
        "KSH településazonosító törzsszám": 33288,
        zipCode: 8353,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszántó",
        "infó:": ""
      },
      {
        "Település ID": 3510,
        "KSH településazonosító törzsszám": 23597,
        zipCode: 8476,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Zalaszegvár",
        "infó:": ""
      },
      {
        "Település ID": 3511,
        "KSH településazonosító törzsszám": 18564,
        zipCode: 8772,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentbalázs",
        "infó:": ""
      },
      {
        "Település ID": 3512,
        "KSH településazonosító törzsszám": 32522,
        zipCode: 8785,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentgrót",
        "infó:": ""
      },
      {
        "Település ID": 3513,
        "KSH településazonosító törzsszám": 32522,
        zipCode: 8789,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentgrót",
        "infó:": ""
      },
      {
        "Település ID": 3514,
        "KSH településazonosító törzsszám": 32522,
        zipCode: 8790,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentgrót",
        "infó:": ""
      },
      {
        "Település ID": 3515,
        "KSH településazonosító törzsszám": 32522,
        zipCode: 8793,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentgrót",
        "infó:": ""
      },
      {
        "Település ID": 3516,
        "KSH településazonosító törzsszám": 32522,
        zipCode: 8795,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentgrót",
        "infó:": ""
      },
      {
        "Település ID": 3517,
        "KSH településazonosító törzsszám": 33136,
        zipCode: 8994,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentgyörgy",
        "infó:": ""
      },
      {
        "Település ID": 3518,
        "KSH településazonosító törzsszám": 2608,
        zipCode: 8921,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentiván",
        "infó:": ""
      },
      {
        "Település ID": 3519,
        "KSH településazonosító törzsszám": 18096,
        zipCode: 8827,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentjakab",
        "infó:": ""
      },
      {
        "Település ID": 3520,
        "KSH településazonosító törzsszám": 7232,
        zipCode: 8788,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentlászló",
        "infó:": ""
      },
      {
        "Település ID": 3521,
        "KSH településazonosító törzsszám": 13301,
        zipCode: 8921,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentl%u0151rinc",
        "infó:": ""
      },
      {
        "Település ID": 3522,
        "KSH településazonosító törzsszám": 13091,
        zipCode: 8764,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentmárton",
        "infó:": ""
      },
      {
        "Település ID": 3523,
        "KSH településazonosító törzsszám": 18449,
        zipCode: 8936,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszentmihály",
        "infó:": ""
      },
      {
        "Település ID": 3524,
        "KSH településazonosító törzsszám": 27562,
        zipCode: 8969,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaszombatfa",
        "infó:": ""
      },
      {
        "Település ID": 3525,
        "KSH településazonosító törzsszám": 25122,
        zipCode: 7839,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Zaláta",
        "infó:": ""
      },
      {
        "Település ID": 3526,
        "KSH településazonosító törzsszám": 29683,
        zipCode: 8947,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalatárnok",
        "infó:": ""
      },
      {
        "Település ID": 3527,
        "KSH településazonosító törzsszám": 9380,
        zipCode: 8822,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalaújlak",
        "infó:": ""
      },
      {
        "Település ID": 3528,
        "KSH településazonosító törzsszám": 13736,
        zipCode: 8392,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalavár",
        "infó:": ""
      },
      {
        "Település ID": 3529,
        "KSH településazonosító törzsszám": 17057,
        zipCode: 8792,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zalavég",
        "infó:": ""
      },
      {
        "Település ID": 3530,
        "KSH településazonosító törzsszám": 15617,
        zipCode: 3957,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Zalkod",
        "infó:": ""
      },
      {
        "Település ID": 3531,
        "KSH településazonosító törzsszám": 6008,
        zipCode: 8621,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zamárdi",
        "infó:": ""
      },
      {
        "Település ID": 3532,
        "KSH településazonosító törzsszám": 30243,
        zipCode: 8081,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Zámoly",
        "infó:": ""
      },
      {
        "Település ID": 3533,
        "KSH településazonosító törzsszám": 20826,
        zipCode: 8251,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Zánka",
        "infó:": ""
      },
      {
        "Település ID": 3534,
        "KSH településazonosító törzsszám": 23445,
        zipCode: 3296,
        "Megye ID": 9,
        "Megye név": "Heves",
        cityName: "Zaránk",
        "infó:": ""
      },
      {
        "Település ID": 3535,
        "KSH településazonosító törzsszám": 14304,
        zipCode: 7182,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Závod",
        "infó:": ""
      },
      {
        "Település ID": 3536,
        "KSH településazonosító törzsszám": 3726,
        zipCode: 8957,
        "Megye ID": 19,
        "Megye név": "Zala",
        cityName: "Zebecke",
        "infó:": ""
      },
      {
        "Település ID": 3537,
        "KSH településazonosító törzsszám": 14960,
        zipCode: 2627,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Zebegény",
        "infó:": ""
      },
      {
        "Település ID": 3538,
        "KSH településazonosító törzsszám": 31608,
        zipCode: 3977,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Zemplénagárd",
        "infó:": ""
      },
      {
        "Település ID": 3539,
        "KSH településazonosító törzsszám": 15848,
        zipCode: 7720,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Zeng%u0151várkony",
        "infó:": ""
      },
      {
        "Település ID": 3540,
        "KSH településazonosító törzsszám": 34263,
        zipCode: 8112,
        "Megye ID": 6,
        "Megye név": "Fejér",
        cityName: "Zichyújfalu",
        "infó:": ""
      },
      {
        "Település ID": 3541,
        "KSH településazonosító törzsszám": 33057,
        zipCode: 8672,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zics",
        "infó:": ""
      },
      {
        "Település ID": 3542,
        "KSH településazonosító törzsszám": 19275,
        zipCode: 3794,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Ziliz",
        "infó:": ""
      },
      {
        "Település ID": 3543,
        "KSH településazonosító törzsszám": 27614,
        zipCode: 7471,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zimány",
        "infó:": ""
      },
      {
        "Település ID": 3544,
        "KSH településazonosító törzsszám": 26499,
        zipCode: 8420,
        "Megye ID": 18,
        "Megye név": "Veszprém",
        cityName: "Zirc",
        "infó:": ""
      },
      {
        "Település ID": 3545,
        "KSH településazonosító törzsszám": 12201,
        zipCode: 7671,
        "Megye ID": 2,
        "Megye név": "Baranya",
        cityName: "Zók",
        "infó:": ""
      },
      {
        "Település ID": 3546,
        "KSH településazonosító törzsszám": 21625,
        zipCode: 7173,
        "Megye ID": 16,
        "Megye név": "Tolna",
        cityName: "Zomba",
        "infó:": ""
      },
      {
        "Település ID": 3547,
        "KSH településazonosító törzsszám": 6257,
        zipCode: 5537,
        "Megye ID": 3,
        "Megye név": "Békés",
        cityName: "Zsadány",
        "infó:": ""
      },
      {
        "Település ID": 3548,
        "KSH településazonosító törzsszám": 4817,
        zipCode: 4142,
        "Megye ID": 8,
        "Megye név": "Hajdú-Bihar",
        cityName: "Zsáka",
        "infó:": ""
      },
      {
        "Település ID": 3549,
        "KSH településazonosító törzsszám": 25034,
        zipCode: 2072,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Zsámbék",
        "infó:": ""
      },
      {
        "Település ID": 3550,
        "KSH településazonosító törzsszám": 22035,
        zipCode: 2116,
        "Megye ID": 13,
        "Megye név": "Pest",
        cityName: "Zsámbok",
        "infó:": ""
      },
      {
        "Település ID": 3551,
        "KSH településazonosító törzsszám": 15158,
        zipCode: 6411,
        "Megye ID": 1,
        "Megye név": "Bács-Kiskun",
        cityName: "Zsana",
        "infó:": ""
      },
      {
        "Település ID": 3552,
        "KSH településazonosító törzsszám": 28750,
        zipCode: 4961,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Zsarolyán",
        "infó:": ""
      },
      {
        "Település ID": 3553,
        "KSH településazonosító törzsszám": 16869,
        zipCode: 9346,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Zsebeháza",
        "infó:": ""
      },
      {
        "Település ID": 3554,
        "KSH településazonosító törzsszám": 21643,
        zipCode: 9635,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Zsédeny",
        "infó:": ""
      },
      {
        "Település ID": 3555,
        "KSH településazonosító törzsszám": 26842,
        zipCode: 7477,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zselickisfalud",
        "infó:": ""
      },
      {
        "Település ID": 3556,
        "KSH településazonosító törzsszám": 24110,
        zipCode: 7400,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zselickislak",
        "infó:": ""
      },
      {
        "Település ID": 3557,
        "KSH településazonosító törzsszám": 17491,
        zipCode: 7474,
        "Megye ID": 14,
        "Megye név": "Somogy",
        cityName: "Zselicszentpál",
        "infó:": ""
      },
      {
        "Település ID": 3558,
        "KSH településazonosító törzsszám": 12292,
        zipCode: 9766,
        "Megye ID": 17,
        "Megye név": "Vas",
        cityName: "Zsennye",
        "infó:": ""
      },
      {
        "Település ID": 3559,
        "KSH településazonosító törzsszám": 4622,
        zipCode: 9476,
        "Megye ID": 7,
        "Megye név": "Gy%u0151r-Moson-Sopron",
        cityName: "Zsira",
        "infó:": ""
      },
      {
        "Település ID": 3560,
        "KSH településazonosító törzsszám": 17765,
        zipCode: 6792,
        "Megye ID": 5,
        "Megye név": "Csongrád",
        cityName: "Zsombó",
        "infó:": ""
      },
      {
        "Település ID": 3561,
        "KSH településazonosító törzsszám": 11022,
        zipCode: 3897,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Zsujta",
        "infó:": ""
      },
      {
        "Település ID": 3562,
        "KSH településazonosító törzsszám": 13037,
        zipCode: 4627,
        "Megye ID": 15,
        "Megye név": "Szabolcs-Szatmár-Bereg",
        cityName: "Zsurk",
        "infó:": ""
      },
      {
        "Település ID": 3563,
        "KSH településazonosító törzsszám": 19105,
        zipCode: 3723,
        "Megye ID": 4,
        "Megye név": "Borsod-Abaúj-Zemplén",
        cityName: "Zubogy",
        "infó:": ""
      }
    ]
  }

}
