import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationsService} from '../../service';
import {ApplicationModel} from '../../models/application.model';
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-application-modal',
  templateUrl: './application-modal.component.html',
  styleUrls: ['./application-modal.component.scss']
})
export class ApplicationModalComponent implements OnInit {

  activeTabIndex: number = 0;
  selectedApplication: ApplicationModel;
  form: FormGroup;
  startDate = new Date().getTime();
  @ViewChild('picker') datePicker;

  constructor(
    private fb: FormBuilder,
    private applicationService: ApplicationsService,
    private dialogRef: MatDialogRef<ApplicationModalComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) room: ApplicationModel) {

    this.selectedApplication = room;

    this.form = fb.group({
      title: [this.selectedApplication.title, Validators.required],
      summary: [this.selectedApplication.summary, Validators.required],
      body: [this.selectedApplication.body, Validators.required],
      createdAt: [new Date(this.selectedApplication.createdAt), Validators.required],

    });

    // this.applicationService.selectedApplication.subscribe((selectedApplication) => {
    //   this.selectedApplication = selectedApplication;
    // });

  }


  ngOnInit() {

  }

  openCalendar() {
    this.datePicker.open();
  }

  save() {

    if (!this.form.valid) {
      return;
    }

    if (this.selectedApplication.id) {
      this.applicationService.editApplication({...this.selectedApplication, ...this.form.value, updatedAt: new Date().getTime()});
    } else {

      const createdAt = this.form.value.createdAt ? this.form.value.createdAt : new Date().getTime();

      const applicationToSave = {...this.form.value, createdAt, updatedAt: new Date().getTime()};
      if(this.selectedApplication.imageUrl){
        applicationToSave.imageUrl = this.selectedApplication.imageUrl;
        applicationToSave.imageUrlFilePath = this.selectedApplication.imageUrlFilePath;
      }

      this.applicationService.createApplication(applicationToSave);
    }

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  onSelectedTabChanged(event) {
    this.activeTabIndex = event.index;
  }

  onApplicationContentChanged(value) {
    this.form.controls['body'].patchValue(value);
  }

  deleteCoverImage() {
    const fileRef = this.storage.ref(this.selectedApplication.imageUrlFilePath);
    fileRef.delete();
    this.selectedApplication.imageUrl = '';
    this.selectedApplication.imageUrlFilePath = '';
    this.applicationService.editApplication(this.selectedApplication);
  }

  onUploadFinished(event) {
    this.selectedApplication.imageUrl = event.imageUrl;
    this.selectedApplication.imageUrlFilePath = event.imageUrlFilePath;

    if (this.selectedApplication.id) {
      this.applicationService.editApplication(this.selectedApplication);
    }
  }
}
