import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition} from '@ngx-gallery/core';
import {Lightbox} from '@ngx-gallery/lightbox';
import {CustomPageModel} from '../../shared/models/custom-page.model';
import {CustomPagesService} from '../../shared/service/custom-pages.service';
import {GalleryGridItem} from '../../shared/models/gallery-grid-item';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {convert} from 'url-slug';

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrls: ['./custom-page.component.scss']
})
export class CustomPageComponent implements OnInit {
  imageList = [];
  items: GalleryItem[];
  customPageId: string;

  selectedCustomPage: CustomPageModel;
  galleryGridItems: GalleryGridItem[] = [];
  gridColumnCount: number;

  constructor(private route: ActivatedRoute,
              public gallery: Gallery,
              public lightbox: Lightbox,
              private customPageService: CustomPagesService) {

    this.customPageId = this.route.snapshot.params['id'];

    this.customPageService.getCustomPages().subscribe((pages) => {
      this.selectedCustomPage = pages.find(item => convert(item.title) === this.customPageId);
      this.initGalleries();
    });

    this.calculateGridColumnCount();

    fromEvent(window, 'resize')
      .pipe(debounceTime(100),
      )
      .subscribe(() => {
        this.calculateGridColumnCount();
      });

  }


  ngOnInit() {

  }

  initGalleries() {

    if (!this.selectedCustomPage || !this.selectedCustomPage.galleryImages || !this.selectedCustomPage.galleryImages.length) {
      return;
    }


    this.items = this.selectedCustomPage.galleryImages.map(item => new ImageItem({
      src: item.imageUrl,
      thumb: item.imageUrl,
    }));


    for (let imageItem of this.selectedCustomPage.galleryImages) {
      this.galleryGridItems.push({src: imageItem.imageUrl});
    }


    // Get a lightbox customPage ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom customPage config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
      disableThumb: true,
      loadingMode: 'indeterminate',
    });

    // Load items into the lightbox customPage ref
    lightboxRef.load(this.items);
  }

  gridElementClicked(index) {
    this.lightbox.open(index);
  }

  calculateGridColumnCount() {
    let colCount = 4;

    if (window.innerWidth < 640) {
      colCount = 2;
    } else if (window.innerWidth < 800) {
      colCount = 2;
    } else if (window.innerWidth < 1280) {
      colCount = 3;
    }

    this.gridColumnCount = colCount;
  }
}
