import {Component, Input, OnInit} from '@angular/core';
import {ReferenceModel} from '../../models/reference.model';
import {Router} from '@angular/router';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-reference-preview',
  templateUrl: './reference-preview.component.html',
  styleUrls: ['./reference-preview.component.scss']
})
export class ReferencePreviewComponent implements OnInit {

  @Input() reference: ReferenceModel;
  @Input() index: number;
  @Input() referencePage: boolean;
  mobileSize: boolean;

  constructor(private router: Router) {

    this.mobileSize = window.innerWidth < 544;

    fromEvent(window, 'resize')
      .pipe(debounceTime(100),
      )
      .subscribe(() => {

        this.mobileSize = window.innerWidth < 544;

      });

  }

  ngOnInit() {
  }

  getLeaderBackground(reference) {
    return {
      background: `url(${reference.imageUrl})`,
    };
  }

  openGallery(reference) {
    this.router.navigateByUrl('/reference/' + reference.id);
  }

}
