import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CustomPagesService} from '../service/custom-pages.service';

@Injectable()
export class CustomPagesResolver implements Resolve<any> {
  constructor(private customPagesService: CustomPagesService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return this.customPagesService.getCustomPages();

  }
}
