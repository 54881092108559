import {BrowserModule} from '@angular/platform-browser';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeModule} from './home/home.module';
import {
  ApiService,
  JwtService,
  ArticlesService,
  ArticlesResolver,
  SharedModule,
  StateService,
  AuthService,
  CommonEntitiesService,
  RoomContainerService,
  HomeCarouselItemsResolver,
  ReferencesResolver,
  ApplicationsService, CustomPagesService
} from './shared';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpConfigInterceptor} from './shared/service/http-config.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig} from '@angular/material';
import {CrudUtilities} from './shared/util/crud-utilities';
import {GuestEditorService} from './shared/service/guest-editor.service';
import {AddressUtils} from './shared/util/address-utils';
import {GALLERY_CONFIG, GalleryModule} from '@ngx-gallery/core';
import {EmbedVideo} from 'ngx-embed-video/dist';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {RouterModule} from '@angular/router';
import {LIGHTBOX_CONFIG} from '@ngx-gallery/lightbox';
import 'hammerjs';
import localeHu from '@angular/common/locales/hu';
import {registerLocaleData} from '@angular/common';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {CmsService} from './shared/service/cms.service';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {NgxHmCarouselModule} from 'ngx-hm-carousel';
import {HomeCarouselService} from './shared/service/home-carousel.service';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {DROPZONE_CONFIG} from 'ngx-dropzone-wrapper';
import {DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import {ReferencesService} from './shared/service/references.service';
import {PartnerService} from './shared/service/partner.service';
import {PartnerItemsResolver} from './shared/resolvers/partner-items-resolver.service';
import {AgmCoreModule} from "@agm/core";
import {ArticleResolver} from './shared/resolvers/article-resolver.service';
import {ApplicationsResolver} from './shared/resolvers/applications-resolver.service';
import {ApplicationResolver} from './shared/resolvers/application-resolver.service';
import {CustomPagesResolver} from './shared/resolvers/custom-pages-resolver.service';

registerLocaleData(localeHu, 'hu');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const defaultDialogConfig = new MatDialogConfig();
defaultDialogConfig.width = '600px';
defaultDialogConfig.hasBackdrop = true;
defaultDialogConfig.autoFocus = false;

export const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: 'https://httpbin.org/post',
  maxFilesize: 1,
  acceptedFiles: 'image/*',
  clickable: true,
  maxFiles: 1,
  uploadMultiple: false,
  autoReset: null,
  errorReset: null,
  cancelReset: null,
  autoProcessQueue: false,
  autoQueue: false,
};


const rootRouting: ModuleWithProviders = RouterModule.forRoot([], {useHash: true});


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    rootRouting,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    HomeModule,
    ToastrModule.forRoot({
      timeOut: 100000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    GalleryModule,
    EmbedVideo.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularSvgIconModule,
    CKEditorModule,
    NgxHmCarouselModule,
    DropzoneModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDqEJoBGxf9lb38QXI9J3lcvnUpy-AVnVA',
      libraries: ["places"]
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: defaultDialogConfig},
    {provide: GALLERY_CONFIG, useValue: {dots: true, imageSize: 'cover'}},
    {provide: LIGHTBOX_CONFIG, useValue: {keyboardShortcuts: true}},
    {provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG},
    ApiService,
    JwtService,
    StateService,
    AuthService,
    ArticlesService,
    ArticlesResolver,
    ArticleResolver,
    ApplicationsService,
    ApplicationsResolver,
    ApplicationResolver,
    GuestEditorService,
    CrudUtilities,
    AddressUtils,
    CommonEntitiesService,
    RoomContainerService,
    CmsService,
    HomeCarouselService,
    HomeCarouselItemsResolver,
    ReferencesService,
    ReferencesResolver,
    PartnerService,
    PartnerItemsResolver,
    CustomPagesService,
    CustomPagesResolver,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
