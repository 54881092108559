import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from './api.service';
import {CrudUtilities} from '../util/crud-utilities';
import {map} from 'rxjs/operators';
import {HomeCarouselItemsModel} from '../models/home-carousel-items.model';
import {HomeCarouselModel} from '../models/home-carousel.model';

@Injectable()
export class HomeCarouselService {


  public homeCarouselItemsSubject = new BehaviorSubject<HomeCarouselItemsModel>(new HomeCarouselItemsModel());
  public homeCarouselItems = this.homeCarouselItemsSubject.asObservable();

  public selectedHomeCarouselItemSubject = new BehaviorSubject<HomeCarouselModel>(new HomeCarouselModel());
  public selectedHomeCarouselItem = this.selectedHomeCarouselItemSubject.asObservable();


  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {

  }

  setHomeCarouselItems(homeCarouselItems: HomeCarouselItemsModel) {
    this.homeCarouselItemsSubject.next(homeCarouselItems);
  }

  createHomeCarouselItem(homeCarouselItem: HomeCarouselModel) {
    return this.apiService.post('homeCarouselItems.json', homeCarouselItem).subscribe((response) => {

      if (response.name) {
        homeCarouselItem.id = response.name;
        this.selectedHomeCarouselItemSubject.next({...homeCarouselItem});
        this.crudUtils.addNewItemToList(this.homeCarouselItemsSubject, homeCarouselItem);
      }
    });
  }

  editHomeCarouselItem(homeCarouselItem: HomeCarouselModel) {
    return this.apiService
      .patch(`homeCarouselItems/${homeCarouselItem.id}.json`, homeCarouselItem).subscribe((response) => {
        this.selectedHomeCarouselItemSubject.next({...response});
        this.crudUtils.editListItem(this.homeCarouselItemsSubject, response);
      });
  }

  deleteHomeCarouselItem(homeCarouselItem, dialogRef) {
    return this.apiService.delete(`homeCarouselItems/${homeCarouselItem.id}.json`).subscribe(() => {
      this.crudUtils.deleteListItem(this.homeCarouselItemsSubject, homeCarouselItem);
      dialogRef.close();

    });
  }

  getHomeCarouselItems() {
    return this.apiService.get('homeCarouselItems.json').pipe(map((response) => {

      let list = [];
      for (let key in response) {
        response[key].id = key;
        list.push(response[key]);
      }
      return list;
    }));
  }


}
