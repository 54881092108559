import {Injectable,} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Rx';
import {ArticlesService} from '../service';
import {ArticleModel} from '../models/article.model';

@Injectable()
export class ArticleResolver implements Resolve<ArticleModel> {
  constructor(private articlesService: ArticlesService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return this.articlesService.getArticle(route.params['id']);

  }
}
