import {Component, OnInit} from '@angular/core';
import {StateService} from '../../service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {
  isSidebarOpen: boolean;

  constructor(
    private stateService: StateService, private router: Router) {
  }

  ngOnInit() {

    this.stateService.sideMenuState.subscribe((state) => {
      this.isSidebarOpen = state;
    });

  }

  toggleSideBar() {
    this.stateService.setSelectedSideMenuState(!this.isSidebarOpen);
  }

  openHome() {
    this.router.navigateByUrl('home');
  }

}
