import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {GuestModel} from "../models";
import {ApiService} from "./api.service";
import {GuestsModel} from "../models/guests.model";
import {CrudUtilities} from "../util/crud-utilities";

@Injectable()
export class CommonEntitiesService {


  constructor(private apiService: ApiService) {
  }

  getCountries() {
    return this.apiService.get('hotel/countries');
  }

  getStreetTypes() {
    return this.apiService.get('hotel/streetTypes');
  }


}
