import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {JwtService} from './jwt.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

const PROTECTED_ROUTES = ['admin'];

@Injectable()
export class AuthService {

  jwthelper = new JwtHelperService();

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private tokenSubject = new BehaviorSubject<string>(null);
  public token = this.tokenSubject.asObservable();
  currentUrl: string;


  constructor(private apiService: ApiService, private jwtService: JwtService, private router: Router) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event['url'];
      }

      if (event instanceof NavigationStart) {
        this.currentUrl = event['url'];

        if (this.currentUrl.includes('admin')) {
          if (!this.jwtService.getToken() && this.router.navigateByUrl('/auth')) {
            this.router.navigateByUrl('/auth');
          }
        }

      }
    });

  }


  login(credentials): Observable<any> {
    return this.apiService.postAuth(credentials);
  }

  setAuth(token) {
    this.jwtService.saveToken(token);
    this.tokenSubject.next(token);
    this.isAuthenticatedSubject.next(true);
  }

  isTokenExpired() {

    const expirationDate = new Date(localStorage.getItem('expirationDate'));

    if (expirationDate <= new Date()) {
      return true;
    }

    return false;
  }


  populate() {

    if (!this.jwtService.getToken() || this.isTokenExpired()) {
      this.purgeAuth();
    } else {
      this.setAuth(this.jwtService.getToken());
    }
  }

  purgeAuth() {
    console.log('purgeAuth', this.currentUrl);



    this.jwtService.destroyToken();
    this.tokenSubject.next(null);
    this.isAuthenticatedSubject.next(false);
    // this.router.navigateByUrl('/auth');
  }


}
