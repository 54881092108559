import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {CmsService} from '../../shared/service/cms.service';
import {Subscription} from 'rxjs';
import {HomeCarouselItemsModel} from '../../shared/models/home-carousel-items.model';
import {AuthService} from '../../shared/service';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit, OnDestroy {

  content: string;
  subscriptions: Subscription[] = [];
  isLoggedIn: boolean = false;
  constructor(private cmsService: CmsService, private authService: AuthService) {

    this.subscriptions.push(this.cmsService.aboutContent.subscribe((aboutContent: string) => {
      this.content = aboutContent;
    }));


    this.authService.token.subscribe(
      (token) => {
        this.isLoggedIn = !!token;
      }
    );
  }




  ngOnInit() {
  }

  onContentSaved(content) {

    this.cmsService.saveAboutContent(content).subscribe((response) => {
      this.cmsService.aboutContentSubject.next(content);
    });

  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
