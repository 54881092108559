import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from "@angular/material";
import {DataListModel, SmartTableConfigInterface} from "../../interface";
import {DeleteModalComponent, ArticleModalComponent} from "../../modal";
import {RoomModel} from "../../models";


@Component({
  selector: 'app-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss']
})
export class SmartTableComponent implements OnInit, OnChanges {

  @ViewChild(MatSort) sort: MatSort;
  @Input() config: SmartTableConfigInterface;
  @Input() data: DataListModel;
  @Input() hideAdd: boolean;
  @Input() hideDelete: boolean;
  @Output() itemOrNewClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  dataSource = new MatTableDataSource([]);

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
    this.config.displayedColumns.push('deleteColumn');
  }

  addOrEditClicked(value?: object) {
    this.itemOrNewClicked.emit(value)
  }

  showDeleteModal(event, item) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(DeleteModalComponent, {
      hasBackdrop: true,
      data: item,
    });


    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );

    dialogRef.componentInstance.onDelete.subscribe((data: any) => {
      this.deleteClicked.emit({item: data, dialogRef: dialogRef});
    });

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.data.currentValue && changes.data.currentValue.items) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue.items);
    } else {
      this.dataSource = new MatTableDataSource([]);
    }

    this.dataSource.sort = this.sort;
  }

}
