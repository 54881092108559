import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Rx';
import {ApplicationsService} from '../service';
import {ApplicationModel} from '../models/application.model';

@Injectable()
export class ApplicationResolver implements Resolve<ApplicationModel> {
  constructor(private applicationsService: ApplicationsService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return this.applicationsService.getApplication(route.params['id']);

  }
}
