import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AngularFireStorage} from '@angular/fire/storage';
import {HomeCarouselModel} from '../../models/home-carousel.model';
import {HomeCarouselService} from '../../service/home-carousel.service';

@Component({
  selector: 'app-home-carousel-modal',
  templateUrl: './carousel-item-modal.component.html',
  styleUrls: ['./carousel-item-modal.component.scss']
})
export class CarouselItemModalComponent implements OnInit {

  activeTabIndex: number = 0;
  selectedHomeCarouselItem: HomeCarouselModel;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private homeCarouselService: HomeCarouselService,
    private dialogRef: MatDialogRef<CarouselItemModalComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) room: HomeCarouselModel) {

    this.selectedHomeCarouselItem = room;

    this.form = fb.group({
      title: [this.selectedHomeCarouselItem.title, Validators.required],
      summary: [this.selectedHomeCarouselItem.summary, Validators.required],
    });

    // this.homeCarouselService.selectedHomeCarouselItem.subscribe((selectedHomeCarouselItem) => {
    //   this.selectedHomeCarouselItem = selectedHomeCarouselItem;
    // });

  }


  ngOnInit() {

  }

  save() {

    if (!this.form.valid) {
      return;
    }

    if (this.selectedHomeCarouselItem.id) {
      this.homeCarouselService.editHomeCarouselItem({
        ...this.selectedHomeCarouselItem, ...this.form.value,
        updatedAt: new Date().getTime()
      });
    } else {
      this.homeCarouselService.createHomeCarouselItem({
        ...this.form.value,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime()
      });
    }

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  onSelectedTabChanged(event) {
    this.activeTabIndex = event.index;
  }


  deleteCoverImage() {
    const fileRef = this.storage.ref(this.selectedHomeCarouselItem.imageUrlFilePath);
    fileRef.delete();
    this.selectedHomeCarouselItem.imageUrl = '';
    this.selectedHomeCarouselItem.imageUrlFilePath = '';
    this.homeCarouselService.editHomeCarouselItem(this.selectedHomeCarouselItem);
  }

  onUploadFinished(event){
    this.selectedHomeCarouselItem.imageUrl = event.imageUrl;
    this.selectedHomeCarouselItem.imageUrlFilePath = event.imageUrlFilePath;
    this.homeCarouselService.editHomeCarouselItem(this.selectedHomeCarouselItem);
  }
}
