export class ArticleModel {
  id?: number;
  title?: string;
  summary?: string;
  body?: string;
  imageUrl?: string;
  imageUrlFilePath?: string;
  createdAt: number;
  updatedAt?: number;
  topic?: string;
  highlighted?: boolean;
  link?: string;
}
