import {GalleryImageModel} from './gallery-image.model';

export class CustomPageModel {
  id?: number;
  title?: string;
  body?: string;
  imageUrl?: string;
  imageUrlFilePath?: string;
  logoUrl?: string;
  logoUrlFilePath?: string;
  active?: boolean;
  galleryImages?: GalleryImageModel[] = [];
}
