import { Component, OnInit } from '@angular/core';
import {SmartTableConfigInterface} from '../../../../shared/interface';
import {ArticleModel} from '../../../../shared/models/article.model';
import {ArticlesModel} from '../../../../shared/models/articles.model';
import {MatDialog} from '@angular/material';
import {ArticlesService} from '../../../../shared/service';
import {ActivatedRoute} from '@angular/router';
import {ArticleModalComponent} from '../../../../shared/modal';

@Component({
  selector: 'app-partner-manager',
  templateUrl: './article-manager-page.component.html',
  styleUrls: ['./article-manager-page.component.scss']
})
export class ArticleManagerPage implements OnInit {


  tableData: ArticlesModel;
  selectedRoom: ArticleModel;
  smartTableConfig: SmartTableConfigInterface;


  constructor(public dialog: MatDialog, private roomEditorService: ArticlesService, private activatedRoute: ActivatedRoute) {

    this.initSmartTable();
    this.roomEditorService.articles.subscribe((rooms: ArticlesModel) => {
      this.tableData = rooms;
    });

    this.roomEditorService.selectedArticle.subscribe((room: ArticleModel) => {
      this.selectedRoom = room;
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      (data: { articlesResolve: any }) => {
        this.tableData.items = data.articlesResolve;
        console.log('this.tableData', this.tableData);
      }
    );
  }

  initSmartTable() {
    this.smartTableConfig = {
      columns: [
        {labelKey: 'COMMON.COVER_IMAGE', dataKey: 'imageUrl', isImage: true},
        {labelKey: 'ARTICLE.TITLE', dataKey: 'title', isImage: false},
        {labelKey: 'ARTICLE.SUMMARY', dataKey: 'summary', isImage: false},
        {labelKey: 'REFERENCE.CREATED_AT', dataKey: 'createdAt', isDate: true},
      ],
      displayedColumns: ['imageUrl', 'title', 'summary', 'createdAt']
    };
  }


  openDialog(selectedArticleModel?: ArticleModel) {

    const dialogRef = this.dialog.open(ArticleModalComponent, {
      hasBackdrop: true,
      width: '60vw',
      data: selectedArticleModel ? selectedArticleModel : new ArticleModel()
    });

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }


  deleteItem(event) {
    this.roomEditorService.deleteArticle(event.item, event.dialogRef);
  }

}
