import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {from, fromEvent, Subscription} from 'rxjs';
import {GalleryGridItem} from '../../models/gallery-grid-item';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-gallery-grid',
  templateUrl: './gallery-grid.component.html',
  styleUrls: ['./gallery-grid.component.scss'],
})
export class GalleryGridComponent implements OnInit {
  @Input()
  set items(items: any[]) {

    // if (JSON.stringify(newItems) === JSON.stringify(this.gridItems)) {
    //   return;
    // }

    this.gridItems = items;
    this.limitation();
    setTimeout(() => {
      this.updateMasonryLayout = !this.updateMasonryLayout;
    });

    console.log('items', this.limitedItems);
  }

  @Input()
  set columns(val: number) {
    this.colCount = val;
  }


  @Input() showCommentBubbles: boolean;
  @Input() isPdf: boolean;
  @Input() printView: boolean;
  @Input() itemLimit: number;
  @Input() loggedInUserName: string;
  @Input() resultPage: boolean;
  @Input() options: any;
  @Output() onload = new EventEmitter();
  @Output() elementButtonClick = new EventEmitter();
  @Output() elementClick = new EventEmitter();
  @Output() downloadFile = new EventEmitter();
  @ViewChild('cont') cont;
  @ViewChild('masonryContainer') masonryContainer;

  gridItems: any[];
  colCount: number;
  limitedItems: string[];
  loading: boolean;
  currentLimit: number;
  itemWidth: number;
  onLlayoutComplete = new EventEmitter();
  supportedTypes: string[] = ['image', 'gif', 'video', 'pdf', 'video/mp4'];
  commentBubblesByGid: {} = {};
  subscriptions: Subscription[] = [];
  updateMasonryLayout: boolean = false;

  constructor() {
    this.colCount = 2;
    this.itemLimit = 20;
    this.currentLimit = this.itemLimit;

    this.options = {
      transitionDuration: '0s',
      gutter: 20,
    };

    if (window.innerWidth < 544) {
      this.options.gutter = 10;
    }

    fromEvent(window, 'resize')
      .pipe(debounceTime(100),
      )
      .subscribe(() => {
        this.updateMasonryLayout = !this.updateMasonryLayout;
        this.calculateItemWidth();
      });

    from(this.onLlayoutComplete).pipe(debounceTime(100),
    ).subscribe(() => {
      this.onload.emit({
        masonryHeight: this.cont.nativeElement.offsetHeight,
      });
    });

  }

  filterItems(items: GalleryGridItem[]): GalleryGridItem[] {
    return items.filter((item) => {
      return this.supportedTypes.indexOf(item.type) > -1;
    });
  }

  calculateItemWidth() {
    setTimeout(() => {
      let containerWidth = this.cont.nativeElement.getBoundingClientRect().width;
      if (containerWidth) {
        this.updateMasonryLayout = !this.updateMasonryLayout;
        this.itemWidth =
          (Math.round(containerWidth) - this.options.gutter * (this.colCount - 1)) / this.colCount;
      }
    });
  }

  ngOnInit() {
    if (this.printView) {
      this.currentLimit = 50;
    }

    this.calculateItemWidth();
    this.limitation();

  }

  limitation() {
    this.limitedItems = this.gridItems.slice(0, this.currentLimit);
  }


  layoutComplete(event) {
    this.onLlayoutComplete.emit();
  }


  clickHandler(item, index) {
    this.elementClick.emit(index);
  }


}
