import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {CountryModel, StreetTypeModel} from "../../models";
import {map, startWith} from "rxjs/operators";
import {AddressUtils} from "../../util/address-utils";
import {CommonEntitiesService} from "../../service";

@Component({
  selector: 'app-street-type-selector',
  templateUrl: './street-type-selector.component.html',
  styleUrls: ['./street-type-selector.component.scss']
})
export class StreetTypeSelectorComponent implements OnInit {
  @Input() formControl: FormControl;
  options: StreetTypeModel[] = [];
  filteredOptions: Observable<StreetTypeModel[]>;

  constructor(private addressUtils: AddressUtils, private commonEntitiesService: CommonEntitiesService) {
    this.commonEntitiesService.getStreetTypes().subscribe((streetTypes: StreetTypeModel[]) => {
      this.options = streetTypes;
    })

  }


  ngOnInit() {
    this.filteredOptions = this.formControl.valueChanges
      .pipe(
        startWith<string | StreetTypeModel>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
  }

  displayFn(user?: StreetTypeModel): string | undefined {
    return user ? user.name : undefined;
  }

  private _filter(name: string): StreetTypeModel[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

}
