import {Component, Input, OnInit} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {ArticleModel} from '../../shared/models/article.model';

@Component({
  selector: 'article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.scss']
})
export class ArticlePreviewComponent implements OnInit {
  @Input() article: ArticleModel;


  constructor(private router: Router) {
  }

  ngOnInit() {

  }


  openArticle() {
    this.router.navigateByUrl('/article/' + this.article.id);
  }


  getArticleBg() {
    return {
      background: `url(${this.article.imageUrl})`,
    };
  }


}
