import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {JwtService} from './jwt.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

@Injectable()
export class ApiService {
  constructor(private http: HttpClient,
              private jwtService: JwtService,
              private translate: TranslateService) {
  }


  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, {headers: this.setHeaders(), params: params});
  }

  getFromZipCodeApi(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.zip_code_api_url}${path}`, {headers: this.setHeaders(), params: params});
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body),
      {headers: this.setHeaders()}
    )
  }

  postAuth(body: Object = {}): Observable<any> {
    return this.http.post(environment.auth_url,
      JSON.stringify(body),
      {headers: this.setHeaders()}
    )
  }

  dummyPost(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.api_url_login}${path}`,
      JSON.stringify(body),
      {headers: this.setHeaders()}
    )
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(
      `${environment.api_url}${path}`,
      JSON.stringify(body),
      {headers: this.setHeaders()}
    )
  }


  delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete(`${environment.api_url}${path}`, {headers: this.setHeaders(), params: params});
  }


  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Content-Language': this.translate.currentLang,
      'Accept': 'application/json'
    };

    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }


}
