export class PartnerModel {
  id?: string;
  name?: string;
  link?: string;
  isSupplier?: boolean;
  imageUrlFilePath?: string;
  imageUrl?: string;
  updatedAt?: number;
  createdAt?: number;
}
