import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ReferencesService} from '../service/references.service';

@Injectable()
export class ReferencesResolver implements Resolve<any> {
  constructor(private referencesService: ReferencesService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return this.referencesService.getReferences();

  }
}
