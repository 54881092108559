export class UserModel {
  id: number;
  username: string;
  password?: string = '';
  firstName: string;
  lastName: string;
  appToken?: string;
  email?: string = '';
  name?: string = '';
  loginName?: string = '';
  note?: string = '';
  role?: string = '';

  constructor(email?: string, password?: string) {
    this.email = email;
    this.password = password;
  }
}
