import {Component, OnInit} from '@angular/core';
import {SmartTableConfigInterface} from '../../../../shared/interface';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {PartnerModel} from '../../../../shared/models/partner.model';
import {PartnerService} from '../../../../shared/service/partner.service';
import {PartnerItemsModel} from '../../../../shared/models/partner-items.model';
import {PartnerModalComponent} from '../../../../shared/modal/partner-modal/partner-modal.component';

@Component({
  selector: 'app-partner-manager',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-manager-page.component.scss']
})
export class PartnerManagerPage implements OnInit {

  tableData: PartnerItemsModel;
  selectedHomeCarouselItem: PartnerModel;
  smartTableConfig: SmartTableConfigInterface;


  constructor(public dialog: MatDialog, private partnerService: PartnerService, private activatedRoute: ActivatedRoute) {

    this.initSmartTable();
    this.partnerService.partnerItems.subscribe((partnerItems: PartnerItemsModel) => {
      this.tableData = partnerItems;
    });

    this.partnerService.selectedPartnerItem.subscribe((partnerItem: PartnerModel) => {
      this.selectedHomeCarouselItem = partnerItem;
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      (data: { partnersResolve: any }) => {
        this.tableData.items = data.partnersResolve;
      }
    );
  }

  initSmartTable() {
    // this.smartTableConfig = {
    //   columns: [
    //     {labelKey: 'PARTNER.LOGO', dataKey: 'logo', isImage: true},
    //     {labelKey: 'PARTNER.NAME', dataKey: 'name'},
    //     {labelKey: 'PARTNER.LINK', dataKey: 'link'},
    //   ],
    //   displayedColumns: ['logo', 'name', 'link']
    // };
    this.smartTableConfig = {
      columns: [
        {labelKey: 'PARTNER.NAME', dataKey: 'name'},
      ],
      displayedColumns: ['name']
    };
  }


  openDialog(selectedPartnerModel?: PartnerModel) {

    this.dialog.open(PartnerModalComponent, {
      hasBackdrop: true,
      width: '60vw',
      data: selectedPartnerModel ? selectedPartnerModel : new PartnerModel()
    });

    // dialogRef.afterClosed().subscribe(
    //   data =>
    // );
  }


  deleteItem(event) {
    this.partnerService.deletePartnerItem(event.item, event.dialogRef);
  }

}
