import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ArticlesModel} from '../models/articles.model';
import {CrudUtilities} from '../util/crud-utilities';
import {ArticleModel} from '../models/article.model';
import {map} from 'rxjs/operators';

@Injectable()
export class ArticlesService {


  public articlesSubject = new BehaviorSubject<ArticlesModel>(new ArticlesModel());
  public articles = this.articlesSubject.asObservable();

  public selectedArticleSubject = new BehaviorSubject<ArticleModel>(new ArticleModel());
  public selectedArticle = this.selectedArticleSubject.asObservable();


  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {

  }

  setArticleList(articles: ArticlesModel) {
    this.articlesSubject.next(articles);
  }

  createArticle(article: ArticleModel) {
    return this.apiService.post('articles.json', article).subscribe((response) => {

      if (response.name) {
        article.id = response.name;
        this.selectedArticleSubject.next({...article});
        this.crudUtils.addNewItemToList(this.articlesSubject, article);
      }
    });
  }

  editArticle(article: ArticleModel) {
    return this.apiService
      .patch(`articles/${article.id}.json`, article).subscribe((response) => {
        this.selectedArticleSubject.next({...response});
        this.crudUtils.editListItem(this.articlesSubject, response);
      });
  }

  deleteArticle(article, dialogRef) {
    return this.apiService.delete(`articles/${article.id}.json`).subscribe(() => {
      this.crudUtils.deleteListItem(this.articlesSubject, article);
      dialogRef.close();

    });
  }

  getArticles() {
    return this.apiService.get('articles.json').pipe(map((response) => {

      let list = [];
      for (let key in response) {
        response[key].id = key;
        list.push(response[key]);
      }
      return list;
    }));
  }

  getArticle(id): Observable<any> {
    return this.apiService.get('articles/' + id + '.json');
  }


}
