import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {HomeCarouselService} from '../service/home-carousel.service';

@Injectable()
export class HomeCarouselItemsResolver implements Resolve<any> {
  constructor(private homeCarouselItemsService: HomeCarouselService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return this.homeCarouselItemsService.getHomeCarouselItems();

  }
}
