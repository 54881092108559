import { Component, OnInit } from '@angular/core';
import {SmartTabConfigInterface} from '../../shared/interface';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  tabsConfig: SmartTabConfigInterface[];

  constructor() {

    this.tabsConfig = [
      {key: 'MENU.ADMIN.NEWS', route: 'article-manager'},
      {key: 'MENU.ADMIN.HOME_CAROUSEL', route: 'home-carousel-manager'},
      {key: 'MENU.ADMIN.REFERENCE', route: 'reference-manager'},
      {key: 'MENU.ADMIN.PARTNERS', route: 'partner-manager'},
      {key: 'MENU.ADMIN.APPLICATIONS', route: 'application-manager'},
      {key: 'MENU.ADMIN.CUSTOM_PAGES', route: 'custom-pages-manager'},
    ]

  }

  ngOnInit() {
  }


}
