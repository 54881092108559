// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url_login: 'https://reqres.in/api/',
  zip_code_api_url: 'https://irmeva.herokuapp.com/api/v1/node',
  api_url: 'https://schatzhaz-bca68.firebaseio.com/',
  auth_url: 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyBAkBJEWpGfP2bum3UXslpWMiVF0S3sMvo',
  firebase: {
    apiKey: 'AIzaSyBAkBJEWpGfP2bum3UXslpWMiVF0S3sMvo',
    databaseURL: 'https://schatzhaz-bca68.firebaseio.com/',
    projectId: 'schatzhaz-bca68',
    storageBucket: 'gs://schatzhaz-bca68.appspot.com/',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
