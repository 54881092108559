import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-applications-indicator',
  templateUrl: './applications-indicator.component.html',
  styleUrls: ['./applications-indicator.component.scss']
})
export class ApplicationsIndicatorComponent implements OnInit {

  showIndicator = true;
  constructor(private router: Router) { }

  ngOnInit() {
  }
  openApplications() {
    this.router.navigateByUrl('applications');
  }
}
