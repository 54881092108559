import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFireStorage} from '@angular/fire/storage';
import {CustomPageModel} from '../../models/custom-page.model';
import {CustomPagesService} from '../../service/custom-pages.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-custom-page-modal',
  templateUrl: './custom-page-modal.component.html',
  styleUrls: ['./custom-page-modal.component.scss']
})
export class CustomPageModalComponent implements OnInit {

  activeTabIndex = 0;
  selectedCustomPage: CustomPageModel;
  form: FormGroup;
  startDate = new Date().getTime();
  @ViewChild('picker') datePicker;

  constructor(
    private fb: FormBuilder,
    private customPageService: CustomPagesService,
    private dialogRef: MatDialogRef<CustomPageModalComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) room: CustomPageModel) {

    this.selectedCustomPage = room;

    this.form = fb.group({
      title: [this.selectedCustomPage.title, Validators.required],
      body: [this.selectedCustomPage.body],
      imageUrl: [this.selectedCustomPage.imageUrl],
      imageUrlFilePath: [this.selectedCustomPage.imageUrlFilePath],
      active: [this.selectedCustomPage.active],
    });

    // this.customPageService.selectedCustomPage.subscribe((selectedCustomPage) => {
    //   this.selectedCustomPage = selectedCustomPage;
    // });

  }


  ngOnInit() {

  }

  save() {

    if (!this.form.valid) {
      return;
    }

    if (this.selectedCustomPage.id) {
      this.customPageService.editCustomPage({...this.selectedCustomPage, ...this.form.value, updatedAt: new Date().getTime()});
    } else {

      const createdAt = this.form.value.createdAt ? this.form.value.createdAt : new Date().getTime();
      this.customPageService.createCustomPage({...this.form.value, createdAt: createdAt, updatedAt: new Date().getTime()});
    }

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  onSelectedTabChanged(event) {
    this.activeTabIndex = event.index;
  }

  onCustomPageContentChanged(value) {
    this.form.controls['body'].patchValue(value);
  }

  deleteCoverImage() {
    const fileRef = this.storage.ref(this.selectedCustomPage.imageUrlFilePath);
    fileRef.delete();
    this.selectedCustomPage.imageUrl = '';
    this.selectedCustomPage.imageUrlFilePath = '';
    this.customPageService.editCustomPage(this.selectedCustomPage);
  }

  deleteLogo() {
    const fileRef = this.storage.ref(this.selectedCustomPage.logoUrlFilePath);
    fileRef.delete();
    this.selectedCustomPage.logoUrl = '';
    this.selectedCustomPage.logoUrlFilePath = '';
    this.customPageService.editCustomPage(this.selectedCustomPage);
  }

  deleteGalleryItem(item) {
    const fileRef = this.storage.ref(item.imageUrlFilePath);
    fileRef.delete();
    this.selectedCustomPage.galleryImages = _.without(this.selectedCustomPage.galleryImages, item);
    this.customPageService.editCustomPage(this.selectedCustomPage);
  }

  galleryImageUploadFinished(event) {

    if (this.selectedCustomPage.galleryImages && this.selectedCustomPage.galleryImages.length) {
      this.selectedCustomPage.galleryImages.push(event);
    } else {
      this.selectedCustomPage.galleryImages = [event];
    }

    if (this.selectedCustomPage.id) {
      this.customPageService.editCustomPage(this.selectedCustomPage);
    }
  }


  onUploadFinished(event, fieldKeyPrefix) {
    this.selectedCustomPage[`${fieldKeyPrefix}Url`] = event.imageUrl;
    this.selectedCustomPage[`${fieldKeyPrefix}UrlFilePath`] = event.imageUrlFilePath;
    this.form.controls[`${fieldKeyPrefix}Url`].patchValue(event.imageUrl);
    this.form.controls[`${fieldKeyPrefix}UrlFilePath`].patchValue(event.imageUrlFilePath);

    if (this.selectedCustomPage.id) {
      this.customPageService.editCustomPage(this.selectedCustomPage);
    }
  }

  openCalendar() {
    this.datePicker.open();
  }
}
