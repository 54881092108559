import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ArticlesService, StateService, AuthService} from './shared/service';
import {NavigationEnd, Router} from '@angular/router';
import {SmartTabConfigInterface} from './shared/interface';
import {routerTransition} from '../animations';
import {ArticlesModel} from './shared/models/articles.model';
import {Subscription} from 'rxjs';
import {HomeCarouselService} from './shared/service/home-carousel.service';
import {HomeCarouselItemsModel} from './shared/models/home-carousel-items.model';
import {CmsService} from './shared/service/cms.service';
import {ReferencesService} from './shared/service/references.service';
import {ReferencesModel} from './shared/models/references.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition]
})

// animations: [routerTransition]
export class AppComponent implements OnInit, OnDestroy {

  isSidebarOpen: boolean;
  tabsConfig: SmartTabConfigInterface[];
  subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService,
              private authService: AuthService,
              private router: Router,
              private stateService: StateService,
              private articlesService: ArticlesService,
              private homeCarouselService: HomeCarouselService,
              private referencesService: ReferencesService,
              private cmsService: CmsService) {
    translate.addLangs(['hu']);
    translate.setDefaultLang('hu');
    translate.use('hu');


    this.tabsConfig = [
      {key: 'MENU.MENU_0', route: 'home'},
      {key: 'MENU.MENU_1', route: 'references'},
      {key: 'MENU.MENU_2', route: 'services'},
      {key: 'MENU.MENU_3', route: 'contact'},
      {key: 'MENU.MENU_4', route: 'partners'}
    ];

    this.getAppData();


  }

  getAppData() {
    // ------ ARTICLES ------
    this.subscriptions.push(this.articlesService.getArticles().subscribe((response) => {
      const articles = new ArticlesModel();
      articles.items = response;
      this.articlesService.setArticleList(articles);
    }));

    // ------ CAROUSEL ITEMS------
    this.subscriptions.push(this.homeCarouselService.getHomeCarouselItems().subscribe((response) => {
      const homeCarousel = new HomeCarouselItemsModel();
      homeCarousel.items = response;
      this.homeCarouselService.setHomeCarouselItems(homeCarousel);
    }));

    // ------ ABOUT PAGE------
    this.cmsService.getAboutContent();


    // ------ REFERENCES ITEMS------
    this.subscriptions.push(this.referencesService.getReferences().subscribe((response) => {
      const references = new ReferencesModel();
      references.items = response;
      this.referencesService.setReferenceList(references);
    }));
  }


  ngOnInit() {

    this.authService.populate();

    this.stateService.sideMenuState.subscribe((state) => {
      this.isSidebarOpen = state;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'] === '/') {

          this.router.navigateByUrl('/home');
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  toggleSideBar() {
    this.stateService.setSelectedSideMenuState(!this.isSidebarOpen);
  }

  showLogoutModal() {
    this.authService.purgeAuth();
  }


}


