import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SmartTabConfigInterface} from '../../interface';
import {Router} from '@angular/router';
import {AuthService, CustomPagesService, StateService} from '../../service';
import {CustomPageModel} from '../../models';
import { convert } from 'url-slug';

@Component({
  selector: 'app-main-header-menu',
  templateUrl: './main-header-menu.component.html',
  styleUrls: ['./main-header-menu.component.scss']
})
export class MainHeaderMenu implements OnInit {

  tabsConfig: SmartTabConfigInterface[];
  @Output() tabChanged = new EventEmitter();
  isSidebarOpen: boolean;
  isLoggedIn: boolean;
  numberOfMenuItems = 6;


  private MenuItemsBeforePages = [
    {key: 'home', route: 'home'},
    {key: 'MENU.MENU_1', route: 'about'},
    {key: 'MENU.MENU_2', route: 'references'},
  ];

  private MenuItemsAfterPage = [
    {key: 'MENU.MENU_3', route: 'services'},
    {key: 'MENU.MENU_4', route: 'partners'},
    {key: 'MENU.MENU_5', route: 'contact'}
  ];


  constructor(private router: Router, private stateService: StateService, private authService: AuthService, private customPagesService: CustomPagesService) {

    this.fetchCustomPages();

    this.tabsConfig = [
      ...this.MenuItemsBeforePages,
        ...this.MenuItemsAfterPage
    ];

    this.stateService.sideMenuState.subscribe((state) => {
      this.isSidebarOpen = state;
    });

    this.authService.token.subscribe(
      (token) => {
        this.isLoggedIn = !!token;
      }
    );

  }

  fetchCustomPages(){

    this.customPagesService.getCustomPages().subscribe((customPages: CustomPageModel[])=> {
      const customPageMenuItems = customPages
        .filter(item=> item.active)
        .map(item=> ({key: item.id.toString(), route: `pages/${convert(item.title)}`, label: item.title}));


      if (customPageMenuItems.length) {
        this.tabsConfig = [
          ...this.MenuItemsBeforePages,
          ...customPageMenuItems,
          ...this.MenuItemsAfterPage
        ];

        this.numberOfMenuItems =  this.tabsConfig.length;
      }

    })
  }

  ngOnInit() {
  }

  tabClicked(tab) {
    this.router.navigateByUrl(tab.path);
  }


  toggleSideBar() {
    this.stateService.setSelectedSideMenuState(!this.isSidebarOpen);
  }

  openHome() {
    this.router.navigateByUrl('home');
  }

  logout() {
    this.authService.purgeAuth();
    this.router.navigateByUrl('home');
  }

}
