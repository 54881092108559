import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AngularFireStorage} from '@angular/fire/storage';
import {PartnerService} from '../../service/partner.service';
import {PartnerModel} from '../../models/partner.model';

@Component({
  selector: 'app-partner-modal',
  templateUrl: './partner-modal.component.html',
  styleUrls: ['./partner-modal.component.scss']
})
export class PartnerModalComponent implements OnInit {

  activeTabIndex: number = 0;
  selectedPartnerItem: PartnerModel;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private partnerService: PartnerService,
    private dialogRef: MatDialogRef<PartnerModalComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) partner: PartnerModel) {

    this.selectedPartnerItem = partner;

    // this.form = fb.group({
    //   name: [this.selectedPartnerItem.name, Validators.required],
    //   link: [this.selectedPartnerItem.link],
    //   isSupplier: [this.selectedPartnerItem.isSupplier],
    // });

    this.form = fb.group({
      name: [this.selectedPartnerItem.name, Validators.required],
      isSupplier: [this.selectedPartnerItem.isSupplier],
    });

    // this.partnerService.selectedPartnerItem.subscribe((selectedPartnerItem) => {
    //   this.selectedPartnerItem = selectedPartnerItem;
    // });

  }


  ngOnInit() {

  }

  save() {

    if (!this.form.valid) {
      return;
    }

    if (this.selectedPartnerItem.id) {
      this.partnerService.editPartnerItem({
        ...this.selectedPartnerItem, ...this.form.value,
        updatedAt: new Date().getTime()
      });
    } else {
      this.partnerService.createPartnerItem({
        ...this.form.value,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime()
      });
    }

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  onSelectedTabChanged(event) {
    this.activeTabIndex = event.index;
  }


  deleteCoverImage() {
    const fileRef = this.storage.ref(this.selectedPartnerItem.imageUrlFilePath);
    fileRef.delete();
    this.selectedPartnerItem.imageUrl = '';
    this.selectedPartnerItem.imageUrlFilePath = '';
    this.partnerService.editPartnerItem(this.selectedPartnerItem);
  }

  onUploadFinished(event) {
    this.selectedPartnerItem.imageUrl = event.imageUrl;
    this.selectedPartnerItem.imageUrlFilePath = event.imageUrlFilePath;
    this.partnerService.editPartnerItem(this.selectedPartnerItem);
  }
}
