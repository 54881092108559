import {Component, OnDestroy, OnInit} from '@angular/core';
import {ArticlesService, AuthService, CustomPagesService, StateService} from '../shared/service';
import {ArticleModel} from '../shared/models/article.model';
import {ArticlesModel} from '../shared/models/articles.model';
import {fromEvent, Subscription} from 'rxjs';
import {HomeCarouselService} from '../shared/service/home-carousel.service';
import {HomeCarouselItemsModel} from '../shared/models/home-carousel-items.model';
import {HomeCarouselModel} from '../shared/models/home-carousel.model';
import {Router} from '@angular/router';
import {CustomPageModel} from '../shared';
import {convert} from 'url-slug';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private isSidebarOpen: boolean;

  articles: ArticleModel[] = [];
  filteredArticles: ArticleModel[] = [];
  homeCarouselItems: HomeCarouselModel[] = [];
  highlightedArticle: ArticleModel;
  subscriptions: Subscription[] = [];
  highlightedCustomPage: CustomPageModel;
  mobileSize: boolean;

  constructor(private stateService: StateService,
              private userService: AuthService,
              private articlesService: ArticlesService,
              private homeCarouselService: HomeCarouselService,
              private customPagesService: CustomPagesService,
              private router: Router) {

    this.stateService.sideMenuState.subscribe((state) => {
      this.isSidebarOpen = state;
    });

    this.subscriptions.push(this.articlesService.articles.subscribe((articles: ArticlesModel) => {
      if (articles && articles.items) {
        this.articles = articles.items.sort((a, b) => {
          // @ts-ignore
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        this.filteredArticles = articles.items.slice(0, 2).reverse();

       this.setHighlightedArticle();
      }
    }));


    this.subscriptions.push(this.homeCarouselService.homeCarouselItems.subscribe((carouselItems: HomeCarouselItemsModel) => {
      if (carouselItems && carouselItems.items) {
        this.homeCarouselItems = carouselItems.items;
      }
    }));

    this.fetchCustomPages();

    fromEvent(window, 'resize')
      .pipe(debounceTime(100),
      )
      .subscribe(() => {

        this.mobileSize = window.innerWidth < 768;

      });

  }

  ngOnInit() {
  }

  setHighlightedArticle() {
    const highlightedArticles = this.articles.filter((item) => item.highlighted).sort((a, b) => {
      // @ts-ignore
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    if (highlightedArticles.length) {
      this.highlightedArticle = highlightedArticles[0];
    }

  }

  getHighlightedArticleStyle() {
    return {
      'background': `url('${this.highlightedArticle.imageUrl}') center center no-repeat`,
      'background-size': 'cover'
    };
  }

  toggleSideBar() {
    this.stateService.setSelectedSideMenuState(!this.isSidebarOpen);
  }

  showLogoutModal() {
    this.userService.purgeAuth();
  }


  getArticleBg() {
    return {
      background: `url(${this.highlightedArticle.imageUrl})`,
    };
  }


  openArticle() {
    this.router.navigateByUrl('/article/' + this.highlightedArticle.id);
  }


  openArticlesPage() {
    this.router.navigateByUrl('/articles');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  fetchCustomPages()  {

    this.customPagesService.getCustomPages().subscribe((customPages: CustomPageModel[])=> {

      if (customPages && customPages.length) {
        if (customPages[0].active){
          this.highlightedCustomPage = customPages[0];
        }
      }
    });
  }
  openHighlightedCustomPage(){
    this.router.navigateByUrl(`pages/${convert(this.highlightedCustomPage.title)}`);
  }

  getHighlightedCustomPageBackground() {
    return {
      background: `url(${this.highlightedCustomPage.imageUrl})`,
    };
  }

}

export interface HighlightedArticle {
  imageUrl: string;
  title: string;
  description: string;
  link: string
}


