import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {SideNavComponent} from './layout/side-nav/side-nav.component';
import {ArticleModalComponent} from './modal/article-modal/article-modal.component';
import {ApplicationModalComponent} from './modal/application-modal/application-modal.component';
import {SmartTableComponent} from './layout/smart-table/smart-table.component';
import {ModalFooterComponent} from './modal/modal-footer/modal-footer.component';
import {DeleteModalComponent} from './modal/delete-modal/delete-modal.component';
import {MainHeaderMenu} from './layout/main-header-menu/main-header-menu.component';
import {UserRoleSelectorComponent} from './input/user-role-selector/user-role-selector.component';
import {StreetTypeSelectorComponent} from './input/street-type-selector/street-type-selector.component';
import {CountrySelectorComponent} from './input/country-selector/country-selector.component';
import {ResizableModule} from 'angular-resizable-element';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AngularDraggableModule} from 'angular2-draggable';
import {GuestHouseMapComponent} from './layout/guest-house-map/guest-house-map.component';
import {LocalizedDatePipe} from './pipes';
import {SafeHtmlPipe} from './pipes/safe-html';
import {HtmlEditorWrapperComponent} from './input/html-editor-wrapper/html-editor-wrapper.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {SmartTabsComponent} from './layout/smart-tabs/smart-tabs.component';
import {CarouselWrapperComponent} from './layout/carousel-wrapper/carousel-wrapper.component';
import {NgxHmCarouselModule} from 'ngx-hm-carousel';
import {CarouselItemModalComponent} from './modal/carousel-item-modal/carousel-item-modal.component';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {CustomDropZoneComponent} from './input/custom-drop-zone/custom-drop-zone.component';
import {UploadingSpinner} from './layout/uploading-spinner/uploading-spinner.component';
import {ReferenceModalComponent} from './modal/reference-modal/reference-modal.component';
import {TrapezeLabelComponent} from './layout/trapeze-label/trapeze-label.component';
import {NgxMasonryModule} from 'ngx-masonry';
import {GalleryGridComponent} from './layout/gallery-grid/gallery-grid.component';
import {ReferencePreviewComponent} from './layout/reference-preview/reference-preview.component';
import {ToastrModule} from 'ngx-toastr';
import {MobileHeaderComponent} from './layout/mobile-header/mobile-header.component';
import {PartnerModalComponent} from './modal/partner-modal/partner-modal.component';
import {AddressMapComponent} from './layout/address-map/address-map.component';
import {AgmCoreModule} from '@agm/core';
import {ArraySortPipe} from './pipes/sort-by.pipe';
import {CustomPageModalComponent} from './modal/custom-page-modal/custom-page-modal.component';
import {CustomPageHeaderComponent} from './layout/custom-page-header/custom-page-header.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    TranslateModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ResizableModule,
    DragDropModule,
    AngularDraggableModule,
    CKEditorModule,
    NgxHmCarouselModule,
    DropzoneModule,
    NgxMasonryModule,
    ToastrModule,
    AgmCoreModule
  ],
  declarations: [
    SideNavComponent,
    ArticleModalComponent,
    ApplicationModalComponent,
    ReferenceModalComponent,
    CustomPageModalComponent,
    PartnerModalComponent,
    CarouselItemModalComponent,
    SmartTableComponent,
    ModalFooterComponent,
    DeleteModalComponent,
    MainHeaderMenu,
    SmartTabsComponent,
    UserRoleSelectorComponent,
    StreetTypeSelectorComponent,
    CountrySelectorComponent,
    GuestHouseMapComponent,
    LocalizedDatePipe,
    SafeHtmlPipe,
    HtmlEditorWrapperComponent,
    CarouselWrapperComponent,
    CustomDropZoneComponent,
    UploadingSpinner,
    TrapezeLabelComponent,
    GalleryGridComponent,
    ReferencePreviewComponent,
    CustomPageHeaderComponent,
    MobileHeaderComponent,
    AddressMapComponent,
    ArraySortPipe
  ],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    BrowserAnimationsModule,
    SideNavComponent,
    ArticleModalComponent,
    ApplicationModalComponent,
    ReferenceModalComponent,
    CustomPageModalComponent,
    CustomPageHeaderComponent,
    PartnerModalComponent,
    CarouselItemModalComponent,
    SmartTableComponent,
    DeleteModalComponent,
    MainHeaderMenu,
    SmartTabsComponent,
    UserRoleSelectorComponent,
    StreetTypeSelectorComponent,
    CountrySelectorComponent,
    ResizableModule,
    DragDropModule,
    AngularDraggableModule,
    GuestHouseMapComponent,
    LocalizedDatePipe,
    SafeHtmlPipe,
    HtmlEditorWrapperComponent,
    CKEditorModule,
    CarouselWrapperComponent,
    CustomDropZoneComponent,
    UploadingSpinner,
    TrapezeLabelComponent,
    GalleryGridComponent,
    ReferencePreviewComponent,
    MobileHeaderComponent,
    AddressMapComponent,
    ArraySortPipe
  ],
  entryComponents: [
    ArticleModalComponent,
    ApplicationModalComponent,
    ReferenceModalComponent,
    CustomPageModalComponent,
    DeleteModalComponent,
    CarouselItemModalComponent,
    PartnerModalComponent
  ]
})
export class SharedModule {
}
