import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition} from '@ngx-gallery/core';
import {Lightbox} from '@ngx-gallery/lightbox';
import {ReferenceModel} from '../../shared/models/reference.model';
import {ReferencesService} from '../../shared/service/references.service';
import {GalleryGridItem} from '../../shared/models/gallery-grid-item';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {
  imageList = [];
  items: GalleryItem[];
  sectionId: string;
  referenceId: string;

  selectedReference: ReferenceModel;
  galleryGridItems: GalleryGridItem[] = [];
  gridColumnCount: number;

  constructor(private route: ActivatedRoute,
              public gallery: Gallery,
              public lightbox: Lightbox,
              private referenceService: ReferencesService) {

    this.referenceId = this.route.snapshot.params['id'];
    this.sectionId = 'wedding';

    this.referenceService.getReference(this.referenceId).subscribe((response) => {
      this.selectedReference = response;

      this.initGalleries();
    });

    this.calculateGridColumnCount();

    fromEvent(window, 'resize')
      .pipe(debounceTime(100),
      )
      .subscribe(() => {
        this.calculateGridColumnCount();
      });

  }


  ngOnInit() {

  }

  initGalleries() {

    if (!this.selectedReference || !this.selectedReference.galleryImages || !this.selectedReference.galleryImages.length) {
      return;
    }


    this.items = this.selectedReference.galleryImages.map(item => new ImageItem({
      src: item.imageUrl,
      thumb: item.imageUrl,
    }));


    for (let imageItem of this.selectedReference.galleryImages) {
      this.galleryGridItems.push({src: imageItem.imageUrl});
    }


    // Get a lightbox reference ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom reference config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
      disableThumb: true,
      loadingMode: 'indeterminate',
    });

    // Load items into the lightbox reference ref
    lightboxRef.load(this.items);
  }

  gridElementClicked(index) {
    this.lightbox.open(index);
  }

  calculateGridColumnCount() {
    let colCount = 4;

    if (window.innerWidth < 640) {
      colCount = 2;
    } else if (window.innerWidth < 800) {
      colCount = 2;
    } else if (window.innerWidth < 1280) {
      colCount = 3;
    }

    this.gridColumnCount = colCount;
  }
}
