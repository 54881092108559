import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFireStorage} from '@angular/fire/storage';
import {ReferenceModel} from '../../models/reference.model';
import {ReferencesService} from '../../service/references.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-reference-modal',
  templateUrl: './reference-modal.component.html',
  styleUrls: ['./reference-modal.component.scss']
})
export class ReferenceModalComponent implements OnInit {

  activeTabIndex: number = 0;
  selectedReference: ReferenceModel;
  form: FormGroup;
  startDate = new Date().getTime();
  @ViewChild('picker') datePicker;

  constructor(
    private fb: FormBuilder,
    private referenceService: ReferencesService,
    private dialogRef: MatDialogRef<ReferenceModalComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) room: ReferenceModel) {

    this.selectedReference = room;

    this.form = fb.group({
      title: [this.selectedReference.title, Validators.required],
      summary: [this.selectedReference.summary, Validators.required],
      type: [this.selectedReference.type, Validators.required],
      createdAt: [new Date(this.selectedReference.createdAt), Validators.required],
      body: [this.selectedReference.body],
      imageUrl: [this.selectedReference.imageUrl],
      imageUrlFilePath: [this.selectedReference.imageUrlFilePath],
    });

    // this.referenceService.selectedReference.subscribe((selectedReference) => {
    //   this.selectedReference = selectedReference;
    // });

  }


  ngOnInit() {

  }

  save() {

    if (!this.form.valid) {
      return;
    }

    if (this.selectedReference.id) {
      this.referenceService.editReference({...this.selectedReference, ...this.form.value, updatedAt: new Date().getTime()});
    } else {

      const createdAt = this.form.value.createdAt ? this.form.value.createdAt : new Date().getTime();
      this.referenceService.createReference({...this.form.value, createdAt: createdAt, updatedAt: new Date().getTime()});
    }

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  onSelectedTabChanged(event) {
    this.activeTabIndex = event.index;
  }

  onReferenceContentChanged(value) {
    this.form.controls['body'].patchValue(value);
  }

  deleteCoverImage() {
    const fileRef = this.storage.ref(this.selectedReference.imageUrlFilePath);
    fileRef.delete();
    this.selectedReference.imageUrl = '';
    this.selectedReference.imageUrlFilePath = '';
    this.referenceService.editReference(this.selectedReference);
  }

  deleteGalleryItem(item) {
    const fileRef = this.storage.ref(item.imageUrlFilePath);
    fileRef.delete();
    this.selectedReference.galleryImages = _.without(this.selectedReference.galleryImages, item);
    this.referenceService.editReference(this.selectedReference);
  }

  galleryImageUploadFinished(event) {

    if (this.selectedReference.galleryImages && this.selectedReference.galleryImages.length) {
      this.selectedReference.galleryImages.push(event);
    } else {
      this.selectedReference.galleryImages = [event];
    }

    if (this.selectedReference.id) {
      this.referenceService.editReference(this.selectedReference);
    }
  }


  onUploadFinished(event) {
    this.selectedReference.imageUrl = event.imageUrl;
    this.selectedReference.imageUrlFilePath = event.imageUrlFilePath;
    this.form.controls['imageUrl'].patchValue(event.imageUrl);
    this.form.controls['imageUrlFilePath'].patchValue(event.imageUrlFilePath);

    if (this.selectedReference.id) {
      this.referenceService.editReference(this.selectedReference);
    }
  }

  openCalendar() {
    this.datePicker.open();
  }
}
