import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  openURL = function () {
    let win = window.open('https://www.facebook.com/', '_blank');
    win.focus();
  };

  openMail() {
    window.location.href = 'mailto:' + 'schatzhaz@gmail.com';

  }

  openPhone() {
    window.open('tel:' + '+36 20 965 6619' + '');
  }

}
