import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from './api.service';
import {CustomPagesModel} from '../models/custom-pages.model';
import {CrudUtilities} from '../util/crud-utilities';
import {map} from 'rxjs/operators';
import {CustomPageModel} from '../models/custom-page.model';

@Injectable()
export class CustomPagesService {


  public customPagesSubject = new BehaviorSubject<CustomPagesModel>(new CustomPagesModel());
  public customPages = this.customPagesSubject.asObservable();

  public selectedCustomPageSubject = new BehaviorSubject<CustomPageModel>(new CustomPageModel());
  public selectedCustomPage = this.selectedCustomPageSubject.asObservable();


  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {

  }

  setCustomPageList(customPages: CustomPagesModel) {
    this.customPagesSubject.next(customPages);
  }

  createCustomPage(customPage: CustomPageModel) {
    return this.apiService.post('customPages.json', customPage).subscribe((response) => {

      if (response.name) {
        customPage.id = response.name;
        this.selectedCustomPageSubject.next({...customPage});
        this.crudUtils.addNewItemToList(this.customPagesSubject, customPage);
      }
    });
  }

  editCustomPage(customPage: CustomPageModel) {
    return this.apiService
      .patch(`customPages/${customPage.id}.json`, customPage).subscribe((response) => {
        this.selectedCustomPageSubject.next({...response});
        this.crudUtils.editListItem(this.customPagesSubject, response);
      });
  }

  deleteCustomPage(customPage, dialogRef) {
    return this.apiService.delete(`customPages/${customPage.id}.json`).subscribe(() => {
      this.crudUtils.deleteListItem(this.customPagesSubject, customPage);
      dialogRef.close();

    });
  }

  getCustomPages() {
    return this.apiService.get('customPages.json').pipe(map((response) => {

      let list = [];
      for (let key in response) {
        response[key].id = key;
        list.push(response[key]);
      }
      return list;
    }));
  }

  getCustomPage(id): Observable<any> {
    return this.apiService.get(`customPages/${id}.json`);
  }


}
