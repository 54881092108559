import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-custom-drop-zone',
  templateUrl: './custom-drop-zone.component.html',
  styleUrls: ['./custom-drop-zone.component.scss']
})
export class CustomDropZoneComponent implements OnInit {

  @ViewChild('dropZoneWrapper') dropZoneWrapper;
  @Input() height: string = '150px';
  @Input() uploadPath: string;
  @Output() uploadFinished = new EventEmitter();
  dropZoneConfig: DropzoneConfigInterface;
  uploading: boolean = false;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private storage: AngularFireStorage) {
  }

  ngOnInit() {
    this.dropZoneWrapper.nativeElement.style.height = this.height;

  }

  filesAdded(file) {
    this.uploading = true;
    const filePath = this.uploadPath + '/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe((response) => {
          this.uploading = false;
          this.uploadFinished.emit({imageUrl: response, imageUrlFilePath: filePath});
        });

      })
    ).subscribe();
  }


}
