import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ReferenceModel} from '../../shared/models/reference.model';
import {ReferencesService} from '../../shared/service/references.service';
import {ReferencesModel} from '../../shared/models/references.model';

@Component({
  selector: 'app-references-page',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferencesComponent implements OnInit {

  references: ReferenceModel[] = [];

  constructor(private router: Router,
              private referenceService: ReferencesService,
              private cdr: ChangeDetectorRef) {


    this.referenceService.references.subscribe((references: ReferencesModel) => {
      this.references = references.items;
      this.cdr.markForCheck();
    });

  }

  ngOnInit() {
  }

  getLeaderBackground(reference) {
    return {
      background: `url(${reference.imageUrl})`,
    };
  }

  openGallery(reference) {
    this.router.navigateByUrl('/reference/' + reference.id);
  }

}

