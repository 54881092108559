import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../shared/service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  authForm: FormGroup;
  hide: boolean = true;

  constructor(private authService: AuthService, private fb: FormBuilder, private router: Router, private toastr: ToastrService) {

    this.authForm = this.fb.group({
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'password': ['', Validators.required],
    });


    this.authService.token.subscribe((token) => {
      if (token && !this.authService.isTokenExpired()) {
        this.router.navigateByUrl('/admin/article-manager');
      }
    });

  }

  ngOnInit() {
  }

  login() {
    if (!this.authForm.valid) {
      return;
    }

    const authData = {
      ...this.authForm.value,
      returnSecureToken: true
    };

    this.authService.login(authData).subscribe((response) => {
      const expirationDate = new Date(new Date().getTime() + response.expiresIn * 1000);
      localStorage.setItem('expirationDate', expirationDate.toString());
      this.authService.setAuth(response['idToken']);
      this.router.navigateByUrl('/admin/article-manager');
    });

  }


}
