import {Component, OnInit} from '@angular/core';
import {ApplicationModel} from '../../shared/models/application.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss']
})
export class ApplicationPageComponent implements OnInit {

  application: ApplicationModel;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.route.data.subscribe(
      (data: { application: ApplicationModel }) => {
        this.application = data.application;
        console.log('this.application', this.application);

      }
    );
  }


}
