import {Component, Input, OnInit} from '@angular/core';
import {RoomContainerModel, RoomModel, RoomsModel} from "../../models";
import {ActivatedRoute} from "@angular/router";
import {RoomContainerService} from "../../service";

@Component({
  selector: 'app-guest-house-map',
  templateUrl: './guest-house-map.component.html',
  styleUrls: ['./guest-house-map.component.scss']
})
export class GuestHouseMapComponent implements OnInit {
  @Input() rooms: RoomModel[];
  @Input() adminView: boolean = false;

  roomContainers: RoomContainerModel[] = [];
  inBounds = true;
  selectableRooms: RoomModel[];

  constructor(private activatedRoute: ActivatedRoute, private roomContainersService: RoomContainerService) {

    this.roomContainersService.roomContainers.subscribe((roomContainers) => {
      this.roomContainers = roomContainers.items;

    })

  }


  ngOnInit() {
    this.selectableRooms = [...this.rooms];

    setTimeout(() => {
      for (let roomContainer of this.roomContainers) {
        this.initElementPosition(document.getElementById(String(roomContainer.id)), roomContainer.mapDimensions);
        for (let room of roomContainer.rooms) {
          this.initElementPosition(document.getElementById(String(room.id)), room.mapDimensions);
        }
      }
    })

  }

  initElementPosition(elementToUpdate, mapDimensions) {


    if (!mapDimensions) {
      return;
    }

    if (mapDimensions.x && mapDimensions.y) {
      elementToUpdate.style.transform = 'translate(' + mapDimensions.x + 'px,' + mapDimensions.y + 'px)';
    }

    if (mapDimensions.width) {
      elementToUpdate.style.width = mapDimensions.width + 'px';
    }

    if (mapDimensions.height) {
      elementToUpdate.style.height = mapDimensions.height + 'px';
    }


  }

  onRoomContainerResizeEnd(event, roomContainer) {
    const roomContainerToUpdate = {...this.roomContainers.find(item => item.id === roomContainer.id)};
    roomContainerToUpdate.mapDimensions = {
      ...roomContainerToUpdate.mapDimensions,
      width: event.size.width,
      height: event.size.height
    };
    this.roomContainersService.editRoomContainer(roomContainerToUpdate);
  }

  onRoomContainerMoveEnd(event, roomContainer) {
    const roomContainerToUpdate = {...this.roomContainers.find(item => item.id === roomContainer.id)};
    roomContainerToUpdate.mapDimensions = {...roomContainerToUpdate.mapDimensions, x: event.x, y: event.y};
    this.roomContainersService.editRoomContainer(roomContainerToUpdate);
  }

  onRoomMoveEnd(event, room, roomContainer) {
    const roomContainerToUpdate = {...this.roomContainers.find(item => item.id === roomContainer.id)};
    const roomToUpdate = roomContainerToUpdate.rooms.find(item => item.id === room.id);
    roomToUpdate.mapDimensions = {...roomToUpdate.mapDimensions, x: event.x, y: event.y};
    this.roomContainersService.editRoomContainer(roomContainerToUpdate);
  }

  onRoomResizeEnd(event, room, roomContainer) {
    const roomContainerToUpdate = {...this.roomContainers.find(item => item.id === roomContainer.id)};
    const roomToUpdate = roomContainerToUpdate.rooms.find(item => item.id === room.id);
    roomToUpdate.mapDimensions = {...roomToUpdate.mapDimensions, width:  event.size.width, height:  event.size.height};
    this.roomContainersService.editRoomContainer(roomContainerToUpdate);
  }


  compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  alreadyAddedAnotherContainer(room, currentRoomContainer) {

    for (let roomContainer of this.roomContainers) {

      if (roomContainer.rooms.find(item => item.id === room.id)) {
      }

      if (roomContainer.rooms.find(item => item.id === room.id) && roomContainer['id'] !== currentRoomContainer.id) {
        return true;
      }
    }

    return false;
  }


  editRoomContainerTitle(roomContainer: RoomContainerModel) {
    console.log("editRoomContainerTitle", roomContainer);
  }

  assignedRoomsChanged(matSelectChange) {
    // @TODO
  }


}

