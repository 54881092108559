import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApplicationModel} from '../../shared/models/application.model';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {


  applications: ApplicationModel[];

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      (data: { applicationsResolve: any }) => {
        this.applications = data.applicationsResolve;

        console.log('this.applications', this.applications);
      }
    );
  }
}
