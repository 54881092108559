import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ArticlesService} from '../../service';
import {ArticleModel} from '../../models/article.model';
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-article-modal',
  templateUrl: './article-modal.component.html',
  styleUrls: ['./article-modal.component.scss']
})
export class ArticleModalComponent implements OnInit {

  activeTabIndex: number = 0;
  selectedArticle: ArticleModel;
  form: FormGroup;
  startDate = new Date().getTime();
  @ViewChild('picker') datePicker;

  constructor(
    private fb: FormBuilder,
    private articleService: ArticlesService,
    private dialogRef: MatDialogRef<ArticleModalComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) room: ArticleModel) {

    this.selectedArticle = room;

    this.form = fb.group({
      title: [this.selectedArticle.title, Validators.required],
      summary: [this.selectedArticle.summary, Validators.required],
      body: [this.selectedArticle.body, Validators.required],
      createdAt: [new Date(this.selectedArticle.createdAt), Validators.required],
      highlighted: [this.selectedArticle.highlighted],
      link: [this.selectedArticle.link],
    });

    // this.articleService.selectedArticle.subscribe((selectedArticle) => {
    //   this.selectedArticle = selectedArticle;
    // });

  }


  ngOnInit() {

  }

  openCalendar() {
    this.datePicker.open();
  }

  save() {

    if (!this.form.valid) {
      return;
    }

    if (this.selectedArticle.id) {
      this.articleService.editArticle({...this.selectedArticle, ...this.form.value, updatedAt: new Date().getTime()});
    } else {

      const createdAt = this.form.value.createdAt ? this.form.value.createdAt : new Date().getTime();
      this.articleService.createArticle({...this.form.value, createdAt, updatedAt: new Date().getTime()});
    }

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  onSelectedTabChanged(event) {
    this.activeTabIndex = event.index;
  }

  onArticleContentChanged(value) {
    this.form.controls['body'].patchValue(value);
  }

  deleteCoverImage() {
    const fileRef = this.storage.ref(this.selectedArticle.imageUrlFilePath);
    fileRef.delete();
    this.selectedArticle.imageUrl = '';
    this.selectedArticle.imageUrlFilePath = '';
    this.articleService.editArticle(this.selectedArticle);
  }

  onUploadFinished(event) {
    this.selectedArticle.imageUrl = event.imageUrl;
    this.selectedArticle.imageUrlFilePath = event.imageUrlFilePath;
    this.articleService.editArticle(this.selectedArticle);
  }
}
