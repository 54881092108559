import {Component, OnInit} from '@angular/core';
import {SmartTableConfigInterface} from '../../../../shared/interface';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {ReferencesModel} from '../../../../shared/models/references.model';
import {ReferenceModel} from '../../../../shared/models/reference.model';
import {ReferencesService} from '../../../../shared/service/references.service';
import {ReferenceModalComponent} from '../../../../shared/modal/reference-modal/reference-modal.component';

@Component({
  selector: 'app-reference-manager-page',
  templateUrl: './reference-manager-page.component.html',
  styleUrls: ['./reference-manager-page.component.scss']
})
export class ReferenceManagerPageComponent implements OnInit {


  tableData: ReferencesModel;
  selectedReference: ReferenceModel;
  smartTableConfig: SmartTableConfigInterface;


  constructor(public dialog: MatDialog, private referencesService: ReferencesService, private activatedRoute: ActivatedRoute) {

    this.initSmartTable();
    this.referencesService.references.subscribe((references: ReferencesModel) => {
      this.tableData = references;
    });

    this.referencesService.selectedReference.subscribe((reference: ReferenceModel) => {
      this.selectedReference = reference;
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      (data: { referencesResolve: any }) => {
        this.tableData.items = data.referencesResolve;
      }
    );
  }

  initSmartTable() {
    this.smartTableConfig = {
      columns: [
        {labelKey: 'COMMON.COVER_IMAGE', dataKey: 'imageUrl', isImage: true},
        {labelKey: 'REFERENCE.TITLE', dataKey: 'title', isImage: false},
        {labelKey: 'REFERENCE.SUMMARY', dataKey: 'summary', isImage: false},
        {labelKey: 'REFERENCE.CREATED_AT', dataKey: 'createdAt', isDate: true},
      ],
      displayedColumns: ['imageUrl', 'title', 'summary', 'createdAt']
    };
  }


  openDialog(selectedReferenceModel?: ReferenceModel) {

    const dialogRef = this.dialog.open(ReferenceModalComponent, {
      hasBackdrop: true,
      width: '60vw',
      data: selectedReferenceModel ? selectedReferenceModel : new ReferenceModel()
    });

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }


  deleteItem(event) {
    this.referencesService.deleteReference(event.item, event.dialogRef);
  }

}
