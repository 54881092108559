import {Component, OnInit} from '@angular/core';
import {SmartTableConfigInterface} from '../../../../shared/interface';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {HomeCarouselItemsModel} from '../../../../shared/models/home-carousel-items.model';
import {HomeCarouselService} from '../../../../shared/service/home-carousel.service';
import {HomeCarouselModel} from '../../../../shared/models/home-carousel.model';
import {CarouselItemModalComponent} from '../../../../shared/modal/carousel-item-modal/carousel-item-modal.component';

@Component({
  selector: 'app-article-manager',
  templateUrl: './home-carousel-manager-page.component.html',
  styleUrls: ['./home-carousel-manager-page.component.scss']
})
export class HomeCarouselManagerPage implements OnInit {
  
  tableData: HomeCarouselItemsModel;
  selectedHomeCarouselItem: HomeCarouselModel;
  smartTableConfig: SmartTableConfigInterface;


  constructor(public dialog: MatDialog, private homeCarouselService: HomeCarouselService, private activatedRoute: ActivatedRoute) {

    this.initSmartTable();
    this.homeCarouselService.homeCarouselItems.subscribe((homeCarouselItems: HomeCarouselItemsModel) => {
      this.tableData = homeCarouselItems;
    });

    this.homeCarouselService.selectedHomeCarouselItem.subscribe((homeCarouselItem: HomeCarouselModel) => {
      this.selectedHomeCarouselItem = homeCarouselItem;
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      (data: { homeCarouselItemsResolve: any }) => {
        this.tableData.items = data.homeCarouselItemsResolve;
      }
    );
  }

  initSmartTable() {
    this.smartTableConfig = {
      columns: [
        {labelKey: 'COMMON.COVER_IMAGE', dataKey: 'imageUrl', isImage: true},
        {labelKey: 'HOME_CAROUSEL.TITLE', dataKey: 'title'},
        {labelKey: 'HOME_CAROUSEL.SUMMARY', dataKey: 'summary'},
      ],
      displayedColumns: ['imageUrl', 'title', 'summary']
    };
  }


  openDialog(selectedHomeCarouselModel?: HomeCarouselModel) {

    const dialogRef = this.dialog.open(CarouselItemModalComponent, {
      hasBackdrop: true,
      width: '60vw',
      data: selectedHomeCarouselModel ? selectedHomeCarouselModel : new HomeCarouselModel()
    });

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }


  deleteItem(event) {
    this.homeCarouselService.deleteHomeCarouselItem(event.item, event.dialogRef);
  }

}
