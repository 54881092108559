import {Component, OnInit} from '@angular/core';
import {CmsService} from '../../shared/service/cms.service';
import {PartnerService} from '../../shared/service/partner.service';
import {PartnerModel} from '../../shared/models/partner.model';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  content: string;
  subcontractors: PartnerModel[] = [];
  suppliers: PartnerModel[] = [];

  constructor(private cmsService: CmsService, private partnerService: PartnerService) {
    this.cmsService.getPartnersContent().subscribe((response) => {
      this.content = response.content;
    });


    this.partnerService.getPartnerItems().subscribe((response: PartnerModel[]) => {
      this.subcontractors = response.filter((item) => !item.isSupplier);
      this.suppliers = response.filter((item) => item.isSupplier);
    });

  }

  ngOnInit() {
  }

  onContentSaved(content) {

    this.cmsService.savePartnersContent(content).subscribe((response) => {
      console.log('response', response);
    });

  }


  openURL(link) {
    if (!link) {
      return;
    }
    let win = window.open(link, '_blank');
    win.focus();
  };


}
