import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PartnerService} from '../service/partner.service';

@Injectable()
export class PartnerItemsResolver implements Resolve<any> {
  constructor(private partnerService: PartnerService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return this.partnerService.getPartnerItems();

  }
}
