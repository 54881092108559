import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {CustomPageModel} from '../../models';
import {reflectTypeEntityToDeclaration} from '@angular/compiler-cli/src/ngtsc/metadata';

@Component({
  selector: 'app-custom-page-header',
  templateUrl: './custom-page-header.component.html',
  styleUrls: ['./custom-page-header.component.scss']
})
export class CustomPageHeaderComponent implements OnInit {

  @Input() customPage: CustomPageModel;
  @Input() index: number;
  @Input() customPagePage: boolean;
  mobileSize: boolean;

  constructor(private router: Router) {

    this.mobileSize = window.innerWidth < 544;

    fromEvent(window, 'resize')
      .pipe(debounceTime(100),
      )
      .subscribe(() => {

        this.mobileSize = window.innerWidth < 544;

      });

  }

  ngOnInit() {
  }

  getLeaderBackground(customPage) {
    return {
      background: `url(${customPage.imageUrl})`,
    };
  }

  openGallery(customPage) {
    this.router.navigateByUrl('/customPage/' + customPage.id);
  }
}
