import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {GuestModel} from "../models";
import {ApiService} from "./api.service";
import {GuestsModel} from "../models/guests.model";
import {CrudUtilities} from "../util/crud-utilities";

@Injectable()
export class GuestEditorService {


  public guestsSubject = new BehaviorSubject<GuestsModel>(new GuestsModel());
  public guests = this.guestsSubject.asObservable();

  public selectedGuestSubject = new BehaviorSubject<GuestModel>(new GuestModel());
  public selectedGuest = this.selectedGuestSubject.asObservable();


  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {
  }


  setGuestList(guests: GuestsModel) {
    this.guestsSubject.next(guests);
  }


  createGuest(guest: GuestModel) {
    return this.apiService.post('hotel/guests', guest).subscribe((response) => {
      this.crudUtils.addNewItemToList(this.guestsSubject, response);
    }, () => {
      // TODO
    })
  }

  editGuest(guest: GuestModel) {
    return this.apiService
      .patch(`hotel/guests/${guest.id}`, guest).subscribe((response) => {
        this.crudUtils.editListItem(this.guestsSubject, response);
      }, () => {
        // TODO
      });
  }


  deleteGuest(guest, dialogRef) {
    return this.apiService.delete(`hotel/guests/${guest.id}`).subscribe(() => {
      this.crudUtils.deleteListItem(this.guestsSubject, guest);
      dialogRef.close();
    }, () => {
      // TODO
    })
  }

  getGuests() {
    return this.apiService.get('hotel/guests?limit=10&offset=0');
  }

  getCitiesByZipCode(zipCode) {
    return this.apiService.getFromZipCodeApi('?irsz=' + zipCode);
  }


}
