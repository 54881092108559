import {Component, Input, OnInit} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {ApplicationModel} from '../../shared/models/application.model';

@Component({
  selector: 'application-preview',
  templateUrl: './application-preview.component.html',
  styleUrls: ['./application-preview.component.scss']
})
export class ApplicationPreviewComponent implements OnInit {
  @Input() application: ApplicationModel;


  constructor(private router: Router) {
  }

  ngOnInit() {

  }


  openApplication() {
    this.router.navigateByUrl('/application/' + this.application.id);
  }


  getApplicationBg() {
    return {
      background: `url(${this.application.imageUrl})`,
    };
  }


}
