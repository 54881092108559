import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ReferencesModel} from '../models/references.model';
import {CrudUtilities} from '../util/crud-utilities';
import {map} from 'rxjs/operators';
import {ReferenceModel} from '../models/reference.model';

@Injectable()
export class ReferencesService {


  public referencesSubject = new BehaviorSubject<ReferencesModel>(new ReferencesModel());
  public references = this.referencesSubject.asObservable();

  public selectedReferenceSubject = new BehaviorSubject<ReferenceModel>(new ReferenceModel());
  public selectedReference = this.selectedReferenceSubject.asObservable();


  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {

  }

  setReferenceList(references: ReferencesModel) {
    this.referencesSubject.next(references);
  }

  createReference(reference: ReferenceModel) {
    reference.createdAt = new Date(reference.createdAt).getTime();
    return this.apiService.post('references.json', reference).subscribe((response) => {

      if (response.name) {
        reference.id = response.name;
        this.selectedReferenceSubject.next({...reference});
        this.crudUtils.addNewItemToList(this.referencesSubject, reference);
      }
    });
  }

  editReference(reference: ReferenceModel) {
    reference.createdAt = new Date(reference.createdAt).getTime();
    return this.apiService
      .patch(`references/${reference.id}.json`, reference).subscribe((response) => {
        this.selectedReferenceSubject.next({...response});
        this.crudUtils.editListItem(this.referencesSubject, response);
      });
  }

  deleteReference(reference, dialogRef) {
    return this.apiService.delete(`references/${reference.id}.json`).subscribe(() => {
      this.crudUtils.deleteListItem(this.referencesSubject, reference);
      dialogRef.close();

    });
  }

  getReferences() {
    return this.apiService.get('references.json').pipe(map((response) => {

      let list = [];
      for (let key in response) {
        response[key].id = key;
        list.push(response[key]);
      }
      return list;
    }));
  }

  getReference(id): Observable<any> {
    return this.apiService.get(`references/${id}.json`);
  }


}
