import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {CrudUtilities} from '../util/crud-utilities';
import {BehaviorSubject} from 'rxjs';
import {ArticlesModel} from '../models/articles.model';

@Injectable()
export class CmsService {

  public aboutContentSubject = new BehaviorSubject<string>('');
  public aboutContent = this.aboutContentSubject.asObservable();

  constructor(private apiService: ApiService, public crudUtils: CrudUtilities) {
  }

  saveAboutContent(content){
    return this.apiService.patch('/aboutPage.json', {content: content});
  }

  getAboutContent(){
    return this.apiService.get('/aboutPage.json').subscribe((response)=>{
      this.aboutContentSubject.next(response.content);
    });
  }

  savePartnersContent(content){
    return this.apiService.patch('/partnersPage.json', {content: content});
  }

  getPartnersContent(){
    return this.apiService.get('/partnersPage.json');
  }

  saveContactContent(content){
    return this.apiService.patch('/contactPage.json', {content: content});
  }

  getContactContent(){
    return this.apiService.get('/contactPage.json');
  }




}
